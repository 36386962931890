<template>
    <div>
        <kiosk-select @selected="askForSignature($event)"/>
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
import ReceptionApi from '@src/api/reception';
import KioskSelect from '@components/reception/kioskSelect';
export default {
    components: {
        KioskSelect
    },
    props: {
        invoiceId: {
            type: Number,
            required: false,
            default: undefined
        },

    },
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        askForSignature(kioskId) {
            ReceptionApi.requestSignature(kioskId, {invoice_id: this.invoiceId});
        }
    },
};
</script>
<style>

</style>