<template lang="html">
    <div>
        <login-password />
        <ModalLoading 
            :active="loading"
            :message="status"
        />
    </div>
</template>
<script>
import { GET_AUTH_USER } from '@src/store/actionTypes';
import LoginPassword from '@components/loginPassword';
import ModalLoading from '@components/modalLoading';

export default {
    components: { 
        LoginPassword, 
        ModalLoading 
    },
    data() {
        return {
            loading: true,
            watcherHandle: undefined,
            status: 'Athuga ef notandi er innskráður'
        };
    },
    computed: {
        userExists() {
            return this.$store.getters.userExists;
        }
    },
    beforeDestroy() {
        // Destroy watcher when component is destroyed
        if (this.watcherHandle) {
            this.watcherHandle();
        }
    },
    mounted() {
        this.registerWatcher();
        this.$store.dispatch(GET_AUTH_USER).then(() => {
            this.status = 'Notandi er innskráður';
        }).catch(() => {
            // User is not currently authenticated
            this.loading = false;
        });
    },
    methods: {
        emitAuthenticated() {
            this.$emit('authenticated');
        },
        registerWatcher() {
            // Watch user object for changes
            this.watcherHandle = this.$store.watch((state, { userExists }) => userExists, value => {
                if (value) {
                    this.emitAuthenticated();
                }
            });
        }
    },
};
</script>
