<template>
    <div class="container">
      <div class="row justify-content-center pt-5">
        <div class="col-md-8">
          <h1><i class="fal fa-loveseat fa-fw"></i> {{ $t('admin_view.destinations') }}</h1>
        </div>
      </div>
      <div v-for="destination in destinations" :key="destination.id" class="row justify-content-center">
        <div class="col-md-8 mb-5">
          <div class="card shadow-sm">
            <div class="card-header">
              <i class="fal fa-loveseat fa-fw"></i> {{ destination.name }}
            </div>
            <div class="card-body">
              <div class="form-group">
                <label for="users">{{ $t('employees.add_employee') }}</label>
                <div class="input-group">
                  <select id="users" v-model="selectedUsers[destination.id]" class="form-control">
                    <option value="x" disabled hidden>{{ $t('employees.select_an_employee') }}</option>
                    <option v-for="(user, index) in users" :key="index" :value="index">{{ user.userdata.name }}</option>
                  </select>
                  <div class="input-group-append">
                    <button class="btn btn-outline-primary" :disabled="selectedUsers[destination.id] == 'x'" @click="attachUser(destination.id)">
                      <i class="fal fa-plus fa-fw"></i> {{ $t('employees.add') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group list-group-flush">
              <div v-for="(user, index) in destination.users" :key="index" class="list-group-item">
                {{ user.userdata.name }}
                <i class="fal fa-times fa-fw close float-right" @click="detachUser(destination.id, user.id)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import LocalApi from '@src/api/local';
  import KioskApi from '@src/api/kiosk';
  
  export default {
    setup() {
      const users = ref([]);
      const destinations = ref([]);
      const selectedUsers = ref({});
  
      const getUsers = () => {
        LocalApi.getDoctorsByUnit().then(response => users.value = response.data);
      };
  
      const getDestinations = () => {
        KioskApi.getDestinations().then(response => {
          destinations.value = response.data;
          destinations.value.forEach(destination => {
            selectedUsers.value[destination.id] = 'x';
          });
        });
      };
  
      const attachUser = (destinationId) => {
        KioskApi.attachUserToDestination(destinationId, { user_id: users.value[selectedUsers.value[destinationId]].id }).then(() => {
          getDestinations();
        });
      };
  
      const detachUser = (destinationId, userId) => {
        KioskApi.detachUserFromDestination(destinationId, { user_id: userId }).then(() => {
          getDestinations();
        });
      };
  
      onMounted(() => {
        getUsers();
        getDestinations();
      });
  
      return {
        users,
        destinations,
        selectedUsers,
        attachUser,
        detachUser
      };
    }
  };
  </script>
  
  <style></style>
  
