export const PATIENT_CHANGED = 'PatientChanged';
export const PRESCRIPTION_STATE_CHANGED = 'PrescriptionStateChanged';
export const MEDICAL_DATA_REPLY = 'MedicalDataReply';
export const TEXT_VIEW_REPLY = 'TextViewReply';
export const UNIT_CHANGED = 'UnitChanged';
export const USER_LOGOUT = 'UserLogout';
export const INVOICE_STORED = 'InvoiceStored';
export const INVOICE_PRINTED = 'InvoicePrinted';

// Register events here for listener
export default [
    PATIENT_CHANGED,
    PRESCRIPTION_STATE_CHANGED,
    MEDICAL_DATA_REPLY,
    TEXT_VIEW_REPLY,
    UNIT_CHANGED,
    USER_LOGOUT,
    INVOICE_STORED,
    INVOICE_PRINTED
];