<template>
  <v-list-item class="journal-left-indent">
    <v-list-subheader>
      <strong>{{ unitName }}</strong> - {{ ownerName }}
    </v-list-subheader>
    <v-list>
      <journal-component :journalData="journalData" @edit-diagnose-item="handleEditDiagnoseItem" @edit-attention-item="handleEditAttentionItem"  @edit-note-item="handleEditNoteItem"  />
    </v-list>
  </v-list-item>
</template>
<script>
import JournalComponent from '@components/journal/journalview/journalComponent/journalComponent.vue';

export default {
  components: {
    JournalComponent
  },
  props: {
    journalData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ownerName() {
      return this.journalData.owner_name;
    },
    unitName() {
      return this.journalData.unit_name;
    }
  },
  methods: {
    handleEditDiagnoseItem(index) {
      this.$emit('edit-diagnose-item', index);

    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
     },
     handleEditNoteItem(item) {
      this.$emit('edit-note-item', item);
     },
  }
};
</script>

<style>
.journal-left-indent {
  margin-left: 30px;
}
</style>
