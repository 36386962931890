<template>
    <div class="lang-banner">
        <div class="float-right">
            <div class="d-flex justify-content-center flex-wrap">
                <div v-if="lang != 'is'" class="flex-column p-3 text-center" @click="changeLang('is')">
                    <img :src="$http.defaults.baseURL + '/img/iceland-2.png'" alt="" width="50px"><br>
                    IS
                </div>
                <div v-if="lang != 'en'" class="flex-column p-3 text-center" @click="changeLang('en')">
                    <img :src="$http.defaults.baseURL + '/img/united-kingdom.png'" alt="" width="50px"><br>
                    EN
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        lang() {
            return this.$store.state.lang;
        }
    },
    created() {},
    mounted() {},
    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.$store.dispatch('changeLang', lang);
        }
    },
};
</script>
<style>
    .lang-banner {
        position: absolute;
        top: 0px;
        right: 0px;
    }
</style>