export default {
    // Client getters
    client: state => {
        return state.client;
    },

    // Current user getters
    userExists: state => {
        return Object.keys(state.user).length > 0 && state.user.constructor === Object;
    },
    userDataExists: (state, getters) => {
        return getters.userExists && state.user.userdata instanceof Object;
    },
    userCodesExists: (state, getters) => {
        return getters.userExists && state.user.usercodes instanceof Object;
    },
    currentUserName: (state, getters)  => {
        return getters.userDataExists ? state.user.userdata.name : undefined;
    },
    currentUserEmail: state => {
        return state.user.email;
    },
    currentUserID: state => {
        return state.user.id;
    },
    currentUserTitle: (state, getters) => {
        return getters.userDataExists ? state.user.userdata.title : undefined;
    },
    currentUserDataID: (state, getters) => {
        return getters.userDataExists ? state.user.userdata.id : undefined;
    },
    currentUserUsername: state => {
        return state.user.username;
    },
    currentUserRoles: state => {
        return state.user.roles || [];
    },
    currentUserCodenumber: (state, getters) => {
        return getters.userCodesExists ? state.user.usercodes.userCodenumber : undefined;
    },
    currentUserAccess: (state, getters) => {
        return getters.currentUserRoles.reduce((acc, role) => {
            role.features.forEach(feature => acc[feature.name] = true);
            return acc;
        }, {});
    },
    currentUserCanAccess: (state, getters) => feature => {
        return getters.currentUserRoles.some(r => r.features.some(f => f.name === feature));
    },

    // Current unit getters
    unitExists: state => {
        return Object.keys(state.unit).length > 0 && state.unit.constructor === Object;
    },
    currentUnitID: state => {
        return state.unit.id;
    },
    currentUnitDepartment: state => {
        return state.unit.department;
    },
    currentUnitInstitute: state => {
        return state.unit.institute;
    },
    currentUnitAddress: state => {
        return state.unit.address;
    },
    currentUnitCodenumber: state => {
        return state.unit.codenumber;
    },

    // Current patient getters
    patientExists: state => {
        return Object.keys(state.patient).length > 0 && state.patient.constructor === Object;
    },
    patientAddressExists: (state, getters) => {
        return getters.patientExists && state.patient.address instanceof Object;
    },
    currentPatientID: state => {
        return state.patient.id;
    },
    currentPatientName: state => {
        return state.patient.name;
    },
    currentPatientCodenumber: state => {
        return state.patient.codenumber;
    },
    currentPatientAddress: (state, getters) => {
        return getters.patientAddressExists ? state.patient.address.address1 : undefined;
    },
    currentPatientAddressZip: (state, getters) => {
        return getters.patientAddressExists ? state.patient.address.zip : undefined;
    },
    currentPatientAddressCity: (state, getters) => {
        return getters.patientAddressExists ? state.patient.address.city : undefined;
    },

    // Current Journal Type getters
    currentJournalTypeID: state => {
        return state.journalType.id;
    },

    // Session Pos getters
    sessionPos: state => {
        return state.pos;
    },
    signatureType: state => {
        return state.signatureType;
    }
};