<template>
	<v-card flat>
		<v-toolbar color="transparent" >
			<v-avatar :color="getHoverColor()" @mouseenter="hovered = true" @mouseleave="hovered = false">
				<v-icon>{{ getAvatarIcon() }}</v-icon>
			</v-avatar>
			<v-toolbar-title>
				<span class="font-weight-medium">{{ journalData.data.name }}</span> {{ journalData.data.strength }}
				<span class="text-grey text-caption">{{ journalData.data.package }}.</span>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-chip prepend-icon="mdi-doctor" size="small" class="text-0">{{ journalData.data.metadata.owner_name }}</v-chip>
		</v-toolbar>
		<v-card-text class="journal-indent pt-0">
			<p>
				{{ journalData.data.dosage }}

			</p>
			<p class="mt-2">
				<span class="font-weight-bold">Valid from</span> {{ formatDate(journalData.data.start_date) }}
				<span v-if="journalData.data.end_date && journalData.data.end_date != ''"> - {{ formatDate(journalData.data.end_date) }}</span> 
			</p>
			<p v-if="journalData.data.comment && journalData.data.comment != ''" class="mt-2">
				<span class="font-weight-bold">Comment</span><br>{{ journalData.data.comment }}
			</p>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			hovered: false,
			prescriptionItems: [],
			hoveredItemIndex: null,

			hoverColor: 'blue',
			avatarIcon: 'mdi-prescription'
		};
	},
	computed: {
		transformedPrescriptionItems() {
			// console.log("prescriptin inside transformed", this.prescriptionItems)
			return this.journalData.map(item => ({
				name: item.data.name,
				strength: item.data.strength,
				dosage: item.data.dosage,
				package: item.data.package,
				start_date: item.data.start_date,
				end_date: item.data.end_date,
				owner_name: item.data.metadata.owner_name,
			}));

		}
	},
	methods: {
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave(index) {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hovered ? 'grey' : 'blue';
		},
		getAvatarIcon(index) {
			return this.hovered ? 'mdi-pencil' : 'mdi-prescription';
		},
		formatDate(date) {
			return new Date(date).toLocaleDateString();
		}
	}
};
</script>
