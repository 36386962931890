<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 mt-5">
                <h1> {{ t('kiosk.select_kiosk') }}</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 pt-5">
                <div class="card shadow">
                    <div class="card-header">Kiosk listi</div>
                    <div class="list-group-flush">
                        <div 
                            v-for="kiosk in kiosks" 
                            :key="kiosk.id" 
                            class="list-group-item list-group-item-action" 
                            @click="selectKiosk(kiosk.id)"
                        >
                            <h5 class="mb-1">{{ kiosk.name }}</h5>
                            <p class="mb-1">{{ kiosk.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
export default {
    components: {},
    props: {},
    data() {
        return {
            kiosks: []
        };
    },
    computed: {},
    created() {
        this.getKiosks();
    },
    mounted() {},
    methods: {
        t(key) {
            return this.$t(key);
        },
        getKiosks() {
            KioskApi.getAllKiosks().then(response => this.kiosks = response.data);
        }, 
        selectKiosk(id) {
            KioskApi.storeSessionKiosk(id).then(() => {
                this.$router.push({name: 'welcome'});
            });
        }
    },
};
</script>
<style></style>