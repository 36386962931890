<template>
    <div class="card border-primary mb-4">
      <div class="card-header bg-primary text-white">
        <i class="fas fa-plus-circle"></i>
        {{ $t('prescription.pharmacy') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label-basic :required="true">
                {{ $t('prescription.deliver_to') }}
              </label-basic>
              <select v-model="pharmacy" class="form-control">
                <option v-for="item in available" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group">
              <label-basic :required="true">
                {{ $t('prescription.takes_effect') }}
              </label-basic>
              <datepicker v-model="startDate" :language="is" format="d. MMM yyyy" :disabled-dates="disabledStartDates" bootstrap-styling @selected="updateDate"></datepicker>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group">
              <label-basic :required="true">
                {{ $t('prescription.valid_until') }}
              </label-basic>
              <datepicker v-model="endDate" :language="is" format="d. MMM yyyy" :disabled-dates="disabledEndDates" bootstrap-styling></datepicker>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>&nbsp;</label><br>
              <check-box-button v-model="homeDelivery" class="w-100">{{ $t('prescription.send_home') }}</check-box-button>
            </div>
          </div>
        </div>
        <div v-if="homeDelivery" class="row">
          <div class="col-md-3">
            <text-input v-model="address" :error="errors.address" :required="true" v-bind:label="$t('prescription.address')"></text-input>
          </div>
          <div class="col-md-3">
            <text-input v-model="zipcode" :error="errors.zip" :required="true" v-bind:label="$t('prescription.postal_code')"></text-input>
          </div>
          <div class="col-md-6">
            <text-input v-model="comment" v-bind:label="$t('prescription.comment_for_delivery')"></text-input>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted ,getCurrentInstance} from 'vue';
  import localService from '@src/api/local';
  import Datepicker from 'vue3-datepicker';
  import CheckBoxButton from '@components/checkBoxButton';
  import LabelBasic from '@components/labelBasic';
  import TextInput from '@components/textInput';
  import { is } from 'date-fns/locale';
  
  let now = new Date();
  
  export default {
    components: {
      Datepicker,
      CheckBoxButton,
      LabelBasic,
      TextInput
    },
    setup() {
      const available = ref([]);
      const pharmacy = ref(0);
      const address = ref('');
      const zipcode = ref('');
      const comment = ref('');
      const homeDelivery = ref(false);
      const startDate = ref(now);
    //   const endDate = ref(new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000));
      const disabledStartDates = computed(() => ({ to: now }));
      const errors = ref({ address: false, zip: false });
      const is = is;
      const endDate = ref(new Date());
  
      const disabledEndDates = computed(() => ({
        to: startDate.value,
        from: endDate.value
      }));

   

  
      const requestData = computed(() => {
        let request = {
          pharmacy_id: pharmacy.value,
          start_date: startDate.value.toISOString().split('T')[0],
          end_date:  endDate.value.toISOString().split('T')[0],
        };
        if (homeDelivery.value) {
          request.delivery = {
            address: address.value,
            zip: zipcode.value,
            comment: comment.value
          };
        }
        return request;
      });
      

      console.log(requestData.value,'________________________in pharmacy1')
  
      const updateDate = (date) => {
        endDate.value = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      };
  
      const setError = (error) => {
        errors.value[error] = true;
      };
  
      const clearError = (error) => {
        errors.value[error] = false;
      };
  
      const t = (key) => {
        return key; // Placeholder for translation function
      };
  
      onMounted(() => {
        localService.getAvailablePharmacies().then(res => {
          available.value = res.data;
        }).catch(err => {
          // Some 500 error
        });
      });
  
      return {
        available,
        pharmacy,
        address,
        zipcode,
        comment,
        homeDelivery,
        startDate,
        endDate,
        disabledStartDates,
        errors,
        is,
        disabledEndDates,
        requestData,
        updateDate,
        setError,
        clearError,
        t,
      };
    }
  };
  </script>
  