<!-- 
//changes vue3




   <template>
    <div v-if="authenticated">
        <app-view @loggedOut="onLogout" />
    </div>
    <div v-else>
        <auth :username-login="usernameLogin" @loggedIn="onLogin" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex'; // Assuming you are using Vuex for state management
import AppView from '@components/appView';
import Auth from '@components/auth';
import { SET_SESSION_LIFETIME, SET_DEBUG } from '@src/store/mutationTypes';

export default defineComponent({
    components: {
        AppView,
        Auth
    },
    props: {
        lifetime: { // Session lifetime
            type: Number,
            required: false,
            default: 720,
        },
        usernameLogin: {
            type: Boolean,
            required: false,
            default: true,
        },
        debug: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const store = useStore();
        const authenticated = ref(false);

        const onLogin = () => {
            authenticated.value = true;
        };

        const onLogout = () => {
            store.commit(SET_SESSION_LIFETIME, props.lifetime);
            store.commit(SET_DEBUG, props.debug);
            router.push({ name: 'home' });
            window.location.reload();
        };

        return {
            authenticated,
            onLogin,
            onLogout
        };
    }
});
</script> -->



<template>
    <div v-if="authenticated">
        <app-view @loggedOut="onLogout" />
    </div>
    <div v-else>
        <auth :username-login="usernameLogin" @loggedIn="onLogin" />
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex'; 
import { useRouter } from 'vue-router'; 
import AppView from '@components/appView';
import Auth from '@components/auth';
import { SET_SESSION_LIFETIME, SET_DEBUG } from '@src/store/mutationTypes';

export default defineComponent({
    components: {
        AppView,
        Auth
    },
    props: {
        lifetime: {
            type: Number,
            required: false,
            default: 720,
        },
        usernameLogin: {
            type: Boolean,
            required: false,
            default: true,
        },
        debug: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const authenticated = ref(false);

        const onLogin = () => {
            authenticated.value = true;
        };

        const onLogout = () => {
            store.commit(SET_SESSION_LIFETIME, props.lifetime);
            store.commit(SET_DEBUG, props.debug);
            router.push({ name: 'home' });
            window.location.reload();
        };

        return {
            authenticated,
            onLogin,
            onLogout
        };
    }
});
</script>
