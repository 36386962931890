<template>
    <div id="side-menu" @click="containerClicked">
      <div id="side-menu-container">
        <span
          id="side-menu-close"
          class="float-right"
          @click="onClose()"
        >
          <i class="fa fa-times" />
        </span>
        <div class="egatt-logo-small d-none d-md-block">
          <LogoEgatt />
        </div>
        <div class="row pt-4 pb-4">
          <div class="col-3 text-center">
            <router-link
              :to="{ name: 'home' }"
              class="btn btn-outline-primary pt-2 pb-2"
              :title="$t('sidebar.back_to_dashboard')"
            >
              <i class="fas fa-home-alt fa-fw fa-2x" />
            </router-link>
          </div>
          <div class="col-3 text-center">
            <a
              class="btn btn-outline-primary pt-2 pb-2"
              href="http://www.egatt.is/egatt_help.html"
              target="_blank"
              :title="$t('sidebar.instructions')"
            >
              <i class="fas fa-question fa-fw fa-2x" />
            </a>
          </div>
          <div class="col-3 text-center">
            <router-link
              :to="{ name: 'user-show' }"
              :title="$t('sidebar.user_settings')"
              class="btn btn-outline-primary pt-2 pb-2"
            >
              <i class="fas fa-cog fa-fw fa-2x" />
            </router-link>
          </div>
          <div class="col-3 text-center">
            <a
              :title="$t('sidebar.logout')"
              href=""
              class="btn btn-outline-primary pt-2 pb-2"
              @click.prevent="onLogoutClick"
            >
              <i class="far fa-sign-out fa-fw fa-2x" />
            </a>
          </div>
        </div>
  
        <div class="d-md-none">
          <NavbarPatient />
        </div>
  
        <form v-if="!client" class="form">
          <UnitSelect
            :icon-front="true"
            :units="$store.state.availableUnits"
            @change="changeUnit"
          />
        </form>
  
        <ul id="side-menu-list">
          <li>
            <router-link
              :to="{ name: 'invoice-create' }"
              class="btn btn-primary btn-block text-left pt-2 pb-2"
            >
              <i class="far fa-dollar-sign fa-fw" /> {{ $t('sidebar.new_invoice') }}
            </router-link>
          </li>
          <li>
            <router-link
              v-if="currentUserAccess['prescriptions']"
              :to="{ name: 'presc-create' }"
              class="btn btn-primary btn-block text-left pt-2 pb-2"
            >
              <i class="far fa-prescription fa-fw" /> {{ $t('sidebar.new_priscription') }}
            </router-link>
          </li>
          <li>
            <router-link
              v-if="currentUserAccess['records']"
              class="btn btn-primary btn-block text-left pt-2 pb-2"
              :to="{ name: 'patient-index' }"
              disabled
            >
              <i class="far fa-exchange fa-fw" /> {{ $t('sidebar.national_health_records') }}
            </router-link>
          </li>
          <li>
            <router-link
              v-if="currentUserAccess['drugs']"
              :to="{ name: 'presc-registry' }"
              class="btn btn-primary btn-block text-left pt-2 pb-2"
              disabled
            >
              <i class="fas fa-pills fa-fw" /> {{ $t('sidebar.drug_database') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="btn btn-primary btn-block text-left pt-2 pb-2"
              :to="{ name: 'cr-index' }"
            >
              <i class="fas fa-cash-register fa-fw" /> {{ $t('sidebar.cash_register') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="btn btn-primary btn-block text-left pt-2 pb-2"
              :to="{ name: 'reception' }"
            >
              <i class="fa fa-user-headset fa-fw" /> {{ $t('sidebar.reception') }}
            </router-link>
          </li>
          <li>
            <router-link
              class="btn btn-primary btn-block text-left pt-2 pb-2"
              :to="{ name: 'reception-signature' }"
            >
              <i class="fa fa-signature fa-fw" /> {{ $t('sidebar.signature') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import { SELECT_UNIT } from '@src/store/actionTypes';
  import localService from '../../api/local';
  import LogoEgatt from '../logoEgatt';
  import UnitSelect from '../unitSelect';
  import NavbarPatient from '../navbarPatient';
  
  export default {
    components: {
      LogoEgatt,
      UnitSelect,
      NavbarPatient
    },
    data() {
      return {
        visible: false,
        isClient: true,
        selectedUnit: null
      };
    },
    computed: {
      ...mapGetters(['client', 'currentUserAccess'])
    },
    watch: {
      $route: {
        deep: true,
        handler: function (refreshPage) {
          this.onClose();
        }
      }
    },
  
    methods: {
      containerClicked(e) {
        e.stopPropagation();
      },
      changeUnit(unit) {
        this.$store.dispatch(SELECT_UNIT, unit);
      },
      onLogoutClick() {
        localService.logout();
      },
      onClose() {
        this.$emit('close');
      }
    }
  };
  </script>  
