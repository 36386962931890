<template>
	<v-card flat>
		<template v-if="noteItems.length > 0">
			<div v-for="(item, index) in transformedNoteItems" :key="index">
				<v-toolbar color="transparent" @mouseenter="handleMouseEnter(index)"
					@mouseleave="handleMouseLeave(index)">
					<v-avatar :color="getHoverColor(index)">
						<v-icon @click="editNote(item)">{{ getAvatarIcon(index) }}</v-icon>
					</v-avatar>
					<v-toolbar-title>
						<strong>{{ $t('journal.view.note') }}</strong> {{ item.title }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
				</v-toolbar>
				<div v-for="(keyword, keyIndex) in item.cbm_keyword" :key="keyIndex">
					<v-card-title>{{ keyword.term_name }}</v-card-title>
					<v-card-text>
						{{ keyword.term_value ? keyword.term_value : keyword.text }}
					</v-card-text>
				</div>
			</div>
		</template>
	</v-card>
</template>

<script>
import { useJournalStore } from '@src/stores/journalStore';

export default {
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			noteItems: [],
			hoveredItemIndex: null,
			isNoteEditorOpen: false,
			selectedDataRowId: null,
			selectedVersion: null,


		};
	},
	computed: {
		transformedNoteItems() {
			return this.noteItems.map(item => ({
				title: item.data.title,
				owner_name: item.data.metadata.owner_name,
				cbm_keyword: item.data.cbm_keyword,
				type:item.type,
				data_row_id:item.data.data_row_id
			}));
		}
	},
	mounted() {
		const type5Items = this.journalData.items.filter(item => item.type === '5');
		this.noteItems = type5Items;
	},
	methods: {
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave(index) {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hoveredItemIndex === index ? '#EEEEEE' : 'blue';
		},
		getAvatarIcon(index) {
			return this.hoveredItemIndex === index ? 'mdi-pencil' : 'mdi-file';
		},
		noteItem() {
			const journalStore = useJournalStore();
			return journalStore.getNoteItem();

		},
		editNote(item) {

			this.$emit('edit-note-item', item);
			const journalStore = useJournalStore();
			journalStore.setNoteItem(item); // Store the item in the journal store

			if (this.isNoteEditorOpen && this.selectedDataRowId === item.data_row_id) {
				this.closeNoteEditor();
			} else {
				this.selectedDataRowId = item.data_row_id;
				this.selectedVersion = item.version;
				this.isNoteEditorOpen = true;
			}
		},
		closeNoteEditor() {
			this.isNoteEditorOpen = false;
			this.selectedDataRowId = null;
			this.selectedVersion = null;
		},
	}
};
</script>

<style scoped>
.hover-toolbar:hover .avatar {
	background-color: #EEEEEE !important;
}
</style>
