const Samtengd = () => import(/* webpackChunkName: "group-pindex" */ '@components/samtengdSjukraskraAlt');

import { GUARD_RECORDS } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/pindex', 
        component: Samtengd, 
        name: 'patient-index',
        meta: { access: GUARD_RECORDS }
    }
]