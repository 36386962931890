<template>
	<v-card>
		<v-toolbar color="transparent" @mouseenter="hovered = true" @mouseleave="hovered = false">
			<v-avatar :color="getHoverColor()">
				<v-icon @click="editNote(journalData)">{{ getAvatarIcon() }}</v-icon>
			</v-avatar>
			<v-toolbar-title>
				<strong>{{ $t('journal.view.note') }}</strong> {{ journalData.data.title }}
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-chip prepend-icon="mdi-doctor" size="small" class="text-0">{{ journalData.data.metadata.owner_name
				}}</v-chip>

		</v-toolbar>
		<div class="ml-8" v-for="(keyword, index) in journalData.data.cbm_keyword" :key="index">
			<v-card-title>{{ keyword.term_name }}</v-card-title>
			<v-card-text>
				{{ keyword.term_value && keyword.term_value != '' ? keyword.term_value : keyword.text }}
			</v-card-text>
		</div>

		<!-- Confirmation Modal -->
		<v-dialog v-model="isModalOpen" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="headline">Confirm Edit</span>
				</v-card-title>
				<v-card-text>
					Another item is being edited. Do you want to continue and lose unsaved data?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="confirmEdit">continue</v-btn>
					<v-btn color="red darken-1" text @click="cancelEdit">No</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
import { useJournalStore } from '@src/stores/journalStore';

export default {
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			isModalOpen: false,

			hovered: false,
			noteItems: [],
			hoveredItemIndex: null,
			// isNoteEditorOpen: false,
			// selectedDataRowId: null,
			// selectedVersion: null,


		};
	},
	computed: {
		// transformedNoteItems() {
		// 	return this.noteItems.map(item => ({
		// 		title: item.data.title,
		// 		owner_name: item.data.metadata.owner_name,
		// 		cbm_keyword: item.data.cbm_keyword,
		// 		type: item.type,
		// 		data_row_id: item.data.data_row_id
		// 	}));
		// }
	},
	mounted() {
		// this.noteItems = this.journalData;
	},
	methods: {
		getHoverColor(index) {
			return this.hovered ? 'gray' : 'blue';
		},
		getAvatarIcon(index) {
			return this.hovered ? 'mdi-pencil' : 'mdi-file';
		},
		// noteItem() {
		// 	const journalStore = useJournalStore();
		// 	return journalStore.getNoteItem();

		// },
		editNote(item) {

			this.$emit('edit-note-item', item);


			const journalStore = useJournalStore();

			// currently temporaray dta is also being saved , need to modify logic 
			if (journalStore.editor.dataRowId === undefined && journalStore.editor.type === undefined && journalStore.editor.templateId === undefined) {
				this.setEditor(item); // Proceed directly if both are undefined
			} else {
				this.isModalOpen = true;
			}
			// console.log("note data is",item)
			// const journalStore = useJournalStore();
			// journalStore.setNoteItem(item); // Store the item in the journal store

			// if (this.isNoteEditorOpen && this.selectedDataRowId === item.data_row_id) {
			// 	this.closeNoteEditor();
			// } else {
			// 	this.selectedDataRowId = item.data_row_id;
			// 	this.selectedVersion = item.version;
			// 	this.isNoteEditorOpen = true;
			// }
		},
		confirmEdit(item) {

			this.$emit('edit-note-item', item);

			const journalStore = useJournalStore();

			// Set editor properties using the current lab result data
			journalStore.setEditor({
				dataRowId: this.journalData.data.data_row_id,
                type: this.journalData.data.metadata.module_id,
                templateId: undefined,
			});

			journalStore.showEditor = true; // Show the editor
			this.isModalOpen = false; // Close the modal

		},
		cancelEdit() {
			this.isModalOpen = false; // Close the modal without making changes

			// Reset the editor properties
			const journalStore = useJournalStore();
			journalStore.resetEditor();
		},
		setEditor(item) {
			const journalStore = useJournalStore();

			console.log("this is set editor",this.journalData);
			// Set editor properties
			journalStore.setEditor({
                dataRowId: this.journalData.data.data_row_id,
                type: this.journalData.data.metadata.module_id,
                templateId: undefined,
            });

			console.log("set data is ",journalStore.editor);
			journalStore.showEditor = true; // Show the editor
		},
		// closeNoteEditor() {
		// 	this.isNoteEditorOpen = false;
		// 	this.selectedDataRowId = null;
		// 	this.selectedVersion = null;
		// },
	}
};
</script>

<style scoped>
.hover-toolbar:hover .avatar {
	background-color: #EEEEEE !important;
}
</style>
