// Configure doctor module getters
export default {
    doctorInvoices: state => {
        return state.invoices;
    },
    doctorPrescriptions: state => {
        return state.prescriptions;
    },
    doctorCashRegisterByID: state => id => {
        return state.cashRegisters.find(cr => cr.id === id);
    }
};