<template>
  <div class="tip-tap">
    <div v-if="editor">
      <!-- MARKS -->
      <div class="btn-group mr-2 mb-2">
        <button v-tooltip="'Bold'" :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()">
          <i class="fas fa-bold" />
        </button>
        <button v-tooltip="'Italic'" :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()">
          <i class="fal fa-italic" />
        </button>
        <button v-tooltip="'Underline'"
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()">
          <i class="fal fa-underline" />
        </button>
        <button v-tooltip="'Strikethrough'"
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()">
          <i class="fal fa-strikethrough" />
        </button>
      </div>
      <!-- HISTORY -->
      <div class="btn-group mr-2 mb-2">
        <button v-tooltip="'Undo'" class="btn btn-outline-primary" @click="editor.chain().focus().undo().run()">
          <i class="fal fa-undo" />
        </button>
        <button v-tooltip="'Redo'" class="btn btn-outline-primary" @click="editor.chain().focus().redo().run()">
          <i class="fal fa-redo" />
        </button>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit'; // imported starterkit
import Underline from '@tiptap/extension-underline';

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    initializeEditor() {
      try {
        this.editor = new Editor({
          content: this.modelValue,
          extensions: [
          Document,
          Underline,
            StarterKit, // this includes by default  Paragraph,Bold, Italic, Strike, etc.
          ],
          onUpdate: ({ editor }) => {
            this.$emit('update:modelValue', editor.getHTML());
          },
        });
      } catch (error) {
        console.error('Editor initialization error:', error);
      }
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.editor && this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue, false);
      }
    },
  },
};
</script>

<style scoped>
.tip-tap {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 200px;
}
</style>
