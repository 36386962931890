<template>
  <v-app>
    <v-container>
      <v-card>
        <v-toolbar color="blue" class="mb-3" density="compact">
          <v-app-bar-nav-icon><v-icon>mdi-microscope</v-icon></v-app-bar-nav-icon>
          <v-toolbar-title>{{ isEditMode ? $t('journal.editor.labresult.edit_lab_result') :
            $t('journal.editor.labresult.create_lab_result') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ml-lg-8" outline @click="savelabresult">
            <v-icon>mdi-floppy</v-icon>
            {{ $t('journal.editor.labresult.save') }}
          </v-btn>

          <v-btn icon @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-md-12 px-sm-6">
          <v-form>
            <v-row>
              <v-col cols="3">
                <v-select :density="formDensity" :label="$t('journal.editor.labresult.laboratory')"
                  :items="catalogOptions" v-model="selectedCatalog" />
              </v-col>
              <v-col cols="3">
                <v-select :label="$t('journal.editor.labresult.priority')" :density="formDensity" :items="priorities"
                  v-model="selectResult" />
              </v-col>
              <v-col cols="3">
                <v-select :label="$t('journal.editor.labresult.status')" :density="formDensity" :items="[
            $t('journal.editor.labresult.status_values.nil'),
            $t('journal.editor.labresult.status_values.aco'),
            $t('journal.editor.labresult.status_values.co'),
            $t('journal.editor.labresult.status_values.pco'),
            $t('journal.editor.labresult.status_values.na'),
            $t('journal.editor.labresult.status_values.pa'),
            $t('journal.editor.labresult.status_values.ppa'),
            $t('journal.editor.labresult.status_values.o'),
            $t('journal.editor.labresult.status_values.i'),
            $t('journal.editor.labresult.status_values.u'),
            $t('journal.editor.labresult.status_values.p'),
            $t('journal.editor.labresult.status_values.a'),
            $t('journal.editor.labresult.status_values.x'),
            $t('journal.editor.labresult.status_values.c'),
            $t('journal.editor.labresult.status_values.d')
          ]" v-model="selectedStatus">
                </v-select>

              </v-col>
              <v-col cols="3">
                <v-text-field :label="$t('journal.editor.labresult.lid')" :density="formDensity"
                  v-model="selectedLID" />
              </v-col>
              <v-col>
                <v-textarea :label="$t('journal.editor.labresult.comment')" rows="1" :density="formDensity"
                  v-model="comment" />
              </v-col>
            </v-row>

            <v-row v-if="state == 2">
              <v-col>
                <h3 class="d-flex justify-space-between" @mouseover="showDeleteSpecimen = true"
                  @mouseleave="showDeleteSpecimen = false">
                  <span class="my-3">{{ $t('journal.editor.labresult.specimen') }}: {{ selectedSpecimen }}</span>
                  <v-btn v-if="showDeleteSpecimen" icon="mdi-close" size="small"
                    @click="state = 1; removeAllResults()" />
                </h3>
                <v-table>
                  <thead>
                    <tr>
                      <th>{{ $t('journal.editor.labresult.analysis') }}</th>
                      <th>{{ $t('journal.editor.labresult.result') }}</th>
                      <th>{{ $t('journal.editor.labresult.unit') }}</th>
                      <th>{{ $t('journal.editor.labresult.range') }}</th>
                      <th>{{ $t('journal.editor.labresult.comment') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Loop through the results array and display each result -->
                    <tr v-for="(result, index) in results" :key="index">
                      <!-- Analysis name is not editable -->
                      <td>
                        {{ result.analysis }}
                      </td>
                      <!-- Editable fields: Result, Unit, Range, Comment -->
                      <td>
                        <div v-if="ShowEditArray[index]">
                          <p>{{ result.result }}</p>
                        </div>
                        <v-text-field v-else v-model="result.result" :density="formDensity"
                          :label="$t('journal.editor.labresult.result')" />
                      </td>

                      <td>
                        <div v-if="ShowEditArray[index]">
                          <p>{{ result.unit }}</p>
                        </div>
                        <v-text-field v-else v-model="result.unit" :density="formDensity"
                          :label="$t('journal.editor.labresult.unit')" />
                      </td>

                      <td>
                        <div v-if="ShowEditArray[index]">
                          <p>{{ result.range }}</p>
                        </div>
                        <v-text-field v-else v-model="result.range" :density="formDensity"
                          :label="$t('journal.editor.labresult.range')" />
                      </td>

                      <td>
                        <div v-if="ShowEditArray[index]">
                          <p>{{ result.comment }}</p>
                        </div>
                        <v-text-field v-else v-model="result.comment" :density="formDensity"
                          :label="$t('journal.editor.labresult.comment')" />
                      </td>

                      <td class="text-right">
                        <v-btn icon v-if="!ShowEditArray[index]" @click="saveResult(result, index)" size="small">
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn icon v-if="ShowEditArray[index]" @click="editResult(index)" size="small">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn icon @click="removeResult(index)" size="small">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td v-if="!addnew" colspan="6" class="text-center py-5" @click="addnew = true">
                        <v-btn icon="mdi-plus" />
                      </td>
                      <td v-else colspan="6" class="pt-5 py-2">
                        <v-select :label="$t('journal.editor.labresult.analysis')" prepend-icon="mdi-plus"
                          :placeholder="$t('journal.editor.labresult.select_analysis')" :items="analyses"
                          :density="formDensity" width="300" v-model="selectedAnalysis"
                          @update:model-value="addNewAnalysis" />
                      </td>
                    </tr>
                  </tfoot>
                </v-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2 v-if="state == 1" class="text-center mt-4">
                  {{ $t('journal.editor.labresult.select_specimen') }}
                </h2>
                <p v-if="state == 1" class="text-center mb-5">
                  {{ $t('journal.editor.labresult.select_specimen_started') }}
                </p>
                <v-divider v-else class="mb-5" />
                <v-select :label="$t('journal.editor.labresult.specimen')" prepend-icon="mdi-plus"
                  :placeholder="$t('journal.editor.labresult.select_specimen')" :items="specimenOptions"
                  :density="formDensity" v-model="selectedSpecimen" @update:model-value="state = 2" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar :timeout="2000" v-model="snackbar" color="primary" rounded="pill" :style="{ marginBottom: '5%' }"
        class="d-flex justify-center">
        <div class="text-center w-100">{{ snackbarMessage }}</div>
      </v-snackbar>
    </v-container>

  </v-app>
</template>


<script setup>
import { ref, onMounted, watch } from 'vue';
import { useJournalStore } from '@src/stores/journalStore';
import labResult from '@src/api/labResult';
import labcatalog from '@src/api/labCatalog';
import labSpecimen from '@src/api/labSpecimen';
import { useStore } from 'vuex';



const props = defineProps({
  name: 'labResultEditor',
  dataRowId: {
    type: Number,
    default: null
  },
  version: {
    type: Number,
    default: null
  },
})

const store = useStore();
const formDensity = ref('compact')

const journalStore = useJournalStore();
const showDialog = ref(true);
const selectedSpecimen = ref(null);
const specimenOptions = ref([]);

const selectedCatalog = ref(null);
const catalogOptions = ref([]);

const analyses = ref([]);

const selectResult = ref(null);
const selectedStatus = ref(null);
const processedStatus = ref(null);

const selectedLID = ref(null);
const comment = ref(null);

const priorities = ref(['R', 'S']);

const state = ref(1);

const addnew = ref(false);
const showAnalysis = ref(false);

const showDeleteSpecimen = ref(false);

const results = ref([]);

const selectedAnalysis = ref(null);
// Array to track ShowEdit state for each row
const ShowEditArray = ref(results.value.map(() => false));
const ShowEdit = ref(false);


const patient_id = store.getters.currentPatientID;
const journal_type_id = store.getters.currentJournalTypeID;
const unit_id = store.getters.currentUnitID;
const userDataID = store.getters.currentUserDataID;


const dataRowID = ref(props.dataRowId);
const version = ref(props.version);

const snackbarMessage = ref('');
const isEditMode = ref(false);
const snackbar = ref(false);



const savelabresult = async () => {

  const labresultdata = {

    referred_by: 111111119,
    priority: selectResult.value,
    status: processedStatus.value,
    lid: selectedLID.value,
    comment: comment.value,
    specimen: selectedSpecimen ? selectedSpecimen._rawValue : null,
    result_group: [
      {

        specimen: selectedSpecimen ? selectedSpecimen._rawValue : null,
        group_id: 1,
        sample_time: 0,
        items: results.value.map((result, index) => ({
          sort_order: index + 1,
          group_id: 1,
          analysis: result.analysis,
          result: result.result,
          unit: result.unit,
          range: result.range,
          analysis_comment: result.comment
        }))
      }
    ],
    patient_id: 1, // used from Vuex store
    journal_type_id: journal_type_id, // used from Vuex store
    unit_id: unit_id, // used from Vuex store
    deleted: 0,
    sign_wanted: 0,
    private: 0,
    data_row_origin_id: 1,
    created_by: userDataID, // used from Vuex store
    owned_by: userDataID, // used from Vuex store
    data_date: null,
    signed_by: null,
    signed_date: null,
    counter_signed_by: null,
    counter_signed_date: null,
    draft: 0,
    use_time: 0,
    autosave: 1

  };

  try {
    if (!dataRowID.value && !version.value) {


      const response = await labResult.store(labresultdata);
      const newlabresult = response;
      dataRowID.value = newlabresult.data.DataRowID;
      version.value = newlabresult.data.Version;
      snackbarMessage.value = 'LabResult created successfully!';
      isEditMode.value = true;


    } else {

      await labResult.update(dataRowID.value, labresultdata);
      snackbarMessage.value = 'labResult updated successfully!';


      // handleClose();
    }
    snackbar.value = true;
  } catch (error) {
    console.error('Failed to save or update labResult:', error);
  }
}


const fetchLabResult = async () => {

  try {
    if (dataRowID.value) {
      const response = await labResult.show(dataRowID.value);
      const fetchedLabResult = response.data;

      selectedSpecimen.value = fetchedLabResult.lab_result_group[0].specimen;
      selectResult.value = fetchedLabResult.lab.Priority;
      selectedStatus.value = fetchedLabResult.status;
      selectedLID.value = fetchedLabResult.lid;
      comment.value = fetchedLabResult.lab.Comment;

      results.value = fetchedLabResult.lab_result_group[0].items.map(item => ({
        analysis: item.analysis,
        result: item.result,
        unit: item.unit,
        range: item.range,
        comment: item.analysis_comment
      }));


      // Update the ShowEditArray to reflect initial state
      ShowEditArray.value = results.value.map(() => true);

      state.value = 2;

      snackbarMessage.value = 'LabResult loaded successfully!';
      isEditMode.value = true;

    } else {
      // Handle case when dataRowID is not set
      console.log('No DataRowID set for fetching lab result.');
    }
  } catch (error) {
    console.error('Failed to fetch labResult:', error);
  }
};
const emit = defineEmits(['close']);
const handleClose = () => {
  closelabResultEditor();
  emit('close');
};

const addNewAnalysis = () => {
  if (selectedAnalysis.value) {
    results.value.push({
      analysis: selectedAnalysis.value,
      result: '',
      unit: '',
      range: '',
      comment: ''
    });
    selectedAnalysis.value = null;
    addnew.value = false;
  }
};


const saveResult = (result, index) => {
  ShowEditArray.value[index] = true;
};

const editResult = (index) => {
  // Toggle ShowEdit state back to false to enter edit mode
  ShowEditArray.value[index] = false;
};

const clearRow = (index) => {
  results.value[index].result = '';
  results.value[index].unit = '';
  results.value[index].range = '';
  results.value[index].comment = '';
};

const closelabResultEditor = () => {
  journalStore.resetEditor();
  showDialog.value = false;
};

const removeResult = (index) => {

  results.value.splice(index, 1);
  ShowEditArray.value.splice(index, 1);
};

//removing all analyses
const removeAllResults = () => {
  results.value = [];
  ShowEditArray.value = [];
};

const params = {
  unit_id: 1,
  with_analyses: 1
};


const fetchlabspecimen = async () => {
  try {
    const response = await labSpecimen.index(params);
    specimenOptions.value = response.data.map(specimen => specimen.name);
  } catch (error) {
    console.error('Failed to fetch lab specimen:', error);

  }
};


const fetchLabCatalog = async () => {
  try {
    // Fetch data from the API
    const response = await labcatalog.index(params);
    const data = response.data;

    // Clear existing options and analyses
    catalogOptions.value = [];
    analyses.value = [];

    // Use a Set to prevent duplicate analysis names
    const analysisSet = new Set();

    data.forEach(item => {
      // Retrieve Address Name and LabCatalog Name
      const addressName = item.address.length > 0 ? item.address[0].name : '';
      const formattedName = addressName ? `${addressName} - ${item.name}` : item.name;

      // Push the formatted name into catalog options
      catalogOptions.value.push(formattedName);

      // Collect analyses by specimen for the selected catalog
      item.specimen.forEach(specimen => {
        // Extract analysis names for this specimen and add them to analyses
        specimen.lab_analysis.forEach(analysis => {
          analyses.value.push(analysis.analysis);
        });
      });
    });


  } catch (error) {
    console.error('Failed to fetch lab catalog:', error);
  }
};

// this is done to select only status code from the dropdown and only thos 3 digit will be stored in databse
watch(selectedStatus, (newValue) => {
  if (newValue) {
    processedStatus.value = newValue.split('-')[0]; // Extract the part before '-'
  } else {
    processedStatus.value = null;
  }
});



onMounted(() => {
  // Fetch necessary data when the component mounts
  fetchlabspecimen();
  fetchLabCatalog();
  fetchLabResult();
});

</script>

<style scoped>
.fill-height {
  height: 100%;
  width: 100%;
}
</style>
