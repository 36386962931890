<template>
  <v-app>
    <v-container>
      <v-card v-if="showDialog">
        <v-toolbar color="blue" class="mb-3" density="compact">
          <v-app-bar-nav-icon><v-icon>mdi-file-plus</v-icon></v-app-bar-nav-icon>
          <v-toolbar-title>{{ isEditMode ? "Edit Note" : "Create Note" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ml-lg-8" outline @click="saveNotes">
            <v-icon>mdi-floppy</v-icon>
            Save
          </v-btn>
          <v-btn icon @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-md-12 px-sm-6">
          <v-form>
            <h3 v-if="!editTitle" class="d-flex justify-space-between text-h4" @mouseover="displayPencil = true"
              @mouseleave="displayPencil = false">
              <span class="my-1">{{ msg }}</span>
              <v-btn v-if="displayPencil" icon @click="editTitle = true"
                variant="text"><v-icon>mdi-pencil</v-icon></v-btn>
            </h3>
            <v-text-field v-else v-model="msg" :density="formDensity" label="Title" :variant="formVariant"
              append-icon="mdi-check" @click:append="editTitle = false"></v-text-field>
            <v-container>
              <component v-for="(keyword, index) in sortedKeywords" :is="getComponentType(keyword)" :key="index"
                :keywordData="keyword" @update:keywordData="onKeywordDataUpdate(index, $event)" />


            </v-container>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar :timeout="2000" v-model="snackbar" color="primary" rounded="pill" :style="{ marginBottom: '5%' }"
        class="d-flex justify-center">
        <div class="text-center w-100">{{ snackbarMessage }}</div>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import { ref, onMounted, defineComponent, computed } from 'vue';
import KeywordValue from '@src/api/keywordvalue';
import DateValue from '@src/components/journal/journalEditor/noteEditor/DateValue/DateValue.vue';
import MultipleValues from '@src/components/journal/journalEditor/noteEditor/MultipleValues/MultipleValues.vue';
import SingleValue from '@src/components/journal/journalEditor/noteEditor/SingleValue/SingleValue.vue';
import Numeric from '@src/components/journal/journalEditor/noteEditor/Numeric/Numeric.vue';
import UnformattedText from '@src/components/journal/journalEditor/noteEditor/UnformattedText/UnformattedText.vue';
import RegExValue from '@src/components/journal/journalEditor/noteEditor/RegExValue/RegExValue.vue';
import FormattedText from '@src/components/journal/journalEditor/noteEditor/FormattedText/FormattedText.vue';
import Note from '@src/api/note';
import { useStore } from 'vuex';
import { useJournalStore } from '@src/stores/journalStore';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'noteEditor',
  components: {
    DateValue,
    MultipleValues,
    SingleValue,
    Numeric,
    UnformattedText,
    RegExValue,
    FormattedText,
  },
  props: {
    dataRowId: {
      type: String,
      default: null
    },
  },

  setup(props, { emit }) {
    const journalStore = useJournalStore();
    const showDialog = ref(true);
    const router = useRouter();

    const msg = ref('Admission note');
    const formVariant = ref('solo');
    const formDensity = ref('compact');
    const editTitle = ref(false);
    const displayPencil = ref(false);
    const cbmKeywords = ref([]);
    const dataRowId = ref(props.dataRowId);
    const isEditMode = ref(false);
    const snackbar = ref(false);
    const snackbarMessage = ref('');
    const store = useStore();
    const journal_type_id = store.getters.currentJournalTypeID;
    const unit_id = store.getters.currentUnitID;
    const userDataID = store.getters.currentUserDataID;

    const fetchDataWithParams = async (api, params) => {
      return await api.index(params);
    };

    const handleClose = () => {
      closeNoteEditor();
      emit('close');
      localStorage.removeItem("templateId");
    };

    const closeNoteEditor = () => {
      journalStore.clearNoteItem();
      showDialog.value = false;
    };

    const saveNotes = async () => {
      const apiPayload = createApiPayload();
      const NotesData = {
        title: msg.value,
        version_update: 0,
        patient_id: 1,
        journal_type_id: journal_type_id,
        unit_id: unit_id,
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID,
        owned_by: userDataID,
        data_date: null,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: 0,
        use_time: 0,
        cbm_keyword: apiPayload.cbm_keyword
      };
      try {
        if (!dataRowId.value) {
          const response = await Note.store(NotesData);
          dataRowId.value = response.DataRowID;
          snackbarMessage.value = 'Note created successfully!';
        } else {
          await Note.update(dataRowId.value, NotesData);
          snackbarMessage.value = 'Note updated successfully!';
          isEditMode.value = true;
        }
        snackbar.value = true;
        // Call loadNoteData after saving
        await loadNoteData();
      } catch (error) {
        console.error('Failed to save or update note:', error);
        snackbarMessage.value = 'Failed to save or update note!';
        snackbar.value = true;
      }
    };

    const loadNoteData = async () => {

      if (dataRowId.value != null) {
        isEditMode.value = true;

        try {
          const response = await Note.show(dataRowId.value, { data_row_id: dataRowId.value });
          if (response.data && response.data[0] && response.data[0].title) {
            msg.value = response.data[0].title;
          }

          cbmKeywords.value = response.data[0].cbm_keyword;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        const templateId = localStorage.getItem('templateId');
        const response = await fetch(`/note/template/${templateId}`);
        const result = await response.json();
        msg.value = result.data.template_name;
        cbmKeywords.value = result.data.template_terms;
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      }
    };

    onMounted(() => {
      loadNoteData();
    });

    const sortedKeywords = computed(() => {
      return cbmKeywords.value.slice().sort((a, b) => a.sort_order - b.sort_order);
    });

    const getComponentType = (keyword) => {
      if (dataRowId.value != null) {
        if (!keyword.keyword_valid) {
          return 'UnformattedText';
        } else {
          switch (keyword.keyword_valid.input_type) {
            case "N":
              return 'Numeric';
            case "S":
              return 'SingleValue';
            case "M":
              return 'MultipleValues';
            case "R":
              return 'RegExValue';
            case "D":
              return 'DateValue';
            case "T":
              return 'FormattedText';
            default:
              return 'UnformattedText';
          }
        }
      } else {
        if (!keyword.input_type) {
          return 'UnformattedText';
        } else {
          switch (keyword.input_type) {
            case "N":
              return 'Numeric';
            case "S":
              return 'SingleValue';
            case "M":
              return 'MultipleValues';
            case "R":
              return 'RegExValue';
            case "D":
              return 'DateValue';
            case "T":
              return 'FormattedText';
            default:
              return 'UnformattedText';
          }
        }
      }
    };

    const createApiPayload = () => {
      return {
        cbm_keyword: cbmKeywords.value.map(keyword => ({
          sort_order: keyword.sort_order,
          parent_cbm_key_id: dataRowId.value ? keyword.parent_cbm_key_id : keyword.parent_tterm_id,
          term_id: keyword.term_id,
          term_name: keyword.term_name,
          cbm_key_id: dataRowId.value ? keyword.cbm_key_id : keyword.tterm_id,
          term_value: keyword.term_value,
          text: keyword.text
        }))
      };
    };

    const onKeywordDataUpdate = (index, updatedData) => {
      cbmKeywords.value[index] = updatedData;
    };

    return {
      msg,
      formVariant,
      formDensity,
      editTitle,
      displayPencil,
      cbmKeywords,
      sortedKeywords,
      getComponentType,
      saveNotes,
      onKeywordDataUpdate,
      snackbar,
      snackbarMessage,
      handleClose,
      showDialog,
      dataRowId,
      isEditMode
    };
  }
});
</script>


<style scoped>
/* Your styles here */
</style>
