<template>
    <div :class="wrapperClass">
        <div v-if="!showSelectPatient"
            class="mb-auto h-100 bg-primary text-white p-4 rounded clickable-cursor patient-panel"
            @click="toggleShowSelectPatient()">
            <i class="fal fa-clipboard-user fa-5x mb-2" />
            <i v-if="currentUserAccess.admin || currentUserAccess.billing" v-tooltip="t('patients_dashboard.view_all_invoice')"
                class="fas fa-box-usd fa-2x float-right" @click.stop="onPatientInvoiceClick()" /> <br>
            <!-- second class should be undirskrift icon (where it says fa-box-usd) 
                                Function should obvoiusly take us to signatures with the patient as the default.-->
            <i 
            	v-if="currentUserAccess.admin || currentUserAccess.signatures"
                v-tooltip="'Skoða allar undirskriftir þjónustuþega'" 
                class="fas fa-signature fa-2x float-right" 
                @click.stop="onPatientSignatureClick()" 
            />  
            <h2 v-if="currentPatientName">{{ currentPatientName }}</h2>
            <h3 v-else class="text-center">
                <i class="fal fa-exclamation-triangle" /> {{ t('patients_dashboard.no_patient_selected') }}

            </h3>
            <p v-if="currentPatientCodenumber">
                Kennitala: {{ $filters.codenumber(currentPatientCodenumber) }} <br>
                {{ currentPatientAddress }} <br>
                {{ currentPatientAddressZip }} {{ currentPatientAddressCity }}
            </p>
        </div>
        <div v-else class="mb-auto h-100 bg-warning p-4 rounded clickable-cursor" @click="toggleShowSelectPatient()">
            <i class="fal fa-users fa-5x mb-2" />
            <patient-change button-class="btn btn-secondary" @update="onPatientChange()" @click.stop />
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import PatientChange from '@components/patient/patientChange/';

export default {
    components: {
        PatientChange
    },
    props: {
        wrapperClass: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            showSelectPatient: false
        };
    },
    computed: {
        ...mapGetters([
            'currentUserAccess',
            'currentPatientName',
            'currentPatientCodenumber',
            'currentPatientAddress',
            'currentPatientAddressZip',
            'currentPatientAddressCity'
        ])
    },
    created() { },
    mounted() { },
    methods: {
        t(key) {
            return this.$t(key);
        },
        toggleShowSelectPatient() {
            this.showSelectPatient = !this.showSelectPatient;
        },
        onPatientInvoiceClick() {
            this.$router.push({ name: 'invoice-patient' });
        },
        onPatientSignatureClick() {
            this.$router.push({name: 'signature-overview'}); // params: {id: signatureType.id} for signature-show, but make it work for patient id as well
        },
        onPatientChange() {
            this.showSelectPatient = false;
        }
    },
};
</script>
<style></style>