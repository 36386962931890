<template>
    <div>
        <div v-if="invoices && invoices.length > 0">
            <h3 class="mb-4">{{ $t('invoices.invoices') }}</h3>
            <div v-for="(invoice, index) in invoices" :key="invoice.id" class="card mb-4 shadow bg-info">
                <div class="card-body py-5 text-white" @click="$emit('select', index)">
                    <div class="row">
                        <div class="col-7">
                            <h5>
                                <i class="fas fa-user-md fa-fw text-primary" />
                                {{ invoice.doctor.userdata.name }}
                            </h5>
                        </div>
                        <div class="col-5 text-right">
                            <h5 class="font-weight-bold">
                                {{ $filters.currency($filters.scale(invoice.patient_share)) }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="alert alert-warning">
            <h4 class="alert-heading">
                {{ $t('invoices.not_found') }}
            </h4>
            <p>
                <i class="fal fa-info-circle fa-fw" />
                {{ $t('invoices.contact_reception') }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        invoices: {
            type: Array,
            required: true
        },
    },
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
<style></style>