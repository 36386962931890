<template>
    <div :class="'kiosk-appointments d-flex align-content-center flex-wrap ' + color" @click="goBackToWelcome()">
        <div class="container">
            <div class="row justify-content-center">
                <div v-if="appointment" class="col-12">
                    <h1 class="text-center mb-5">{{ appointment.message }}</h1>
                    <h2 class="text-center mb-5">{{ appointment.detail }}</h2>
                    <h4 class="text-center">{{ appointment.todo }}</h4>
                </div>
                <div v-else class="col-12">
                    <h1 class="text-center mb-5"><i class="fas fa-circle-notch fa-spin fa-5x" /> </h1>
                    <h4 class="text-center">{{ $t('appointment.todo') }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
export default {
    components: {},
    props: {},
    data() {
        return {
            appointment: undefined
        };
    },
    computed: {
        patient() {
            return this.$store.state.patient;
        },
        lang() {
            return this.$store.state.lang;
        },
        color() {
            if (!this.appointment) { return 'bg-info';}
            if (this.appointment.type == 'appointment') {
                return 'bg-success';
            } else {
                return 'bg-danger';
            }
        }
    },
    created() {
        this.getAppointment();
        setTimeout(() => {
            this.goBackToWelcome();
        }, 30000);
    },
    methods: {
        getAppointment() {
            KioskApi.getAppointment(this.patient.id, {lang: this.lang}).then(response => this.appointment = response);
        },
        goBackToWelcome() {
            this.$router.push({name: 'welcome'});
        }
    },
};
</script>
<style></style>