<template>
  
  <v-container style="  width:100%;">
    <template v-slot:default style=" width:100%;">

      <v-dialog v-model="showDialog" scrollable style=" width:100%;">

        <template v-slot:activator="{ props: activatorProps }" style=" width:100%;">

          <!-- List of selected items -->
          <v-container style=" width:100%;  ">

            <v-list style=" display: flex; flex-direction: column; align-items:center; ">

              <v-list-item v-for=" (selectedItem, index) in selectedItems" :key="index" :title="selectedItem.title"
                style=" display: flex; flex-direction: column; ">

                <template v-slot:prepend>
                  <div style="display: flex; align-items: center; ">
                    <v-avatar :color="hoveredIndex === index ? 'grey-lighten-1' : 'blue'"
                      @mouseover="setHoveredIndex(index)" @mouseleave="clearHoveredIndex()">
                      <v-icon color="white" @click="toggleForm(index)">
                        {{ hoveredIndex === index ? 'mdi-pencil' : 'mdi-file' }}
                      </v-icon>
                    </v-avatar>
                    <div style="margin-left: 8px;">
                      <strong>{{ selectedItem.code }}</strong>
                      {{ selectedItem.name }}
                    </div>
                    <v-icon class="mr-2" @click="removeItem(index)">mdi-close</v-icon>
                  </div>
                </template>

                <v-container style=" width:100%;  ">
                  <template v-slot:default v-if="showForm === index">

                    <v-card class="elevation-3 ma-2" style="width: 90%;  ">
                      <v-toolbar color="transparent" class="d-flex justify-content-between">
                        <v-app-bar-nav-icon icon="mdi-pencil" color="blue"></v-app-bar-nav-icon>
                        <strong class="mr-2">{{ selectedItem.code }} </strong>

                        <v-card-text style="width:100%; ">
                          {{ selectedItem.name }}
                        </v-card-text>

                        <v-btn prepend-icon="mdi-content-save" variant="tonal" color="primary" class="mr-2"
                          @click="saveItem(selectedItem)">
                          Save
                        </v-btn>

                        <v-btn icon="mdi-close" @click="closeForm(index)"></v-btn>
                      </v-toolbar>

                      <v-card-text>
                        <v-form
                          style="display: flex; flex-direction: column;justify-content: space-evenly ;align-items: center; ">
                          <v-row style="display: flex; flex-direction: column;width: 96%;">
                            <v-text-field v-model="selectedItem.code" label="Code" style="width: 98%;" />
                            <v-text-field v-model="selectedItem.name" label="Name" style="width: 98%;" />
                            <v-textarea v-model="selectedItem.comment" label="Comment" style="width: 98%;" />
                            <v-select v-model="selectedType" label="Select Diagnose Type"
                              :items="['Chronic', 'Main', 'Secondary']"></v-select>
                            <v-select v-if="selectedType === 'Chronic'" v-model="selectedYear" label="Since"
                              :items="years"></v-select>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-container>
              </v-list-item>
            </v-list>
          </v-container>
          <!-- Add empty diagnose form -->
          <center>
            <v-container style=" width:100%;  ">
              <template v-slot:default v-if="showEmptyDiagnose">

                <v-card class="elevation-3 ma-2" style="width: 90%;  ">
                  <v-toolbar color="transparent" class="d-flex justify-content-between">
                    <v-text style=" margin-left: 4%"><strong>{{ isEditMode ? 'Edit'
        : 'Create'
                        }} </strong></v-text>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-snackbar :timeout="2000" v-model="isSnackbarVisible" color="primary" variant="tonal"
                      rounded="pill" :style="{ marginBottom: '8%', textAlign: 'center' }">
                      <template v-slot:activator="{ props }">
                        <v-btn class="ma-2" color="primary" variant="tonal" v-bind="props"
                          prepend-icon="mdi-content-save" @click="addDiagnose(index)">Save</v-btn>
                      </template>
                      {{ snackbarMessage }}
                    </v-snackbar>


                    <v-btn icon="mdi-close" @click="closeEmptyDiagnose(index)"></v-btn>
                  </v-toolbar>

                  <v-card-text>
                    <v-form
                      style="display: flex; flex-direction: column;justify-content: space-evenly ;align-items: center; ">

                      <v-row style="display: flex; flex-direction: column;width: 96%;">
                        <v-text-field label="Code" v-model="code" style="width: 98%;" />
                        <v-text-field label="Name" v-model="name" style="width: 98%;" />
                        <v-textarea label="Comment" v-model="comment" style="width: 98%;" />
                        <v-select v-model="selectedType" label="Select Diagnose Type"
                          :items="['Chronic', 'Main', 'Secondary']"></v-select>
                        <v-select v-if="selectedType === 'Chronic'" v-model="selectedYear" label="Since"
                          :items="years"></v-select>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </template>
            </v-container>

            <!--Edit Existing Diagnose form -->
            <v-container>
              <template v-slot:default v-if="dataRowId !== null && version !== null">

                <v-card class="elevation-3 ma-2" style="width: 90%;  ">
                  <v-toolbar color="transparent" class="d-flex justify-space-between align-center">
                    <v-text style=" margin-left: 4%"><strong>Edit</strong></v-text>
                    <v-spacer></v-spacer>

                    <v-snackbar :timeout="2000" v-model="isSnackbarVisible" color="primary" variant="tonal"
                      rounded="pill" :style="{ marginBottom: '8%', textAlign: 'center' }">
                      <template v-slot:activator="{ props }">
                        <v-btn class="ma-2" color="primary" variant="tonal" v-bind="props"
                          prepend-icon="mdi-content-save" @click="SaveExistingDiagnose(index)">Save</v-btn>
                      </template>
                      {{ snackbarMessage }}
                    </v-snackbar>


                    <v-btn icon="mdi-close" @click="closeExistingDiagnose(index)"></v-btn>
                  </v-toolbar>

                  <v-card-text>
                    <v-form
                      style="display: flex; flex-direction: column;justify-content: space-evenly ;align-items: center; ">

                      <v-row style="display: flex; flex-direction: column;width: 96%;">
                        <v-text-field label="Code" v-model="selectedCode" style="width: 98%;" />
                        <v-text-field label="Name" v-model="selectedName" style="width: 98%;" />
                        <v-textarea label="Comment" v-model="comment" style="width: 98%;" />
                        <v-select v-model="selectedType" label="Select Diagnose Type"
                          :items="['Chronic', 'Main', 'Secondary']"></v-select>
                        <v-select v-if="selectedType === 'Chronic'" v-model="selectedYear" label="Since"
                          :items="years"></v-select>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </template>
            </v-container>

            <!-- Add button -->
            <v-btn v-bind="activatorProps" color="primary" prepend-icon="mdi-plus" text="Add diagnosis"
              variant="tonal"></v-btn>
          </center>

        </template>

        <!-- Code for the Modal  -->

        <template v-slot:default="{ isActive }">
          <v-card>
            <v-toolbar>
              <v-app-bar-nav-icon icon="mdi-file" color="blue"></v-app-bar-nav-icon>
              <v-toolbar-title>Add diagnosis</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon="mdi-close" @click="showDialog = false"></v-btn>
            </v-toolbar>

            <v-card-text>
              <!-- Search Bar for Diagnose  -->
              <v-text-field v-model="searchInput" prepend-inner-icon="mdi-magnify" append-inner-icon="mdi-close"
                density="compact" label="Search diagnosis by name or code" variant="solo" hide-details single-line
                @click:append-inner="clearSearch"></v-text-field>

              <template v-for="(item, index) in searchData" :key="index">

                <v-list style="cursor:pointer" @click="updateDiagnosisExample(item)" v-if="searchInput != ''"
                  class="mt-4">
                  <v-icon>mdi-file
                  </v-icon>
                  <span :style="{ color: isSelected(item) ? '#1E66C1' : 'black' }">
                    {{ item.code }}
                    {{ item.name }}
                  </span>
                  <br><br>
                </v-list>
              </template>

              <!-- TreeView Code Starts  -->
              <v-treeview :items="FinalItems" item-value="code" class="mt-4"
                v-if="searchData == '' || searchInput == ''">
                <template v-slot:prepend="{ item, open }">
                  <a @click="updateDiagnosisExample(item)">
                    <v-icon v-if="item.dia_folder_id">
                      {{ open ? 'mdi-file-open' : 'mdi-file' }}
                    </v-icon>
                    <v-icon v-else>mdi-folder</v-icon>
                    &nbsp;
                    <span :style="{ color: isSelected(item) ? 'green' : 'black' }">
                      {{ item.code }}
                    </span>
                  </a>
                </template>
                <template v-slot:title="{ item }" class="pl-5">
                  <a @click="updateDiagnosisExample(item)">
                    <span :style="{ color: isSelected(item) ? 'green' : 'black' }">

                      {{ item.name }}
                    </span>

                  </a>
                </template>
              </v-treeview>
            </v-card-text>

            <!-- Different Action Buttons  -->
            <v-card-actions>
              <v-btn color="primary" prepend-icon="mdi-plus" text="Add empty" @click="addEmptyDiagnosis"></v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue" prepend-icon="mdi-check" text="Add" @click="addSelectedItemToList()"
                variant="flat"></v-btn>
              <v-btn color="primary" prepend-icon="mdi-close" text="Close" @click="closeDiagnosis"></v-btn>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" color="blue" append-icon="mdi-chevron-up">{{ selectedDiaListName ||
        'Select DiaList' }}</v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in diaLists" :key="index" :value="index"
                    @click="updateSelectedDiaListName(item.name, item.id)">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import { ref, defineComponent, onMounted, computed, watch } from 'vue';
import dialist from '@src/api/dialist';
import diaItem from '@src/api/diaItem';
import diaFolder from '@src/api/diaFolder';
import diagnose from '@src/api/diagnose'
import { useStore } from 'vuex';
import { defineProps } from 'vue';

export default defineComponent({

  props: {
    dataRowId: {
      type: String,
      default: null
    },
    version: {
      type: Number,
      default: null
    }
  },
  name: 'DiagnoseSearch',
  setup(props) {

    const showDialog = ref(false);
    const showEmptyDiagnose = ref(false);

    const selectedItems = ref([]);
    const selectedType = ref(null);
    const selectedYear = ref(null);
    const years = ref([2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]);
    const hoveredIndex = ref(null);
    const showForm = ref(-1);
    const snackbarMessage = ref('');
    const isSnackbarVisible = ref(false)
    const diagnosisExample = ref([]);
    const diaLists = ref([]);
    const diaItems = ref([]);
    const ItemFolder = ref();
    const initialChild = ref();
    const temporarySelection = ref([]);
    const subItem = ref();
    const store = useStore();
    const selectedDiaListName = ref('');
    const params = ref({ dia_list_id: 1 });
    const dia_folder_params = ref({ parent_folder_id: 0, dia_list_id: 1, with_items: 1 })
    const code = ref('')
    const name = ref('')
    const comment = ref('')
    const searchInput = ref('');
    const searchData = ref([]);
    const isEditMode = ref(false);
    const data_Row_ID = ref('');
    const dataRowId = ref(props.dataRowId)
    const version = ref(props.version)

    const selectedCode = ref('');
    const selectedName = ref('');



    const clearSearch = () => {
      searchInput.value = '';
      searchData.value = [];
    };

    const fetchSearchData = async (searchItems) => {
      if (searchItems === '') {
        searchData.value = [];
        return;
      }
      else {
        const apiResponse = await fetchDataWithParams(diaItem, { dia_list_id: params.value.dia_list_id, search: searchItems });
        searchData.value = apiResponse.data;
      }

    };

    watch(searchInput, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        fetchSearchData(newValue);
      }
    });

    const setHoveredIndex = (index) => {
      hoveredIndex.value = index;
    };

    const clearHoveredIndex = () => {
      hoveredIndex.value = null;
    };

    const toggleForm = (index) => {
      if (showForm.value === index) {
        showForm.value = -1;
      } else {
        showForm.value = index;
      }
    };

    const closeForm = () => {
      showForm.value = -1;
    }

    const closeEmptyDiagnose = () => {
      showEmptyDiagnose.value = false
      code.value = ''
      name.value = ''
      comment.value = ''
      selectedType.value = ''
      selectedYear.value = ''
    }

    const closeExistingDiagnose = () => {
      dataRowId.value = null
      version.value = null
    }

    const journal_type_id = store.getters.currentJournalTypeID;
    const unit_id = store.getters.currentUnitID;
    const userDataID = store.getters.currentUserDataID;

    const SaveExistingDiagnose = async () => {


      let chronicDiaValue = "0";
      let mainDiaValue = "0";
      let secDiaValue = "0";


      if (selectedType.value === "Main") {
        mainDiaValue = "1";

      }
      else if (selectedType.value === "Chronic") {
        chronicDiaValue = "1";

      }
      else if (selectedType.value === "Secondary") {
        secDiaValue = "1"

      }
      const diagnoseData = {

        code: selectedCode.value,
        name: selectedName.value,
        chronic_dia: chronicDiaValue,
        main_dia: mainDiaValue,
        sec_dia: secDiaValue,
        Comment: comment.value,
        patient_id: 1, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: null,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: 0,
        use_time: 0,
      };

      await diagnose.update(props.dataRowId, diagnoseData);
      snackbarMessage.value = 'Diagnose updated successfully!';

    }

    const addDiagnose = async () => {

      let chronicDiaValue = "0";
      let mainDiaValue = "0";
      let secDiaValue = "0";


      if (selectedType.value === "Main") {
        mainDiaValue = "1";

      }
      else if (selectedType.value === "Chronic") {
        chronicDiaValue = "1";

      }
      else if (selectedType.value === "Secondary") {
        secDiaValue = "1"

      }
      const diagnoseData = {

        code: code.value,
        name: name.value,
        chronic_dia: chronicDiaValue,
        main_dia: mainDiaValue,
        sec_dia: secDiaValue,
        Comment: comment.value,
        patient_id: 1, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: null,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: 0,
        use_time: 0,
      };
      try {

        if (!data_Row_ID.value) {
          const response = await diagnose.store(diagnoseData);
          if (response.Code) {
            isEditMode.value = true;
            isSnackbarVisible.value = true;
            snackbarMessage.value = 'Diagnose created successfully!';
            data_Row_ID.value = response.DataRowID;
          }

        } else {
          await diagnose.update(data_Row_ID.value, diagnoseData);
          snackbarMessage.value = 'Diagnose updated successfully!';
        }
      } catch (error) {
        console.error('Failed to save or update attention:', error);
      }

    }


    const saveItem = async (value) => {

      let chronicDiaValue = "0";
      let mainDiaValue = "0";
      let secDiaValue = "0";


      if (selectedType.value === "Main") {
        mainDiaValue = "1";

      }
      else if (selectedType.value === "Chronic") {
        chronicDiaValue = "1";

      }
      else if (selectedType.value === "Secondary") {
        secDiaValue = "1"

      }
      const diagnoseData = {

        code: value.code,
        name: value.name,
        chronic_dia: chronicDiaValue,
        main_dia: mainDiaValue,
        sec_dia: secDiaValue,
        comment: value.comment,
        patient_id: 1, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: null,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: 0,
        use_time: 0
      };

      try {
        const response = await diagnose.store(diagnoseData);
        isSnackbarVisible.value = true;
        snackbarMessage.value = 'Diagnose added successfully!';
        const newDiagnose = response;


      } catch (error) {
        console.error('Failed to save or update attention:', error);

      }



    }



    const addSelectedItemToList = () => {
      selectedItems.value.push(...temporarySelection.value);
      temporarySelection.value = [];
      showDialog.value = false;
      searchInput.value = '';
      searchData.value = [];
    };

    const closeDiagnosis = () => {
      showDialog.value = false;
      searchInput.value = '';
      searchData.value = [];
    }

    const updateDiagnosisExample = async (item) => {


      if (!item.dia_items) {
        const inTemporarySelection = temporarySelection.value.some(i => i.dia_item_id === item.dia_item_id);
        const inSelectedItems = selectedItems.value.some(i => i.dia_item_id === item.dia_item_id);

        if (!inTemporarySelection && !inSelectedItems) {
          temporarySelection.value.push(item);
        } else {
          const index = temporarySelection.value.findIndex(i => i.dia_item_id === item.dia_item_id);
          if (index !== -1) {
            temporarySelection.value.splice(index, 1);
          }
        }
      }


      if (item.dia_items.length == 0) {
        const apiResponse = await fetchDataWithParams(diaFolder, { dia_list_id: params.value.dia_list_id, parent_folder_id: item.id, with_items: 1 });

        item.children = apiResponse.data.map(child => ({
          id: child.id,
          code: child.code,
          name: child.name,
          ParentFolderID: child.ParentFolderID,
          children: [],
          dia_items: child.dia_items
        }));

      }
      else {
        item.children = item.dia_items
      }


    };

    const isSelected = (item) => {
      return temporarySelection.value.includes(item) || selectedItems.value.includes(item);
    };

    const addEmptyDiagnosis = () => {
      showDialog.value = false;
      showEmptyDiagnose.value = true;
    };

    const removeItem = (index) => {
      selectedItems.value.splice(index, 1);
      showForm.value = -1;
    };


    const fetchDataWithParams = async (api, params) => {
      return await api.index(params);
    };

    const updateSelectedDiaListName = (name, dia_list_id) => {
      selectedDiaListName.value = name;
      showDialog.value = true;

      params.value.dia_list_id = dia_list_id;
      dia_folder_params.value.dia_list_id = dia_list_id

      DiagnoseData().then(() => {
        getTreeData();
      });
    };


    const DiagnoseData = async () => {
      try {
        const [dialistData, diaFolderData] = await Promise.all([
          fetchDataWithParams(dialist).catch(error => {
            console.error('Error fetching dialist data:', error);
            return [];
          }),

          fetchDataWithParams(diaFolder, dia_folder_params.value).catch(error => {
            console.error('Error fetching dialtem data:', error);
            return [];
          }),

        ]);

        return { dialistData, diaFolderData };


      } catch (error) {
        console.error('Error fetching data:', error);
        return { dialistData: [], diaItemData: [], diaFolderData: [] };
      }
    };

    const FinalItems = ref(initialChild)

    const getTreeData = async () => {
      const { dialistData, diaFolderData } = await DiagnoseData();


      //DiaList Data
      diaLists.value = dialistData.data.map(item => ({
        name: item.name,
        id: item.dia_list_id
      }));

      // for showing first item name from dialist
      selectedDiaListName.value = diaLists.value[0].name


      initialChild.value = diaFolderData.data
        .map(item => ({
          id: item.id,
          code: item.code,
          name: item.name,
          ParentFolderID: item.ParentFolderID,
          dia_items: item.dia_items,
          children: [],
        }));

    }

    onMounted(async () => {
      getTreeData()
      if (props.dataRowId && props.version) {

        try {
          const response = await diagnose.show(props.dataRowId, { data_row_id: props.dataRowId, version: props.version });
          selectedCode.value = response.data[0].code
          selectedName.value = response.data[0].name

        } catch (error) {
          console.error('Failed to fetch existing attention:', error);
        }
      }

    });




    return {
      closeDiagnosis,
      fetchSearchData,
      searchData,
      searchInput,
      clearSearch,
      showDialog,
      showEmptyDiagnose,
      selectedItems,
      addSelectedItemToList,
      addEmptyDiagnosis,
      removeItem,
      diagnosisExample,
      updateDiagnosisExample,
      FinalItems,
      diaLists,
      diaItems,
      ItemFolder,
      updateSelectedDiaListName,
      selectedDiaListName,
      diaItems,
      initialChild,
      subItem,
      setHoveredIndex,
      clearHoveredIndex,
      hoveredIndex,
      toggleForm,
      showForm,
      selectedType,
      selectedYear,
      years,
      closeForm,
      saveItem,
      addDiagnose,
      code,
      name,
      comment,
      isSelected,
      isEditMode,
      snackbarMessage,
      isSnackbarVisible,
      selectedCode,
      selectedName,
      SaveExistingDiagnose,
      closeEmptyDiagnose,
      closeExistingDiagnose,
      dataRowId, version
    };
  },
});
</script>

<style scoped>
.selected {
  background-color: transparent;
  pointer-events: none;
  color: black
}
</style>