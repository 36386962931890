import { is } from './index.js'//'./Locale';

export default {
    DD: d => is.getDayFormats()[d.getDay()].longName,
    dd: d => is.getDayFormats()[d.getDay()].shortName,
    d: d => d.getDate().toString().padStart(2, '0'),
    MM: d => is.getMonthFormats()[d.getMonth()].longName,
    mm: d => is.getMonthFormats()[d.getMonth()].shortName,
    m: d => (d.getMonth() + 1).toString().padStart(2, '0'),
    M: d => (d.getMonth() + 1).toString(),
    Y: d => d.getFullYear(),
    H: d => d.getHours().toString().padStart(2, '0'),
    i: d => d.getMinutes().toString().padStart(2, '0'),
    s: d => d.getSeconds().toString().padStart(2, '0'),
};
