<template>
  <div>
    <div v-bind="getRootProps()">
      <input v-bind="getInputProps()" />
            <div v-if="!isDragActive" :class="around">
              <i :class="iconClass"></i>
              <div class="mx-auto">
                {{ dropText }}
              </div>
            </div>
            <div v-else :class="aroundDrag">
              <i :class="iconClass"></i>
              <div class="mx-auto">
                {{ dropText }}
              </div>
            </div>
    </div>
    <button hidden @click="open">open</button>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";

export default {
  props: {
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    around: {
      type: String,
      required: false,
      default: 'aroundDZ'
    },
    aroundDrag: {
      type: String,
      required: false,
      default: 'aroundDragDZ'
    },
    dropText: {
      type: String,
      required: false,
      default: ''
    },
    supportedExtensions: {
      type: String,
      required: false,
      default: ''
    }
  },
  name: "dropzone",
  setup(props, { emit }) {
    function onDrop(acceptFiles) {
      // supportedExtensions should be a comma separated list of validExtensions e.g. 'json,xml'
      let ex = props.supportedExtensions, validExtensions = [], accepted = [];
      if(ex) {
        const formData = new FormData();
        validExtensions = ex.indexOf(',') > 0 ? ex.split(',') : [ex];
        for (let file of acceptFiles) {
          const name = file.name;
          if(name.indexOf('.') < 0) {
            continue;
          }
          ex = name.split('.');
          ex = ex[ex.length - 1].toLowerCase().trim();
          if(validExtensions.indexOf(ex) >= 0) {
            formData.append("files[]", file);
            accepted.push(file);
          }
        }
        emit('filesSelected', formData, accepted);
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>
