<template>
    <div>
        <h3>{{ $t('invoice.invoice') }}: {{ invoice.invoice_id }}</h3>
        <p class="text-muted mb-0">{{ $t('invoice') }}</p>
        <p>{{ $filters.date($filters.ieDate(invoice.issue_date)) }}</p>
        <p class="text-muted mb-0">{{ $t('invoice.issuer') }}</p>
        <p>{{ invoice.doctor.userdata.name }} </p>
        <p class="text-muted mb-0">{{ $t('invoice.payer') }}</p>
        <p>{{ displayPatient.name }} ({{ $filters.codenumber(displayPatient.codenumber) }}) </p>
        <h5 class="mt-5">{{ $t('invoice.lines') }}</h5>
        <table class="table">
            <thead>
                <tr>
                    <th class="py-1">{{ $t('invoice.line') }}</th>
                    <th class="py-1 text-center">{{ invoice.quantity }}</th>
                    <th class="py-1 text-right">{{ invoice.amount }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="line in invoice.lines" :key="line.line_number">
                    <td class="py-1">
                        {{ line.rate.name }}
                    </td>
                    <td class="py-1 text-center">
                        {{ $filters.scale(line.quantity) }}
                    </td>
                    <td class="py-1 text-right">
                        {{ $filters.currency($filters.scale(line.total), 0, false) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="py-1 font-weight-bold">{{ $t('invoice.lines_total') }}</td>
                    <td class="py-1" />
                    <td class="py-1 text-right font-weight-bold">
                        {{ $filters.currency($filters.scale(invoice.subtotal), 0, false) }}
                    </td>
                </tr>
                        
            </tfoot>
        </table>
        <h5 class="mt-5">{{ $t('invoice.settlement') }}</h5>
        <table class="table">
            <tbody>
                <tr v-if="hasDiscount">
                    <td class="py-1">{{ $t('invoice.discount') }}</td>
                    <td class="py-1 text-right font-weight-bold">
                        {{ $filters.currency($filters.scale(invoice.discount), 0, false) }}
                    </td>
                </tr>
                <tr v-if="hasDiscount">
                    <td class="py-1">{{ $t('invoice.total') }}</td>
                    <td class="py-1 text-right">
                        {{ $filters.currency($filters.scale(invoice.total), 0, false) }}
                    </td>
                </tr>
                <tr>
                    <td class="py-1">{{ $t('invoice.si_share') }}</td>
                    <td class="py-1 text-right">
                        {{ $filters.currency($filters.scale(invoice.si_share), 0, false) }}
                    </td>
                </tr>
                <tr>
                    <td class=" font-weight-bold">{{ $t('invoice.patient_share') }}</td>
                    <td class=" text-right font-weight-bold"> 
                        {{ $filters.currency($filters.scale(invoice.patient_share), 0, false) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        patient: {
            type: Object,
            required: false,
            default: undefined
        },
    },
    data() {
        return {};
    },
    computed: {
        hasDiscount() {
            let discount = Number(this.invoice.discount);
            return discount > 0;
        },
        displayPatient() {
            return (this.patient) ? this.patient : this.invoice.patient;
        }
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>
<style></style>