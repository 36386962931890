<template>
    <div class="card border-primary mb-4">
        <div class="card-header bg-primary text-white">
            <i class="fas fa-plus-circle" /> {{ t('prescription.pharmacy') }}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label-basic :required="true">{{ t('prescription.deliver_to') }}</label-basic>
                        <select v-model="pharmacy" class="form-control">
                            <option 
                                v-for="item in available" 
                                :key="item.id" 
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="form-group">
                        <label-basic :required="true">{{ t('prescription.takes_effect') }}</label-basic>
                        <datepicker 
                            v-model="startDate" 
                            :language="is" 
                            format="d. MMM yyyy" 
                            :disabled-dates="disabledStartDates" 
                            bootstrap-styling 
                            @selected="updateDate"
                        />
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="form-group">
                        <label-basic :required="true">{{ t('prescription.valid_until') }}</label-basic>
                        <datepicker v-model="endDate" :language="is" format="d. MMM yyyy" :disabled-dates="disabledEndDates" bootstrap-styling />
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label class="d-none d-lg-block">&nbsp;</label><br class="d-none d-lg-block">
                        <check-box-button v-model="homeDelivery">{{ t('prescription.send_home') }}</check-box-button>
                    </div>
                </div>
            </div>
            <div v-if="homeDelivery" class="row">
                <div class="col-lg-6 col-md-8">
                    <text-input v-model="address" :error="errors.address" :required="true" :label="$t('prescription.address')" />

                </div>
                <div class="col-lg-2 col-md-4">
                    <text-input v-model="zipcode" :error="errors.zip" :required="true" :label="$t('prescription.postal_code')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import localService from '@src/api/local';
import { is } from 'date-fns/locale';
import Datepicker from 'vue3-datepicker';
import CheckBoxButton from '../../../checkBoxButton';
import LabelBasic from '../../../labelBasic';
import TextInput from '../../../textInput';

let now = new Date();

export default {
    components: {
        Datepicker,
        CheckBoxButton,
        LabelBasic,
        TextInput
    },
    data() {
        return {
            available: [],
            pharmacy: 0,
            address: '',
            zipcode: '',
            homeDelivery: false,
            startDate: now,
            endDate: now.addDays(365),
            disabledStartDates: {
                to: now.addDays(-1)
            },
            errors: {
                address: false,
                zip: false
            },
            is
        };
    },
    computed: {
        disabledEndDates() {
            return {
                to: this.startDate,
                from: this.endDate
            };
        },
        requestData() {
            let request = {
                pharmacy_id: this.pharmacy,
                start_date: this.startDate.toISOString().split('T')[0],
                end_date: this.endDate.toISOString().split('T')[0]
            };
            if (this.homeDelivery) {
                request.delivery = {
                    address: this.address,
                    zip: this.zipcode
                };
            }
            return request; 
        }
    },
    mounted() {
        localService.getAvailablePharmacies().then(res => {
            this.available = res.data;
        }).catch(err => {
            // Some 500 error
        });
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        updateDate(date) {
            this.endDate = date.addDays(30);
        },
        setError(error) {
            this.errors[error] = true;
        },
        clearError(error) {
            this.errors[error] = false;
        }
    },
};
</script>
