<template>
    <div class="form-group">
        <div class="form-check">
            <label :class="{'text-danger': error}">
                <input
                    ref="box"
                    class="form-check-input"
                    type="checkbox"
                    :checked="value"
                    @change="onChange"
                >
                <span v-if="required" class="text-danger">&nbsp; &#42;</span>
                <slot></slot>
            </label>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            required: false,
            type: Boolean,
            default: false,
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    methods: {
        onChange() {
            this.$emit('update:value', this.$refs.box.checked);
        }
    }
});
</script>
