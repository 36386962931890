<template>
    <div class="container">
        <!-- Snackbar -->
        <v-snackbar :color="snackClass" class="ma-2" v-model="snackbar" :timeout="timeout" >
            <div class="text-center">{{ snackbarText }}</div>
        </v-snackbar>
        <!-- Mode Selector -->
        <div class="d-flex justify-content-center">
            <v-radio-group class="mx-auto" label="Hamur" v-model="mode" inline>
                <v-radio value="upload">
                    <template v-slot:label>
                        <div class="mt-2">Hlaða upp</div>
                    </template>
                </v-radio>
                <v-radio value="preview">
                    <template v-slot:label>
                        <div class="mt-2">Skoða</div>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
        <hr>
        <!-- Preview Mode -->
        <div v-if="mode === 'preview'">
            <h1 class="mx-auto text-center mb-4">Rafrænar síður - Sýnishorn</h1>
            <v-select
                :key="id"
                class="col-6 mx-auto"
                style="margin-top:50px"
                variant="outlined" 
                density="compact"
                v-model="selectedId"
                label="Smelltu hér til að sjá valmöguleika"
                :items="allSheetNames"
                item-title="name"
                item-value="id"
            />
            <div v-if="selectedId" class="d-flex"> 
                <v-radio-group class="mx-auto" label="Skoða" v-model="previewMode" inline>
                    <v-radio v-for="(rval, index) in pModeTypes" :value="rval" :key="index">
                        <template v-slot:label>
                            <div class="mt-2">{{ rval }}</div>
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-btn @click="removeEntry" class="my-auto" depressed color="error" >Eyða</v-btn>
            </div>
            <!-- The Parser -->
            <XMLPreview ref="jsonparser"/>
            <!-- XML Preview -->
            <div v-if="previewMode === 'xml'">
                <code><pre>{{ parsed }}</pre></code>
            </div>
            <!-- XSD Preview -->
            <div v-if="previewMode === 'xsd'">
                <code><pre>{{ this.allSheets.find(x => x.id === this.selectedId).xsd }}</pre></code>
            </div>
            <!-- JSON Preview -->
            <div v-if="previewMode === 'json'">
                <pre>{{ selectedJson }}</pre>
            </div>
            <!-- The Form -->
            <FormRenderer :show="previewMode === 'form'" :esId="selectedId" esDataId="" preview="true" />
        </div>
        <!-- Upload Mode -->
        <div v-if="mode === 'upload'">
            <h1 class="mx-auto text-center mb-4">Rafrænar síður - Hlaða upp</h1>
            <v-radio-group class="mx-auto" label="Tegund skjals" v-model="uploadMode" inline v-if="!uploaded">
                <v-radio v-for="(xval, index) in ['xml', 'xsd']" :value="xval" :key="index">
                    <template v-slot:label>
                        <div class="mt-2">{{ xval }}</div>
                    </template>
                </v-radio>
            </v-radio-group>
            <div v-if="!uploaded">
                <div v-if="uploadMode === 'xml'">
                    <DropZone 
                        style="max-width:300px; margin:50px auto"
                        iconClass="fa-regular fa-cloud-arrow-up fa-xl mb-4 mx-auto"
                        :around="aroundDZ"
                        :aroundDrag="aroundDragDZ"
                        dropText="Hægt er að draga .xml skjal hingað (eða smella til að velja)"
                        @filesSelected="XMLUpload"
                        supportedExtensions="xml" 
                    />
                </div>
                <div v-else>
                    <v-select
                        :key="id"
                        class="col-6 mx-auto"
                        style="margin-top:50px"
                        variant="outlined" 
                        density="compact"
                        v-model="selectedId"
                        label="Smelltu hér til að sjá valmöguleika"
                        :items="allSheetNames"
                        item-title="name"
                        item-value="id"
                    />
                    <DropZone 
                        v-if="selectedId > 0"
                        disabled="true"
                        style="max-width:300px; margin:50px auto"
                        iconClass="fa-regular fa-cloud-arrow-up fa-xl mb-4 mx-auto"
                        :around="aroundDZ"
                        :aroundDrag="aroundDragDZ"
                        dropText="Hægt er að draga .xsd skjal hingað (eða smella til að velja)"
                        @filesSelected="XSDUpload"
                        supportedExtensions="xsd" 
                    />
                </div>
            </div>
            <div class="my-4" v-if="this.uploaded">
                <h3>Skjal: {{ uploaded.filename }}</h3>
                <!-- Sheet id -->
                <div class="my-4">
                    <v-text-field
                        v-model="kindOfSheet"
                        label="Tegund skjals (id)"
                        hide-details="auto"
                        variant="outlined" 
                        density="compact"
                    />
                </div>
                <div class="d-flex flex-column mx-auto justify-content-between"
                    v-for="(item, index) in JSON.parse(uploaded.json)" :key="index" 
                    :class="Object.keys(item).includes('closing') ? ''  : 'tableBorder'" 
                >
                    <!-- opening tags -->
                    <div class="d-flex justify-content-center align-items-center" v-if="'opening' in item">
                        <div class="mb-5">{{ item.tag }}</div>
                        <v-text-field @input="updateUserTitleChange(index, $event.target)" variant="outlined" density="compact" placeholder="Flokkur" class="ml-auto" style="max-width:300px" />
                    </div>
                    <!-- value tags -->
                    <div class="d-flex justify-content-between" v-if="'datatype' in item">
                        <div class="mt-2">{{ item.tag }}</div>
                        <div class="d-flex">
                            <v-select
                                label="Tegund"
                                :items="dataTypes"
                                variant="outlined"
                                density="compact"
                                v-model="fieldExtras.find(value => value.index === index).datatype"
                            />
                            <v-select
                                class="swh"
                                variant="outlined" 
                                density="compact"
                                v-if="hasManyTrans(index)"
                                :key="index"
                                v-model="fieldExtras.find(x => x.index === index).translation"
                                label="Þýðingar"
                                :items="getTransArr(index)"
                            />
                            <v-text-field v-if="hasManyTrans(index)" variant="outlined" density="compact" v-model="fieldExtras.find(x => x.index === index).translation" placeholder="Þýðing" class="ml-auto mt-0 swh"/>
                            <v-text-field v-else variant="outlined" density="compact" v-model="fieldExtras.find(x => x.index === index).translation" placeholder="Þýðing" class="ml-auto mt-0 swh"/>

                            <v-select
                                v-model="fieldExtras.find(x => x.index === index).autofill"
                                class="swh"
                                variant="outlined" 
                                density="compact"
                                :key="id"
                                label="Sjálfvirkt"
                                :items="autoFields"
                                item-title="name"
                                item-value="name"
                            />
                            <div class="d-flex align-items-center mx-4 mt-2">
                                <v-row class="justify-center">
                                    <v-checkbox v-model="fieldExtras.find(value => value.index === index).disabled">
                                        <template v-slot:label><div class="mt-2">óvirkur</div></template>
                                    </v-checkbox>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <button :disabled="cannotUpdate" @click="updateSheet" type="button" class="btn btn-success btn-lg mx-auto">Uppfæra</button>
                </div>
                <h3 class="my-4">XML Sýnishorn</h3>
                <div class="d-flex justify-content-center mt-4">
                    <pre class="mx-auto">{{ uploaded.xml }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DropZone from '@components/dropzone/dropzone.vue';
import XMLPreview from '@components/electronicSheets/xmlPreview.vue';
import FormRenderer from '@components/electronicSheets/formRenderer.vue';
import ElectronicSheetsAPI from '@src/api/electronicSheets';

export default {
    components: { DropZone, XMLPreview, FormRenderer },
    created() {
        this.getAll();
    },
    watch: {
        mode() {
            this.selectedId=  '';
            // update data
            if(this.mode === 'preview') {
                this.getAll();
            }
        },
        selectedId() {
            this.parsed = '';
            if(this.$refs.jsonparser && this.selectedId > 0) {
                const curr = this.allSheets.filter(x => x.id === this.selectedId)[0];
                const json = JSON.parse(curr.json);
                this.selectedJson = json;
                this.parsed = this.$refs.jsonparser.xmlFromJson(json);
            }
        }
    },
    data() {
        return {
            mode: 'upload', // preview or upload
            uploadMode: 'xml', // xml or xsd
            fileLimit: 1,
            fileExceededError: false,
            kindOfSheet: '',

            // for preview
            previewMode: 'form',
            pModeTypes: ['xml', 'xsd', 'json', 'form'],
            selectedId: '',
            selectedJson: [],
            allSheets: [],
            allSheetNames: [],
            parsed: '',

            // for setting details after uploading
            fillInTypesMode: false,
            dataTypes: ['string','bool', 'integer', 'float', 'date', 'datetime'],
            autoFields: [
                {'id':0,'name':'off'},
                {'id':1,'name':'currentUserName'},
                {'id':2,'name':'currentUserEmail'},
                {'id':3,'name':'currentUserID'},
                {'id':4,'name':'currentUserTitle'},
                {'id':5,'name':'currentUserDataID'},
                {'id':6,'name':'currentUserUsername'},
                {'id':7,'name':'currentUserCodenumber'},
                {'id':8,'name':'currentUnitID'},
                {'id':9,'name':'currentUnitDepartment'},
                {'id':10,'name':'currentUnitInstitute'},
                {'id':11,'name':'currentUnitAddress'},
                {'id':12,'name':'currentUnitCodenumber'},
                {'id':13,'name':'currentPatientID'},
                {'id':14,'name':'currentPatientName'},
                {'id':15,'name':'currentPatientCodenumber'},
                {'id':16,'name':'currentPatientAddress'},
                {'id':17,'name':'currentPatientAddressZip'},
                {'id':18,'name':'currentPatientAddressCity'},
                {'id':19,'name':'currentPatientSex'},
                {'id':20,'name':'currentPatientDateOfBirth'},
                {'id':21,'name':'currentJournalTypeID'}
            ],
            uploaded: '',
            fieldExtras: [], // datatype, translation, disabled etc.
            translations: [],

            // snackbar
            snackbar: false,
            snackClass: "success",
            snackbarText: '',
            timeout: 2000,
        }
    },
    computed: {
        // update is only allowed with valid sheet kind (id)
        cannotUpdate() {
            let sid = this.kindOfSheet.split(''), numbers = '1234567890'.split('');
            if(sid.length === 0) {
                return true;
            }
            return sid.map(value => numbers.includes(value)).includes(false);
        }
    },
    methods: {
        activateSnackBar(text, state) {
            this.snackbar = false;
            this.snackbarText = text;
            this.snackClass = state;
            this.timeout = this.timeout;
            this.snackbar = true;
        },
        // for translations
        hasManyTrans(index) {
            const item = this.fieldExtras[index];
            return Object.keys(item).indexOf('translations') >= 0; 
        },
        // for translations
        getTransArr(index) {
            return this.fieldExtras[index].translations;
        },
        updateUserTitleChange(index, text) {
            this.fieldExtras[index].heading = text.value.trim();
        },
        updateUserSelection(index, index2) {
            this.fieldExtras[index].datatype = this.dataTypes[index2];
        },
        currentButtonValue(index, type) {
            const value = this.fieldExtras[index].datatype;
            return value === type;
        },
        removeEntry() {
            ElectronicSheetsAPI.destroy(this.selectedId)
                .then(res => {
                    const id = res.data;
                    if(id === this.selectedId) {
                        this.activateSnackBar('Skjali var eytt', 'success');
                        window.location.reload();
                    }
                }).catch(err => {
                    this.activateSnackBar('Ekki gekk að eyða (kannski eru gögn tengd þessari síðu)', 'error');
                });
        },
        getAll() {
            this.allSheets = [];
            this.allSheetNames = [];
            ElectronicSheetsAPI.getAll()
                .then(res => {
                    const data = res.data.data
                    this.allSheets = data;
                    for(let entry of data) {
                        let openingTag = JSON.parse(entry.json)[0].tag;
                        const selection = `${openingTag} (${entry.filename})`;
                        this.allSheetNames.push({"name":selection, "id": entry.id });
                    }
                    ElectronicSheetsAPI.getTranslations()
                        .then(res => {
                            if(res.data.length > 0) {
                                this.translations = res.data;
                                this.activateSnackBar('Gögn voru sótt', 'success');
                            }
                        }).catch(err => this.activateSnackBar('Ekki gekk að sækja gögn', 'error'))
                }).catch(err => this.activateSnackBar('Ekki gekk að sækja gögn', 'error'))
        },
        getTranslation(tag) {
            let result = this.translations.filter(value => value.tag === tag);
            if(result.length === 0) {
                return '';
            }
            result = result.map(value => value.translation);
            return result.length === 1 ? result[0] : result;
        },
        reset() {
            this.uploaded = '';
            this.fieldExtras = [];
            this.fileExceededError = false;
        },
        XSDUpload(files, info) {
            if(info.length === 0) {
                return;
            }
            if(info.length > this.fileLimit) {
                this.activateSnackBar('Bara eitt skjal í einu', 'error');
                return;
            }
            ElectronicSheetsAPI.storeSchema(this.selectedId, { 'files': files } )
                .then(res => {
                    this.activateSnackBar('XSD var vistað á lista', 'success')
                    setTimeout(() => window.location.reload(), 2000);
                })
                .catch(err => this.activateSnackBar('Ekki gekk að vista XSD á lista', 'error'));
        },
        XMLUpload(files, info) {
            this.reset();
            if(info.length === 0) {
                return;
            }
            if(info.length > this.fileLimit) {
                this.activateSnackBar('Bara eitt skjal í einu', 'error');
                return;
            }

            ElectronicSheetsAPI.store({ 'files': files })
                .then(res => {
                    const result = res.data;
                    if('filename' in result) {
                        console.log('uploaded filename:',result.filename);
                        this.uploaded = result;
                        this.fieldExtras = [];
                        const json = JSON.parse(this.uploaded.json);
                        let index = 0;
                        for(let item of json) {
                            if('opening' in item) {
                                this.fieldExtras.push({"heading": "", "index":index});
                            }
                            else if('datatype' in item) {
                                const translation = this.getTranslation(json[index].tag);
                                const manyTranslations = Array.isArray(translation);
                                let extra = {
                                    "datatype": "string",
                                    "index":index, 
                                    'tag':json[index].tag, 
                                    'disabled':false,
                                    'autofill':this.autoFields[0].name
                                };
                                if(manyTranslations) {
                                    extra.translation = '';
                                    extra.translations = translation;
                                } else {
                                    extra.translation = translation;
                                }
                                this.fieldExtras.push(extra);
                            }
                            else {
                                this.fieldExtras.push({"index":index});
                            }
                            index += 1;
                        }
                    }
                })
                .catch(err => {
                    let errorMessage = err.response.data.message;
                    this.activateSnackBar(errorMessage, 'error');
                    this.reset();
                })
        },
        // saving data types and translations to json
        updateSheet() {
            const id = this.uploaded.id
            let json = JSON.parse(this.uploaded.json)
            const extras = this.fieldExtras;

            if(json.length != extras.length) {
                this.activateSnackBar('Villa í gögnum!', 'error');
                return;
            }

            let index = 0;
            for(let entry of json) {
                // open tag heading
                if('opening' in entry && extras[index].heading) {
                    entry.heading = extras[index].heading;
                }
                // value tag type (default is string)
                if('datatype' in entry) {
                    entry.datatype = extras[index].datatype;
                    if('translation' in extras[index]) {
                        const translation = extras[index].translation.trim();
                        if(translation.length > 0) {
                            entry.translation = translation;
                        }
                    }
                    if('autofill' in extras[index]) {
                        entry.autofill = extras[index].autofill;
                    }
                }
                // only setting checked disabled fields
                'disabled' in extras[index] && extras[index].disabled ? entry.disabled = true : '';
                index += 1;
            }

            const kindOfSheet = this.kindOfSheet;

            ElectronicSheetsAPI.updateElectronicSheet(id, { "json": json, "kind": kindOfSheet })
                .then(res => {
                    this.reset();
                    this.fillInTypesMode = false;
                    this.activateSnackBar('Gögn voru uppfærð', 'success');
                    window.location.reload();
                })
                .catch(err => this.activateSnackBar('Ekki gekk að uppfæra gögn', 'error'));
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
margin-top: 100px;
}
.tableBorder {
padding:10px;
margin:0;
border:1px solid rgb(226, 226, 226);
width:1300px;
max-height:60px;
}
.swh {
width:200px;
height:40px;
}
</style>
