<!-- <template lang="html">
    <div class="row pt-3">
        <div 
            class="col-12" 
            @submit.prevent.stop="" 
            @keydown.ctrl.83.prevent="onSend"
        >
            <div class="card mb-2 border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label> 
                                    {{ t('prescription.patient_name') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ currentPatientName }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>
                                    {{ t('prescription.ssn') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ $filters.codenumber(currentPatientCodenumber }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>&nbsp;</label><br>
                                <button 
                                    :disabled="$route.params.presc" 
                                    class="btn btn-outline-secondary btn-block ws-normal" 
                                    @click="displayOldPrescriptions = true"
                                >
                                    <i class="fal fa-repeat fa-fw" /> 
                                    {{ t('prescription.renew_older_prescription') }}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loaded">
                <transition-group name="slide-fade-fast">
                        <medicine 
                            v-for="(med_id, index) in medicines"
                            :key="med_id"
                            ref="medicines"
                            :removable="medicines.length > 1"
                            :forms="forms"
                            :expressions="expressions"
                            :tab-index-start="index * 9"
                            :pre-selected="onPreSelectedMedicine(index)"
                            @close="removeMedicine(med_id)"
                            @help="onMedicineHelp"
                        />
                </transition-group>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button 
                        type="button" 
                        class="btn btn-outline-secondary btn-presc mb-3" 
                        @click="addMedicine"
                    >
                        <i class="fal fa-plus" /> 
                        {{ t('prescription.add_another_drug') }}
                    </button>
                </div>
            </div>
            <pharmacy ref="pharmacy" />
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button 
                            v-tooltip="'CTRL+S'" 
                            type="button" 
                            class="btn btn-success btn-lg btn-block btn-presc-send" 
                            :tabindex="medicines.length * 9 + 1" 
                            @click="onSend"
                        >
                            <i class="fal fa-paper-plane" />
                            {{ t('prescription.send_prescription') }}

                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-simple 
            v-if="displayOldPrescriptions" 
            size="large" 
            @close="displayOldPrescriptions = false"
        >
            <template slot="header">
                <i class="fal fa-repeat fa-fw" />    {{ t('prescription.renew_old_prescription') }}
            </template>
            <div 
                v-if="loadingOldPrescriptions" 
                class="text-center"
            >
                <i class="fal fa-spinner fa-spin" /> {{ t('prescription.fetch_old_prescription') }}
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions.length > 0" 
                class="scroller"
            >
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                {{ t('prescription.date') }}
                            </th>
                            <th>
                                {{ t('prescription.drug_name') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.no_of_packages') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.dispensations') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="(item, index) in oldPrescriptions" 
                            :key="item.id" 
                            class="clickable" 
                            @click="onOldPrescriptionSelect(index)"
                        >
                            <td>{{ $filters.date(item.date_created) }}</td>
                            <td>{{ item.medicine.name }} {{ item.medicine.strength }} {{ item.medicine.form }}</td>
                            <td class="text-center">{{ item.number_of_packages }}</td>
                            <td class="text-center">{{ item.iteration }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions < 1" 
                class="text-center"
            >
                {{ t('prescription.no_precsription_found') }}
            </div>
            <template slot="footer">
                <button 
                    class="btn btn-primary" 
                    @click="displayOldPrescriptions = false"
                >
                    <i class="fal fa-times fa" />
                    {{ t('prescription.close') }}

                </button>
            </template>
        </modal-simple>
    </div>
</template>
<script>
import Medicine from './medicine';
import Pharmacy from './pharmacy';
import localService from '../../../api/local';
import localService2 from '../../../api/local';
import { mapGetters } from 'vuex';
import ModalSimple from '../../modalSimple/';

export default {
    components: {
        Medicine,
        Pharmacy,
        ModalSimple
    },
    props: {
        errors: {
            required: false,
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            id: 1,
            medicines: [0],
            forms: [],
            expressions: [],
            loadingOldPrescriptions: false,
            displayOldPrescriptions: false,
            oldPrescriptions: [],
            selectedOldPrescription: -1,
            autoFillData: [],
            loaded: false
        };
    },
    
    computed: {
        ...mapGetters([
            'currentPatientID',
            'currentPatientName',
            'currentPatientCodenumber',
            'currentUnitID'
        ])
    },
    watch: {
        errors() {
            this.mapErrorsToSubComponents();
        },
        currentPatientID(newValue, oldValue) {
            this.getOldPrescriptions();
        }
    },
    created() {
        const keys = Object.keys(this.$route.params)
        // checking if autofilled
        if(keys.length === 3 && keys.indexOf('autofill') >= 0) {
            const id = this.$route.params.id;
            const data = this.$route.params.data;
            this.medicine = [0], this.autoFillData = [];
            let pi = data.PrescribedItems.PrescribedItem;
            if(pi.length > 0) {
                pi = pi[0]; 

                const item = {
                    'medicine': {'nordic_id': pi.ProductInfo.ProductId},
                    'atc_code': pi.ProductInfo.AtcCode,
                    'instruction': pi.DosageInstructions,
                    'number_of_packages': pi.NumberOfPackages,
                    'daily_max_dosage': pi.MaxPerDayDosage,
                    'days_between_dispensations': data.NumberOfDaysBetweenDispensations,
                    'daily_max_dosage_unit': pi.TimeUnit
                };
                this.autoFillData = [item];
            }
        } 
    },
    mounted() {
        if(this.autoFillData.length === 0) {
            this.getMedicineForms();
            this.getShortDosageExpressions();
            this.getOldPrescriptions();
        }
        this.loaded = true;
    },
    methods: {
        addMedicine() {
            this.medicines.push(this.id++);  
        },
        onMedicineHelp(atc) {
            this.$emit('lookup', this.lgnUrl(atc));
        },
        lgnUrl(atc) {
            if (atc !== undefined) {
                return `https://www.serlyfjaskra.is/ShowResult.aspx?d=1&p=1&n=0&i=1&t=0&a=0&at=1&m=0&q=${atc}`;
            } else {
                return 'https://www.serlyfjaskra.is/';
            }
        },
        mapErrorsToSubComponents() {
            for (let key in this.errors) {
                if (key.includes('prescriptions')) {
                    let split = key.split('.');
                    if (split.length === 3) {
                        this.$refs.medicines[split[1]].setError(split[2]);
                    }
                } else if (key.includes('delivery')) {
                    let split = key.split('.');
                    if (split.length === 2) {
                        this.$refs.pharmacy.setError(split[1]);
                    }
                }
            }
        },
        getMedicineForms() {
            localService.getMedicineForms().then(res => {
                this.forms = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        getShortDosageExpressions() {
            localService.getShortDosageExpressions().then(res => {
                this.expressions = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        removeMedicine(id) {
            let index = this.medicines.indexOf(id);
            if (index !== -1) {
                this.medicines.splice(index, 1);
            }
        },
        newPrescriptionRequestParams() {
            return Object.assign(this.$refs.pharmacy.requestData, {
                prescriptions: this.$refs.medicines.map(medicine => medicine.requestData)
            }, {
                patient_id: this.currentPatientID,
                unit_id: this.currentUnitID,
            });
        },
        onSend() {
            this.$emit('send', this.newPrescriptionRequestParams());
        },
        onPreSelectedMedicine(index) {
            if(this.autoFillData.length > 0) {
                const preSelected = this.autoFillData[0];
                return preSelected;
            }
            if (this.selectedOldPrescription >= 0 && index === 0) {
                // this.medicines = [0];
                return this.oldPrescriptions[this.selectedOldPrescription];
            }
            return null;
        },
        getOldPrescriptions() {
            this.loadingOldPrescriptions = true;
            if (this.currentPatientID !== undefined) {
                localService2.getPatientPrescriptions(this.currentPatientID).then(response => {
                    this.oldPrescriptions = response.data;
                }).catch(error => {
                        
                }).finally(() => {
                    this.loadingOldPrescriptions = false;
                });
            }
        },
        onOldPrescriptionSelect(index) {
            this.selectedOldPrescription = index;
            this.medicines = [index];
            this.displayOldPrescriptions = false; 
        },
        t(key) {
            return this.$t(key);
        },
    }
};
</script> -->


<!-- <template lang="html">
    <div class="row pt-3">
        <div 
            class="col-12" 
            @submit.prevent.stop="" 
            @keydown.ctrl.83.prevent="onSend"
        >
            <div class="card mb-2 border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label> 
                                    {{ t('prescription.patient_name') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ currentPatientName }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>
                                    {{ t('prescription.ssn') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ $filters.codenumber(currentPatientCodenumber }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>&nbsp;</label><br>
                                <button 
                                    :disabled="$route.params.presc" 
                                    class="btn btn-outline-secondary btn-block ws-normal" 
                                    @click="displayOldPrescriptions = true"
                                >
                                    <i class="fal fa-repeat fa-fw" /> 
                                    {{ t('prescription.renew_older_prescription') }}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loaded">
                <transition-group name="slide-fade-fast">
                        <medicine 
                            v-for="(med_id, index) in medicines"
                            :key="med_id"
                            ref="medicines"
                            :removable="medicines.length > 1"
                            :forms="forms"
                            :expressions="expressions"
                            :tab-index-start="index * 9"
                            :pre-selected="onPreSelectedMedicine(index)"
                            @close="removeMedicine(med_id)"
                            @help="onMedicineHelp"
                        />
                </transition-group>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button 
                        type="button" 
                        class="btn btn-outline-secondary btn-presc mb-3" 
                        @click="addMedicine"
                    >
                        <i class="fal fa-plus" /> 
                        {{ t('prescription.add_another_drug') }}
                    </button>
                </div>
            </div>
            <pharmacy ref="pharmacy" />
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button 
                            v-tooltip="'CTRL+S'" 
                            type="button" 
                            class="btn btn-success btn-lg btn-block btn-presc-send" 
                            :tabindex="medicines.length * 9 + 1" 
                            @click="onSend"
                        >
                            <i class="fal fa-paper-plane" />
                            {{ t('prescription.send_prescription') }}

                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-simple 
            v-if="displayOldPrescriptions" 
            size="large" 
            @close="displayOldPrescriptions = false"
        >
            <template slot="header">
                <i class="fal fa-repeat fa-fw" />    {{ t('prescription.renew_old_prescription') }}
            </template>
            <div 
                v-if="loadingOldPrescriptions" 
                class="text-center"
            >
                <i class="fal fa-spinner fa-spin" /> {{ t('prescription.fetch_old_prescription') }}
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions.length > 0" 
                class="scroller"
            >
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                {{ t('prescription.date') }}
                            </th>
                            <th>
                                {{ t('prescription.drug_name') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.no_of_packages') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.dispensations') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="(item, index) in oldPrescriptions" 
                            :key="item.id" 
                            class="clickable" 
                            @click="onOldPrescriptionSelect(index)"
                        >
                            <td>{{ $filters.date(item.date_created) }}</td>
                            <td>{{ item.medicine.name }} {{ item.medicine.strength }} {{ item.medicine.form }}</td>
                            <td class="text-center">{{ item.number_of_packages }}</td>
                            <td class="text-center">{{ item.iteration }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions < 1" 
                class="text-center"
            >
                {{ t('prescription.no_precsription_found') }}
            </div>
            <template slot="footer">
                <button 
                    class="btn btn-primary" 
                    @click="displayOldPrescriptions = false"
                >
                    <i class="fal fa-times fa" />
                    {{ t('prescription.close') }}

                </button>
            </template>
        </modal-simple>
    </div>
</template>
<script>
import Medicine from './medicine';
import Pharmacy from './pharmacy';
import localService from '../../../api/local';
import localService2 from '../../../api/local';
import { mapGetters } from 'vuex';
import ModalSimple from '../../modalSimple/';

export default {
    components: {
        Medicine,
        Pharmacy,
        ModalSimple
    },
    props: {
        errors: {
            required: false,
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            id: 1,
            medicines: [0],
            forms: [],
            expressions: [],
            loadingOldPrescriptions: false,
            displayOldPrescriptions: false,
            oldPrescriptions: [],
            selectedOldPrescription: -1,
            autoFillData: [],
            loaded: false
        };
    },
    
    computed: {
        ...mapGetters([
            'currentPatientID',
            'currentPatientName',
            'currentPatientCodenumber',
            'currentUnitID'
        ])
    },
    watch: {
        errors() {
            this.mapErrorsToSubComponents();
        },
        currentPatientID(newValue, oldValue) {
            this.getOldPrescriptions();
        }
    },
    created() {
        const keys = Object.keys(this.$route.params)
        // checking if autofilled
        if(keys.length === 3 && keys.indexOf('autofill') >= 0) {
            const id = this.$route.params.id;
            const data = this.$route.params.data;
            this.medicine = [0], this.autoFillData = [];
            let pi = data.PrescribedItems.PrescribedItem;
            if(pi.length > 0) {
                pi = pi[0]; 

                const item = {
                    'medicine': {'nordic_id': pi.ProductInfo.ProductId},
                    'atc_code': pi.ProductInfo.AtcCode,
                    'instruction': pi.DosageInstructions,
                    'number_of_packages': pi.NumberOfPackages,
                    'daily_max_dosage': pi.MaxPerDayDosage,
                    'days_between_dispensations': data.NumberOfDaysBetweenDispensations,
                    'daily_max_dosage_unit': pi.TimeUnit
                };
                this.autoFillData = [item];
            }
        } 
    },
    mounted() {
        if(this.autoFillData.length === 0) {
            this.getMedicineForms();
            this.getShortDosageExpressions();
            this.getOldPrescriptions();
        }
        this.loaded = true;
    },
    methods: {
        addMedicine() {
            this.medicines.push(this.id++);  
        },
        onMedicineHelp(atc) {
            this.$emit('lookup', this.lgnUrl(atc));
        },
        lgnUrl(atc) {
            if (atc !== undefined) {
                return `https://www.serlyfjaskra.is/ShowResult.aspx?d=1&p=1&n=0&i=1&t=0&a=0&at=1&m=0&q=${atc}`;
            } else {
                return 'https://www.serlyfjaskra.is/';
            }
        },
        mapErrorsToSubComponents() {
            for (let key in this.errors) {
                if (key.includes('prescriptions')) {
                    let split = key.split('.');
                    if (split.length === 3) {
                        this.$refs.medicines[split[1]].setError(split[2]);
                    }
                } else if (key.includes('delivery')) {
                    let split = key.split('.');
                    if (split.length === 2) {
                        this.$refs.pharmacy.setError(split[1]);
                    }
                }
            }
        },
        getMedicineForms() {
            localService.getMedicineForms().then(res => {
                this.forms = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        getShortDosageExpressions() {
            localService.getShortDosageExpressions().then(res => {
                this.expressions = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        removeMedicine(id) {
            let index = this.medicines.indexOf(id);
            if (index !== -1) {
                this.medicines.splice(index, 1);
            }
        },
        newPrescriptionRequestParams() {
            return Object.assign(this.$refs.pharmacy.requestData, {
                prescriptions: this.$refs.medicines.map(medicine => medicine.requestData)
            }, {
                patient_id: this.currentPatientID,
                unit_id: this.currentUnitID,
            });
        },
        onSend() {
            this.$emit('send', this.newPrescriptionRequestParams());
        },
        onPreSelectedMedicine(index) {
            if(this.autoFillData.length > 0) {
                const preSelected = this.autoFillData[0];
                return preSelected;
            }
            if (this.selectedOldPrescription >= 0 && index === 0) {
                // this.medicines = [0];
                return this.oldPrescriptions[this.selectedOldPrescription];
            }
            return null;
        },
        getOldPrescriptions() {
            this.loadingOldPrescriptions = true;
            if (this.currentPatientID !== undefined) {
                localService2.getPatientPrescriptions(this.currentPatientID).then(response => {
                    this.oldPrescriptions = response.data;
                }).catch(error => {
                        
                }).finally(() => {
                    this.loadingOldPrescriptions = false;
                });
            }
        },
        onOldPrescriptionSelect(index) {
            this.selectedOldPrescription = index;
            this.medicines = [index];
            this.displayOldPrescriptions = false; 
        },
        t(key) {
            return this.$t(key);
        },
    }
};
</script> -->


<!-- <template lang="html">
    <div class="row pt-3">
        <div 
            class="col-12" 
            @submit.prevent.stop="" 
            @keydown.ctrl.83.prevent="onSend"
        >
            <div class="card mb-2 border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label> 
                                    {{ t('prescription.patient_name') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ currentPatientName }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>
                                    {{ t('prescription.ssn') }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ $filters.codenumber(currentPatientCodenumber }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>&nbsp;</label><br>
                                <button 
                                    :disabled="$route.params.presc" 
                                    class="btn btn-outline-secondary btn-block ws-normal" 
                                    @click="displayOldPrescriptions = true"
                                >
                                    <i class="fal fa-repeat fa-fw" /> 
                                    {{ t('prescription.renew_older_prescription') }}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loaded">
                <transition-group name="slide-fade-fast">
                        <medicine 
                            v-for="(med_id, index) in medicines"
                            :key="med_id"
                            ref="medicines"
                            :removable="medicines.length > 1"
                            :forms="forms"
                            :expressions="expressions"
                            :tab-index-start="index * 9"
                            :pre-selected="onPreSelectedMedicine(index)"
                            @close="removeMedicine(med_id)"
                            @help="onMedicineHelp"
                        />
                </transition-group>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button 
                        type="button" 
                        class="btn btn-outline-secondary btn-presc mb-3" 
                        @click="addMedicine"
                    >
                        <i class="fal fa-plus" /> 
                        {{ t('prescription.add_another_drug') }}
                    </button>
                </div>
            </div>
            <pharmacy ref="pharmacy" />
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button 
                            v-tooltip="'CTRL+S'" 
                            type="button" 
                            class="btn btn-success btn-lg btn-block btn-presc-send" 
                            :tabindex="medicines.length * 9 + 1" 
                            @click="onSend"
                        >
                            <i class="fal fa-paper-plane" />
                            {{ t('prescription.send_prescription') }}

                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-simple 
            v-if="displayOldPrescriptions" 
            size="large" 
            @close="displayOldPrescriptions = false"
        >
            <template slot="header">
                <i class="fal fa-repeat fa-fw" />    {{ t('prescription.renew_old_prescription') }}
            </template>
            <div 
                v-if="loadingOldPrescriptions" 
                class="text-center"
            >
                <i class="fal fa-spinner fa-spin" /> {{ t('prescription.fetch_old_prescription') }}
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions.length > 0" 
                class="scroller"
            >
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                {{ t('prescription.date') }}
                            </th>
                            <th>
                                {{ t('prescription.drug_name') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.no_of_packages') }}
                            </th>
                            <th class="text-center">
                                {{ t('prescription.dispensations') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="(item, index) in oldPrescriptions" 
                            :key="item.id" 
                            class="clickable" 
                            @click="onOldPrescriptionSelect(index)"
                        >
                            <td>{{ $filters.date(item.date_created) }}</td>
                            <td>{{ item.medicine.name }} {{ item.medicine.strength }} {{ item.medicine.form }}</td>
                            <td class="text-center">{{ item.number_of_packages }}</td>
                            <td class="text-center">{{ item.iteration }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div 
                v-if="!loadingOldPrescriptions && oldPrescriptions < 1" 
                class="text-center"
            >
                {{ t('prescription.no_precsription_found') }}
            </div>
            <template slot="footer">
                <button 
                    class="btn btn-primary" 
                    @click="displayOldPrescriptions = false"
                >
                    <i class="fal fa-times fa" />
                    {{ t('prescription.close') }}

                </button>
            </template>
        </modal-simple>
    </div>
</template>
<script>
import Medicine from './medicine';
import Pharmacy from './pharmacy';
import localService from '../../../api/local';
import localService2 from '../../../api/local';
import { mapGetters } from 'vuex';
import ModalSimple from '../../modalSimple/';

export default {
    components: {
        Medicine,
        Pharmacy,
        ModalSimple
    },
    props: {
        errors: {
            required: false,
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            id: 1,
            medicines: [0],
            forms: [],
            expressions: [],
            loadingOldPrescriptions: false,
            displayOldPrescriptions: false,
            oldPrescriptions: [],
            selectedOldPrescription: -1,
            autoFillData: [],
            loaded: false
        };
    },
    
    computed: {
        ...mapGetters([
            'currentPatientID',
            'currentPatientName',
            'currentPatientCodenumber',
            'currentUnitID'
        ])
    },
    watch: {
        errors() {
            this.mapErrorsToSubComponents();
        },
        currentPatientID(newValue, oldValue) {
            this.getOldPrescriptions();
        }
    },
    created() {
        const keys = Object.keys(this.$route.params)
        // checking if autofilled
        if(keys.length === 3 && keys.indexOf('autofill') >= 0) {
            const id = this.$route.params.id;
            const data = this.$route.params.data;
            this.medicine = [0], this.autoFillData = [];
            let pi = data.PrescribedItems.PrescribedItem;
            if(pi.length > 0) {
                pi = pi[0]; 

                const item = {
                    'medicine': {'nordic_id': pi.ProductInfo.ProductId},
                    'atc_code': pi.ProductInfo.AtcCode,
                    'instruction': pi.DosageInstructions,
                    'number_of_packages': pi.NumberOfPackages,
                    'daily_max_dosage': pi.MaxPerDayDosage,
                    'days_between_dispensations': data.NumberOfDaysBetweenDispensations,
                    'daily_max_dosage_unit': pi.TimeUnit
                };
                this.autoFillData = [item];
            }
        } 
    },
    mounted() {
        if(this.autoFillData.length === 0) {
            this.getMedicineForms();
            this.getShortDosageExpressions();
            this.getOldPrescriptions();
        }
        this.loaded = true;
    },
    methods: {
        addMedicine() {
            this.medicines.push(this.id++);  
        },
        onMedicineHelp(atc) {
            this.$emit('lookup', this.lgnUrl(atc));
        },
        lgnUrl(atc) {
            if (atc !== undefined) {
                return `https://www.serlyfjaskra.is/ShowResult.aspx?d=1&p=1&n=0&i=1&t=0&a=0&at=1&m=0&q=${atc}`;
            } else {
                return 'https://www.serlyfjaskra.is/';
            }
        },
        mapErrorsToSubComponents() {
            for (let key in this.errors) {
                if (key.includes('prescriptions')) {
                    let split = key.split('.');
                    if (split.length === 3) {
                        this.$refs.medicines[split[1]].setError(split[2]);
                    }
                } else if (key.includes('delivery')) {
                    let split = key.split('.');
                    if (split.length === 2) {
                        this.$refs.pharmacy.setError(split[1]);
                    }
                }
            }
        },
        getMedicineForms() {
            localService.getMedicineForms().then(res => {
                this.forms = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        getShortDosageExpressions() {
            localService.getShortDosageExpressions().then(res => {
                this.expressions = res.data;
            }).catch(err => {
                // 500 err
            });
        },
        removeMedicine(id) {
            let index = this.medicines.indexOf(id);
            if (index !== -1) {
                this.medicines.splice(index, 1);
            }
        },
        newPrescriptionRequestParams() {
            return Object.assign(this.$refs.pharmacy.requestData, {
                prescriptions: this.$refs.medicines.map(medicine => medicine.requestData)
            }, {
                patient_id: this.currentPatientID,
                unit_id: this.currentUnitID,
            });
        },
        onSend() {
            this.$emit('send', this.newPrescriptionRequestParams());
        },
        onPreSelectedMedicine(index) {
            if(this.autoFillData.length > 0) {
                const preSelected = this.autoFillData[0];
                return preSelected;
            }
            if (this.selectedOldPrescription >= 0 && index === 0) {
                // this.medicines = [0];
                return this.oldPrescriptions[this.selectedOldPrescription];
            }
            return null;
        },
        getOldPrescriptions() {
            this.loadingOldPrescriptions = true;
            if (this.currentPatientID !== undefined) {
                localService2.getPatientPrescriptions(this.currentPatientID).then(response => {
                    this.oldPrescriptions = response.data;
                }).catch(error => {
                        
                }).finally(() => {
                    this.loadingOldPrescriptions = false;
                });
            }
        },
        onOldPrescriptionSelect(index) {
            this.selectedOldPrescription = index;
            this.medicines = [index];
            this.displayOldPrescriptions = false; 
        },
        t(key) {
            return this.$t(key);
        },
    }
};
</script> -->

<template>
    <div class="row pt-3">
      <div class="col-12" @submit.prevent.stop="" @keydown.ctrl.83.prevent="onSend">
        <div class="card mb-2 border-primary">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>{{ $t('prescription.patient_name') }}</label>
                  <div class="form-control non-form-info">
                    {{ $store.state.patient.name }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label>{{ $t('invoice_create.ssn') }}</label>
                  <div class="form-control non-form-info">
                    {{ $store.state.patient.codenumber || '' }}
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label>&nbsp;</label><br>
                  <button :disabled="$route.params.presc" class="btn btn-outline-secondary btn-block ws-normal"
                    @click="openModel($store.state.patient.id)">
                    <i class="fal fa-repeat fa-fw" /> {{ $t('prescription.renew_older_prescription') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition-group name="slide-fade-fast">
          <template v-for="(med_id, index) in medicines" :key="med_id">
            <medicine :ref="el => setMedicineRef(el, index)" :removable="medicines.length > 1" :forms="forms" :expressions="expressions"
              :tab-index-start="index * 9" :pre-selected="onPreSelectedMedicine(index)" @close="removeMedicine(med_id)"
              @help="onMedicineHelp" />
          </template>
        </transition-group>
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-outline-secondary btn-presc mb-3" @click="addMedicine">
              <i class="fal fa-plus" /> {{ $t('prescription.add_another_drug') }}
            </button>
          </div>
        </div>
        <pharmacy ref="pharmacyRef" />
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <button v-tooltip="'CTRL+S'" type="button" class="btn btn-success btn-lg btn-block btn-presc-send"
                :tabindex="String(medicines.length * 9 + 1)" @click="onSend">
                <i class="fal fa-paper-plane" /> {{ $t('prescription.send_prescription') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <modal-simple v-if="displayOldPrescriptions" size="large" @close="closeOldPrescriptionsModal">
        <template v-slot:header>
          <i class="fal fa-repeat fa-fw" /> {{ $t('prescription.renew_older_prescription') }}
        </template>
        <div v-if="loadingOldPrescriptions" class="text-center">
          <i class="fal fa-spinner fa-spin" /> {{ $t('prescription.fetch_old_prescription') }}
        </div>
        <div v-if="!loadingOldPrescriptions && oldPrescriptions.length > 0" class="scroller">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>{{ $t('prescription.date') }}</th>
                <th>{{ $t('prescription.drug_name') }}</th>
                <th class="text-center">{{ $t('prescription.no_of_packages') }}</th>
                <th class="text-center">{{ $t('prescription.dispensations') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in oldPrescriptions" :key="item.id" class="clickable"
                @click="onOldPrescriptionSelect(index)">
                <td>{{ formatDate(item.date_created) }}</td>
                <td>{{ item.medicine.name }} {{ item.medicine.strength }} {{ item.medicine.form }}</td>
                <td class="text-center">{{ item.number_of_packages }}</td>
                <td class="text-center">{{ item.iteration }} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!loadingOldPrescriptions && oldPrescriptions.length < 1" class="text-center">
          {{ $t('prescription.no_precsription_found') }}        </div>
        <template v-slot:footer>
          <button type="button" class="btn btn-primary" @click="closeOldPrescriptionsModal">
            <i class="fal fa-times"></i> {{ $t('prescription.close') }}
          </button>
        </template>
      </modal-simple>
    </div>
  </template>




<script>
import { ref, watch, onMounted, getCurrentInstance } from 'vue';
import Medicine from './medicine';
import Pharmacy from './pharmacy';
import localService from '../../../api/local.js';
import ModalSimple from '../../modalSimple/';
import { useStore } from 'vuex';

export default {
  components: {
    Medicine,
    Pharmacy,
    ModalSimple,
  },
  props: {
    errors: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const id = ref(1);
    const medicines = ref([0]);
    const forms = ref([]);
    const expressions = ref([]);
    const loadingOldPrescriptions = ref(false);
    const displayOldPrescriptions = ref(false);
    const oldPrescriptions = ref([]);
    const selectedOldPrescription = ref(-1);
    const pharmacyRef = ref(null);
    const medicinesRefs = ref([]);

    const store = useStore();
    const currentUnitID = store.getters.currentUnitID;
     const currentPatientID = ref(null);

    const addMedicine = () => {
      medicines.value.push(id.value++);
    };

    const setMedicineRef = (el, index) => {
      if (el) {
        medicinesRefs.value[index] = el;
      }
    };

    const onMedicineHelp = (atc) => {
      emit('lookup', lgnUrl(atc));
    };

    const lgnUrl = (atc) => {
      return atc !== undefined
        ? `https://www.serlyfjaskra.is/ShowResult.aspx?d=1&p=1&n=0&i=1&t=0&a=0&at=1&m=0&q=${atc}`
        : 'https://www.serlyfjaskra.is/';
    };

    const mapErrorsToSubComponents = () => {
      for (const key in props.errors) {
        if (key.includes('prescriptions')) {
          const split = key.split('.');
          if (split.length === 3) {
            medicinesRefs.value[split[1]]?.setError(split[2]);
          }
        } else if (key.includes('delivery')) {
          const split = key.split('.');
          if (split.length === 2) {
            pharmacyRef.value?.setError(split[1]);
          }
        }
      }
    };

    const getMedicineForms = () => {
      localService.getMedicineForms()
        .then((res) => {
          forms.value = res.data;
        })
        .catch((err) => {
          // Handle error
        });
    };

    const getShortDosageExpressions = () => {
      localService.getShortDosageExpressions()
        .then((res) => {
          expressions.value = res.data;
        })
        .catch((err) => {
          // Handle error
        });
    };

    const removeMedicine = (id) => {
      const index = medicines.value.indexOf(id);
      if (index !== -1) {
        medicines.value.splice(index, 1);
        medicinesRefs.value.splice(index, 1);
      }
    };

    const openModel = (id) => {
        console.log("iddd-----",id)
      displayOldPrescriptions.value = true;
      currentPatientID.value = id;
      getOldPrescriptions();
    };

    const closeOldPrescriptionsModal = () => {
      displayOldPrescriptions.value = !displayOldPrescriptions.value;
    };

    const newPrescriptionRequestParams = () => {
      const pharmacyData = pharmacyRef.value ? pharmacyRef.value.requestData : {};
      const prescriptions = medicinesRefs.value.map((medicine) => medicine.requestData);
      return {
        ...pharmacyData,
        prescriptions,
        unit_id: currentUnitID,
        patient_id: 1,
      };
    };

    const onSend = () => {
      const requestData = newPrescriptionRequestParams();

      // Ensure required fields are provided
      if (!requestData.unit_id) {
        console.error("The unit id field is required.");
      }
      if (!requestData.prescriptions || requestData.prescriptions.length === 0) {
        console.error("The prescriptions field is required.");
      }

      // Emit the send event with the request data
      emit('send', requestData);
    };

    const onPreSelectedMedicine = (index) => {
      if (proxy.$route.params.presc && index === 0) {
        return proxy.$route.params.presc;
      }
      if (selectedOldPrescription.value >= 0 && index === 0) {
        return oldPrescriptions.value[selectedOldPrescription.value];
      }
      return null;
    };

    const getOldPrescriptions = () => {
      loadingOldPrescriptions.value = true;
      if (currentPatientID.value !== undefined && currentPatientID.value !== null) {
        localService.getPatientPrescriptions(currentPatientID.value).then(response => {
          oldPrescriptions.value = response.data;
        }).catch(error => {
          // Handle error
        }).finally(() => {
          loadingOldPrescriptions.value = false;
        });
      }
    };
    const onOldPrescriptionSelect = (index) => {
      selectedOldPrescription.value = index;
      medicines.value = [index];
      displayOldPrescriptions.value = false;
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };


    watch(
      () => props.errors,
      () => {
        mapErrorsToSubComponents();
      },
      { deep: true }
    );

    onMounted(() => {
      getMedicineForms();
      getShortDosageExpressions();
    });

    return {
      currentPatientID,
      id,
      medicines,
      forms,
      expressions,
      loadingOldPrescriptions,
      displayOldPrescriptions,
      oldPrescriptions,
      selectedOldPrescription,
      pharmacyRef,
      medicinesRefs,
      addMedicine,
      setMedicineRef,
      onMedicineHelp,
      lgnUrl,
      mapErrorsToSubComponents,
      getMedicineForms,
      getShortDosageExpressions,
      removeMedicine,
      openModel,
      closeOldPrescriptionsModal,
      newPrescriptionRequestParams,
      onSend,
      onPreSelectedMedicine,
      getOldPrescriptions,
      onOldPrescriptionSelect,
      formatDate
    };
  },
};
</script>