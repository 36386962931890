<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="500">
        <v-card class="p-4">
            <v-text-field v-model="title" class="mb-4" variant="solo" hide-details="auto" :label="$t('autoletter.config.label_for_config')"></v-text-field>
            <v-select
                :label="$t('autoletter.config.opens_in_mode')"
                :items="modes"
                item-title="name"
                v-model="selectedMode"
            ></v-select>
            <v-checkbox v-if="secretary" v-model="shared"> 
                <template v-slot:label>
                    <div class="mt-2 ml-1">
                        {{ $t('autoletter.config.shared_setting') }}
                    </div>
                </template>
            </v-checkbox>
            <div v-if="this.title && isInvalid" class="text-center text-danger user-select-none">{{ $t('autoletter.config.save_already_exists') }}</div>
            <v-btn class="v-btn" @click="saveConfiguration()" :disabled="isInvalid">
                {{ $t('autoletter.config.save') }}
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    props: {
        invalid: {
            type: Array,
            required: false,
            default: () => []
        },
        secretary: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        this.init();
    },
    watch: {
        dialog() {
            if(!this.dialog) {
                this.resetModal();
            }
        }
    },
    computed: {
        isInvalid() {
            if(this.invalid.includes(this.title)) {
                return true;
            }
            return this.title.length === 0;
        }
    },
    data() {
        return {
            dialog: false,
            title: '',
            selectedMode: 'tmitem',
            shared: false,
            modes: []
        };
    },
    methods: {
        init() {
            this.modes = [
                { "value": "tmitem", "name": this.$i18n.t('autoletter.settings.bookings') },
                { "value": "case", "name": this.$i18n.t('autoletter.settings.treatments') },
                { "value": "casebook", "name": this.$i18n.t('autoletter.settings.casebooks') },
                { "value": "ipitem", "name": this.$i18n.t('autoletter.settings.admissions') }
            ];
        },
        saveConfiguration() {
            this.$emit('save', this.title, this.selectedMode, this.shared);
        },
        resetModal() {
            this.title = '';
        },
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        }
    }
};
</script>
<style scoped>
.v-btn {
    height: 56px;
}
</style>
