import ApiBase from './apibase'; 


export default new class Prescription extends ApiBase { 
    constructor() {
        super('prescription');
    }
}


// export default new class Prescription extends ApiBase {
//     // constructor() {
//     // }

//      /**
//      * Get available pharmacies
//      * 
//      * @returns {Promise}
//      */
//      getAvailablePharmacies() {
//         return this.getData('presc/pharmacies');
//     }

//       /**
//      * Get Doctors recently issued prescriptions 
//      * 
//      * @param {Number} id
//      * @returns {Promise}
//      */
//       getDoctorsRecentPrescriptions(id) {
//         return this.getData(`presc/recent/${id}`);
//     } 

//      /**
//      * Get Medicine forms table
//      * 
//      * @returns {Promise}
//      */
//      getMedicineForms() {
//         return this.getData('presc/forms');
//     }

//      /**
//      * Get a patient's prescriptions
//      * 
//      * @param {Number} patient The Patient's id
//      * @returns {Promise}
//      */
//      getPatientPrescriptions(patient) {
//         return this.getData(`presc/patient/${patient}`);
//     }

//     /**
//      * Get prescription short dosage expressions
//      * 
//      * @returns {Promise}
//      */
//     getShortDosageExpressions() {
//         return this.getData('presc/exprs');
//     }

// }();


