<template>
    <span>
        <svg
            x="0px" 
            y="0px" 
            viewBox="0 0 209.3 78.2"
            enable-background="new 0 0 209.3 78.2" 
            xml:space="preserve"
            :whidth="width"
            :height="height"
        >
            <g 
                id="layer1" 
                transform="translate(-269.17966,-434.91074)"
            >
                <path 
                    id="path3354-8-2" 
                    fill="#57A2FF" 
                    d="M308.3,434.9c-21.6,0-39.1,17.5-39.1,39.1c0,0,0,0,0,0c0,21.6,17.5,39.1,39.1,39.1
				c21.6,0,39.1-17.5,39.1-39.1c0,0,0,0,0,0C347.4,452.4,329.9,434.9,308.3,434.9C308.3,434.9,308.3,434.9,308.3,434.9L308.3,434.9z
				M308.3,446.3c15.3,0,27.7,12.4,27.7,27.7c0,0,0,0,0,0c0,15.3-12.4,27.7-27.7,27.7c-15.3,0-27.7-12.4-27.7-27.7
				C280.6,458.7,293,446.3,308.3,446.3z" 
                />
                <g enable-background="new    ">
                    <path 
                        fill="#131313" 
                        d="M370,486.6c-3.5,0-6.2-1-8.1-3c-1.9-2-2.9-4.8-2.9-8.3c0-3.4,0.9-6.2,2.7-8.2c1.8-2,4.3-3.1,7.5-3.1
					c3,0,5.3,0.9,6.9,2.8c1.6,1.9,2.5,4.3,2.5,7.4c0,0.2,0,0.6,0,1.1c0,0.5,0,0.9,0,1.1h-14.4c0.1,2,0.6,3.6,1.7,4.7s2.5,1.7,4.4,1.7
					c2.4,0,4.6-0.6,6.8-1.9l0.7,3.7C375.6,485.9,373,486.6,370,486.6z M364.3,473.2h9.5c0-1.8-0.4-3.1-1.2-4.1
					c-0.8-0.9-1.9-1.4-3.3-1.4c-1.3,0-2.4,0.5-3.4,1.4C364.9,470,364.4,471.3,364.3,473.2z"
                    />
                    <path 
                        fill="#131313" 
                        d="M396.1,486.6c-4.4,0-7.8-1.3-10.3-4c-2.5-2.7-3.7-6.3-3.7-10.9c0-2.9,0.6-5.5,1.7-7.7s2.9-4,5.1-5.2
					c2.2-1.2,4.9-1.9,8-1.9c3.5,0,6.5,0.8,9,2.3l-1.7,4c-2.4-1.3-4.8-1.9-7.2-1.9c-2.9,0-5.2,0.9-6.9,2.8s-2.5,4.5-2.5,7.7
					c0,3.2,0.7,5.8,2.2,7.7s3.7,2.8,6.5,2.8c1.8,0,3.5-0.3,5-0.9v-6.7h-6l0-4.2h11v13.9c-1.8,0.7-3.5,1.3-5.1,1.6
					S398,486.6,396.1,486.6z"
                    />
                    <path 
                        fill="#131313" 
                        d="M417.3,486.6c-2,0-3.6-0.6-4.9-1.7c-1.2-1.1-1.9-2.7-1.9-4.8c0-2.2,0.7-3.9,2.2-5c1.5-1.1,3.7-1.8,6.7-2.1
					c0.4-0.1,0.8-0.1,1.3-0.2c0.5-0.1,1-0.1,1.6-0.2c0.6-0.1,1.1-0.1,1.4-0.1v-1.1c0-1.3-0.3-2.3-0.9-2.9c-0.6-0.6-1.5-0.9-2.8-0.9
					c-1.8,0-3.9,0.5-6.5,1.5c0,0-0.2-0.6-0.7-1.8s-0.6-1.7-0.6-1.8c2.5-1.1,5.3-1.6,8.3-1.6c2.9,0,5,0.6,6.4,1.9c1.3,1.3,2,3.3,2,6.2
					v14.1h-3.8c0-0.1-0.2-0.5-0.4-1.3c-0.3-0.8-0.4-1.3-0.4-1.3c-1.1,1-2.1,1.8-3.2,2.3S418.8,486.6,417.3,486.6z M418.7,482.9
					c1.2,0,2.2-0.3,3.1-0.9c0.9-0.6,1.5-1.3,1.9-2.1v-4.2c0,0-0.4,0-1,0.1c-0.7,0.1-1,0.1-1.1,0.1c-2.1,0.2-3.6,0.6-4.6,1.2
					c-1,0.6-1.5,1.6-1.5,2.9c0,0.9,0.3,1.6,0.8,2.1S417.7,482.9,418.7,482.9z M418.3,461.6l3.4-7.5h5.9l-6.2,7.5H418.3z" 
                    />
                    <path 
                        fill="#131313" 
                        d="M442.8,486.5c-1.5,0-2.7-0.2-3.7-0.5s-1.7-0.9-2.3-1.6c-0.6-0.7-0.9-1.6-1.2-2.6c-0.2-1-0.3-2.3-0.3-3.8
					v-10.2h-3.5l0.2-2.8l3.7-0.3l1.7-5.9l3.2,0v5.9h5.4v3.1h-5.4V478c0,1.6,0.2,2.8,0.7,3.5c0.5,0.7,1.3,1,2.6,1
					c0.7,0,1.5-0.1,2.4-0.2l0.1,3.7c0,0-0.2,0-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.2c-0.3,0-0.6,0.1-1,0.1
					C443.5,486.4,443.1,486.5,442.8,486.5z"
                    />
                    <path 
                        fill="#131313" 
                        d="M458.6,486.5c-1.5,0-2.7-0.2-3.7-0.5c-1-0.3-1.7-0.9-2.3-1.6c-0.6-0.7-0.9-1.6-1.2-2.6
					c-0.2-1-0.3-2.3-0.3-3.8v-10.2h-3.5l0.2-2.8l3.7-0.3l1.7-5.9l3.2,0v5.9h5.4v3.1h-5.4V478c0,1.6,0.2,2.8,0.7,3.5
					c0.5,0.7,1.3,1,2.6,1c0.7,0,1.5-0.1,2.4-0.2l0.1,3.7c0,0-0.2,0-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.2c-0.3,0-0.6,0.1-1,0.1
					C459.3,486.4,458.9,486.5,458.6,486.5z"
                    />
                </g>
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: '100'
        },
        height: {
            type: String,
            required: false,
            default: '100'
        },
    }
};
</script>