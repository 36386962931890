<template>

  <v-card flat>
        <v-toolbar color="transparent" @mouseenter="hovered = true" @mouseleave="hovered = false">
          <v-avatar :color="getHoverColor(index)">
            <v-icon>{{ getAvatarIcon(index) }}</v-icon>
          </v-avatar>
          <v-toolbar-title>
            <strong>{{ journalData.data.name }}</strong>
            <v-chip class="ml-3">{{ journalData.data.atc }}</v-chip>
          </v-toolbar-title>
          <v-chip prepend-icon="mdi-doctor" size="small" class="text-0">{{ journalData.user }}</v-chip>
        </v-toolbar>
        <div class="journal-indent">
          <v-card-title class="pt-0"> Disease </v-card-title>
          <v-card-text class="pt-0">
            {{ journalData.data.disease }}
          </v-card-text>
          <v-card-title class="pt-0">Administration</v-card-title>
          <v-card-text class="pt-0"> {{ journalData.data.administration }} </v-card-text>
          <v-card-title class="pt-0">Location</v-card-title>
          <v-card-text class="pt-0"> {{ journalData.data.location }}</v-card-text>
        </div>
  </v-card>
</template>

<script>
export default {
  props: {
    journalData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      hovered: false,
      vaccinationItems: [],
      hoverColor: 'blue',
      avatarIcon: 'mdi-needle',
      hoveredItemIndex: null,

    };
  },
  computed: {
    transformedVaccinationItems() {
      return this.vaccinationItems.map(item => ({
        name: item.data.name,
        atc: item.data.atc,
        owner_name: item.data.metadata.owner_name,
        disease: item.data.disease,
        administration: item.data.administration,
        location: item.data.location,
      }));

    }
  },
  mounted() {
    this.vaccinationItems = this.journalData;
  },
  methods: {
    getHoverColor() {
      return this.hovered  ? 'grey' : 'blue';
    },
    getAvatarIcon() {
      return this.hovered ? 'mdi-pencil' : 'mdi-needle';
    }
  }
};
</script>

<style>
.journal-indent {
  margin-left: 40px;
}
</style>