<template>
  <v-row align="center">
    <v-col>
      <div v-if="!isEditing" @mouseover="showEditButton = true" @mouseleave="showEditButton = false" class="term-name">
        {{ modelValue }}
        <v-btn icon v-if="showEditButton" @click="isEditing = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div v-else class="edit-container">
        <v-text-field v-model="internalTermName" label="Edit Term Name" class="editing-field" />
        <v-btn icon @click="saveTermName" class="save-btn">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TermName",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      showEditButton: false,
      internalTermName: this.modelValue,
    };
  },
  watch: {
    modelValue(newTermName) {
      this.internalTermName = newTermName;
    },
  },
  methods: {
    saveTermName() {
      this.isEditing = false;
      this.$emit("update:modelValue", this.internalTermName);
    },
  },
};
</script>

<style scoped>
.term-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.editing-field {
  width: 75%;
}

.save-btn {
  margin-left: auto;
  margin-bottom: 20px;
}
</style>
