// This file contains all constants for actions
export const GET_AUTH_USER = 'getAuthUser';
export const PASSWORD_LOGIN = 'passwordLogin';
export const EID_LOGIN = 'eidLogin';
export const USER_LOGOUT = 'userLogout';
export const UPDATE_CURRENT_USER_EMAIL = 'updateCurrentUserEmail';
export const GET_IS_CLIENT = 'getIsClient';
export const SELECT_UNIT = 'selectUnit';

export const GET_CURRENT_UNIT = 'getCurrentUnit';
export const GET_CURRENT_USER_UNITS = 'getCurrentUserUnits';

export const GET_CURRENT_PATIENT = 'getCurrentPatient';
export const FIND_PATIENT_BY_CODENUMBER = 'findPatientByCodenumber';

export const GET_DOCTORS_RECENT_PRESCRIPTIONS = 'getDoctorsRecentPrescriptions';
export const GET_DOCTORS_INVOICES_TODAY = 'getDoctorsInvoicesToday';
export const GET_DOCTORS_CASH_REGISTERS = 'getDoctorsCashRegisters';

export const GET_SESSION_POS = 'getSessionPos';
export const GET_SIGNATURE_TYPE = 'getSignatureType';
export const GET_SESSION_KIOSK = 'getSessionKiosk';

export const CHANGE_LANG = 'changeLang';