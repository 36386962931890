import ApiBase from './apibase';

export default new class Invoice extends ApiBase {
    
    /**
     * 
     * @param {Object} params 
     * @returns {Promise}
     */
    verifyPassword(params) {
        return this.postData('user/password/verify', params);
    }
};