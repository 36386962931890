<template>
    <div class="base-countdown" :style="timeStyle">
        <div class="countdown-wrapper">
            <svg 
                class="base-timer__svg" 
                :width="width" 
                :height="height" 
                viewBox="0 0 100 100"
            >
                <g class="base-timer__circle">
                    <circle 
                        class="base-timer__path-elapsed" 
                        cx="50" 
                        cy="50" 
                        r="45"
                    />
                    <path
                        id="base-timer-path-remaining"
                        :stroke-dasharray="circleDasharray"
                        class="base-timer__path-remaining"
                        d="
                            M 50, 50
                            m -45, 0
                            a 45,45 0 1,0 90,0
                            a 45,45 0 1,0 -90,0
                            "
                    />
                </g>
            </svg>
            <span id="base-timer-label" class="base-timer__label">
                {{ formattedTimeLeft }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        timeLimit: {
            type: Number,
            required: false,
            default: 20
        },
        timeColor: {
            type: String,
            required: false,
            default: 'rgb(0, 123, 255)'
        },
        width: {
            type: String,
            required: false,
            default: '100'
        },
        height: {
            type: String,
            required: false,
            default: '100'
        },
    },
    data() {
        return {
            timeLeft: 20,
            timePassed: 0,
            fullDashArray: 283,
            circleDasharray: '286',
            timerInterval: null,
            tcolor: 'red'
        };
    },
    computed: {
        timeStyle() {
            return {
                '--time-color': this.timeColor
            };
        },
        formattedTimeLeft() {
            
            if (this.timeLeft < 0) {
                return '00';
            }

            let seconds = this.timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            if (seconds < 0) {
                return '00';
            }

            return seconds;
        }
    },
    created() {
        this.timeLeft = this.timeLimit;
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        startTimer() {
            this.timerInterval = setInterval(() => {
                
                this.timePassed++;
                
                this.timeLeft = this.timeLimit - this.timePassed;
                this.circleDasharray = `${(
                    this.calculateTimeFraction() * this.fullDashArray
                ).toFixed(0)} 283`;

                if (this.timeLeft <= 0) {
                    this.onTimesUp();
                }
            }, 1000);
        },
        formatTime(time) {
            let seconds = time % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return seconds;
        },
        calculateTimeFraction() {
            const rawTimeFraction = this.timeLeft / this.timeLimit;
            return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
        },
        setCircleDashArray() {
            
        },
        onTimesUp() {
            this.$emit('finish');
        }
    },
};
</script>
<style scoped>
    .countdown-wrapper {
        position: relative;
    }
    .base-countdown {
        position: absolute;
        max-width: 100px;
        max-height: 100px;
        bottom: 50px;
        left: calc(50% - 50px);
    }
    /* Removes SVG styling that would hide the time label */
    .base-timer__circle {
    fill: none;
    stroke: none;
    }

    /* The SVG path that displays the timer's progress */
    .base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: rgba(182, 182, 182, 0.5)
    }

    .base-timer__label {
        position: absolute;
        
        /* Size should match the parent container */
        width: 100px;
        height: 100px;
        
        /* Keep the label aligned to the top */
        top: 0;
        
        /* Create a flexible box that centers content vertically and horizontally */
        display: flex;
        align-items: center;
        justify-content: center;

        /* Sort of an arbitrary number; adjust to your liking */
        font-size: 48px;
    }
    
    .base-timer__path-remaining {
        /* Just as thick as the original ring */
        stroke-width: 7px;

        /* Rounds the line endings to create a seamless circle */
        stroke-linecap: round;

        /* Makes sure the animation starts at the top of the circle */
        transform: rotate(90deg);
        transform-origin: center;

        /* One second aligns with the speed of the countdown timer */
        transition: 1s linear all;

        /* Allows the ring to change color when the color value updates */
        stroke: var(--time-color);
    }

    .base-timer__svg {
        /* Flips the svg and makes the animation to move left-to-right */
        transform: scaleX(-1);
    }
</style>