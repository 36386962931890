export default {  
    'áburður':      'áburður', 
    'áhella':       'áhella',  
    'aug.eydr':     'augn- og eyrnadropar',   
    'augndr':       'augnropar',   
    'augngel':      'augngel', 
    'augnhl':       'augnhlaup',  
    'augnsm':       'augnsmyrsl',  
    'baðþ':         'baðþ',    
    'bl.lausn':     'blettunarlausn',
    'blóð.sk':      'blóðskilunarlausn', 
    'bóluefni':     'bóluefni',
    'dreifit.':     'dreifitöflur',
    'dropar':       'dropar',   
    'duft':         'duft',    
    'endaþ.dr':     'endaþarmsdreifa',
    'eþ-krem':      'endaþarmskrem', 
    'eþ-lausn':     'endaþarmslausn',
    'eþ-smyr':      'endaþarmssmyrsli', 
    'eþ-stíll':     'endaþarmsstíll',
    'eyrnadr':      'eyrnadropar',   
    'filmhtfl':     'filmuhúðaðar töflur',
    'fleyti':       'fleyti',
    'forbl':        'forblanda',
    'forðahlk':     'forðahylki',
    'forðakyr':     'forðakyrni',
    'forðapl':      'forðaplástur', 
    'forðatfl':     'forðatöflur', 
    'freyðik':      'freyðikyrni',  
    'freyðit':      'freyðitöflur', 
    'frþ.tfl':      'frostþurrkaðar töflur',
    'frþlyf':       'frostþurrkað lyf',
    'hálsband':     'hálsband',
    'hársápa':      'hársápa', 
    'hlaup':        'hlaup',   
    'húðduft':      'húðduft',
    'húðfl':        'húðfleyti',   
    'húðfroða':     'húðfroða',
    'húðlausn':     'húðlausn',
    'húðpl.':       'húðplástur',
    'húðúði':       'húðúði',  
    'húðuðtfl':     'húðuð tafla',   
    'hylki':        'hylki',   
    'innhl':        'innhellislyf',
    'innhl/tf':     'innhellislyf og töflur',
    'innsogsl':     'innsogslyf',
    'innúðadu':     'innúðaduft',
    'innúðal':      'innúðalyf',
    'innö.gas':     'innöndunargas',
    'innö.guf':     'innöndunargufa',
    'innönd.l':     'innöndunarlyf',
    'innönddu':     'innöndunarduft',
    'innöndl':      'innöndunarlausn', 
    'irf':          'innrennslisfleyti',     
    'irl':          'innrennslislyf',     
    'irs':          'innrennslisstofn',     
    'irþ':          'innrennslisþykkni', 
    'irþstofn':     'innrennslisþykknisstofn',
    'iös/sts':      'innöndunarstofn/stungulyfsstofn',
    'ígr.sett':     'ígræðslusett',  
    'kabipen':      'kabipen',
    'krem':         'krem',    
    'krem/sti':     'krem/stíll',
    'kvið.sk':      'kviðskilunarlausn', 
    'kyrni':        'kyrni',   
    'lakk':         'lakk',    
    'lausn':        'lausn',   
    'lausnard':     'lausnardropar',
    'lausnart':     'lausnartöflur',
    'lausnduf':     'lausnarduft',
    'leginnle':     'leginnlegg',
    'legstíl':      'legstíll', 
    'leysir':       'leysir',  
    'ltb':          'lausn til blóðsöfnunar',
    'ltm':          'lausn til munnskolunar',
    'lyf.sápa':     'lyfjasápa',
    'lyfjagas':     'lyfjagas',
    'lykkja':       'lykkja',
    'mixt.kyr':     'mixtúrukyrni',
    'mixtduft':     'mixtúruduft',      
    'mixtúra':      'mixtúra', 
    'mixtúrud':     'mixtúrudropar',
    'mixtúruþ':     'mixtúruþykkni',
    'munndr.t':     'munndreifitöflur',
    'munnh.du':     'munnholsduft',
    'munnh.hl':     'munnholshlaup',
    'munnh.l':      'munnholslausn', 
    'munnh.pa':     'munnholspasta', 
    'munnhúði':     'munnholsúði',
    'munnltfl':     'munnlausnartöflur',
    'munnsmyr':     'munnsmyrsl',   
    'munnskol':     'munnskol',
    'munnstfl':     'munnsogstöflur',   
    'nefdr':        'nefdropar',   
    'nefsm':        'nefsmyrsl',   
    'nefúðad':      'nefúðaduft', 
    'nefúði':       'púst',    
    'olía':         'olía',
    'pasta':        'pasta',   
    'past-inn':     'pasta til inntöku',
    'pillur':       'pillur',
    'pinni':        'pinni (sleikjó)',
    'plástur':      'plástur', 
    'saft':         'saft',
    'sápa':         'sápa',    
    'sáralín':      'sáralín',
    'sement':       'sement', 
    'sh-hylki':     'sýruhjúphylki',
    'sh-tfl':       'sýruhjúptöflur',   
    'skammtar':     'skammtar',
    'sk-hlaup':     'skeiðarhlaup',
    'sk-hring':     'skeiðarhring',
    'sk-innl':      'skeiðarinnl', 
    'sk-krem':      'skeiðarkrem', 
    'sk-stíll':     'skeiðarstíll',
    'sk-svamp':     'skeiðarsvampur',
    'sk-tafla':     'skeiðartafla',
    'sk-tfl':       'skeiðartafla',   
    'skolvökv':     'skolvökvi',
    'smyrsli':      'smyrsli', 
    'sog/tugg':     'sogtöflur/tuggutöflur',   
    'sogtfl':       'sogtafla',   
    'spray':        'spray',
    'std':          'stungulyfsdreifa',
    'stl':          'stungulyf',     
    'stl/irl':      'stungulyf/innrennslislyf', 
    'stl/irs':      'stungulyf/innrennslisstofn', 
    'stl/irþ':      'stungulyf/innrennslisþykkni', 
    'st/irþ':       'stungulyf/innrennslisþykkni', 
    'stofn':        'stofn',   
    'sts':          'stungulyfsstofn',     
    'sts/irs':      'stungulyfsstofn/innrennslisstofn', 
    'stþ':          'stungulyfsþykkni',
    'stþstofn':     'stungulyfsþykknisstofn',     
    'svampur':      'svampur', 
    'sþ-hylki':     'sýruþolið hylki',
    'sþ-tfl':       'sýruþolnar töflur',   
    'tanndrei':     'tanndreifa',
    'tannlakk':     'tannlakk',
    'tannpast':     'tannpasta',
    'thhlaup':      'tannholdshlaup', 
    'tuggutfl':     'tuggutöflur',   
    'tungur.t':     'tungurótartöflur',
    'tyggigúm':     'tyggigúmmí',
    'töflur':       'töflur',   
    'úðaáb':        'úðaáburður',    
    'úðalausn':     'úðalausn',    
    'úðalyf':       'úðalyf',    
    'úðatæki':      'úðatæki',    
    'vefjal.n':     'vefjalímsnetja',
    'vefjalím':     'vefjalím',
    'vefjalyf':     'vefjalyf',
    'vefjasv':      'vefjasvampur', 
    'vefjatfl':     'vefjatöflur',   
    'þvst':         'Þvagrásarstíll',
    'þynnil':       'þynnilausn'
};  