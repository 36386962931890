<template>
    <div :class="alertClass">
      <i :class="fontAwesomeIcon"></i> {{ header }}
      <slot />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      header: {
        type: String,
        default: ''
      },
      alertClass: {
        type: String,
        default: 'alert'
      },
      fontAwesomeIcon: {
        type: String,
        default: ''
      }
    }
  };
  </script>
  