<template>
	<ModalSimple @close="">
		<template #header>
			{{ $t('doctor_dashboard.change_email') }}
		</template>


		<TextInput v-model="emailValue" label="Netfang"></TextInput>
		<template #footer>
			<div>
				<button @click="updateEmail" type="button" class="btn btn-success">{{ $t('access_control.save') }}</button>
				<button @click="$emit('close')" type="button" class="btn btn-default">{{ $t('invoice_create.cancel') }}</button>
			</div>
		</template>
	</ModalSimple>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { UPDATE_CURRENT_USER_EMAIL } from '../../store/actionTypes';
import ModalSimple from '@components/modalSimple/modalSimple.vue';
import TextInput from '@components/textInput/textInput.vue';

export default {
	components: {
		ModalSimple,
		TextInput
	},
	setup() {
		const instance = getCurrentInstance();


		const store = useStore();
		const email = ref(store.getters.currentUserEmail);


		const emailValue = computed({
			get: () => email.value,
			set: (value) => {
				email.value = value;
			}
		});

		const emitClose = () => {
			// getCurrentInstance().emit('close', null);
		};



		const updateEmail = () => {
			store.dispatch(UPDATE_CURRENT_USER_EMAIL, email.value).then(() => {

				emitClose();
			}).catch(err => {

			});
		};

		return {
			emailValue,
			emitClose,
			updateEmail,
		};
	}
};
</script>
