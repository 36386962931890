<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <td><strong>{{ $t('login.username') }}</strong></td>
            <td>{{ username }}</td>
        </tr>
        <tr>
            <td><strong>{{ $t('doctor_dashboard.name') }}</strong></td>
            <td>{{ name }}</td>
        </tr>
        <tr>
            <td><strong>{{ $t('cash_registers.specialty') }}</strong></td>
            <td>{{ title }}</td>
        </tr><tr>
            <td><strong>{{ $t('prescription.email') }}</strong></td>
            <td>{{ email }}</td>
        </tr>
    </thead>
    </table>
</template>
<script>
    export default {
        methods:{
            t(key) {
            return this.$t(key);
        },
        },
        props: {
            user: {
                required: true,
                type: Object
            }
        },
        computed: {
            username() {
                return this.user.username;
            },
            name() {
                return this.user.userdata ? this.user.userdata.name : '';
            },
            title() {
                return this.user.userdata ? this.user.userdata.title : '';
            },
            email() {
                return this.user.email;
            }
        }
    }
</script>
