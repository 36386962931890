

//vue3


const Admin = () => import(/* webpackChunkName: "group-admin" */ '@components/admin');
const Roles = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/roles');
const RoleEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/roles/edit');
const Rates = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/rate/rates');
const RateEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/rate/edit');
const SpecialistCategories = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/specialistCategory');
const SpecialistCategoriesEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/specialistCategory/edit');
const Settings = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/settings');
const Telemedicine = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/telemedicine');
const TelemedicineEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/telemedicine/edit');
const Pos = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/pos');
const PosEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/pos/edit');
const Destinations = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/destination');
const DestinationEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/destination/edit');
const DestinationShow = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/destination/show');
const Kiosks = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk');
const KioskShow = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/show');
const KioskEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/kiosk/edit');
const Signatures = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/signature');
const SignatureShow = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/signature/show');
const SignatureEdit = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/signature/edit');
const SignatureIndex = () => import(/* webpackChunkName: "group-admin" */ '@components/admin/signature/index');

const Rest = () => import(/* webpackChunkName: "group-admin" */ '@components/dev/rest');
const Test = () => import(/* webpackChunkName: "group-admin" */ '@components/dev/test');
const Tiptap = () => import(/* webpackChunkName: "group-admin" */ '@components/dev/tiptap');


import { GUARD_ADMIN, GUARD_SIGNATURE as access } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/admin', 
        component: Admin, 
        name: 'admin', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/role', 
        component: Roles, 
        name: 'roles', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/role/edit/:id', 
        component: RoleEdit, 
        name: 'role-edit', 
        meta: { access : GUARD_ADMIN} 
    },
    {
        path: '/admin/role/create', 
        component: RoleEdit, 
        name: 'role-create', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/rates', 
        component: Rates, 
        name: 'rates', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/rates/edit/:id', 
        component: RateEdit, 
        name: 'rate-edit', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/specialist', 
        component: SpecialistCategories, 
        name: 'specialist', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/specialist/edit/:id', 
        component: SpecialistCategoriesEdit, 
        name: 'specialist-edit', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/settings', 
        component: Settings,
        name: 'settings', 
        meta: { access : GUARD_ADMIN } 
    },
    {
        path: '/admin/telemedicine',
        component: Telemedicine,
        name: 'telemedicine',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/telemedicine/edit/:id',
        component: TelemedicineEdit,
        name: 'telemedicine-edit',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/pos',
        component: Pos,
        name: 'pos',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/pos/edit/:id',
        component: PosEdit,
        name: 'pos-edit',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/pos/create',
        component: PosEdit,
        name: 'pos-create',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/destination',
        component: Destinations,
        name: 'destinations',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/destination/show/:id',
        component: DestinationShow,
        name: 'destination-show',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/destination/edit/:id',
        component: DestinationEdit,
        name: 'destination-edit',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/destination/create',
        component: DestinationEdit,
        name: 'destination-create',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/kiosk',
        component: Kiosks,
        name: 'kiosk-admin',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/kiosk/show/:id',
        component: KioskShow,
        name: 'kiosk-show',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/kiosk/edit/:id',
        component: KioskEdit,
        name: 'kiosk-edit',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/kiosk/create',
        component: KioskEdit,
        name: 'kiosk-create',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/signature',
        component: Signatures,
        name: 'signature-admin',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/signature/show/:id',
        component: SignatureShow,
        name: 'signature-show',
        meta: { access }
    },
    {
        path: '/admin/signature/edit/:id',
        component: SignatureEdit,
        name: 'signature-edit',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/signature/create',
        component: SignatureEdit,
        name: 'signature-create',
        meta: { access : GUARD_ADMIN }
    },
    {
        path: '/admin/signature/index',
        component: SignatureIndex,
        name: 'signature-index',
        meta: { access },
    },
    {    
        path: '/admin/rest',
        component: Rest,
        name: 'rest',
        meta: { access : GUARD_ADMIN }
    },
    {    
        path: '/admin/test',
        component: Test,
        name: 'test',
        meta: { access }
    },
    {    
        path: '/admin/tiptap',
        component: Tiptap,
        name: 'tiptap-demo',
        meta: { access }
    },
];
