<template>
    <div>
        <div v-if="error" class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-center actions">
                        <div class="text-center text-primary">
                            <p><i class="fal fa-exclamation-triangle fa-fw fa-10x " /></p> 
                            <h3 class="text-center">{{ error.message }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedInvoice < 0" class="container">
            <div v-if="patient" class="row pt-5">
                <div class="col-md-6 offset-md-3">
                    <h1>{{ patient.name }}</h1>
                    <p>{{ t('invoice_create.ssn') }}: {{ $filters(patient.codenumber) }}</p>
                </div>
            </div>
            <div v-if="patient" class="row mt-5">
                <div class="col-md-6 offset-md-3">
                    <invoices :invoices="patient.invoices" @select="selectInvoice($event)" />
                </div>
            </div>
        </div>
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-sm-5 px-5 py-5">
                    <invoice :invoice="invoice" :patient="patient" />
                </div>
                <div class="col-sm-7 p-5 bg-primary actions">
                    <div class="d-flex align-items-center justify-content-center">
                        <signature v-if="!signed" class="flex-fill" @signed="onSigned($event)" />
                        <pos 
                            v-if="signed && invoice.printed != '1'" 
                            class="flex-fill" 
                            :invoice="invoice"
                            @finish="onPayed()"
                        />
                        <send 
                            v-if="signed && invoice.printed == '1'" 
                            class="flex-fill" 
                            :invoice-id="invoice.id"
                            :email="patient.email" 
                            @sent="onSent()"
                            @cancel="onSent()"
                        />
                    </div>
                    
                </div>
            </div>
        </div>
        <cancel />
        <countdown v-if="countdown" />
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
import Invoice from '@components/kiosk/payment/invoice';
import Invoices from '@components/kiosk/payment/invoices';
import Signature from '@components/kiosk/payment/signature';
import Pos from '@components/kiosk/payment/pos';
import Cancel from '@components/kiosk/cancel';
import Send from '@components/kiosk/payment/send';
import Countdown from '@components/countdown';

export default {
    components: {
        Invoice,
        Invoices,
        Signature,
        Pos,
        Cancel,
        Send,
        Countdown,
    },
    props: {},
    data() {
        return {
            selectedInvoice: -1,
            signature: '',
            signed: false,
            error: null,
            countdown: false
        };
    },
    computed: {
        patient() {
            return this.$store.state.patient;
        },
        invoice() {
            if (this.selectedInvoice < 0) { return null; }
            return this.patient.invoices[this.selectedInvoice];
        },
    },
    watch: {
        patient:{
            handler(value) {
                if (value.invoices.length == 1) {
                    this.selectedInvoice = 0;
                } else if (value.invoices.length == 0) {
                    this.returnToKeypad();
                }
            },
            deep: true
        },
    },
    created() {
        if (this.patient.invoices.length == 1) {
            this.selectedInvoice = 0;
        } else if (this.patient.invoices.length == 0) {
            this.returnToKeypad();
        }
    },
    mounted() {},
    methods: {
        t(key) {
            return this.$t(key);
        },
        getPatient() {
            KioskApi.getPatient(this.patient.codenumber).then(response => {
                this.$store.commit('setPatient', response);
            }). catch(err => {
                this.error = err.response.data;
                this.returnToKeypad();
            });
        },
        selectInvoice(index) {
            this.selectedInvoice = index;
        },
        onSigned(value) {
            this.signature = value;
            KioskApi.postSignature(this.invoice.id, {signature: value}).then(() => {
                this.signed = true;
            });
        },
        onPayed() {
            KioskApi.setInvoicePayed(this.invoice.id);
            KioskApi.printInvoice(this.invoice.id).then(() => {
                this.patient.invoices[this.selectedInvoice].printed = '1';
            });
            

        },
        onSent() {
            if (this.patient.invoices.length - 1 < 1) {
                this.returnToKeypad(0);
            } else {
                this.getPatient();
            }
        },
        returnToKeypad(seconds) {
            let ms = seconds * 1000;
            this.countdown = true;
            if (typeof seconds === 'undefined' || seconds === null) {
                ms = 20000;
            }
            setTimeout(() => {  this.$router.push({name: 'welcome'}); }, ms);
        }
    },
};
</script>
<style>
.actions {
    min-height: 100vh;
}
.actions > div {
    min-height: 100%;
}
</style>