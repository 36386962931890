<template>
  <v-container>
    <term-name v-model="termName" />

    <v-row>
      <v-col>
        <v-text-field
          v-model="formattedDate"
          label="Select Date"
          readonly
          @click="dialog = true"
        />
        <v-dialog v-model="dialog" width="290">
          <v-date-picker v-model="selectedDate" @input="updateDate" />
          <v-spacer />
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn text @click="dialog = false">OK</v-btn>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'DateValue',
  components: {
    TermName
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const selectedDate = ref(null);
    const formattedDate = ref('');
    const termName = ref('Date of Admission');

    const updateDate = (date) => {
      selectedDate.value = date;
      formattedDate.value = date ? date.toISOString().split('T')[0] : '';
      emit('update:keywordData', { ...props.keywordData, term_value: formattedDate.value });
    };

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          const date = newVal.term_value ? new Date(newVal.term_value) : null;
          selectedDate.value = date;
          formattedDate.value = date ? date.toISOString().split('T')[0] : '';
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    watch(formattedDate, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newVal });
    });

    return {
      dialog,
      selectedDate,
      formattedDate,
      termName,
      updateDate
    };
  }
});
</script>

<style scoped>
/* Your styles here */
</style>
