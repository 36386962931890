<template>
	<v-card flat>

		<template v-if="prescriptionItems.length > 0">
			<div v-for="(item, index) in transformedPrescriptionItems" :key="index">
				<v-toolbar color="transparent" @mouseenter="handleMouseEnter(index)"
					@mouseleave="handleMouseLeave(index)">
					<v-avatar :color="getHoverColor(index)">
						<v-icon>{{ getAvatarIcon(index) }}</v-icon>
					</v-avatar>
					<v-toolbar-title>
						<span class="font-weight-medium">{{ item.name }}</span> {{ item.strength }}
						<span class="text-grey text-caption">{{ item.package }}.</span>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
				</v-toolbar>
				<v-card-text class="journal-indent pt-0">
					<p>
						{{ item.dosage }}

					</p>
					<p class="mt-2">
						<span class="font-weight-bold">Valid from</span> {{ item.start_date }}
						<span class="font-weight-bold">-</span> {{ item.end_date }}
					</p>
					<p class="mt-2">
						<span class="font-weight-bold">Used for</span> {{ item.end_date }}
					</p>
				</v-card-text>
			</div>
		</template>


	</v-card>
</template>

<script>
export default {
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			prescriptionItems: [],
			hoveredItemIndex: null,

			hoverColor: 'blue',
			avatarIcon: 'mdi-prescription'
		};
	},
	computed: {
		transformedPrescriptionItems() {

			// console.log("prescriptin inside transformed", this.prescriptionItems)
			return this.prescriptionItems.map(item => ({
				name: item.data.name,
				strength: item.data.strength,
				dosage: item.data.dosage,
				package: item.data.package,
				start_date: item.data.start_date,
				end_date: item.data.end_date,
				owner_name: item.data.metadata.owner_name,
			}));

		}
	},
	mounted() {

		const type16Items = this.journalData.items.filter(item => item.type === '16');
		this.prescriptionItems = type16Items;
	},
	methods: {

		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave(index) {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hoveredItemIndex === index ? '#EEEEEE' : 'blue';
		},
		getAvatarIcon(index) {
			return this.hoveredItemIndex === index ? 'mdi-pencil' : 'mdi-prescription';
		}
	}
};
</script>
