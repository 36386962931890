<template lang="html">
	<div v-if="active" class="egatt-loading-wheel-wrapper">
		<div class="egatt-loading-wheel">
			<i class="fas fa-spin fa-spinner fa-7x"></i> <br>
			<p>
				Augnablik <br>
				<small v-show="message">{{ message }}</small>
			</p>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		props:{
			message: { required: false},
			active: { required: true }
		}
	}
</script>