const PrescriptionRegistry = () => import(/* webpackChunkName: "group-prescreg" */ '@components/prescriptionRegistry');

import { GUARD_DRUGS } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/prescreg', 
        component: PrescriptionRegistry, 
        name: 'presc-registry',
        meta: { access: GUARD_DRUGS }
    },
]