<template>
	<div class="form-group">
        <div class="card">
            <div class="card-header">
                {{ $t('reception.select_reception_kiosk') }}
            </div>
            <div class="list-group-flush">
                <div v-if="waitingForKiosks" class="list-group-item">
                    <i class="fas fa-spinner fa-spin" />  {{ $t('reception.fetch_reception_kiosk') }}
                </div>
                <div v-if="!waitingForKiosks && kiosks.lenght < 1" class="list-group-item">
                    {{ $t('reception.no_kiosk_found') }}
                </div>
                <div 
                    v-for="kiosk in kiosks" 
                    :key="kiosk.id" 
                    class="list-group-item list-group-item-action clickable" 
                    @click="onClick(kiosk.id)"
                >
                    {{ kiosk.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
export default {
	components: {},
	props: {},
	data() {
		return {
			kiosks: [],
        	waitingForKiosks: false
		};
	},
	computed: {},
	created() {
		this.getKiosks();
	},
	mounted() {},
	methods: {
        t(key) {
            return this.$t(key);
        },
		getKiosks() {
            this.waitingForKiosks = true;
            KioskApi.getAllKiosks().then(({data}) => this.kiosks = data).finally(() => {
                this.waitingForKiosks = false;
            });
        },
        onClick(kioskId) {
        	this.$emit('selected', kioskId);
        }
	},
};
</script>
<style></style>