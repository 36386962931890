<template>
    <modal-simple v-if="active" size="large" @close="onClose">
        <template slot="header">
            {{ $t('invoice.complete_invoice') }}: {{ invoice.invoice_id }}
        </template>
        <select-payment-type v-if="stagePaymentType" v-model="paymentType" />
        <canvas-signature v-else-if="stageSignature && signatureType == 'topaz'" />
        <signature-request v-else-if="stageSignature && signatureType == 'kiosk'" :invoice-id="invoice.id" />
        <div v-else-if="stageEmail">
            <text-input 
                v-model="email" 
                label="$t('invoice.payer_email')"
            />
            <div class="form-group">
                <div 
                    v-if="emailStatus !== undefined" 
                    class="alert" 
                    :class="{'alert-danger':emailStatus=='error', 'alert-info':emailStatus=='sending', 'alert-success':emailStatus=='success'}"
                >
                    <i 
                        class="fal" 
                        :class="{'fa-exclamation-triangle':emailStatus=='error', 'fa-sync fa-spin':emailStatus=='sending', 'fa-check-circle':emailStatus=='success'}" 
                    /> 
                    {{ emailStatusMessage }}
                </div>
            </div>
        </div>
        <template slot="footer">
            <button 
                v-if="selected > stagePaymentType" 
                class="btn btn-outline-secondary"
                @click="onPrintClick" 
            >
                <i class="fal fa-print fa-fw fa-fh" />
            </button>
            <button 
                v-if="!stagePaymentType" 
                class="btn btn-secondary"
                @click="onDownStage" 
            >
                <i class="far fa-angle-left" /> {{ t('navbar.back') }}
            </button>
            <button 
                v-if="!stageEmail" 
                class="btn btn-primary" 
                @click="onUpStage" 
            >
            {{ $t('invoice_create.next') }} <i class="far fa-angle-right" />
            </button>
            <button 
                v-if="stageEmail" 
                type="button" 
                class="btn btn-primary"
                @click="onSend" 
            >
            {{ $t('invoice_create.process') }}
            </button>
        </template>
    </modal-simple>
</template>
<script>
import { mapGetters } from 'vuex';
import localService from '@src/api/local';
import InvoiceApi from '@src/api/invoice';
import ModalSimple from '@components/modalSimple';
import SelectPaymentType from '@components/selectPaymentType';
import CanvasSignature from '@components/canvasSignature';
import TextInput from '@components/textInput';
import SignatureTablet from '@src/classes/signatureTablet';
import SignatureRequest from '@components/reception/paperlessModal/signature-request';

const STAGE_PAYMENT_TYPE = 1, STAGE_SIGNATURE = 2, STAGE_EMAIL = 3;

export default {
    components: {
        ModalSimple,
        SelectPaymentType,
        CanvasSignature,
        SignatureRequest,
        TextInput
    },
    props: {
        invoice: {
            type: Object,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    
    data() {
        return {
            paymentType: 0,
            selected: STAGE_PAYMENT_TYPE,
            emailStatus: undefined,
            error: '',
            email: '',
        };
    },
    computed: {
        ...mapGetters([
            'signatureType'
        ]),
        stagePaymentType() {
            return this.selected === STAGE_PAYMENT_TYPE;
        },
        stageSignature() {
            return this.selected === STAGE_SIGNATURE;
        },
        stageEmail() {
            return this.selected === STAGE_EMAIL;
        },
        emailStatusMessage() {
            if (this.emailStatus === 'sending') {
                return 'Sendi reikning...';
            } else if (this.emailStatus === 'success') {
                return 'Reikningur sendur';
            } else if (this.emailStatus === 'error') {
                return `Villa kom upp: ${this.error}`;
            }
            return '';
        }
    },
    watch: {
        invoice() {
            if (this.invoice.payment_type_id !== undefined) {
                this.paymentType = this.invoice.payment_type_id;
            }
            if (this.invoice.patient !== undefined) {
                this.email = this.invoice.patient.email;
            }
            this.selected = STAGE_PAYMENT_TYPE;
            this.error = '';
            this.emailStatus = undefined;
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        sendSignature(signature) {
            localService.addInvoiceAttachment(this.invoice.id, 'signature', signature).then(() => {
                console.log(signature);
            }).catch(err => {
                console.log('Error saving sig');
            });
        },
        onDownStage() {
            if (this.selected > STAGE_PAYMENT_TYPE) {
                this.selected--;
            }
        },
        onUpStage() {
            if (this.stagePaymentType && this.paymentType != this.invoice.payment_type_id) {
                localService.updateInvoicePaymentType(this.invoice.id, this.paymentType).then(r => {
                    this.selected = STAGE_SIGNATURE;
                }).catch(err => {
                    console.log('error updating payment type');
                });
            } else if (this.stageSignature) {
                if (this.signatureType == 'topaz') {
                    if (SignatureTablet.hasSignature()) {
                        try {
                            SignatureTablet.getSignature64(this.sendSignature);
                            this.selected++;
                        } catch (err) {
                            // Error getting signature from device
                        }
                    } else {
                        // Patient hasn't signed yet, what to do?
                    }
                } else {
                    this.selected++;
                }
            } else {
                if (this.selected < STAGE_EMAIL) {
                    this.selected++;
                }
            }
        },
        onPrintClick() {
            this.onClose();

            this.$router.push({ name: 'invoice-print', params: { id: this.invoice.id }});
        },
        onSend() {
            this.emailStatus = 'sending';
            InvoiceApi.mailPatientInvoice(this.invoice.id, this.email).then(res => {
                this.emailStatus = 'success';
                InvoiceApi.setInvoicePrinted(this.invoice.id);
                this.$emit('success');
                setTimeout(() => {
                    this.onClose();
                }, 1000);
            }).catch(err => {
                this.emailStatus = 'error';
                if (err.response.status === 422) {
                    this.error = 'Ógilt netfang';
                }
            });
        },
        onClose() {
            this.selected = STAGE_PAYMENT_TYPE;
            this.$emit('close');
        }
    }
};
</script>