// // SPA router configuration
// import Vue from 'vue';
// import VueRouter from 'vue-router';
// import routes from './routes';
// import * as middleware from './middleware';

// // Register VueRouter with Vue
// Vue.use(VueRouter);

// // Instantiate router
// const router = new VueRouter({ 
//     routes,
//     scrollBehavior (to, from, savedPosition) {
//         return { x: 0, y: 0 };
//     }
       
// });

// // Register middleware
// router.beforeEach(middleware.sessionTimeout);
// router.beforeEach(middleware.accessControl);

// // Export Router instance
// export default router;


import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import * as middleware from './middleware';

const router = createRouter({
    history: createWebHistory('#'),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    middleware.sessionTimeout(to, from, next);
    middleware.accessControl(to, from, next);
});

export default router;
