import ApiBase from './apibase';

export default new class SRL extends ApiBase {
    
    // ** ---------- MAIN ---------- **
    /**
     * Send an autoletter
     *
     * @param {Number} patientId 
     * @param {String} addressEid 
     * @param {String} ipItemId
     * @param {String} tmItemId
     * @param {Object} receiver 
     * @param {Array} content 
     * @param {Array} treatments 
     * @param {Array} diagnoses 
     * @param {Array} caseBooks
     */
    async send(patientId, addressEid, addressName, ipItemId, tmItemId, caseId, receiver, content, treatments, diagnoses, caseBooks, mode) {
        return this.post('srl', {
            patientId,
            addressEid,
            addressName,
            ipItemId,
            tmItemId,
            caseId,
            receiverId: receiver.id,
            receiverName: receiver.name,
            content,
            treatments,
            diagnoses,
            caseBooks,
            mode
        });
    }
    
    /**
     * Get Autoletter patients
     *
     * @param {Date} dateFrom 
     * @param {Date} dateTo 
     * @returns {Promise}
     */
    async getPatients(dateFrom, dateTo, templates, mode) {
        return this.get('srl/patients', { dateFrom, dateTo, templates, mode });
    }

    /**
     * Get AutoLetter Addresses
     *
     * @returns {Promise}
     */
    async getAddresses(mode) {
        return this.get('srl/addresses', { mode });
    }

    /**
     * Get Autoletter patients who have casebooks
     *
     * @param {Date} dateFrom
     * @param {Date} dateTo
     * @param {Date} titles
     * @returns {Promise}
     */
    async getPatientsWithCasebooks(dateFrom, dateTo, titles) {
        return this.get('srl/casebookPatients', { dateFrom, dateTo, titles });
    }

    // ** ---------- DOCTORS ---------- **
    /**
         * Get Autoletter patients
         *
         * @param {String} codenumber
         * @returns {Promise}
         */
    async getPatientsDoctor(codenumber) {
        return this.get(`srl/patients/${codenumber}/doctor`);
    }

    // ** ---------- CASEBOOK MEMO ---------- **
    /**
     * Get Autoletter patients
     *
     * @param {String} codenumber
     * @param {Date} dateFrom
     * @param {Date} dateTo
     * @param {String} title
     * @returns {Promise}
     */
    async getPatientsCaseBooks(codenumber, dateFrom, dateTo, titles) {
        return this.get(`srl/patients/${codenumber}/casebooks`, { dateFrom, dateTo, titles });
    }

    // ** ---------- ATTENDANCE ---------- **
    /**
    * Get Patient attendance for case
    *
    * @param {Number} patientId
    * @param {String} caseId
    * @returns {Promise}
    */
    async getAttendance(patientId, caseId) {
        return this.get(`srl/patients/${patientId}/cases/${caseId}`);
    }

    // ** ---------- DIAGNOSES ---------- **
    /**
    * Get Autoletter patients
    *
    * @param {String} codenumber
    * @param {Date} dateFrom 
    * @param {Date} dateTo 
    * @returns {Promise}
    */
    async getPatientsDiagnoses(codenumber, dateFrom, dateTo) {
        return this.get(`srl/patients/${codenumber}/diagnoses`, { dateFrom, dateTo });
    }

    // ** ---------- TEMPLATES ---------- **
    /**
    * Get Templates for dropdown
    *
    * @param {String} unit_id
    * @returns {Promise}
    */
    async templates(data) {
        return this.get("srl/templates", data);
    }

    // ** ---------- TITLES ---------- **
    /**
    * Get Titles for dropdown
    *
    * @param {String} unit_id
    * @returns {Promise}
    */
    async titles(unitId) {
        return this.get("srl/titles", { 'unitId':unitId });
    }


    // ** ---------- SETTINGS ---------- **
    /**
    * Save Setting for SRL
    *
     *@param {Object} data 
    * @returns {Promise}
    */
    async saveSetting(data) {
        return this.post("srl/setting/store",  data );
    }

    /**
    * Get all Settings for SRL
    *
    * @returns {Promise}
    */
    async getSettings() {
        return this.get("srl/setting/index");
    }

    /**
    * Set setting as default
    *
    * @returns {Promise}
    */
    async setDefault(id) {
        return this.post("srl/setting/default", id);
    }

    /**
    * Remove setting as default
    *
    * @returns {Promise}
    */
    async removeDefault() {
        return this.post("srl/setting/removedefault");
    }

    /**
    * Deletes a Setting for SRL
    *
    * @returns {Promise}
    */
    async deleteSetting(id) {
        return this.delete(`srl/${id}`);
    }
    
    /**
    * Is the user secretary
    *
    * @returns {Promise}
    */
    async isSecretary() {
        return this.get("srl/issecretary");
    }
}
