<template>
  <div class="journal">
    <div class="sidebar bg-light overflow-y-auto scroll" style="min-width:230px">
      <JournalSettings v-model="settings" @update-sort-order="handleSorting" @search-query="handleSearchQuery"
        @hide-notes="toggleNotesTypeFilter" @hide-diagnoses="toggleDiagnosesTypeFilter"
        @hide-measure="toggleMeasureTypeFilter" @hide-prescription="togglePrescriptionTypeFilter"
        @hide-vaccines="toggleVaccineTypeFilter" @hide-attentions="toggleAttentionTypeFilter"
        @hide-warning="toggleWarningTypeFilter" @hide-lab="togglelabResultTypeFilter" />
    </div>
   
      <div v-if="showJournalView" class="journal-view scroll">
      <JournalView :journalData="originalJournalData" :sortOrder="sortOrder" :IsEditorCollapsed="isEditorCollapsed"
        @show-editor="toggleEditor" @edit-diagnose-item="handleEditDiagnoseItem"
        @edit-attention-item="handleEditAttentionItem" @edit-note-item="handleEditNoteItem" 
        @edit-lab-item="handleEditlabResultItem"/>
    </div>
    
    <transition name="slide" class="vh-110" ref="top">
      <div class="journal-view-editor scroll" :style="{ width: isEditorCollapsed ? '0' : '50%' }" v-if="!isEditorCollapsed">
        <JournalEditor @close="closeEditor()" :isCollapsed="isEditorCollapsed" :indexValue="indexValue" />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, watch ,onBeforeUnmount} from 'vue';
import JournalSettings from './journalSetting/journalsetting.vue';
import JournalView from './journalview/journalview.vue';
import JournalEditor from './journalEditor/journalEditor.vue';
import DiagnoseApi from '@src/api/diagnose';
import PrescriptionApi from '@src/api/prescription';
import NoteApi from '@src/api/note';
import AttentionApi from '@src/api/attention';
import GrowthApi from '@src/api/growth';
import VaccinationApi from '@src/api/vaccination';
import MeasureApi from '@src/api/measure';
import admissionApi from '@src/api/admission';
import WarningApi from '@src/api/warning';
import labResultApi from '@src/api/labResult';

import { useStore } from 'vuex';

export default {
  components: {
    JournalSettings,
    JournalView,
    JournalEditor,
  },

  setup() {
    const store = useStore();
    const settings = ref({});
    const journalData = ref([]);
    const originalJournalData = ref([]);
    const storedJournalData = ref([]);
    const selectedEntry = ref(null);
    const isEditorCollapsed = ref(true);
    const indexValue = ref(0)
    const isFirstClass = ref(true);
    const searchQuery = ref('');
    const hiddenItems = ref([]);
    const filterbyNote = ref(false);
    const filterbyDiagnose = ref(false);
    const filterbyMeasure = ref(false);
    const filterbyPrescription = ref(false);
    const filterbyVaccination = ref(false);
    const filterbyAttention = ref(false);
    const filterbyWarning = ref(false);
    const filterbylabResult = ref(false);
    const showJournalView = ref(true); 
    const journalSettingsWidth = 230;
    const journalViewMinWidth = 400; 
    const totalMinWidth = 630; 
    

    const sortOrder = ref('asc'); 



    watch(filterbyNote, () => {
      JournalData();
    });
    watch(filterbyDiagnose, () => {
      JournalData();
    });
    watch(filterbyMeasure, () => {
      JournalData();
    });
    watch(filterbyPrescription, () => {
      JournalData();
    });
    watch(filterbyVaccination, () => {
      JournalData();
    });
    watch(filterbyAttention, () => {
      JournalData();
    });
    watch(filterbyWarning, () => {
      JournalData();
    });
    watch(filterbylabResult, () => {
      JournalData();
    });


    const currentPatientID = store.getters.currentPatientID;
    const currentUnitID = store.getters.currentUnitID;
    const currentJournalID = store.getters.currentJournalTypeID;


      const checkWidth = () => {
      const availableWidth = window.innerWidth;
      const journalViewWidth = availableWidth - journalSettingsWidth - (isEditorCollapsed.value ? 0 : (availableWidth * 0.5));

      showJournalView.value = journalViewWidth >= journalViewMinWidth && availableWidth >= totalMinWidth;
    };

    const fetchDataWithParams = async (api, params) => {
      return await api.index(params);
    };

    const transformData = (data) => {
      if (!Array.isArray(data.data)) {
        console.error('Data is not an array:', data);
        return [];
      }

      return data.data.map(item => {
        if (!item || !item.metadata) {
          // console.error('Invalid item:', item);
          return null;
        }

        return {
          date: item.metadata.data_date || 'N/A',
          user: item.metadata.owner_name || 'N/A',
          unit: item.metadata.unit_name || 'N/A',
          type: item.metadata.module_id || 'N/A',
          title: item.title || 'N/A',
          data: item,
        };
      }).filter(item => item !== null);
    };

    const toggleNotesTypeFilter = () => {
      filterbyNote.value = !filterbyNote.value;
    };
    const toggleDiagnosesTypeFilter = () => {
      filterbyDiagnose.value = !filterbyDiagnose.value;
    };
    const toggleMeasureTypeFilter = () => {
      filterbyMeasure.value = !filterbyMeasure.value;
    };
    const togglePrescriptionTypeFilter = () => {
      filterbyPrescription.value = !filterbyPrescription.value;
    };
    const toggleVaccineTypeFilter = () => {
      filterbyVaccination.value = !filterbyVaccination.value;
    };
    const toggleAttentionTypeFilter = () => {
      filterbyAttention.value = !filterbyAttention.value;
    };
    const toggleWarningTypeFilter = () => {
      filterbyWarning.value = !filterbyWarning.value;
    };
    const togglelabResultTypeFilter = () => {
      filterbylabResult.value = !filterbylabResult.value;
    };

    const params = {
      unit_id: currentUnitID,
      journal_type_id: currentJournalID,
      patient_id: 1
    };

    const JournalData = async () => {
      try {
        const [diagnoseData, noteData, attentionData, admissionData, growthData, vaccinationData, measureData, prescriptionData, warningData, labResultData] = await Promise.all([
          fetchDataWithParams(DiagnoseApi, params).catch(error => {
            console.error('Error fetching diagnose data:', error);
            return [];
          }),
          fetchDataWithParams(NoteApi, params).catch(error => {
            console.error('Error fetching note data:', error);
            return [];
          }),
          fetchDataWithParams(AttentionApi, params).catch(error => {
            console.error('Error fetching attention data:', error);
            return [];
          }),
          fetchDataWithParams(admissionApi, params).catch(error => {
            console.error('Error fetching admission data:', error);
            return [];
          }),
          fetchDataWithParams(GrowthApi, params).catch(error => {
            console.error('Error fetching growth data:', error);
            return [];
          }),
          fetchDataWithParams(VaccinationApi, params).catch(error => {
            console.error('Error fetching vaccination data:', error);
            return [];
          }),
          fetchDataWithParams(MeasureApi, params).catch(error => {
            console.error('Error fetching measure data:', error);
            return [];
          }),
          fetchDataWithParams(PrescriptionApi, params).catch(error => {
            console.error('Error fetching prescription data:', error);
            return [];
          }),
          fetchDataWithParams(WarningApi, params).catch(error => {
            console.error('Error fetching warning data:', error);
            return [];
          }),
          fetchDataWithParams(labResultApi, params).catch(error => {
            console.error('Error fetching labresult data:', error);
            return [];
          }),
        ]);

        const transformedNoteData = transformData(noteData);
        const filteredNoteData = transformedNoteData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedDiagnoseData = transformData(diagnoseData);
        const filteredDiagnoseData = transformedDiagnoseData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedAttentionData = transformData(attentionData);
        const filteredAttentionData = transformedAttentionData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedAdmissionData = transformData(admissionData);
        const filteredAdmissionData = transformedAdmissionData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedVaccinationData = transformData(vaccinationData);
        const filteredVaccinationData = transformedVaccinationData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedMeasureData = transformData(measureData);
        const filteredMeasureData = transformedMeasureData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedPrescriptionData = transformData(prescriptionData);
        const filteredPrescriptionData = transformedPrescriptionData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedWarningData = transformData(warningData);
        const filteredWarningData = transformedWarningData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        const transformedlabResultData = transformData(labResultData);
        const filteredlabResultData = transformedlabResultData.filter(item => {
          return item.title.toLowerCase().includes(searchQuery.value.toLowerCase());
        });

        var filteredData = []

        filteredData = [
          ...filteredAdmissionData,
        ];

        if (!filterbyNote.value) { filteredData.push(...filteredNoteData) }
        if (!filterbyMeasure.value) { filteredData.push(...filteredMeasureData) }
        if (!filterbyDiagnose.value) { filteredData.push(...filteredDiagnoseData) }
        if (!filterbyPrescription.value) { filteredData.push(...filteredPrescriptionData) }
        if (!filterbyVaccination.value) { filteredData.push(...filteredVaccinationData) }
        if (!filterbyAttention.value) { filteredData.push(...filteredAttentionData) }
        if (!filterbyWarning.value) { filteredData.push(...filteredWarningData) }
        if (!filterbylabResult.value) { filteredData.push(...filteredlabResultData) }





        originalJournalData.value = filteredData;
        storedJournalData.value = filteredData;


        return filteredData;

      } catch (error) {
        console.error('Error fetching journal data:', error);
        return [];
      }
    };

    onMounted(async () => {
      window.addEventListener('resize', checkWidth);
      checkWidth(); 

      const data = await JournalData();

      if (Array.isArray(data)) {
        journalData.value = data;
      } else {
        console.error('Journal data is not an array:', data);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkWidth);
    });

    const handleSorting = (order) => {
      sortOrder.value = order;
    };

    const handleSearchQuery = (searchQuery) => {
      if (!searchQuery) {
        originalJournalData.value = [...storedJournalData.value];
      } else {
        const filteredData = storedJournalData.value.filter(item => {

          if (
            item.data &&
            (
              (item.data.title && item.data.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
              (item.data.name && item.data.name.toLowerCase().includes(searchQuery.toLowerCase()))
            )
          ) {
            return true;
          }
          return false;
        });

        originalJournalData.value = filteredData;
      }
    };

    const closeEditor = () => {
      isEditorCollapsed.value = 1;
    };

    const toggleEditor = () => {
      indexValue.value = 0;
      isEditorCollapsed.value = !isEditorCollapsed.value;

    };

    const toggleClasses = () => {
      isFirstClass.value = !isFirstClass.value;
    };

    const handleEditDiagnoseItem = (index) => {
      indexValue.value = 1;
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditAttentionItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditNoteItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditlabResultItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    return { settings, handleEditDiagnoseItem, handleEditAttentionItem, handleEditNoteItem,handleEditlabResultItem, journalData, selectedEntry, isEditorCollapsed, indexValue, closeEditor, toggleEditor, toggleClasses, isFirstClass, originalJournalData, handleSearchQuery, handleSorting, hiddenItems, toggleNotesTypeFilter, toggleDiagnosesTypeFilter, toggleMeasureTypeFilter, togglePrescriptionTypeFilter, toggleVaccineTypeFilter, toggleAttentionTypeFilter, toggleWarningTypeFilter,togglelabResultTypeFilter, sortOrder,showJournalView };
  },

  computed: {
    visibleJournalData() {
      if (!searchQuery.value) {
        return this.originalJournalData.filter(item => !this.hiddenItems.includes(item.title));
      } else {
        return journalData.value.filter(item => !this.hiddenItems.includes(item.title));
      }
    },
  },


}
</script>

<style scoped>
.journal {
  display: flex;
  height: 92vh;
  width: 100%;
}

.sidebar {
  width: 30%;
  flex: 0.40;
  padding: 20px;
  overflow: auto;
}


.journal-view {
  flex: 2;
  overflow-y: auto;
}

.journal-view-editor {
  overflow-y: auto;
}


.scroll::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scroll::-webkit-scrollbar-track {
  background: #bdbdbd;
}

.scroll::-webkit-scrollbar-thumb {
  background: #8e8d8d;
  border-radius: 10px;
}
</style>