const ErrorForbidden = () => import(/* webpackChunkName: "group-error" */ '@components/errorForbidden');
const ErrorTimeout = () => import(/* webpackChunkName: "group-error" */ '@components/errorTimeout');

export default [
    {
        path: '/error/403', 
        component: ErrorForbidden, 
        name: 'error-403'
    },
    {
        path: '/error/timeout',
        component: ErrorTimeout,
        name: 'error-timeout'
    }
];