export default class ShortDosageParser {
    /**
     * Initialize parser with known expressions and forms
     * 
     * @param {Array} expressions 
     * @param {Array} forms
     */
    constructor(expressions, forms) {
        this.setExpressions(expressions !== undefined ? expressions : []);
        this.setForms(forms !== undefined ? forms : []);
    }

    /**
     * Set expressions
     * 
     * @param {Array} expressions
     * @return {Void}
     */
    setExpressions(expressions) {
        this.expressions = expressions;
    }

    /**
     * Set forms
     * 
     * @param {Array} forms
     * @return {Void}
     */
    setForms(forms) {
        this.forms = forms;
    }

    /**
     * Parse ShortDosage expression
     * 
     * @param {String} expression
     * @param {String} form
     * @return {String}
     */
    parse(expression, form) {
        // Convert user input digits to 1s
        let match = expression.trim().replace(/(\d+)/g, '1');
        // Find shortdosage expression that matches input
        let found = this.expressions.find(expr => { 
            return expr.short.trim() === match 
        });


        // If found and a type has been set
        if (found !== undefined){
            // Get digits form user's string
            let numbers = expression.match(/(\d+)/g);
            // Replace two groups, either #/digit or #/digit <form>
            let output = found.mask.replace(/([\d+#]\s*<form>)|([\d+#]\s+)/g, (p1) => {
                // If replacing a single digit
                if (! p1.includes('<form>')) {
                    // Return the digit if it exists and shift the numbers array indicating that digit has been processed
                    if (numbers !== null) {
                        return numbers.shift() + " ";
                    }
                    // The matched digit comes from the expression, return it
                    else  {
                        return p1;
                    }
                } else { // Replacing #/digit <form>
                    // initialize num as whatever precedes the <form>
                    let num = p1.substr(0, p1.indexOf(' '));
                    // Placeholder detected, put digit 
                    if (p1[0] === '#') {
                        num = numbers.shift();
                    }
                    // Return digit + plural or singular form
                    return num + " " + (num > 1 ? this.formFormatPlural(form) : this.formFormatSingular(form));
                }
            });
            
            return output;
        }
        return '';
    }

    /**
     * Check if form is in known forms array
     * 
     * @param {String} form 
     * @return {Boolean}
     */
    findFormFormat(form){
        return this.forms.find(x => x.form === form || x.singular === form || x.plural === form);
    }

    /**
     * Get plural version of form
     * 
     * @param {String} form
     * @return {String}
     */
    formFormatPlural(form) {
        let found = this.findFormFormat(form);
        return found !== undefined ? found.plural : form;
    }

    /**
     * Get singular version of form
     * 
     * @param {String} form
     * @return {String}
     */
    formFormatSingular(form) {
        let found = this.findFormFormat(form);
        return found !== undefined ? found.singular : form;
    }
}