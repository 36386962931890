<template>
  <v-app>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-card width="100%" height="100%" elevation="11">
          <v-card v-if="showDialog">
            <v-toolbar color="transparent">
              <v-app-bar-nav-icon 
              :icon="isEditMode ? 'mdi-pencil' : 'mdi-file'" 
              :color="isEditMode ? 'black' : 'blue'">
            </v-app-bar-nav-icon>
            <v-toolbar-title>{{ isEditMode ? $t('attention.edit') : $t('attention.create') }}</v-toolbar-title>
            <v-spacer></v-spacer>
              <v-btn prepend-icon="mdi-content-save" variant="tonal" color="primary" class="mr-2" @click="saveAttention">
              {{$t('attention.save')}}
              </v-btn>
              <v-btn icon="mdi-close" @click="handleClose"></v-btn>             
            </v-toolbar>
            <v-container>
              <v-form>
                <v-text-field :label="$t('attention.text')" v-model="attentionText" />
                <v-card v-if="selectedItem" prepend-icon="mdi-pill" class="mb-5" color="blue-lighten-5"
                  style="display: flex; align-items: center;">
                  <div style="flex: 1; display: flex; align-items: center;">
                    <v-chip style="margin-right: 8px;" size="small" density="compact">{{ selectedItem.atcCode }}</v-chip>
                    <span style="font-weight: bold;">{{ selectedItem.description }}</span>
                  </div>
                  <v-icon class="mdi mdi-close-circle mr-3" @click="clearSelectedItem"></v-icon>
                </v-card>
                <!-- <v-text-field v-else v-model="search" label="ATC" @focus="openDialog" @input="filterItems"></v-text-field>
                <v-dialog v-model="dialog" max-width="400">
                  <v-card>
                    <v-list>
                      <v-list-item v-for="item in filteredItems" :key="item.atcCode" @click="selectItem(item)">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-chip color="blue">{{ item.atcCode }}</v-chip>
                            {{ item.description }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-dialog> -->
                <v-autocomplete 
                  v-else 
                  v-model="selectedItem" 
                  label="ATC" 
                  placeholder="Search medicine by atc code or name"
                  :items="atcCodes" 
                  item-title="description"
                  item-value="atcCode" 
                  :loading="atcLoading"
                  @update:search="getAtcCodes" 
                  @update:focus="atcCodes = []"
                  no-filter
                >
                  <template v-slot:item="{ item, index }">
                    <v-list-item @click="selectItem(item.raw)">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip color="blue" density="compact" size="small">{{ item.value}}</v-chip>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-select :label="$t('attention.category')" :items="categories" item-title="description" v-model="selectedCategory"></v-select>
                <v-textarea :label="$t('attention.comment')" rows="2" v-model="comment" />
              </v-form>
            </v-container>
          </v-card>
        </v-card>
      </v-row>
      <v-snackbar color="white" v-model="snackbar" timeout="5000" top>
        {{ snackbarMessage }}
        <v-btn color="primary" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import atcCode from '@src/api/atcCode';
import attention from '@src/api/attention';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useJournalStore } from '@src/stores/journalStore';

const journalStore = useJournalStore();

const props = defineProps({
  dataRowId: {
    type: Number,
    default: null
  },
  version: {
    type: Number,
    default: null
  }
})

const showDialog = ref(true);

const emit = defineEmits(['close']);
const handleClose = () => {
  closeAttentionEditor();
  emit('close'); // emitting the data to the parent component
};

const closeAttentionEditor = () => {
  journalStore.clearAttentionItem(); 
  showDialog.value = false;
};

const store = useStore();
const { t } = useI18n();

const dialog = ref(false);
const search = ref('');
const items = ref([]);
const filteredItems = ref([]);
const selectedCategory = ref('N');
const attentionText = ref('');
const comment = ref('');
const dataRowID = ref(props.dataRowId);
const version = ref(props.version);
const snackbar = ref(false);
const snackbarMessage = ref('');
const isEditMode = ref(false);
const atcCodes = ref([]);
const atcLoading = ref(false);

const categories = [
  { value: 'N', description: t('attention.none') },
  { value: 'A', description: t('attention.allergy') },
  { value: 'I', description: t('attention.infection') },
  { value: 'B', description: t('attention.blood_infection') },
  { value: 'P', description: t('attention.pregnancy') },
  { value: 'L', description: t('attention.lactation') },
  { value: 'M', description: t('attention.metal_implant') },
];

const selectedItem = ref(null);

const openDialog = () => {
  dialog.value = true;
};

const selectItem = (item) => {
  selectedItem.value = item;
  search.value = `${item.atcCode} - ${item.description}`;
  dialog.value = false;
};

const fetchItems = async () => {
  try {
    const response = await atcCode.index();
    items.value = response.data;
    filteredItems.value = items.value;
  } catch (error) {
    console.error('Failed to fetch items:', error);
  }
};

const getAtcCodes = async (search) => {
  atcLoading.value = true;
  if (search.length >= 1) {
    try {
      const response = await atcCode.index({ search: search });
      atcCodes.value = response.data; 
    } catch (error) {
      console.error('Failed to fetch items:', error);
    }    
  }
  atcLoading.value = false;
};

const filterItems = () => {
  filteredItems.value = items.value.filter(item => {
    const atcCode = item.atcCode ? item.atcCode.toLowerCase() : '';
    const description = item.description ? item.description.toLowerCase() : '';
    const searchQuery = search.value.toLowerCase();
    return atcCode.includes(searchQuery) || description.includes(searchQuery);
  });
};

const clearSelectedItem = () => {
  selectedItem.value = null;
  search.value = '';
};

const patient_id = store.getters.currentPatientID;
const journal_type_id = store.getters.currentJournalTypeID;
const unit_id = store.getters.currentUnitID;
const userDataID = store.getters.currentUserDataID;

const saveAttention = async () => {
  const attentionData = {
    text: attentionText.value,
    atc_code: selectedItem.value ? selectedItem.value.atcCode : null,
    atc_desc: selectedItem.value ? selectedItem.value.description : null,
    category: selectedCategory.value,
    comment: comment.value,
    patient_id: 1, // used from Vuex store
    journal_type_id: journal_type_id, // used from Vuex store
    unit_id: unit_id, // used from Vuex store
    deleted: 0,
    sign_wanted: 0,
    private: 0,
    data_row_origin_id: 1,
    created_by: userDataID, // used from Vuex store
    owned_by: userDataID, // used from Vuex store
    data_date: null,
    signed_by: null,
    signed_date: null,
    counter_signed_by: null,
    counter_signed_date: null,
    draft: 0,
    use_time: 0,
  };

  try {
    if (!dataRowID.value && !version.value) {
      const response = await attention.store(attentionData);
      const newAttention = response;
      dataRowID.value = newAttention.DataRowID;
      version.value = newAttention.Version;
      snackbarMessage.value = 'Attention created successfully!';
      isEditMode.value = true;
    } else {
      await attention.update(dataRowID.value, attentionData);
      snackbarMessage.value = 'Attention updated successfully!';
      handleClose();
    }
    snackbar.value = true;
  } catch (error) {
    console.error('Failed to save or update attention:', error);
  }
};


onMounted(() => {
  fetchItems();
  if (dataRowID.value) {
    attention.show(dataRowID.value).then(response => {
      const existingAttention = response.data;
      attentionText.value = existingAttention.text;
      selectedItem.value = existingAttention.atc_code ? { atcCode: existingAttention.atc_code, description: existingAttention.atc_code_desc } : null;
      comment.value = existingAttention.comment;
      selectedCategory.value = existingAttention.category;
      isEditMode.value = true;
    }).catch(error => {
      console.error('Failed to fetch existing attention:', error);
    });
  }
});
</script>
