<!-- <template>
    <button 
        class="btn btn-outline-info float-right" 
        type="button" 
        @click="onClick"
    >
        <i 
            class="fal fa-sync" 
            :class="{'fa-spin':refresh}"
        /> {{ textComputed }}
    </button>
</template>
<script>
export default {
    props: {
        text: {
            required: false,
            type: String,
            default: undefined,
        },
        refresh: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        textComputed() {
            if (this.text) {
                return this.text;
            } else {
                return this.t('refresh.update');
            }
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        onClick() {
            this.$emit('click');
        }
    }
};
</script> -->


//vue3


<template>
    <button class="btn btn-outline-info float-right" type="button" @click="onClick">
      <i class="fal fa-sync" :class="{ 'fa-spin': refresh }" /> {{ text }}
    </button>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    props: {
      text: {
        type: String,
        default: 'Uppfæra',
        required: false
      },
      refresh: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    methods: {
      onClick() {
        this.$emit('click');
      }
    }
  });
  </script>
  