<template>
    <div>
        <div class="form-group mb-4">
            <label v-if="kennitala.length > 0" class="text-muted"> {{ t('invoice_create.ssn') }}</label>
            <label v-else for="">&nbsp;</label>
            <p v-if="kennitala.length > 0" class="text-center display-4">
                {{ $filters.codenumber(kennitala) }}
            </p>
            <p v-else class="text-muted text-center display-4">{{ t('invoice_create.ssn') }}</p>
        </div>
        <div class="form-group mt-3">
            <div class="btn-group-vertical d-block">
                <div class="btn-group">
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(1)">1</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(2)">2</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(3)">3</button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(4)">4</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(5)">5</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(6)">6</button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(7)">7</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(8)">8</button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(9)">9</button>
                </div>
                <div class="btn-group">
                    <button class="btn btn-lg btn-danger py-4 btn-keypad" @click="clear()"><i class="fas fa-times fa-fw" /></button>
                    <button class="btn btn-lg btn-primary py-4 btn-keypad" @click="onKeyStroke(0)">0</button>
                    <button class="btn btn-lg btn-warning py-4 btn-keypad" @click="erase()"><i class="fas fa-backspace fa-fw" /></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        },
    },
    data() {
        return {
            kennitala: ''
        };
    },
    computed: {},
    watch: {
        kennitala(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        }
    },
    created() {
        this.kennitala = this.value;
    },
    mounted() {},
    methods: {
        t(key) {
            return this.$t(key);
        },
        onKeyStroke(val) {
            if (this.kennitala.length < 10) {
                this.kennitala += val;
            }
        },
        clear() {
            this.kennitala = '';
        },
        erase() {
            this.kennitala = this.kennitala.slice(0, -1);
        }
    },
};
</script>
<style>
    .btn-keypad {
        font-size: 175%;
    }
</style>