<template>
    <div class="d-flex justify-content-between flex-wrap">
            <v-date-input prepend-icon="" min-width="300" max-width="300" v-model="dateFrom" variant="solo" :label="$t('autoletter.settings.from')"></v-date-input>
            <v-date-input prepend-icon="" min-width="300"  max-width="300" v-model="dateTo" variant="solo" :label="$t('autoletter.settings.to')"></v-date-input>
        <div class="d-flex">
            <v-btn style="height:56px" class="pt-0 no-upper" size="x-large" @click="onSubmit" :text="$t('autoletter.settings.fetch')"></v-btn>
        </div>
    </div>
</template>
<script>

import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
    components: {
        VDateInput
    },
    mounted() {
        this.dateFrom = new Date();
        this.dateTo = new Date();
    },
    data() {
        return {
            dateFrom: new Date(),
            dateTo: new Date(),
        };
    },
    methods: {
        onSubmit() {
            this.$emit('submit', {
                from: this.dateFrom,
                to: this.dateTo
            });
        },
        updateDate(date) {
            this.dateFrom = date;
        }
    }
};
</script>
<style>
.no-upper {
    text-transform: unset !important;
}
</style>
