import store from '@src/store';
import { GET_AUTH_USER } from '@src/store/actionTypes';
import localService from '@src/api/local';

export default async (to, from, next) => {
    const guards = to.matched.reduce((guards, record) => {
        return record.meta.access !== undefined ? guards.concat(record.meta.access) : [];
    }, []);

    if (guards.length > 0) {
        if (!store.getters.UserExists) {
            await store.dispatch(GET_AUTH_USER);
        }
        if (to.name !== 'error-403' && guards.some(guard => !store.getters.currentUserCanAccess(guard))) {
            localService.log('error', JSON.stringify({
                component: 'accessControl',
                vuex: store.state,
                target: to.name
            }));
            next({ name: 'error-403' });
        } else {
            next()
        }
    } else {
        next();
    }
};

// Feature access constants
export const GUARD_ADMIN = 'admin';
export const GUARD_BILLING = 'billing';
export const GUARD_RECORDS = 'records';
export const GUARD_DRUGS = 'drugs';
export const GUARD_PRESC = 'prescriptions';
export const GUARD_SIGNATURE = 'signatures';