<template>
    <Alert
      :header="header"
      :alertClass="'alert alert-danger'"
      :fontAwesomeIcon="'fa fa-exclamation-triangle'"
    >
      <slot />
    </Alert>
  </template>
  
  <script>
  import Alert from '@components/alert/alert.vue';
  
  export default {
    components: {
      Alert,
    },
    props: {
      header: {
        required: false,
        type: String,
        default: 'Villa kom upp!'
      }
    }
  };
  </script>
  