<template lang="html">
    <div class="container pt-4">
        <!-- Modal -->
        <modal ref="saveModal" :invalid="this.settingNames" :secretary="this.isSecretary" @save="onModalSave" />
        
        <!-- Title -->
        <div class="row my-3 pt-4">
            <div class="col-md-12">
                <h1 class="text-center">{{ $t('autoletter.config.title') }}</h1>
            </div>
        </div>

        <!-- Settings -->
        <div class="d-flex flex-wrap">
            <v-card :title="$t('autoletter.settings.setting')" class="col-12 col-md-3 p-4 bg-info user-select-none">
                <v-radio-group v-model="selectedMode">
                    <v-radio v-for="mode in modes" :value="mode" :disabled="hasError">
                        <template v-slot:label>
                            <div class="mt-2 ml-1" >{{ getRadioLabel(mode) }}</div>
                        </template>
                    </v-radio>
                </v-radio-group>
                <h4 class="text-danger font-weight-bold" v-if="hasError">{{ this.getErrorText() }}</h4>
            </v-card>
            <v-card :title="$t('autoletter.settings.titles_templates')" class="d-flex flex-column p-4 col-12 col-md-9 bg-info user-select-none">
                <div v-for="cmode in config" class="d-flex justify-content-center">
                    <div class="d-flex col-12 px-0" v-if="selectedMode === cmode.name">
                        <v-combobox
                            multiple
                            :label="$t('autoletter.settings.titles')"
                            :items="titles"
                            v-model="cmode.titles"
                            variant="solo"
                            chips
                            closable-chips
                            :disabled="fieldDisabled('titles')"
                        ></v-combobox>
                            <v-btn class="v-btn no-upper" :disabled="fieldDisabled('titles')" @click="cmode.titles = this.titles">{{ $t('autoletter.settings.select_all') }}</v-btn>
                            <v-btn class="v-btn no-upper" :disabled="cmode.titles.length === 0" @click="cmode.titles = []">{{ $t('autoletter.settings.reset') }}</v-btn>
                    </div>
                </div>
                <div v-for="cmode in config" class="d-flex justify-content-center">
                    <div class="d-flex col-12 px-0" v-if="selectedMode === cmode.name">
                        <v-combobox
                            v-if="selectedMode === cmode.name"
                            :label="$t('autoletter.settings.templates')"
                            :items="templates.map(value => value.name)"
                            v-model="cmode.templates"
                            chips
                            variant="solo"
                            multiple
                            clearable
                            closable-chips
                            :disabled="fieldDisabled('templates')"
                        ></v-combobox>
                        <v-btn class="v-btn no-upper" :disabled="fieldDisabled('templates')" @click="cmode.templates = this.templates.map(x => x.name)">{{ $t('autoletter.settings.select_all') }}</v-btn>
                        <v-btn class="v-btn no-upper" :disabled="cmode.templates.length === 0" @click="cmode.templates = []">{{ $t('autoletter.settings.reset') }}</v-btn>
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <div>
                        <date-form dtype="tmitem" :display="selectedMode === 'tmitem'" @updateDates="updateDates"/>
                        <date-form dtype="case" :display="selectedMode === 'case'" @updateDates="updateDates"/>
                        <date-form dtype="casebook" :display="selectedMode === 'casebook'" @updateDates="updateDates"/>
                        <date-form dtype="ipitem" :display="selectedMode === 'ipitem'" @updateDates="updateDates"/>
                    </div>
                    <div class="ml-auto">
                        <v-btn class="v-btn mr-2 no-upper" @click="closeSettings()">{{ $t('autoletter.settings.close') }}</v-btn>
                        <v-btn class="v-btn no-upper" prepend-icon="fa-solid fa-floppy-disk" @click="openModal()" :disabled="hasError">{{ $t('autoletter.config.save') }}</v-btn>
                    </div>
                </div>
            </v-card>
        </div>
        <!--code style="color:black;font-size:20px">{{ config }} </code-->
        <TitleGenerator dtype="tmitem" :display="selectedMode === 'tmitem'" :title="getRadioLabel(this.selectedMode)" @updateGeneratedTitle="updateGeneratedTitle" />
        <TitleGenerator dtype="case" :display="selectedMode === 'case'" :title="getRadioLabel(this.selectedMode)" @updateGeneratedTitle="updateGeneratedTitle" />
        <TitleGenerator dtype="ipitem" :display="selectedMode === 'ipitem'"  :title="getRadioLabel(this.selectedMode)" @updateGeneratedTitle="updateGeneratedTitle" />
    </div>
</template>
<script>

import DateForm from './DateForm';
import TitleGenerator from './TitleGenerator';
import Modal from './SaveConfigModal';

import autolettersApi from '@src/api/srl.js';
import _ from 'lodash';

export default {
    props: {
        settings: {
            type: Array,
            required: false,
            default: () => []
        },
        titles: {
            type: Array,
            required: false,
            default: () => []
        },
        templates: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    components: {
        DateForm,
        TitleGenerator,
        Modal
    },
    data() {
        return {
            isSecretary: false,
            modes: ['tmitem','case','casebook','ipitem'],
            selectedMode: 'tmitem',
            // titles: [],
            selectedTitles: [],
            // templates: [],
            error: '',
            config: {
                bookings:   { name:'tmitem', titles: [], templates: [] },
                treatments: { name:'case', titles: [], templates: [] },
                casebooks:  { name:'casebook', titles: [], templates: [] },
                admissions: { name:'ipitem', titles: [], templates: [] }
            }
        };
    },
    computed: {
        hasError() {
            return this.error.length > 0;
        },
        settingNames() {
            if(this.settings.length > 0) {
                return this.settings.map(x => x.name);
            }
            return [];
        }
    },
    created() {
        this.isSecretaryCheck();
    },
    methods: {
        isSecretaryCheck() {
            autolettersApi.isSecretary().then(response => {
                let result = response.data === 1 ? true : false;
                this.isSecretary = result;
            }).catch(e => {
                this.isSecretary = false;
                console.log(e)  
            });
        },
        closeSettings() {
            this.$emit("closeSettings");
        },
        openModal() {
            if(this.$refs.saveModal) {
                this.$refs.saveModal.openModal()
            }
        },
        onModalSave(name, defaultMode, shared) {
            if(name.length) {
                let json = JSON.parse(JSON.stringify(this.config));
                if(this.modes.indexOf(defaultMode) >= 0) {
                    json['default_mode'] = defaultMode;
                }

                let privatee = true;
                if(shared) {
                    privatee = false;
                }

                let obj = {
                    "name":name,
                    "json":json,
                    "private":privatee,
                }

                autolettersApi.saveSetting(obj).then(response => {
                    this.$emit("closeSettings", "saved");
                }).catch(e => {
                    this.$emit("closeSettings", "saved_error");
                });
            }
        },
        getErrorText() {
            const error = this.$i18n.t('autoletter.error');
            const inn = this.$i18n.t('autoletter.in');
            let type = '';
            if(this.error === 'tmitem') {
                type = this.$i18n.t('autoletter.settings.bookings')
            }
            if(this.error === 'case') {
                type = this.$i18n.t('autoletter.settings.treatments')
            }
            if(this.error === 'ipitem') {
                type = this.$i18n.t('autoletter.settings.admissions')
            }
            return [error,inn,type.toLowerCase()].join(' ');
        },
        updateDates(data) {
            const keys = Object.keys(this.config)
            for(let key of keys) {
                if(this.config[key].name === data.type) {
                    this.config[key]['dateFrom'] = data;
                }
            }
        },
        updateGeneratedTitle(gt) {
            const sm = this.selectedMode;
            if(gt === 'error') { 
                this.error = sm;
                return;
            }
            this.error = '';
            const keys = Object.keys(this.config);
            for(let key of keys) {
                if(this.config[key].name === sm) {
                    this.config[key].generated_title = gt;
                }
            }
        },
        getRadioLabel(current) {
            return [
                this.$i18n.t('autoletter.settings.bookings'),
                this.$i18n.t('autoletter.settings.treatments'),
                this.$i18n.t('autoletter.settings.casebooks'),
                this.$i18n.t('autoletter.settings.admissions')
            ][this.modes.indexOf(current)];
        },
        fieldDisabled(type) {
            if(type === 'titles') {
                return ['case'].indexOf(this.selectedMode) >= 0
            }
            else if(type === 'templates') {
                return ['case', 'casebook','ipitem'].indexOf(this.selectedMode) >= 0
            }
        },
        resetData() {
            this.patientsDataset = [];
            this.addresses = [];
            this.diagnoses = [];
            this.caseBooks = [];
            this.selected = null;
            this.loading = false;
            this.error = null;
            this.attendance = {};
        }
    }
};
</script>
<style scoped>
.v-btn {
height: 56px;
}
.no-upper {
    text-transform: unset !important;
}
</style>
