<template lang="html">
    <label>
        <span 
            v-if="required" 
            class="text-danger"
        >&#42;</span> <slot />
    </label>
</template>
<script>
export default {
    props: {
        required: {
            required: false,
            default: false,
            type: Boolean
        }
    }
};
</script>