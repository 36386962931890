<template>
    <form 
        action="#" 
        :class="{'form-inline my-2 my-lg-0 mr-1':inline, 'mb-2': !inline}"
        @submit.prevent 
    >	
        <button 
            v-show="!isHome"
            v-tooltip.bottom="t('navbar.back')"
            type="button"
            class="btn btn-outline-primary mr-1"
            @click.prevent="onBackClick()"
        >
            <i class="fal fa-arrow-left fa-fw" />
        </button>
        <transition name="slide-fade">
            <div 
                v-if="!showSearch" 
                :class="{'d-flex':!inline}" 
                class="input-group"
            >   
               
                <div 
                v-tooltip.bottom="t('navbar.search_service_recipient')"
                    class="input-group-prepend"
                    @click="switchSearch" 
                >
                    <span class="input-group-text bg-primary border-primary text-white clickable">
                        <i class="fas fa-clipboard-user fa-fw" />
                    </span>
                </div>
                <div 
                v-tooltip.bottom="t('navbar.search_service_recipient')"
                    class="input-group-append clickable"
                    :class="{'flex-grow-1':!inline}" 
                    @click="switchSearch" 
                >
                    <span :class="{'flex-fill':!inline}" class="input-group-text bg-primary border-primary text-white">
                        {{ patientName }}
                    </span>
                </div>
            </div>
            <div v-else class="input-group">
                <div 
                    v-tooltip.bottom="t('invoice_create.service_recipient')"
                    class="input-group-prepend"
                    @click="switchSearch" 
                >
                    <span class="input-group-text bg-primary border-primary text-white clickable">
                        <i class="fas fa-clipboard-user fa-fw" />
                    </span>
                </div>
                <input 
                    id="egatt-navbar-patient-input" 
                    v-model="lookupKennitala" 
                    v-tooltip="t('navbar.search_service')"
                    type="text" 
                    class="form-control border-primary" 
                    :class="{'is-invalid': error}"
                    v-bind:placeholder="[[t('invoice_create.ssn')]]"
                    @input="error = false"
                    @keydown.enter="lookupPatient"
                    @keydown.esc="showSearch = false"					
                >
                <div class="input-group-append">
                    <button 
                        type="submit" 
                        class="btn btn-primary"
                        :title="$t('patient.search_for_patient')"
                        :disabled="error" 
                        @click="lookupPatient"
                    >
                        <i class="fa fa-search" />
                    </button>
                </div>
            </div>
        </transition>
    </form>
</template>
<script type="text/javascript">
import { FIND_PATIENT_BY_CODENUMBER } from '../../store/actionTypes';

export default {
    props: {
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showSearch: false,
            error: false,
            lookupKennitala: '',
        };
    },
    computed: {
        patientName() {
            return this.$store.getters.currentPatientName;
        },
        isHome() {
            return this.$route.name === 'home';
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        lookupPatient() {
            this.$store.dispatch(FIND_PATIENT_BY_CODENUMBER, this.lookupKennitala).then(res => {
                this.showSearch = false;
            }).catch(err => {
                this.error = true; 
            });
        },
        switchSearch() {
            this.showSearch = !this.showSearch;
        },
        onBackClick() {
            this.$router.go(-1);
        }
    }
};

</script>
<style type="text/css" media="screen">
	/* Enter and leave animations can use different */
	/* durations and timing functions.              */
	.slide-fade-enter-active {
	  transition: all .3s ease;
	}
	.slide-fade-leave-active {
	  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
	  transform: translateX(10px);
	  opacity: 0;
	}
</style>