import { defineStore } from 'pinia';

export const useJournalStore = defineStore('journal', {
  state: () => ({
    view: {},
    settings: {},
    editor: {
      dataRowId: undefined,
      type: undefined,
      templateId: undefined,
    },
    showEditor: false,
  }),
  getters: {
    getEditor(state) {
      return state.editor;
    },
  },
  actions: {
    setEditor(editor) {
      // Spread existing state and incoming editor changes
      this.editor = { ...this.editor, ...editor };
    },

    toggleShowEditor(show) {
      this.showEditor = show;
    },

    resetEditor() {
      // Reset the editor back to its initial state
      this.editor = {
        dataRowId: undefined,
        type: undefined,
        templateId: undefined,

      };
      console.log("Editor has been reset: ", this.editor);
    },
  }
});
