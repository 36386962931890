<template>
	<v-card flat>
		<v-toolbar color="transparent">
		 <v-app-bar-nav-icon
		@mouseenter="handleMouseEnter(index)" 
		@mouseleave="handleMouseLeave()"
		>
			<v-avatar :color="getHoverColor(index)">
				<v-icon v-if="hoveredItemIndex === index" @click="editDiagnose(journalData)">mdi-pencil</v-icon>
				<v-icon v-else :class="getAvatarIconClass(index)"></v-icon>
			</v-avatar>
		 </v-app-bar-nav-icon>

			<v-toolbar-title>
				<strong>{{ journalData.data.code }}</strong> - {{ journalData.data.name }}
				<v-chip class="ml-3">{{ journalData.data.dia_list_name }}</v-chip>
			</v-toolbar-title>
			<v-chip prepend-icon="mdi-doctor" class="text-0" size="small">
                {{ journalData.user }}
            </v-chip>
		</v-toolbar>
	</v-card>

</template>

<script>
import DiagnoseEditor from '../../journalEditor/diagnoseEditor/diagnoseEditor.vue';
import { useJournalStore } from '@src/stores/journalStore';

export default {
	components: {
		DiagnoseEditor
	},
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			diagnoseItems: [],
			hoveredItemIndex: null,
			isDiagnoseEditorOpen: false,
			selectedDataRowId: null,
			selectedVersion: null,
		};
	},
	computed: {
	},
	mounted() {
		// this.diagnoseItems = this.journalData;
	},
	methods: {
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave() {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hoveredItemIndex === index ? '#EEEEEE' : 'teal-lighten-2';
		},
		getAvatarIconClass(index) {
			return this.hoveredItemIndex !== index ? 'fat fa-person-dots-from-line' : '';
		},
		editDiagnose(item) {

			this.$emit('edit-diagnose-item', item);

			const journalStore = useJournalStore();
            journalStore.setEditor({
                dataRowId: item.data.data_row_id,
                type: item.type,
            });
			
			// const journalStore = useJournalStore();
			// journalStore.setDiagnoseItem(item); 

			// if (this.isDiagnoseEditorOpen && this.selectedDataRowId === item.data_row_id) {
			// 	this.closeDiagnoseEditor();
			// } else {
			// 	this.selectedDataRowId = item.data_row_id;
			// 	this.selectedVersion = item.version;
			// 	this.isDiagnoseEditorOpen = true;
			// }
		},
		// closeDiagnoseEditor() {
		// 	this.isDiagnoseEditorOpen = false;
		// 	this.selectedDataRowId = null;
		// 	this.selectedVersion = null;
		// },
		// diagnoseItem() {
		// 	const journalStore = useJournalStore();
		// 	return journalStore.getDiagnoseItem();
		// },

	}
};
</script>