import ApiBase from './apibase';

export default new class Signature extends ApiBase {

	/**
	 * Get all signatures
	 * @param {Object} 	Params
	 * @return {Promise}
	 */
	getAllSignatures(params) {
		return this.getData('signature', params);
	}

	/**
	 * Store a signature 
	 * @param  {Object} params Signature parameters
	 * @return {Promise}
	 */
	storeSignature(params) {
		console.log(params);
		return this.postData('signature', params);
	}

	/**
	 * Send a signature request to a kiosk.
	 * @param  {Number} kioskId Id of the kiosk to send to
	 * @param  {Object} params  Signature request parameters
	 * @return {Promise}
	 */
	requestSignature(kioskId, params) {
		return this.postData(`signature/send/${kioskId}`, params);
	}

	/**
	 * Get all Signature Types
	 * @return {Promise}
	 */
	getAllSignatureTypes() {
		return this.getData('signaturetype');
	}

	/**
	 * Get a single Signature Type
	 * @param  {Number} id The ID of the Signuature Type
	 * @return {Promise}
	 */
	getSignatureType(id) {
		return this.getData(`signaturetype/${id}`);
	}

	/**
	 * Store Signature Type
	 * @param  {Object} params Signature Type to be stored.
	 * @return {Promise}
	 */
	storeSignatureType(params) {
		return this.postData('signaturetype', params);
	}

	/**
	 * Update Signature Type
	 * @param  {Number} id     Id of the type to be updated
	 * @param  {Object} params Parameter data to update the signature type.
	 * @return {Promse}
	 */
	updateSignatureType(id, params) {
		return this.patchData(`signaturetype/${id}`, params);
	}

	/**
	 * Delete a signature type by id.
	 * @param  {Number} id The id of the signature type being deleted
	 * @return {Promise}
	 */
	deleteSignatureType(id) {
		return this.delete(`signaturetype/${id}`);
	}
}