export default {
    all: function() {
        for (let f in this) {
            if (typeof this[f] == "function" && this[f] !== this['all']) {
                this[f]();
            }
        }
    },
    addDays: function() {
        if (!Date.prototype.addDays) {
            Date.prototype.addDays = function(days) {
                let d = new Date(this.valueOf());
                d.setDate(d.getDate() + days);
                return d;
            }
        }
    },
    isSameDate: function() {
        if (!Date.prototype.isSameDate) {
            Date.prototype.isSameDate = function(date) {
                let d = new Date(date);
                return (this.toDateString() === d.toDateString());
            }
        }
    },
    isSameOrBefore: function() {
        if (!Date.prototype.isSameOrBefore) {
            Date.prototype.isSameOrBefore = function(date) {
                let d = new Date(date);
                return (this.valueOf() <= d)
            }
        }
    },
    isBefore: function() {
        if (!Date.prototype.isBefore) {
            Date.prototype.isBefore = function(date) {
                let d = new Date(date);
                return (this.valueOf() < d)
            }
        }
    },
    isSameOrAfter: function() {
        if (!Date.prototype.isSameOrAfter) {
            Date.prototype.isSameOrAfter = function(date) {
                let d = new Date(date);
                return (this.valueOf() >= d);
            }
        }
    },
    isAfter: function() {
        if (!Date.prototype.isAfter) {
            Date.prototype.isAfter = function(date) {
                let d = new Date(date);
                return (this.valueOf() > d);
            }
        }
    },
    isBetween: function() {
        if (!Date.prototype.isBetween) {
            Date.prototype.isBetween = function(a, b) {
                let d1 = new Date(a);
                let d2 = new Date(b);
                return (this.valueOf() >= d1 && this.valueOf() <= d2);
            }
        }
    },
    formatIS: function() {
        if (!Date.prototype.formatIS) {
            Date.prototype.formatIS = function() {
                let minutes = this.getMinutes();
                if (minutes < 10) minutes = "0" + minutes;
                return this.getDate() + ". " + monthNames[this.getMonth()].substr(0, 3) + " " + this.getFullYear() + " " + this.getHours() + ':' + minutes;
            }
        }
    },
    formatISNoTime: function() {
        if (!Date.prototype.formatISNoTime) {
            Date.prototype.formatISNoTime = function() {
                return this.getDate() + ". " + monthNames[this.getMonth()].substr(0, 3) + " " + this.getFullYear();
            }
        }
    }
}

let monthNames = ["Janúar", "Febrúar", "Mars", "Apríl", "Maí", "Júní", "Júlí", "Ágúst", "September", "Október", "Nóvember", "Desember"];
let dayNames = ["Mánudagur", "Þriðjudagur", "Miðvikudagur", "Fimmtudagur", "Föstudagur", "Laugardagur", "Sunnudagur"];