<template>
  <div v-if="measureItems.length > 0">
    <div v-for="(item, index) in transformedMeasureItems" :key="index">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-toolbar color="transparent"
                @mouseenter="handleMouseEnter(index)"
                @mouseleave="handleMouseLeave()">
                <v-avatar :color="getHoverColor(index)">
                  <v-icon v-if="hoveredItemIndex === index">{{ getAvatarIcon(index) }}</v-icon>
                  <v-icon v-else :class="getAvatarIconClass(index)"></v-icon>
                </v-avatar>

                <v-toolbar-title>
                  <span class="font-weight-medium">{{ item.name }} </span>
                  <v-chip size="small"> {{ item.code }} </v-chip>
                </v-toolbar-title>
                <v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
              </v-toolbar>
              <v-spacer></v-spacer>
              <v-card-text class="journal-indent pt-0 text-grey-darken-">
                <p>{{ item.comment }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    journalData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      measureItems: [],
      hoveredItemIndex: null,
      mdiPencil: 'mdi-pencil',
    };
  },
  computed: {
    transformedMeasureItems() {
      return this.measureItems.map(item => ({
        name: item.data.name,
        code: item.data.code,
        comment: item.data.comment,
        owner_name: item.data.metadata.owner_name,
      }));
    }
  },
  mounted() {
    const type7Items = this.journalData.items.filter(item => item.type === '7');
    this.measureItems = type7Items;
  },
  methods: {
    handleMouseEnter(index) {
      this.hoveredItemIndex = index;
    },
    handleMouseLeave() {
      this.hoveredItemIndex = null;
    },
    getHoverColor(index) {
      return this.hoveredItemIndex === index ? '#EEEEEE' : 'green';
    },
    getAvatarIcon(index) {
			return this.hoveredItemIndex === index ? 'mdi-pencil' : 'far fa-scalpel';
		},
    getAvatarIconClass(index) {
      return this.hoveredItemIndex !== index ? 'far fa-scalpel' : '';
    }
  }
};
</script>

<style>
.journal-indent {
  margin-left: 42px;
}
</style>
