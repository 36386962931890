<template>
    <div class="text-center pa-4">
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="1000">
            <v-card >
                <v-toolbar class="bg-primary">
                    <v-btn color="black" icon="mdi-close" @click="dialog = false">
                        <i class="fa-solid fa-x text-white"></i>
                    </v-btn>
                    <v-toolbar-title class="user-select-none">{{ $t('autoletter.config.load_title') }}</v-toolbar-title>
                </v-toolbar>
                <v-list lines="two" class="d-flex flex-column p-4" subheader>
                    <div class="d-flex flex-column col-12 col-md-8 mx-auto">
                        <v-autocomplete
                            v-model="selected"
                            :items="items"
                            item-title="name"
                            item-value="id"
                            :label="$t('autoletter.config.load_placeholder')"
                            variant="solo"
                            prepend-icon=""
                            :no-data-text="$t('autoletter.modal.no_data_found')"
                            class="mb-0"
                        />
                        <div class="d-flex justify-content-between col-12 px-0">
                            <div>
                                <v-btn v-if="defaultId < 0" :disabled="isEmpty" prepend-icon="fa-solid fa-star fa-2x text-warning" class="v-btn mr-2 no-upper" 
                                    @click="setAsDefault()">
                                    <div class="black">{{ $t('autoletter.config.set_default') }}</div>
                                </v-btn>
                                <v-btn v-else :disabled="isEmpty" prepend-icon="" class="v-btn mr-2 no-upper" @click="removeDefault()">
                                    <span class="fa-stack">
                                        <i class="fa-solid fa-star text-warning "></i>
                                        <i class="fa-solid fa-x fa-stack-1x" style="color:grey"></i>
                                    </span>
                                    <div class="black">{{ $t('autoletter.config.remove_default') }}</div>
                                </v-btn>
                                <v-btn :disabled="isEmpty || cannotDelete" class="v-btn no-upper" @click="deleteSetting()">
                                    <button class="v-btn circle mr-2" @click="this.remove(index)">
                                        <div class="circle-box"></div>
                                    </button>
                                    <div class="black">{{ $t('autoletter.config.delete') }}</div>
                                </v-btn>
                            </div>
                            <v-btn :disabled="isEmpty" class="v-btn no-upper" @click="loadSetting()">
                                {{ $t('autoletter.config.choose') }}
                            </v-btn>
                        </div>
                    </div>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    watch: {
        dialog() {
            if(!this.dialog) {
                this.selected = '';
            }
            else {
                if(this.items.length > 0) {
                    this.selected = this.items[0].id;
                }
            }
        },
        settings() {
            let items = [];
            for(let setting of this.settings) {
                items.push({"name":setting.name, "id": setting.id});
            }
            this.items = items;
            if(this.items.length > 0) {
                this.selected = this.items[0].id;
            }
        }
    },
    props: {
        settings: {
            type: Array,
            required: false,
            default: () => []
        },
        defaultId: {
            type: Number,
            required: false,
            default: -1
        }
    },
    data() {
        return {
            dialog: false,
            selected: '',
            items: []
        };
    },
    computed: {
        isEmpty() {
            if(!this.selected) {
                return true;
            }
            return false;
        },
        cannotDelete() {
            const item = this.settings.filter(x => x.id == this.selected);
            if(item.length === 1) {
                return !item[0].can_delete;
            }
        }
    },
    methods: {
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        },
        loadSetting() {
            this.$emit('load', this.selected);
        },
        deleteSetting() {
            this.$emit('delete', this.selected);
        },
        setAsDefault() {
            this.$emit('setdefault', this.selected);
        },
        removeDefault() {
            this.$emit('removedefault');
        }
    }
};
</script>
<style scoped>
.black {
    color: black;
}
.circle {
    border-radius: 3rem;
    background-color: #ff3a03ff; 
    max-width:20px;
    max-height:20px;
    width: 20px;
    height: 20px;
    justify-content:center;
    align-items:center;
}
.circle:hover {
    cursor:pointer;
}
.v-btn {
    height: 56px;
}
.circle-box {
    background-color: white;
    width:15px;
    height:4px;
    border-radius: 3rem;
}
.no-upper {
    text-transform: unset !important;
}
</style>
