import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '@src/store/mutationTypes';

export default {
    [ADD_NOTIFICATION](state, payload) {
        payload['ID'] = Math.random().toString(16).substring(2,9);
        state.list.push(payload);
    },

    [REMOVE_NOTIFICATION](state, payload) {
        state.list = state.list.filter(notification => notification.ID !== payload.ID);
    }
};