<template>
    <div class="container pt-3">
        <div class="row">
            <div class="col-md-12 mb-2">
                <h1>
                    <i class="fal fa-user-headset fa-fw" /> {{ $t('sidebar.reception') }}
                    <button-refresh :refresh="refreshing" @click="onRefresh" />
                    <router-link :to="{ name: 'reception-destinations' }" class="btn btn-outline-info float-right mr-2"><i
                            class="fal fa-loveseat fa-fw" /> {{ $t('admin_view.destinations') }}</router-link>
                </h1>
            </div>
            <div class="col-md-12 mb-2">
                <div class="card shadow-sm">
                    <div class="card-header"><i class="fal fa-user-md" /> {{ $t('reception.doctors') }}</div>
                    <div class="card-body">
                        <div class="row">
                            <doctor-nav-pill v-for="(doctor, index) in doctors" :key="index" :name="doctorName(doctor)"
                                :invoices="doctorInvoiceCount(doctor)" :appointments="appointments[doctor.id] || 0" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-2">
                <div class="card shadow-sm">
                    <div class="card-header"><i class="fal fa-filter fa-fw" /> {{ $t('reception.filter') }}</div>
                    <div class="card-body">
                        <p class="card-title">{{ $t('cash_registers.units') }}</p>
                        <div class="row">
                            <button-visibility-toggle v-for="(unit, index) in units" :key="unit.id"
                                :text="unit.department" :selected="selectedUnit === index"
                                :visible="selectedUnit === index || selectedUnit === -1"
                                @click="toggleUnitVisibility(index)" />
                        </div>
                    </div>
                    <div class="card-body">
                        <p class="card-title">{{ $t('admin_view.cash_registers') }}</p>
                        <div class="row">
                            <button-visibility-toggle v-for="cashregister in cashregisters" :key="cashregister.id"
                                :text="cashregister.name" :visible="!isHidden(cashregister.id)"
                                @click="toggleCrVisibility(cashregister.id)" />
                        </div>
                    </div>
                    <div class="card-header"><i class="fal fa-file-invoice" /> {{ $t('invoices.invoices') }}</div>
                    <reception-table :invoices="sortedInvoices" :cashregisters="cashregisters" @selected="onSelected" />
                </div>
            </div>
        </div>
        <reception-paperless-modal :invoice="currentInvoice" :active="active" @close="active = false"
            @success="currentInvoice.printed = '1'" />
        <modal-error :title="errorTitle" :active="error" :error="errorMessage" @close="onErrorModalClose" />
    </div>
</template>
<script type="text/javascript">
import localService from '@src/api/local';
import ButtonRefresh from '@components/buttonRefresh';
import ButtonVisibilityToggle from '@components/buttonVisibilityToggle';
import DoctorNavPill from '@components/doctorNavPill';
import ReceptionPaperlessModal from '@components/reception/paperlessModal';
import ReceptionTable from '@components/reception/receptionTable';
import { mapGetters } from 'vuex';
import ModalError from '@components/modalError';

export default {
    components: {
        ButtonRefresh,
        ButtonVisibilityToggle,
        DoctorNavPill,
        ReceptionPaperlessModal,
        ReceptionTable,
        ModalError
    },
    data() {
        return {
            units: [],
            selectedUnit: -1,
            cashregisters: [],
            doctors: [],
            invoices: [],
            currentInvoice: {},
            active: false,
            appointments: {},
            hidden: [],
            refreshing: false,
            error: false,
            errorMessage: '',
            errorTitle: '',
        };
    },
    computed: {
        ...mapGetters([
            'currentUserID',
            'currentUnitID'
        ]),
        visibleInvoices() {
            return this.invoices.filter(i => !this.isHidden(Number(i.cash_register_id)));
        },
        sortedInvoices() {
            return this.visibleInvoices.slice(0).sort((a, b) => {
                return new Date(b.created_at.replace(' ', 'T')) - new Date(a.created_at.replace(' ', 'T'));
            });
        }
    },
    created() {
        localService.getCashRegistersByUserAndUnit(this.currentUserID, this.currentUnitID).then(res => {
            this.cashregisters = res.data;
            this.getInvoices();
        }).catch(err => {
            this.error = true;
            const { message } = err.response.data;
            this.errorTitle = 'Villa kom upp við að sækja reikningsgerðir';
            this.errorMessage = message;
        });
        this.getUnits();
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        getUnits() {
            this.units = this.$store.state.availableUnits;
            this.units.forEach(unit => { unit.hidden = false; });
        },
        getInvoices() {
            const now = new Date().toISOString().split('T')[0];
            const cashregisters = this.cashregisters.map(a => a.id);
            localService.getInvoicesByCashRegisters(cashregisters, now, now).then(res => {
                this.invoices = res.data;
                this.doctors = this.invoices.reduce((acc, i) => {
                    if (!acc.find(d => d.id == i.doctor.id)) {
                        acc.push(i.doctor);
                        this.getUserAppointments(i.doctor.id);
                    }
                    return acc;
                }, []);
            }).catch(err => {
                this.error = true;
                const { message } = err.response.data;
                this.errorTitle = this.$t('reception.error_while_fething_invoices');

                this.errorMessage = message;
            }).finally(() => {
                this.refreshing = false;
            });
        },
        doctorName(doctor) {
            return doctor.name || this.$t('reception.no_name');
        },
        doctorInvoiceCount(doctor) {
            return this.invoices.reduce((acc, i) => {
                if (i.doctor_id == doctor.id) {
                    if (i.credit_invoice == '0') {
                        return acc + 1;
                    } else {
                        return acc - 1;
                    }
                }
                return acc;
            }, 0);
        },
        onSelected(invoice) {
            if (this.currentInvoice.id !== invoice.id) {
                this.currentInvoice = invoice;
            }
            this.active = true;
        },
        getUserAppointments(id) {
            const now = new Date();
            localService.getUserAppointments(id, this.currentUnitID, now.toISOString().split('T')[0], now.addDays(1).toISOString().split('T')[0]).then(res => {
                this.$set(this.appointments, id, res.data);
            }).catch(err => {
                this.error = true;
                this.errorMessage = this.$t('reception.error_while_fetching_arrivals', { data: JSON.stringify(err.response.data) });
            });
        },
        toggleCrVisibility(id) {
            const idx = this.hidden.findIndex(i => i === id);
            if (idx === -1) {
                this.hidden.push(id);
            } else {
                this.hidden.splice(idx, 1);
            }
        },
        toggleUnitVisibility(index) {
            this.selectedUnit = (this.selectedUnit === index) ? -1 : index;
            this.hidden = [];
            if (this.selectedUnit !== -1) {
                for (let i = 0; i < this.units.length; i++) {
                    this.hidden = this.hidden.concat(this.units[i].cash_register_ids);
                }
                this.hidden = this.hidden.filter((cr) => !this.units[this.selectedUnit].cash_register_ids.includes(cr));
            }
        },
        isHidden(id) {
            return this.hidden.findIndex(i => i === id) !== -1;
        },
        onRefresh() {
            // this.refreshing = true;
            this.getUnits();
            this.getInvoices();
        },
        onErrorModalClose() {
            this.error = false;
            this.errorMessage = '';
        }
    },

};
</script>