<template>
    <div>
        <notification 
            v-for="(notification, index) in notifications.slice().reverse()" 
            :key="notification.text + index"
            :notification="notification" 
            @close-notification="removeNotification" 
        ></notification>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

import Notification from '@components/kiosk/notification/notification/notification';
import { REMOVE_NOTIFICATION } from '@src/store/mutationTypes';

export default {
    components: {
        Notification
    },

    computed: {
        ...mapGetters([
            'notifications'
        ]),
    },

    methods: {
        removeNotification: function (notification) {
            this.$store.commit(REMOVE_NOTIFICATION, notification);
        },
    },
}
</script>