<template>
  <v-card flat>
    <v-toolbar color="transparent">
      <v-app-bar-nav-icon @mouseover="hovered = true" @mouseleave="hovered = false">
        <v-avatar :color="getAvatarColor(journalData.data.lab.Priority)">
          <v-icon @click="editLabResult(journalData.data)">
            {{ hovered ? 'mdi-pencil' : 'mdi-microscope' }}
          </v-icon>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>{{ $t('labresult.lab_result') }}</span>
        <v-chip v-if="journalData.data.status && journalData.data.status !== ''" size="small" class="ml-2 text-caption">
          {{ translatedStatus(journalData.data.status) }}
        </v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip prepend-icon="mdi-doctor" size="small" class="text-1">{{ journalData.user }}</v-chip>
    </v-toolbar>
    <v-card-text v-if="journalData.data.observer_comment && journalData.data.observer_comment !== ''"
      class="journal-indent pt-0 text-grey-darken-">
      <p class="text-grey mb-2">
        {{ journalData.data.observer_comment }}
      </p>
    </v-card-text>
    <v-card-text v-for="sample in journalData.data.lab_result_group" :key="sample.specimen"
      class="journal-indent pt-0 text-grey-darken-">
      <h4 class="d-flex">
        {{ sample.specimen }}
        <v-chip size="small" class="ml-2 text-caption">
          {{ formatDate(sample.sample_time) }}
        </v-chip>
      </h4>
      <p v-if="sample.group_comment && sample.group_comment !== ''" class="text-grey mb-2">
        {{ sample.group_comment }}
      </p>
      <v-table density="compact">
        <thead>
          <tr>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.analysis') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.result') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.range') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.comment') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(resultItem, resultIndex) in sample.items" :key="resultIndex">
            <td>{{ resultItem.analysis }}</td>
            <td>{{ resultItem.result }} {{ resultItem.unit }}</td>
            <td>{{ resultItem.range }}</td>
            <td>{{ resultItem.analysis_comment }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>

    <!-- Confirmation Modal -->
    <v-dialog v-model="isModalOpen" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirm Edit</span>
        </v-card-title>
        <v-card-text>
          Another item is being edited. Do you want to continue and lose unsaved data?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmEdit">continue</v-btn>
          <v-btn color="red darken-1" text @click="cancelEdit">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </v-card>
</template>

<script>
import { useJournalStore } from '@src/stores/journalStore';

export default {
  props: {
    journalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
      hovered: false,
    };
  },
  methods: {
    getAvatarColor(priority) {
      return this.hovered ? 'grey' : priority === 'S' ? 'orange' : 'blue';
    },
    editLabResult(item) {
      this.$emit('edit-lab-item', item);

      const journalStore = useJournalStore();

      // currently temporaray dta is also being saved , need to modify logic 
      if (journalStore.editor.dataRowId === undefined && journalStore.editor.type === undefined) {
        this.setEditor(item); // Proceed directly if both are undefined
      } else {
        this.isModalOpen = true;
      }
    },
    confirmEdit(item) {

      this.$emit('edit-lab-item', item);

      const journalStore = useJournalStore();

      // Set editor properties using the current lab result data
      journalStore.setEditor({
        dataRowId: this.journalData.data.datarow_id,
        type: this.journalData.data.metadata.module_id,
        templateId: undefined,
      });

      journalStore.showEditor = true; // Show the editor
      this.isModalOpen = false; // Close the modal


    },
    cancelEdit() {
      this.isModalOpen = false; // Close the modal without making changes

      // Reset the editor properties
      const journalStore = useJournalStore();
      journalStore.resetEditor();
    },
    setEditor(item) {
      const journalStore = useJournalStore();

      // Set editor properties
      journalStore.setEditor({
        dataRowId: item.datarow_id,
        type: item.metadata.module_id,
        templateId: undefined,
      });
      journalStore.showEditor = true; // Show the editor
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
    translatedStatus(status) {
      return this.$t(`labresult.${status.trim()}`);
    },
  },
};
</script>
