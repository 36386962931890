<template>
    <div ref="" class="form-group">
        <label for="kiosk-signature" class="control-label" />
        <div class="card">
            <div class="card-header">
                <div class="d-flex">
                    <div class="flex-fill">
                        <button :class="{'btn btn-success':true, 'disabled':isEmpty}" :disabled="isEmpty" @click="onSigned()">{{ $t('signature.accept') }}</button> 
                    </div>
                    <div class="flex-fill text-center align-self-center">
                        {{ $t('signature.sign_here') }}
                    </div>
                    <div class="flex-fill">
                        <i v-tooltip="t('signature.clear')" class="fas fa-backspace fa-2x fa-fw float-right close mt-1" @click="clearDrawing" />
                    </div>
                </div>
            </div>
            <div class="card-body p-0 signature-body">
                <canvas 
                    id="kiosk-signature" 
                    ref="kioskSignature" 
                    @mousedown="startDrawing($event)" 
                    @mouseup="stopDrawing($event)"
                    @mousemove="draw($event)"
                />
                <canvas id="blank" style="display:none" />
                <hr class="signature-line mx-5">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            canvas: null,
            canvasContext: null,
            drawing: false,
            isEmpty: true,
        };
    },
    computed: {
        hasContent() {
            if(!this.canvas) { return false; }    
            return this.canvas.toDataURL() != this.emptyUrl;
        }
    },
    created() {
        
    },
    mounted() {
        this.$refs.kioskSignature.addEventListener('touchstart', this.onTouchStart, false);
        this.$refs.kioskSignature.addEventListener('touchend', this.onTouchStop, false);
        this.$refs.kioskSignature.addEventListener('touchmove', this.draw, false);
        this.canvas = this.$refs.kioskSignature;
        this.canvasContext = this.$refs.kioskSignature.getContext('2d');
        
        this.resizeCanvas();
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        resizeCanvas() {
            this.canvas.height = 200;
            this.canvas.width = this.canvas.parentElement.offsetWidth;
        },
        startDrawing(e) {
            this.drawing = true;
            this.draw(e);
        },
        stopDrawing() {
            this.drawing = false;
            this.canvasContext.beginPath();
            
        },
        draw(e) {            
            
            if (!this.drawing) {return;}
            this.canvasContext.lineWidth = 3;
            this.canvasContext.lineCap = 'round';

            let posX = 0;
            let posY = 0;
            
            if (e.type == 'mousemove') {
                posX = e.offsetX;
                posY = e.offsetY;
            } else {
                e.preventDefault();
                let rect = this.canvas.getBoundingClientRect();
                posX = e.x - rect.left;
                posY = e.y - rect.top;
            }


            this.canvasContext.lineTo(posX, posY);
            this.canvasContext.stroke();
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(posX, posY);
            this.isEmpty = false;
            this.$emit('input', this.canvas.toDataURL('image/png').replace('data:image/png;base64,', ''));
        },
        clearDrawing() {
            this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.isEmpty = true;
        },
        onTouchStart(e) {
            let rect = this.canvas.getBoundingClientRect();
            
            let touch = e.touches[0];
            let mouseEvent = new MouseEvent('mousedown', {
                clientX: touch.clientX,
                clientY: touch.clientY,
                pageX: touch.pageX,
                pageY: touch.pageY,
            });
            this.canvas.dispatchEvent(mouseEvent);
        }, 
        onTouchStop() {
            let mouseEvent = new MouseEvent('mouseup', {});
            this.canvas.dispatchEvent(mouseEvent);
        }, 
        onTouchMove(e) {
            e.preventDefault();

            let touch = e.touches[0];
            let rect = this.canvas.getBoundingClientRect();
            this.draw(e);
        }, 
        onSigned() {
            this.$emit('signed', this.canvas.toDataURL('image/png').replace('data:image/png;base64,', ''));
        }
    },
};
</script>
<style>
    #kiosk-signature {
        display: block;
        height: 200;
        width: 100%
    }
    .signature-line {
        margin-top: -60px;
        margin-bottom: 59px;
        border-top: 1px dashed rgba(0, 0, 0, 0.4);
    }
</style>