import Dashboard from '@components/dashboard';

import adminRoutes from './adminRoutes';
import cashRegisterRoutes from './cashRegisterRoutes';
import errorRoutes from './errorRoutes';
import invoiceRoutes from './invoiceRoutes';
import patientIndexRoutes from './patientIndexRoutes';
import prescriptionRoutes from './prescriptionRoutes';
import prescriptionRegistryRoutes from './prescriptionRegistryRoutes';
import receptionRoutes from './receptionRoutes';
import srlRoutes from './srlRoutes';
import userRoutes from './userRoutes';
import kioskRoutes from './kioskRoutes';
import journalRoutes from './journalRoutes';
import signatureRoutes from './signatureRoutes';
import electronicSheetRoutes from './electronicSheetRoutes';


export default [
    {
        path: '/', 
        component: Dashboard, 
        name: 'home' 
    },
    ...adminRoutes,
    ...cashRegisterRoutes,
    ...errorRoutes,
    ...invoiceRoutes,
    ...patientIndexRoutes,
    ...prescriptionRoutes,
    ...prescriptionRegistryRoutes,
    ...receptionRoutes,
    ...userRoutes,
    ...kioskRoutes,
    ...journalRoutes,
    ...signatureRoutes,
    ...electronicSheetRoutes,
    ...srlRoutes
];
