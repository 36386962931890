const CashRegisterIndex = () => import(/* webpackChunkName: "group-cr" */ '@components/cashRegister/index');
const CashRegisterShow = () => import(/* webpackChunkName: "group-cr" */ '@components/cashRegister/show');
const CashRegisterDetails = () => import(/* webpackChunkName: "group-cr" */ '@src/components/cashRegister/detail');
const CashRegisterEdit = () => import(/* webpackChunkName: "group-cr" */ '@components/cashRegister/edit');


import { GUARD_ADMIN, GUARD_BILLING } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/cr/index', 
        component: CashRegisterIndex, 
        name: 'cr-index', 
        meta: { access: GUARD_BILLING } 
    },
    {
        path: '/cr/:id/details', 
        component: CashRegisterDetails, 
        name: 'cr-details', 
        meta: { access: GUARD_BILLING } 
    },
    {
        path: '/cr/create',
        component: CashRegisterEdit,
        name: 'cr-create',
        meta: { access: GUARD_ADMIN }
    },
    {
        path: "/cr/:id", 
        component: CashRegisterShow, 
        name: 'cr-show', 
        meta: { access: GUARD_BILLING } 
    },
    {
        path: "/cr/edit/:id", 
        component: CashRegisterEdit, 
        name: 'cr-edit', 
        meta: { access: GUARD_ADMIN }
    }
]