<template lang="html">
    <modal-simple v-if="active" :size="size" color="danger" @close="emitClose">
        <template slot="header">
            <i class="fal fa-exclamation-triangle"></i> {{ titleComputed }}
        </template>
        {{ errorComputed }}
    </modal-simple>
</template>
<script>
import ModalSimple from '../modalSimple';
import AlertError from '../alertError';

export default {
    components: {
        ModalSimple,
        AlertError
    },
    props: {
        active: { 
            required: false,
            type: Boolean,
            default: false 
        },
        error: { 
            required: false, 
            type: String,
            default: undefined 
        },
        title: { 
            required: false, 
            type: String,
            default: undefined
        },

        size: {
            default: null,
            required: false,
            validator: v => ['small', 'large'].indexOf(v) !== -1
        },
    },
    computed: {
        errorComputed() {
            if (this.error) {
                return this.error;
            
            } else {
                return this.t('refresh.error_occurred');
            }
        },
        titleComputed() {
            if (this.title) {
                return this.title;
            
            } else {
                return this.t('invoice_create.error');
            }
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        emitClose() {
            this.$emit('close');
        }
    }
}
</script>