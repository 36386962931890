import { createApp } from 'vue';
const app = createApp({});

// Define the initial value of PatientChangedHandler
app.config.globalProperties.PatientChangedHandler = null;

// Method to set the event handler
app.config.globalProperties.setEventHandler = function(event, cb) {
    this.config.globalProperties[event + 'Handler'] = cb;
};

// Method to listen to an event
app.config.globalProperties.listenToEvent = function(event, cb) {
    const listener = () => {
        this.config.globalProperties[event + 'Handler'] = cb;
    };
    listener();
    return listener;
};

export default app;
