<template>
    <div class="diagnosis-items-tree">
      <v-treeview
        :items="items"
        activatable
        item-key="id"
        :load-children="loadChildren"
        @update:active="fetchChildren"
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.isFolder">mdi-folder</v-icon>
          <v-icon v-else>mdi-file</v-icon>
        </template>
      </v-treeview>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { VTreeview } from 'vuetify/labs/VTreeview';
  
  export default defineComponent({
    name: 'DiagnoseTree',
    components: {
      VTreeview,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    emits: ['fetch-children'],
    setup(props, { emit }) {
      const loadChildren = async (item) => {
        if (item.isFolder) {
          const response = await fetch(`/api/diaFolders?parentId=${item.id}`);
          const children = await response.json();
          return children;
        } else {
          const response = await fetch(`/api/diaItems?folderId=${item.id}`);
          const items = await response.json();
          return items;
        }
      };
  
      const fetchChildren = (activeItem) => {
        emit('fetch-children', activeItem.id);
      };
  
      return {
        loadChildren,
        fetchChildren,
      };
    },
  });
  </script>
  
  <style scoped>
  .diagnosis-items-tree {
    /* Add styles for the tree component */
  }
  </style>
  