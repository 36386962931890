<template>

  <v-card flat>
    <template v-if="vaccinationItems.length > 0">
      <div v-for="(item, index) in transformedVaccinationItems" :key="index">
        <v-toolbar color="transparent" @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)">
          <v-avatar :color="getHoverColor(index)">
            <v-icon>{{ getAvatarIcon(index) }}</v-icon>
          </v-avatar>
          <v-toolbar-title>
            <strong>{{ item.name }}</strong>
            <v-chip class="ml-3">{{ item.atc }}</v-chip>
          </v-toolbar-title>
          <v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
        </v-toolbar>
        <div class="journal-indent">
          <v-card-title> Disease </v-card-title>
          <v-card-text>
            {{ item.disease }}
          </v-card-text>
          <v-card-title>Administration</v-card-title>
          <v-card-text> {{ item.administration }} </v-card-text>
          <v-card-title>Location</v-card-title>
          <v-card-text> {{ item.location }}</v-card-text>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    journalData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      vaccinationItems: [],
      hoverColor: 'blue',
      avatarIcon: 'mdi-needle',
      hoveredItemIndex: null,

    };
  },
  computed: {
    transformedVaccinationItems() {
      return this.vaccinationItems.map(item => ({
        name: item.data.name,
        atc: item.data.atc,
        owner_name: item.data.metadata.owner_name,
        disease: item.data.disease,
        administration: item.data.administration,
        location: item.data.location,
      }));

    }
  },
  mounted() {
    const type18Items = this.journalData.items.filter(item => item.type === '18');
    this.vaccinationItems = type18Items;
  },
  methods: {
    handleMouseEnter(index) {
      this.hoveredItemIndex = index;
    },
    handleMouseLeave(index) {
      this.hoveredItemIndex = null;
    },
    getHoverColor(index) {
      return this.hoveredItemIndex === index ? '#EEEEEE' : 'blue';
    },
    getAvatarIcon(index) {
      return this.hoveredItemIndex === index ? 'mdi-pencil' : 'mdi-needle';
    }
  }
};
</script>

<style>
.journal-indent {
  margin-left: 40px;
}
</style>