import { createApp } from 'vue';
import App from './components/app/app.vue'; 

import extension from './helpers/date_extension';
extension.all();

import VTooltip from 'v-tooltip';

import axios from 'axios';

const app = createApp(App);

// VTooltip
app.use(VTooltip);

// Axios
const pmoChangePath = '/pmo/change';
const pmoChangePath2 = 'telemed';
let baseURL = window.location.href.substring(0, window.location.href.length - window.location.hash.length - 1);
if (window.location.pathname.indexOf(pmoChangePath) !== -1) {
    baseURL = window.location.href.substring(0, window.location.href.length - window.location.hash.length - pmoChangePath.length);
} else if (window.location.pathname.indexOf(pmoChangePath2) !== -1) {
    baseURL = window.location.href.substring(0, window.location.href.length - window.location.hash.length - pmoChangePath2.length);
}
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;    
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
app.config.globalProperties.$axios = axios;

// VB Client
import './vb_client';

// Global event bus
import EventBus from './event-bus';
app.config.globalProperties.$eventBus = EventBus;

// Numeral.js
import numeral from 'numeral';
numeral.register('locale', 'is', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'þ',
        million: 'm',
        billion: 'ma',
        trillion: 't'
    },
    currency: {
        symbol: 'kr.'
    }
});
numeral.locale('is');
app.config.globalProperties.$numeral = numeral;

import medicineforms from './medicine/forms';

// Vue.js Global Filters
app.config.globalProperties.$filters = {
    codenumber(value) {
        if ((typeof value === 'string' || value instanceof String) && value.length === 10) {
            return value.substring(0, 6) + '-' + value.substring(6);
        } else {
            return value;
        }
    },
    scale(value, precision) {
        if (value === undefined || value === '') {
            return value;
        }
        precision = (precision === undefined ? 0 : precision);
        const scale = Number(`0.00${'0'.repeat(precision)}1`);
        return Math.round((Number(value) + scale) * 100) / 100;
    },
    currency(value, precision, unit) {
        if (value === undefined || value === '') {
            return value;
        }
        precision = (precision === undefined ? 0 : precision);
        unit = (unit === undefined ? true : unit);
        const format = `0,0${precision > 0 ? '.' + '0'.repeat(precision) : ''}${unit ? ' $' : ''}`;
        return numeral(value).format(format);
    },
    date(value) {
        let date = value instanceof Date ? value : new Date(value);
        if (isNaN(date.valueOf())) {
            return value;
        }
        let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return date.getDate() + '. ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
    },
    ieDate(value) {
        return value.replace(' ', 'T');
    },
    shortdate(value) {
        if (!(value instanceof Date)) {
            value = new Date(value.replace(' ', 'T'));
        }
        return value.getDate() + '.' + (value.getMonth() + 1) + '.' + value.getFullYear();
    },
    isodate(value) {
        if (!(value instanceof Date)) {
            value = new Date(value.replace(' ', 'T'));
        }
        return value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
    },
    wraptext(value, length) {
        if (typeof value === 'string' || value instanceof String) {
            let wrap = (length === undefined) ? 15 : length;
            let appendix = (value.length > wrap) ? '…' : '';
            return value.substring(0, wrap) + appendix;
        } else {
            return value;
        }
    },
    medicineForm(value) {
        if(medicineforms.hasOwnProperty(value)) {
            return medicineforms[value];
        } else {
            return value;
        }
    }
};

// Vue.js Global Custom Directives
app.directive('focus', {
    mounted(el) {
        el.focus();
    }
});

app.config.keyCodes = {
    esc: 27
};


import router from './router';
app.use(router);

import store from './store';
app.use(store);

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);


import i18n from '@src/i18n.js';
app.use(i18n);

// Vuetify
import '@mdi/font/css/materialdesignicons.css'; 
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { VTreeview } from 'vuetify/labs/VTreeview';

const vuetify = createVuetify({
    components: {
        ...components,
        VTreeview, // Ensure VTreeview is registered here
    },
    directives,
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,   
        },
    },
});
app.use(vuetify);


// Initialize the Vue.js Object
app.mount('#app');
