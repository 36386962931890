<template>
  <v-container>
    <term-name v-model="termName" />


    <v-text-field
      v-model="termValue"
      :rules="validationRules"
      label="Enter value"
      clearable
      @update:modelValue="onTermValueChange"
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import { defineComponent, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'RegExValue',
  components: {
    TermName
  },
  props: {
    regExp: {
      type: String,
      required: true
    },
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const termName = ref('This is RegEx Example');
    const termValue = ref('');

    const validationRules = computed(() => {
      const regex = new RegExp(props.regExp);
      return [
        v => regex.test(v) || 'Invalid value'
      ];
    });

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          termValue.value = newVal.term_value || termValue.value;
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    const onTermValueChange = (newValue) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newValue });
    };

    return {
      termName,
      termValue,
      validationRules,
      onTermValueChange
    };
  }
});
</script>

<style scoped>
/* Your styles here */
</style>
