<template>
    <div id="signature-view-logo" class="d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 text-center">
                    <img :src="'./img/signature-logo.png'" alt="" class="signature-logo">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
<style>
    .signature-logo {
        max-width: 100%;
    }
</style>