<template>
  <v-list>
    <v-list-item v-for="(item, index) in journalData.items" :key="index">
      <component 
        :is="componentTypes[item.type].component"  
        :journalData="item" 
        @edit-diagnose-item="handleEditDiagnoseItem(index)"
        @edit-attention-item="handleEditAttentionItem($event)" 
        @edit-note-item="handleEditNoteItem(index)"
        @edit-lab-item="handleEditlabResultItem($event)" />
    </v-list-item>
  </v-list>
</template>

<script>
import Note from '@components/journal/journalview/note/note.vue';
import Vaccination from '@components/journal/journalview/vaccination/vaccination.vue';
import Diagnose from '@components/journal/journalview/diagnose/diagnose.vue';
import Prescription from '@components/journal/journalview/prescription/prescription.vue';
import Attention from '@components/journal/journalview/attention/attention.vue';
import Measure from '@components/journal/journalview/measure/measure.vue';
import LabResult from '@components/journal/journalview/labresult/labresult.vue';

export default {
  components: {
    Note,
    Vaccination,
    Diagnose,
    Prescription,
    Attention,
    Measure,
    LabResult,
  },
  props: {
    journalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentTypes: {
        '2': { name: 'Diagnose', component: Diagnose},
        '3': { name: 'Warning', component: Attention},
        '4': { name: 'Lab', component: LabResult},
        '5': { name: 'Note', component: Note},
        '6': { name: 'Contact', component: 'Contact'},
        '7': { name: 'Measure', component: Measure},
        '8': { name: 'Document', component: 'Document'},
        '9': { name: 'Attention', component: Attention},
        '10':{ name:  'Summary', component:  'Summary'},
        '11':{ name:  'Symbol', component:  'Symbol'},
        '12':{ name:  'Dictate', component:  'Dictate'},
        '13':{ name:  'Measurement', component:  'Measurement'},
        '15':{ name:  'Growth', component:  'Growth'},
        '16':{ name:  'Prescription', component:  Prescription},
        '17':{ name:  'Draw', component:  'Draw'},
        '18':{ name:  'Vaccination', component:  Vaccination},
        '19':{ name:  'Audiometry', component:  'Audiometry'},
        '20':{ name:  'StatusCheck', component:  'StatusCheck'},
        '21':{ name:  'Accident', component:  'Accident'},
        '22':{ name:  'HealthIssues', component:  'HealthIssues'},
        '23':{ name:  'Optometry', component:  'Optometry'},
        '24':{ name:  'MedicalAdvice', component:  'MedicalAdvice'},
        '25':{ name:  'ChildDevelopment', component:  'ChildDevelopment'},
        '26':{ name:  'Heredity', component:  'Heredity'},
        '30':{ name:  'Nutrition', component:  'Nutrition'},
        '31':{ name:  'Ophthalmology', component:  'Ophthalmology'},
        '32':{ name:  'DrugAdministration', component:  'DrugAdministration'},
      },
    };
  },
  methods: {
    handleEditDiagnoseItem(index) {
      this.$emit('edit-diagnose-item', index);
    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
    },
    handleEditNoteItem(index) {
      this.$emit('edit-note-item', index);
    },
    handleEditlabResultItem(item) {
      this.$emit('edit-lab-item', item);
    },
  },
};
</script>

<style scoped></style>
