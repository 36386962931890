import { UPDATE_SESSION, SET_SESSION_LIFETIME } from '@src/store/mutationTypes';

export default {
    [UPDATE_SESSION](state) {
        const updatedTimestamp = new Date().getTime();
        state.expired = (updatedTimestamp - state.timestamp) / (1000 * 60) > state.lifetime;
        state.timestamp = updatedTimestamp;
    },
    [SET_SESSION_LIFETIME](state, lifetime) {
        state.lifetime = lifetime;
    }
};