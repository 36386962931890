<template>

  <div class="diagnosis-editor">

    <v-spacer></v-spacer>
    <v-toolbar color="transparent">

      <v-toolbar-title class="mb-2 text-center">
        <strong>Add Diagnosis</strong>
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar-title>
    </v-toolbar>

    <div class="mb-2 text-center">
    Please Click the Add Diagnosis Button
  </div>

    <DiagnoseTree :items="diagnosisItems" @fetch-children="fetchTreeChildren" />
    <DiagnoseSearch :dataRowId="dataRowId" :version="version" @search="handleSearch" />
    <DiagnoseForm :data-row-id="dataRowId" :initialData="formData" @save="handleSave" />

  </div>
</template>

<script setup>
import { ref, defineComponent, onMounted, watch, defineProps } from 'vue';
import DiagnoseForm from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseForm/diagnoseForm.vue';
import DiagnoseTree from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseTree/diagnoseTree.vue';
import DiagnoseSearch from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseSearch/diagnoseSearch.vue';
import { useStore } from 'vuex';
import { useJournalStore } from '@src/stores/journalStore';

const journalStore  = useJournalStore();

const props = defineProps({
  dataRowId: {
    type: Number,
    default: null
  },
  version: {
    type: Number,
    default: null
  }
});

const showDialog = ref(true);


const emit = defineEmits(['close']);
const handleClose = () => {
  closeDiagnoseEditor();
  emit('close');
};

const closeDiagnoseEditor = () => {
  journalStore.resetEditor();
  showDialog.value = false;
};

// Example function for handling search event
const handleSearch = (searchTerm) => {
};

</script>

<style scoped>
.diagnosis-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
