<template>
  <div>
    <component :is="componentType" :journalData="journalData" v-for="(componentType, index) in componentTypes"
      :key="index" @edit-diagnose-item="handleEditDiagnoseItem" @edit-attention-item="handleEditAttentionItem"
      @edit-note-item="handleEditNoteItem" />
  </div>
</template>

<script>
import Note from '@components/journal/journalview/note/note.vue';
import Vaccination from '@components/journal/journalview/vaccination/vaccination.vue';
import Diagnose from '@components/journal/journalview/diagnose/diagnose.vue';
import Prescription from '@components/journal/journalview/prescription/prescription.vue';
import Attention from '@components/journal/journalview/attention/attention.vue';
import Measure from '@components/journal/journalview/measure/measure.vue';

export default {
  components: {
    Note,
    Vaccination,
    Diagnose,
    Prescription,
    Attention,
    Measure
  },
  props: {
    journalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentTypes: {
        '2': 'Diagnose',
        '3': 'Warning',
        '4': 'Lab',
        '5': 'Note',
        '6': 'Contact',
        '7': 'Measure',
        '8': 'Document',
        '9': 'Attention',
        '10': 'Summary',
        '11': 'Symbol',
        '12': 'Dictate',
        '13': 'Measurement',
        '15': 'Growth',
        '16': 'Prescription',
        '17': 'Draw',
        '18': 'Vaccination',
        '19': 'Audiometry',
        '20': 'StatusCheck',
        '21': 'Accident',
        '22': 'HealthIssues',
        '23': 'Optometry',
        '24': 'MedicalAdvice',
        '25': 'ChildDevelopment',
        '26': 'Heredity',
        '30': 'Nutrition',
        '31': 'Ophthalmology',
        '32': 'DrugAdministration',
      },
    };
  },
  methods: {
    handleEditDiagnoseItem(index) {

      this.$emit('edit-diagnose-item', index);
    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
    },
    handleEditNoteItem(index) {
      this.$emit('edit-note-item', index);
    },
  }
};
</script>

<style></style>
