<template>
  <transition name="slide" v-if="!isCollapsed" class="vh-100" >
    <div class="editor pt-0 pb-0">
      <div v-if="currentEditor === null">
        <div class="d-flex m-2 toolbar-height">
          <button type="button" class="close ml-auto mr-4" @click="closeEditor">
            <span>&times;</span>
          </button>
        </div>
        <div class="d-flex align-items-center mt-4 my-auto toolbar-height">
          <h4 class="my-auto mx-auto">{{ $t('journal.editor.title') }}</h4>
        </div>
      </div>
      <JournalEditorMenu @select-editor="selectEditor" @close="closeEditor" v-if="!currentEditor" />
      <component :is="currentEditor" :data-row-id="dataRowId" :type="type" @close="handleEditorClose" v-else />
    </div>
  </transition>
</template>

<script>
import JournalEditorMenu from './journalEditorMenu/journalEditorMenu.vue';
import NoteEditor from './noteEditor/noteEditor.vue';
import DiagnoseEditor from './diagnoseEditor/diagnoseEditor.vue';
import PrescriptionEditor from '@src/components/prescriptionCreate/prescriptionCreate.vue';
import AttentionEditor  from './attentionEditor/attentionEditor.vue';
import LabResultEditor from './labResultEditor/labResultEditor.vue';

// An example of Pinia store implementation
import { mapState } from 'pinia';
import { useJournalStore } from '@src/stores/journalStore';

export default {
  props: {
    isCollapsed: Boolean,
    dataRowId: Number,
    type: Number,
    indexValue: Number
  },
  data() {
    return {
      currentEditor: null,
    };
  },
  computed: {
    ...mapState(useJournalStore, ['attentionItem']),
    editorTitle() {
      return (this.dataRowId === undefined && this.type === undefined) ? 'Create' : 'Edit';
    },
    editorTitleFontSize() {
      return this.editorTitle === 'Create' ? '1.5rem' : '';
    },
  },
  mounted() {
    const journadata = useJournalStore();
  },
  watch: {
    isCollapsed: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.openEditor();
        }
      },
    },
  },
  methods: {
    openEditor() {
      // console.log(this.indexValue)
    },
    selectEditor(editorType) {
      this.currentEditor = editorType;
    },
    closeEditor() {
      this.currentEditor = null;
      this.$emit('close');
    },
    handleEditorClose() {
      this.currentEditor = null; // Switch back to JournalEditorMenu
    },
  },
  components: {
    JournalEditorMenu,
    NoteEditor,
    PrescriptionEditor,
    DiagnoseEditor,
    AttentionEditor,
    LabResultEditor,
  },
};
</script>

<style scoped>
.toolbar-height {
  height: 64px;
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}
</style>
