import 'script-loader!./SigWebTablet.js';
/* eslint-disable */

// Abstraction for SigWebTablet legacy code
// Use a html canvas width 500 and height 100 for the S460 Topaz model
export default new class SignatureTablet {
    /**
     * Constructor
     */
    constructor() {
        this.intervalHandle;
    }

    /**
     * Set Canvas context
     * 
     * @param {Object} context Canvas 2D Context
     */
    setContext(ctx) {
        this.ctx = ctx;
    }

    /**
     * Start capturing signature on the tablet
     * 
     * @param {Number} refreshRate
     */
    start(refreshRate) {
        let interval = refreshRate === undefined ? 100 : refreshRate;

        // Set tablet config
        SetDisplayXSize(this.ctx.canvas.clientWidth);
        SetDisplayYSize(this.ctx.canvas.clientHeight);
        SetTabletState(0, this.intervalHandle);
        SetJustifyMode(0);
        ClearTablet();
        if (this.intervalHandle == null) {
            this.intervalHandle = SetTabletState(1, this.ctx, interval);
        } else {
            SetTabletState(0, this.intervalHandle);
            this.intervalHandle = null;
            this.intervalHandle = SetTabletState(1, this.ctx, interval);
        }
    }

    /**
     * Clear the signature, clears both the canvas and the tablet data
     */
    clear() {
        // Clear canvas
        ClearSigWindow(1);

        // Clear tablet signature data
        ClearTablet();
    }

    /**
     * Stop capture so the signature may be stored
     */
    stop() {
        // Set tablet state to done
        SetTabletState(0, this.intervalHandle);
    }

    /**
     * Determine if there is a signature
     * 
     * @returns {Boolean}
     */
    hasSignature() {
        return NumberOfTabletPoints() != 0;
    }

    /**
     * Get Signature as a Base64 string
     * 
     * @param {Function} cb Callback called with the string as first param
     */
    getSignature64(cb) {
        SetSigCompressionMode(1);
        SetImageXSize(this.ctx.canvas.clientWidth);
        SetImageYSize(this.ctx.canvas.clientHeight);
        SetImagePenWidth(5);
        GetSigImageB64(cb);
    }
};