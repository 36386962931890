import ApiBase from './apibase';

export default new class Kiosk extends ApiBase {
    
    /**
     * Get all Kiosks
     * @returns {Promise}
     */
    getAllKiosks() {
        return this.getData('kiosk/all');
    }

    /**
     * Get a somgæe Kiosk by id 
     * @param {Number} id Kiosk id
     * @returns {Promise}
     */
    getKiosk(id) {
        return this.getData(`kiosk/${id}`);
    }
    
    /**
     * Store a Kiosk
     * @param {Object} params Kiosk object to be stored
     * @returns {Promise}
     */
    storeKiosk(params) {
        return this.postData('kiosk', params);
    }

    /**
     * Update a Kiosk
     * @param {Number} id Id of the Kiosk to be updated
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    updateKiosk(id, params) {
        return this.putData(`kiosk/${id}/edit`, params);
    }

    /**
     * Delete a Kiosk by Id
     * @param {Number} id Id of the Kiosk to be deleted
     * @returns {Promise}
     */
    deleteKiosk(id) {
        return this.delete(`/kiosk/${id}`);
    }
    
    /**
     * Attach a unit to a Kiosk
     * @param {Number} id Id of the Kiosk
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    attachUnitToKiosk(id, params) {
        return this.postData(`kiosk/${id}/unit`, params);
    }

    /**
     * Detach a unit from a Kiosk
     * @param {Number} id Id of the Kiosk
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    detachUnitFromKiosk(id, params) {
        return this.delete(`kiosk/${id}/unit`, {data: params});
    }

    /**
     * Attach a destination to a Kiosk
     * @param {Number} id Id of the Kiosk
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    attachDestinationToKiosk(id, params) {
        return this.postData(`kiosk/${id}/destination`, params);
    }

    /**
     * Detach a destination from a Kiosk
     * @param {Number} id Id of the Kiosk
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    detachDestinationFromKiosk(id, params) {
        return this.delete(`kiosk/${id}/destination`, {data: params});
    }

    /**
     * Store a pos id in session.
     * @param {Number} id 
     * @returns {Promise}
     */
    storeSessionPos(id) {
        return this.postData(`kiosk/pos/${id}`);
    }

    /**
     * Get Session pos id.
     * @returns {Promise}
     */
    getSessionPos() {
        return this.getData('kiosk/pos');
    }

    /**
     * Store a pos id in session.
     * @param {Number} id 
     * @returns {Promise}
     */
    storeSessionKiosk(id) {
        return this.postData(`kiosk/session/${id}`);
    }

    /**
     * Get Session pos id.
     * @returns {Promise}
     */
    getSessionKiosk() {
        return this.getData('kiosk/session');
    }
    
    /**
     * Get Invoices by Patient Kennitala.
     * @param {string} kennitala 
     * @returns {Promise}
     */
    getPatient(kennitala) {
        return this.getData('kiosk/patient', {'kennitala': kennitala});
    }

    /**
     * Attach a signature to an invoice.
     * @param {Number} invoiceId 
     * @param {Object} params 
     * @returns {Promise}
     */
    postSignature(invoiceId, params) {
        return this.postData(`kiosk/signature/${invoiceId}`, params);
    }

    /**
     * Print an invoice.
     * @param {Number} invoiceId 
     * @returns {Promise}
     */
    printInvoice(invoiceId) {
        return this.putData(`kiosk/invoice/${invoiceId}/print`);
    }

    /**
     * Print an invoice.
     * @param {Number} invoiceId 
     * @returns {Promise}
     */
    setInvoicePayed(invoiceId) {
        return this.putData(`kiosk/invoice/${invoiceId}/payed`);
    }

    /**
     * Get Invoice Details by ID
     * 
     * @param {Number} invoice
     * @returns {Promise}
     */
    getInvoiceDetails(invoice) {
        return this.getData(`kiosk/invoice/${invoice}`, {eager: 1});
    }

    /**
     * Get all destinations.
     * @returns {Promise}
     */
    getDestinations() {
        return this.getData('kiosk/destination');
    }

    /**
     * Store a destination.
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    storeDestination(params) {
        return this.postData('kiosk/destination', params);
    }

    /**
     * Get a single destination.
     * @param {Number} id destination id.
     * @returns {Promise}
     */
    getDestination(id) {
        return this.getData(`kiosk/destination/${id}`);
    }

    /**
     * Update a destination.
     * @param {Number} id destination id
     * @param {Object} params query parameters
     * @returns {Promise}
     */
    updateDestination(id, params) {
        return this.putData(`kiosk/destination/${id}/edit`, params);
    }

    /**
     * Delete a destination
     * @param {Number} id destination id
     * @returns {Promise}
     */
    deleteDestination(id) {
        return this.delete(`kiosk/destination/${id}`);
    }

    /**
     * Attach a user to a destination
     * @param {Number} id destination id
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    attachUserToDestination(id, params) {
        return this.putData(`kiosk/destination/${id}/attach`, params);
    }

    /**
     * Detach a user to a destination
     * @param {Number} id destination id
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    detachUserFromDestination(id, params) {
        return this.putData(`kiosk/destination/${id}/detach`, params);
    }

    /**
     * Get Patients apointment
     * @param {Number} id Patient Id
     * @param {Object} params Query parameters
     * @returns {Promise}
     */
    getAppointment(id, params) {
        return this.getData(`kiosk/patient/${id}/appointment`, params);
    }
    
};