<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="dialog" max-width="600">
            <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                    class="text-none font-weight-regular"
                    prepend-icon="mdi-email-newsletter"
                    text="Rafræn skjöl"
                    variant="tonal"
                    v-bind="activatorProps"
                />
            </template>
            <v-card prepend-icon="mdi-email-newsletter" title="Senda rafrænt skjal">
                <v-card-text>
                    <v-row dense>
                        <v-select
                            v-model="selectedId"
                            label="Tegund skjals"
                            :items="sheets"
                            item-title="name"
                            item-value="id"
                            variant="outlined" 
                            density="compact"
                        />
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text="Velja"
                        variant="outlined" 
                        density="compact"
                        :class="no-upper"
                        :disabled="!this.selectedId"
                        @click="selectES"
                    />
                    <v-btn
                        text="Loka"
                        variant="outlined" 
                        density="compact"
                        :class="no-upper"
                        @click="dialog = false"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    props: {
        sheets: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    watch: {
        dialog() {
            if(!this.dialog) {
                this.selectedId = ''; 
            }
        }
    },
    data() {
        return {
            dialog: false,
            selectedId: ''
        };
    },
    methods: {
        selectES() {
            this.$emit('sheetSelected', this.selectedId);
            this.dialog = false;
        },
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        }
    }
};
</script>
<style scoped>
.v-btn {
height: 35px;
}
.no-upper {
text-transform: unset !important;
}
</style>
