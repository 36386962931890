<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-7 col-xl-6 mt-5">
                <h1>{{ $t('sidebar.signature') }}</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-7 col-xl-6 mt-4">
                <patientCard wrapper-class="form-group mb-4" />
                <div v-if="isInvalid('patient_id')" class="form-group">
                    <small class="text-danger mb-4"><i class="fas fa-asterisk" /> {{ errors['patient_id'][0] }}</small>
                </div>
                <div class="form-group">
                    <label>{{ $t('signature.what_is_being_signed_for') }}?</label>
                    <select v-model="selectedSignatureType" :class="{'form-control': true, 'is-invalid': isInvalid('signature_type_id')}">
                        <option value="x" hidden disabled>{{ $t('signature.select_signature_type') }}</option>
                        <option v-for="(signatureTyper, index) in signatureTypes" :key="index" :value="index">{{ signatureTyper.name }}</option>
                    </select>
                    <small v-if="isInvalid('signature_type_id')" class="text-danger"><i class="fas fa-asterisk" /> {{ errors['signature_type_id'][0] }}</small>
                </div>
            </div>
        </div>
        <div v-if="signatureTypes[selectedSignatureType]">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-7 col-xl-6 mt-3">
                    <div class="form-group">
                        <div class="form-check">
                            <input 
                                id="hasProxy" 
                                v-model="hasProxy" 
                                type="checkbox" 
                                class="form-check-input"
                            >
                            <label for="hasProxy" class="form-check-label">{{ $t('signature.on_behalf_of') }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="hasProxy" class="row justify-content-center">
                <div class="col-md-10 col-lg-7 col-xl-6 mt-3">
                    <div class="form-group">
                        <label>{{ $t('signature.who_is_signing') }}</label>
                        <input type="text" :class="{'form-control': true, 'is-invalid': isInvalid('proxy')}">
                        <small v-if="isInvalid('proxy')" class="text-danger"><i class="fas fa-asterisk" /> {{ errors['proxy'][0] }}</small>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-7 col-xl-6 mt-3">
                    <!-- <canvas-signature v-if="signatureType == 'topaz'"/> -->
                    <!-- <kiosk-select v-if="signatureType == 'kiosk' && isSignatureRequest" @selected="requestSignature($event)"/> -->
                    <sign v-if="true" @signed="saveSignature($event)" />
                    <small v-if="isInvalid('signature')" class="text-danger"><i class="fas fa-asterisk" /> {{ errors['signature'][0] }}</small>
                </div>
            </div>
            <div v-if="message" class="row justify-content-center">
                <div class="col-md-10 col-lg-7 col-xl-6 mt-3">
                    <div class="alert alert-success">
                        <i class="fal fa-check fa-fw" />
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mb-4">
            <div class="col-md-10 col-lg-7 col-xl-6">
                <hr>
                <div v-if="signatureType == 'topaz'" class="form-group text-center">
                    <button class="btn btn-primary mr-1 px-4" @click="onSave()"><i class="fal fa-check fa-fw" /> {{ t('access_control.save') }}</button>
                    <button class="btn btn-outline-secondary mr-1" @click="$router.back()"><i class="fal fa-times fa-fw" /> {{ t('invoice_create.cancel') }}</button>
                </div>
                <div v-else class="form-group text-center">
                    <button class="btn btn-outline-secondary mr-1" @click="$router.back()"><i class="fal fa-angle-left fa-fw" /> {{ t('navbar.back') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SET_PRINT_VIEW } from '@src/store/mutationTypes';

import CanvasSignature from '@components/canvasSignature';
import KioskSelect from '@components/reception/kioskSelect';
import PatientCard from '@components/patient/patientCard';
import { PasswordModal } from '@src/components/modals';
import Sign from '@components/kiosk/payment/signature';
import SignatureApi from '@src/api/signature';
import SignatureTablet from '@src/classes/signatureTablet';

export default {
    components: {
        PatientCard,
        CanvasSignature,
        KioskSelect,
        Sign
    },
    props: {},
    data() {
        return {
            isSignatureRequest: false,
            selectedSignatureType: 'x',
            signatureTypes: [],
            hasProxy: false,
            proxy: undefined,
            errors: undefined,
            signatureError: undefined,
            message: undefined
        };
    },
    computed: {
        ...mapGetters([
            'signatureType',
            'currentPatientID'
        ]),
		
    },
    created() {
        this.getSignatureTypes();
    },
    mounted() {},
    methods: {
        t(key) {
            return this.$t(key);
        },
        getSignatureTypes() {
            SignatureApi.getAllSignatureTypes().then(response => {
                this.signatureTypes = response.data;
                if (this.signatureTypes.length == 1) {
                    this.selectedSignatureType = 0;
                }
            });
        },
        onSave() {
            this.errors = undefined;
            if (SignatureTablet.hasSignature()) {
                try {
                    SignatureTablet.getSignature64(this.saveSignature);
                } catch (err) {
                    this.signatureError = err;
                }
            } else {
                this.errors = {signature: ['Signature is missing!']};
            }
        },
        saveSignature(signature) {
            this.errors = undefined;
            SignatureApi.storeSignature({
                patient_id: this.currentPatientID,
                signature_type_id: this.signatureTypes[this.selectedSignatureType].id,
                signature: signature,
                proxy: this.proxy
            }).then(() => {
                this.$router.push({name: 'home'});
            }).catch(err => {
                this.errors = err.response.data.errors;
            });
        },
        requestSignature(kioskId) {
            this.errors = undefined;
            this.message = undefined;
            SignatureApi.requestSignature(
                kioskId, 
                {
                    patient_id: this.currentPatientID,
                    signature_type_id: this.signatureTypes[this.selectedSignatureType].id,
                    proxy: this.proxy
                }).then(response => {
                this.message = response.message;
            }).catch(err => {
                console.log(err);
                // this.errors = err.response.data.errors;
            });
        },
        isInvalid(value) {
            if (!this.errors) {
                return false;
            }
            if (value in this.errors) {
                return true;
            }
        }
    },
};
</script>
<style></style>