<template>
    <div v-tooltip="`Reikningar: ${invoices} / Komur: ${appointments}`" class="col-md-3 col-sm-4 col-xs-6">
            {{ name }}
            <span :class="{'badge':true,  'badge-info': appointments == invoices, 'badge-warning': appointments != invoices}">{{ invoices }} / {{ appointments }}</span>
    </div>
</template>
<script>
    export default {
        props: {
            name: {
                required: true,
                type: String
            },
            invoices: {
                required: false,
                default: 0,
                type: Number
            },
            appointments: {
                required: false,
                default: 0,
                type: Number
            }
        },
        data() {
            return {
                hover: false
            }
        },
        methods: {
            onMouseOver() {
                this.hover = true;
            },
            onMouseOut() {
                this.hover = false;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .doc-nav-pill:hover {
        text-decoration: none;
    }
</style>
