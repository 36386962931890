import listener from '../../classes/listener';
import * as mutations from '../mutationTypes';
import * as events from '../../classes/listener/events';
import bus from '../../event-bus'; // Import the default export

// Define listener events here
export default () => {
    return store => {
        store.subscribe(mutation => {
            if (mutation.type === mutations.LOGIN_USER) {
                listener.start(mutation.payload.id);
            } else if (mutation.type === mutations.LOGOUT_USER) {
                listener.stop();
            }
        });
        listener.on(events.PATIENT_CHANGED, e => {
            store.commit(mutations.CHANGE_PATIENT, e.patient || {});
        }).on(events.UNIT_CHANGED, e => {
            store.commit(mutations.CHANGE_UNIT, e.unit || {});
        }).on(events.USER_LOGOUT, () => {
            store.commit(mutations.LOGOUT_USER);
            listener.stop();
        }).on(events.PRESCRIPTION_STATE_CHANGED, e => {
            store.commit(mutations.UPDATE_DOCTOR_PRESCRIPTION, e.resource);
        }).on(events.TEXT_VIEW_REPLY, e => {
            bus.$emit(events.TEXT_VIEW_REPLY, e.resource); // Use bus.$emit directly
        }).on(events.MEDICAL_DATA_REPLY, e => {
            bus.$emit(events.MEDICAL_DATA_REPLY, e.resource); // Use bus.$emit directly
        }).on(events.INVOICE_STORED, e => {
            store.commit(mutations.ADD_DOCTOR_INVOICE, e.resource);
        }).on(events.INVOICE_PRINTED, e => {
            store.commit(mutations.UPDATE_INVOICE_PRINTED, e.id);
        });
    };
};
