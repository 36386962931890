<script>
export default {
    methods: {
        xmlFromJson(json) {
            let result = [], level = 0;
            for(let entry of json) {
                const tag = entry.tag;
                if('opening' in entry) { result.push(`${this.getMargin(level)}<${tag}>`); level += 1;  }
                if('datatype' in entry) { result.push(`${this.getMargin(level)}<${tag}></${tag}>`); }
                if('closing' in entry) { level -= 1; result.push(`${this.getMargin(level)}</${tag}>`);  }
            }
            return result.join("\n");
        },
        getMargin(level) {
            let margin = '';
            level = level * 5;
            while (level > 0) {
                margin += ' ';
                level -= 1;
            }
            return margin;
        }
    }
}
</script>
