<template>
    <div class="d-flex justify-content-between around">
        <!-- <code><pre class="content mr-4">{{ sheet }}</pre></code> -->
        <code><pre class="content mr-4" v-for="(item, index) in sheet" :key="index">{{ item }}</pre></code>
        <div v-if="debug" class="d-flex ml-4">
            <div class="d-flex flex-column"> 
                <h4 class="text-white mx-auto">FORM</h4>
                <code><pre class="content">{{ form }}</pre></code>
            </div>
            <div class="d-flex flex-column"> 
                <h4 class="text-white mx-auto">DATA</h4>
                <code><pre class="content">{{ data }}</pre></code>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        form: {
            type: Object,
            required: false,
            default: () => {}
        },
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        data: {
            handler: function() {
                this.$emit('updateForm', this.sheet);
            },
            deep: true
        }
    },
    computed: {
        sheet() {
            let result = [], level = 0;
            for(let entry of this.form) {
                const tag = entry.tag;
                const lineid = entry.lineid;

                if('opening' in entry) { result.push(`${this.getMargin(level)}<${tag}>`); level += 1;  }
                if('datatype' in entry) { 
                    result.push(`${this.getMargin(level)}<${tag}>`); 
                    let value = this.data.find(item => item.lineid === lineid).value;
                    value === undefined ? value = '' : '';
                    if(entry.datatype === 'date' || entry.datatype === 'datetime') {
                        if(value) {
                            value = new Date(value).toISOString();
                        }
                    }
                    if(entry.datatype === 'bool') {
                        if(typeof(value) === 'boolean') {
                            value = value ? 'true' : 'false';
                        }
                    }
                    if(value) {
                        result.push(`${this.getMargin(level)}${value}`); 
                    }
                    result.push(`${this.getMargin(level)}</${tag}>`); 
                }

                if('closing' in entry) { level -= 1; result.push(`${this.getMargin(level)}</${tag}>`);  }
            }
            return result;
        }
    },
    methods: {
        getMargin(level) {
            let margin = '';
            level = level * 5;
            while (level > 0) {
                margin += ' ';
                level -= 1;
            }
            return margin;
        }
    }
}
</script>
<style scoped>
.around {
    padding:20px;
    border:2px solid green;
    width:fit-content;
}
.content {
    color:white;
    font-weight:bolder;
}
</style>
