// Configure doctor module
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
    state: {
        prescriptions: [],
        invoices: [],
        cashRegisters: [],
    },
    getters,
    actions,
    mutations
};