<template>
  <ul 
      ref="list" 
      class="list-group list-group-flush"
  >
      <div v-if="!medicines.length">{{ $t('drug_database.no_data') }}</div>
      <medicine-list-item 
          v-for="(medicine, index) in medicines"
          :key="index"
          :medicine="medicine"
          :highlighted="index === highlight"
          :query="query"
          :clickable="clickable"
          @click="onRowClick(index)"
      />
  </ul>
</template>
<script>
import MedicineListItem from './medicineListItem';

export default {
  components: {
      MedicineListItem
  },
  props: {
      medicines: {
          required: false,
          default: () => [],
          type: Array
      },
      highlight: {
          required: false,
          default: -1,
          type: Number
      },
      query: {
          required: false,
          default: '',
          type: String
      },
      clickable: {
          required: false,
          type: Boolean,
          default: false
      }
  },
  watch: {
      highlight(v) {
          if (v !== undefined) {
              this.scrollToItem(v);
          }
      }
  },
  methods: {
      onRowClick(index) {
          if (this.clickable) {
              this.$emit('clicked', index);
          }
      },
      scrollToItem(index) {
          if (this.$refs.list.children.length > index && index >= 0) {
              this.$refs.list.children[index].scrollIntoView(false);
          }
      }
  }
};
</script>
