<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div :class="`modal-dialog ${sizeClass}`" @click="containerClicked">
        <div :class="`modal-content${borderClass}`">
          <div :class="`modal-header${borderClass}${bgClass}`">
            <h5 class="modal-title">
              <slot name="header" />
            </h5>
            <button type="button" class="close" @click="$emit('close')">
              <span>&times;</span>
            </button>

          </div>
          <div class="modal-body" :class="{ 'scroller': scrollable }">
            <slot />
          </div>
          <div v-if="$slots.footer" class="modal-footer">
            <slot name="footer" />
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
  </div><!-- /.modal -->
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: '',
      validator: v => ['', 'small', 'large'].indexOf(v) !== -1
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: null,
      validator: v => ['primary', 'info', 'success', 'danger', 'warning', 'secondary', 'dark', 'light'].indexOf(v) !== -1
    }
  },
  computed: {
    sizeClass() {
      if (this.size === 'large') {
        return 'modal-lg';
      } else if (this.size === 'small') {
        return 'modal-sm';
      } else {
        return '';
      }
    },
    borderClass() {
      if (this.color) {
        return ` border-${this.color}`;
      } else {
        return '';
      }
    },
    bgClass() {
      if (this.color) {
        return ` bg-${this.color} text-white`;
      } else {
        return '';
      }
    }
  },
  created() {
    document.addEventListener('click', this.emitBackgroundClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.emitBackgroundClick);
  },

  methods: {
    emitBackgroundClick() {
      // this.emitClose();
    },
    containerClicked(e) {
      e.stopPropagation();
    },
    emitClose() {
      // Check if `this` (component instance) is defined before accessing `emit`
      if (this) {
        this.$emit('close');
      } else {
        console.error('Component instance is null or undefined');
      }
    }
  }
};
</script>
