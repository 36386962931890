<template>
    <div>
        <div v-if="!sent && !cancelled" class="text-center text-white">
            <i class="fal fa-envelope-open-text fa-fw fa-10x" />
            <h3 class="mb-5 mt-3">{{ $t('send.receive_mail') }}</h3>
            <div class="form-group shadow">
                <input 
                    v-model="currentEmail" 
                    type="email" 
                    name="email" 
                    class="form-control form-control-lg text-center"
                    placeholder="Vinsamlegast sláðu inn netfang"
                >
            </div>
            <div class="form-group">
                <button class="btn btn-lg btn-success shadow" @click="send()">{{ $t('send.send') }}</button>
                <button class="btn btn-lg btn-outline-light shadow" @click="cancel()">{{ $t('send.decline') }}</button>
            </div>
            <div v-if="error" class="alert alert-danger">
                {{ error.message }}
            </div>
        </div>
        <div v-if="sent" class="text-center text-white mailbox-parent">
            <i class="fas fa-hand-holding-heart fa-fw fa-10x" />
            <h3 class="mb-5 mt-3">{{ $t('send.invoice_sent') }}</h3>
            <h2>{{ $t('send.thank_you') }}</h2>
        </div>
        <div v-if="cancelled" class="text-center text-white mailbox-parent">
            <i class="fas fa-hand-holding-heart fa-fw fa-10x" />
            <h2 class="mb-5 mt-3">{{ $t('send.thank_you') }}</h2>
        </div>
        <countdown v-if="showCountdown" :time-limit="5" time-color="white" />
        <countdown 
            v-if="inactive" 
            :time-limit="timeToCountDown"
            time-color="white"
            @finish="onCountDownFinish()"
        />
        
    </div>
</template>
<script>
import InvoiceApi from '@src/api/invoice';
import Countdown from '@components/countdown';
export default {
    components: {
        Countdown
    },
    props: {
        email: {
            type: String,
            required: false,
            default: ''
        },
        invoiceId: {
            type: Number,
            required: false,  
            default: -1
        },
    },
    data() {
        return {
            currentEmail: '',
            sent: false,
            cancelled: false,
            error: null,
            showCountdown: false,
            inactive: false,
        };
    },
    computed: {
        timeToCountDown() {
            return KIOSK_IDLE_COUNT_DOWN || 20;
        }
    },
    created() {
        document.addEventListener('touchstart', () => {this.resetInactive();});
        document.addEventListener('mousedown', () => {this.resetInactive();});
    },
    mounted() {
        setTimeout(() => {this.inactive = true;}, 5000);
        this.currentEmail = this.email;
    },
    beforeDestroy() {
        document.removeEventListener('touchstart', () => {this.resetInactive();});
        document.removeEventListener('mousedown', () => {this.resetInactive();});
    },
    methods: {
        send() {
            this.sent = true;
            this.inactive = false;
            InvoiceApi.mailPatientInvoice(this.invoiceId, this.currentEmail).then( () => {
                this.showCountdown = true;
                setTimeout(() => {  this.$emit('sent'); }, 5000);
            }).catch(err => {
                this.error = err.response.data;
                this.$emit('failed', err.response.data);
            });
        },
        cancel() {
            this.cancelled = true;
            this.showCountdown = true;
            setTimeout(() => {  this.$emit('cancel'); }, 5000);
        },
        resetInactive() {
            this.inactive = false;
            setTimeout(() => {this.inactive = true;}, 20000);
        },
        onCountDownFinish() {
            if (this.inactive) {
                this.$emit('cancel');
            }
        }
    },
};
</script>
<style>
</style>