<template>
	<div v-if="diagnoseItems.length > 0">
		<div v-for="(item, index) in transformdiagnoseItems" :key="index">
			<v-card flat>
				<v-toolbar color="transparent" @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave()">
					<v-avatar :color="getHoverColor(index)">
						<v-icon v-if="hoveredItemIndex === index" @click="editDiagnose(item)">mdi-pencil</v-icon>
						<v-icon v-else :class="getAvatarIconClass(index)"></v-icon>
					</v-avatar>
					<v-toolbar-title>
						<strong>{{ item.code }}</strong> - {{ item.name }}
						<v-chip class="ml-3">{{ item.dia_list_name }}</v-chip>
					</v-toolbar-title>
					<v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
				</v-toolbar>
			</v-card>
		</div>
	</div>
</template>

<script>
import DiagnoseEditor from '../../journalEditor/diagnoseEditor/diagnoseEditor.vue';
import { useJournalStore } from '@src/stores/journalStore';

export default {
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			diagnoseItems: [],
			hoveredItemIndex: null,
			isDiagnoseEditorOpen: false,
			selectedDataRowId: null,
			selectedVersion: null,
		};
	},
	computed: {
		// transformedAttentionItems() {
		// 	return this.attentionItems.map(item => ({
		// 		...item.data,
		// 		type: item.type,
		// 	}));
		// },

		transformdiagnoseItems() {
			return this.diagnoseItems.map(item => ({
			
				...item.data,
				type: item.type,
			}));
		}
	},
	mounted() {
		const type2Items = this.journalData.items.filter(item => item.type === '2');
		this.diagnoseItems = type2Items;
	},
	methods: {
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave() {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hoveredItemIndex === index ? '#EEEEEE' : 'teal-lighten-2';
		},
		getAvatarIconClass(index) {
			return this.hoveredItemIndex !== index ? 'fat fa-person-dots-from-line' : '';
		},
		editDiagnose(item) {

			this.$emit('edit-diagnose-item', item);
			const journalStore = useJournalStore();
			journalStore.setDiagnoseItem(item); 

			if (this.isDiagnoseEditorOpen && this.selectedDataRowId === item.data_row_id) {
				this.closeDiagnoseEditor();
			} else {
				this.selectedDataRowId = item.data_row_id;
				this.selectedVersion = item.version;
				this.isDiagnoseEditorOpen = true;
			}
		},
		closeDiagnoseEditor() {
			this.isDiagnoseEditorOpen = false;
			this.selectedDataRowId = null;
			this.selectedVersion = null;
		},
		diagnoseItem() {
			const journalStore = useJournalStore();
			return journalStore.getDiagnoseItem();
		},

	}
};
</script>