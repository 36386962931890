<template>
    <span class="close cancel" @click="onClick()">
       <!-- &times;-->
    </span>
</template>
<script>
export default {
    components: {},
    props: {
        namedRoute: {
            type: String,
            required: false,
            default: 'welcome'
        },
    },
    data() {
        return {
            clicks: 0,
        };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        onClick() {
            this.clicks++;
            if (this.clicks >= 10) {
                this.$router.push({name: this.namedRoute});
            }
        }
    },
};
</script>
<style>
    .cancel {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        color: transparent !important;
    }
</style>