<template lang="html">
    <div v-if="settingMode">
        <SettingsView :settings="loadedSettings" :titles="titles" :templates="templates" @closeSettings="settingsToggle" />
    </div>
    <div v-else class="container pt-4">

        <!-- Snackbar -->
        <v-snackbar :color="snackClass" class="ma-2" v-model="snackbar" :timeout="timeout" >
            <div class="text-center">{{ snackbarText }}</div>
        </v-snackbar>

        <!-- Title -->
        <div class="row my-3 pt-4">
            <div class="col-md-12">
                <h1 class="text-center">{{ $t('autoletter.title') }}</h1>
            </div>
        </div>

        <!-- Spinner -->
        <div v-if="loading" class="d-flex justify-content-center col-3 mx-auto mt-2 p-4" style="background-color: transparent;">
            <v-progress-circular color="rgba(24,103,192,0.7)" indeterminate size="250" style="user-select: none;">
                {{ $t('autoletter.loading') }} 
            </v-progress-circular>
        </div>

        <!-- Settings -->
        <div v-else class="d-flex flex-wrap">
            <v-card :title="$t('autoletter.settings.setting')" class="col-12 col-md-3 p-4 bg-primary user-select-none">
                <v-radio-group v-if="displayTag" v-model="selectedMode">
                    <v-radio v-for="mode in modes" :value="mode">
                        <template v-slot:label>
                            <div class="mt-2 ml-1">{{ getTranslatedLabel(mode) }}</div>
                        </template>
                    </v-radio>
                </v-radio-group>
                <div class="d-flex justify-content-center" :class="displayTag ? '' : 'mt-3'">
                    <v-btn @click="settingsToggle()" class="v-btn bg-primary mx-auto" style="border:2px solid white">
                        <i class="fas fa-cog"></i>
                    </v-btn>
                    <v-btn @click="openLoadSettingsModal()" class="v-btn bg-primary mx-auto" v-show="showListButton" style="border:2px solid white">
                        <i class="far fa-list-alt"></i>
                    </v-btn>
                </div>
                <div v-if="currentSettingId != -1" class="d-flex justify-content-center align-items-center rounded-pill mt-4 mx-auto">
                    <div>{{ getTranslatedLabel(this.selectedMode) }}:{{ getActiveSettingName() }}</div>
                </div>
            </v-card>
            <v-card class="d-flex flex-column p-4 col-12 col-md-9 bg-primary">
                <div class="d-flex justify-content-between my-4"> 
                    <h5 class="ms-4 user-select-none" style="font-size:20px">{{ $t('autoletter.settings.titles_templates') }}</h5>
                    <i v-if="defaultIsLoaded" @click="eyeIconClick()" class="eye-icon" :class="eyeIcon"></i>
                </div>
                <div v-if="displayTag" class="d-flex justify-content-center">
                    <v-combobox
                        multiple
                        :label="$t('autoletter.settings.titles')"
                        :items="titles"
                        v-model="selectedTitles"
                        variant="solo"
                        chips
                        closable-chips
                        :disabled="fieldDisabled('titles')"
                    ></v-combobox>
                    <v-btn class="v-btn" :disabled="fieldDisabled('titles')" @click="this.selectedTitles = this.titles">{{ $t('autoletter.settings.select_all') }}</v-btn>
                    <v-btn class="v-btn" :disabled="this.selectedTitles.length === 0" @click="this.selectedTitles = []">{{ $t('autoletter.settings.reset') }}</v-btn>
                </div>
                <div v-if="displayTag" class="d-flex justify-content-center">
                    <v-combobox
                        :label="$t('autoletter.settings.templates')"
                        :items="templates.map(value => value.name)"
                        v-model="selectedTemplates"
                        chips
                        variant="solo"
                        multiple
                        clearable
                        closable-chips
                        :disabled="fieldDisabled('templates')"
                    ></v-combobox>
                    <v-btn class="v-btn" :disabled="fieldDisabled('templates')" @click="this.selectedTemplates = this.templates.map(x => x.name)">{{ $t('autoletter.settings.select_all') }}</v-btn>
                    <v-btn class="v-btn" :disabled="this.selectedTemplates.length === 0" @click="this.selectedTemplates = []">{{ $t('autoletter.settings.reset') }}</v-btn>
                </div>
                <div>
                    <date-form ref="dateform" @submit="onDateSubmit"/>
                </div>
            </v-card>
        </div>

        <!-- Error -->
        <div v-if="error && !loading" class="row mt-3">
            <div class="col-md-6 offset-md-3">
                <div class="alert alert-danger">
                    <strong>{{ $t('autoletter.error') }}</strong> {{ error }}
                </div>
            </div>
        </div>

        <!-- Table -->
        <VTable
            ref="table"
            v-if="!loading && patientsDataset.length > 0"
            :mode="selectedMode"
            :dataset="patientsDataset"
            @columnClicked="columnClicked"
            @rowClicked="rowClicked"
        />

        <!-- Modals -->
        <modal
            ref="modal"
            :addresses="addresses"
            :mode="selectedMode"
            :selected="selected"
            :casebooks="caseBooks"
            :attendance="attendance"
            :diagnoses="diagnoses"

            :setting="settingForModal"

            @closeModal="onModalClose"
            @send="onModalSend"
        />

        <!-- Settings List -->
        <SettingsListModal
            ref="settingslistmodal"
            :settings="loadedSettings"
            :defaultId="defaultId"
            @load="onLoadSetting"
            @delete="onDeleteSetting"
            @setdefault="onSetDefaultSetting"
            @removedefault="onRemoveDefaultSetting"
            @closeModal="onModalClose"
        />


    </div>
</template>
<script>

import VTable from './VTable';
import DateForm from './DateForm';
import Modal from './SrlModal';
import SettingsView from './config/srlSettings';
import SettingsListModal from './config/SettingListModal';
import autolettersApi from '@src/api/srl.js';
import _ from 'lodash';

export default {
    created() {
        // fetching data
        this.getTemplatesForSelect();
        this.getTitlesForSelect();
        this.loadSettingsData();
    },

    components: {
        VTable,
        DateForm,
        Modal,
        SettingsView,
        SettingsListModal
    },
    watch: {
        selectedMode() {
            const id = this.currentSettingId;
            this.currentSettingId = -1;
            this.selectedTitles = [];
            this.selectedTemplates = [];
            this.resetData();
            this.currentSettingId = id;
            this.loadSetting(false);
        },
        selectedTitles() {
            this.updateLoadedSettings('titles');
        },
        selectedTemplates() {
            this.updateLoadedSettings('templates');
        }
    },
    data() {
        return {
            modes: ['tmitem','case','casebook','ipitem'],
            selectedMode: 'tmitem',
            titles: [],
            selectedTitles: [],
            templates: [],
            selectedTemplates: [],

            patientsDataset: [],
            addresses: [],
            diagnoses: [],
            caseBooks: [],
            selected: null,
            loading: false,
            error: null,
            attendance: {},

            // settings
            settingMode: false, // shows create settings
            loadedSettings: [],
            original: [],
            currentSettingId: -1,
            settingForModal: {},

            // default settings
            eyeIcon: "fa-solid fa-eye",
            defaultIsLoaded: false,
            defaultId: -1,
            eyeClosed: true, // default closed

            // snackbar
            snackbar: false,
            snackClass: "success",
            snackbarText: '',
            timeout: 2000,
        };
    },
    computed: {
        selectedTreatment() {
            if (!this.selected) {
                return {};
            }
            const { title, outDate:dateOut, inDate:dateIn }  = this.selected;
            return { title, dateOut, dateIn };
        },
        showListButton() {
            if(this.loadedSettings.length > 0) {
                return true;
            }
            return false;
        },
        displayTag() {
            if(!this.defaultIsLoaded) {
                return true;
            }
            return !this.eyeClosed;
        },
    },
    methods: {
        getActiveSettingName() {
            const cid = this.currentSettingId;
            if(cid == -1) {
                return '';
            }
            const item = this.loadedSettings.filter(item => item.id === cid);
            return item.length === 0 ? '' : item[0].name;
        },
        eyeIconClick() {
            if(!this.defaultIsLoaded) { 
                this.eyeIcon = "";
                return; 
            }
            const open = 'fa-solid fa-eye';
            const closed = open + '-slash';
            this.eyeClosed = !this.eyeClosed;
            this.eyeClosed ? this.eyeIcon = open : this.eyeIcon = closed;
        },
        activateSnackBar(text, state) {
            let defaultWasChanged = false;
            if(text === 'setting_default_set' || text === 'setting_default_disabled') {
                defaultWasChanged = true;
            }
            this.snackbar = false;
            this.snackbarText = this.$i18n.t('autoletter.config.snackbar.'+text);;
            this.snackClass = state;
            this.timeout = 2000;
            this.snackbar = true;

            // reloading page
            if(defaultWasChanged) {
                setTimeout(() => {
                    location.reload(); 
                }, "2500");
            }
        },
        getDefaultId() {
            for(let setting of this.loadedSettings) {
                const id = setting.id;
                const default_id = setting.default_id;
                if(id.toString() === default_id.toString()){
                    return id;
                }
            }
            return -1;
        },
        loadSettingsData() {
            autolettersApi.getSettings().then(res => {
                const data = res.data.data;
                this.loadedSettings = data;
                this.original = JSON.parse(JSON.stringify(data));
                // check for default setting
                this.defaultId = this.getDefaultId();
                if(this.defaultId >= 0) {
                    this.loadDefault(this.defaultId);
                    this.defaultIsLoaded = true;
                }
            }).catch(e => {
                    alert('Error') // todo remove line
                    console.log(e.message)
                });
        },
        openLoadSettingsModal() {
            this.$refs.settingslistmodal.openModal();
        },
        // user presses load
        onLoadSetting(id) {
            this.currentSettingId = id;
            this.$refs.settingslistmodal.closeModal();
            this.loadedSettings = JSON.parse(JSON.stringify(this.original));
            const loadDefaultMode = true;
            this.loadSetting(loadDefaultMode);
        },
        // user presses load
        loadDefault(id) {
            this.currentSettingId = id;
            const loadDefaultMode = true;
            this.loadSetting(loadDefaultMode);
        },
        loadSetting(loadDefaultMode) {
            const id = this.currentSettingId;
            if(id < 0) { 
                return; 
            }
            let current = this.loadedSettings.filter(entry => entry.id === id); 
            try { 
                if(current.length === 0) {
                    throw 'error: filter fail';
                }
                current = current[0];
                if(typeof(current) === 'string')  {
                    current = JSON.parse(JSON.stringify(current)); // deep copy
                }
                if(!Object.keys(current).includes('json')){
                    throw 'error: missing json key';
                }
            } catch(e) {
                console.log(e)
                this.activateSnackBar('setting_loaded_error', 'error');
                this.$refs.settingslistmodal.closeModal();
                return;
            }

            let json = current['json'];
            if(typeof(json) == 'string') {
                json = JSON.parse(json);
            }

            // id is set to -1 to prevent watch
            const sid = this.currentSettingId;
            this.currentSettingId = -1;

            // switching to selected default mode
            if(loadDefaultMode) {
                this.selectedMode = json.default_mode;
            }

            const jsonModes = ['bookings', 'treatments', 'casebooks', 'admissions'];
            const index = this.modes.indexOf(this.selectedMode);
            const data = json[jsonModes[index]];

            this.selectedTitles = data.titles;
            if(this.selectedMode === 'tmitem') {
                this.selectedTemplates = data.templates;
            }

            // restore id
            this.currentSettingId = sid;

            // calculating from date
            const number = data.dateFrom.number;
            const type = data.dateFrom.dateType;
            
            this.updateDateForm(number, type);

            if(loadDefaultMode) {
                this.activateSnackBar('setting_loaded', 'success');
            }

            // update generated title for modal
            const key = 'generated_title';
            if(Object.keys(data).includes(key)) {
                this.settingForModal = data[key];
                // console.log(this.settingForModal)
            } else {
                this.settingForModal = {};
            }
        },
        updateDateForm(number, type) {
            let today = new Date();
            let date = new Date(today);

            if(type === 'D') {
                date.setDate(today.getDate() - number);
            }
            else if(type === 'W') {
                date.setDate(today.getDate() - (7 * number));
            }
            else if(type === 'M') {
                date.setMonth(today.getMonth() - number);
            }
            else if(type === 'Y') {
                date.setFullYear(today.getFullYear() - number);
            }
            this.$refs.dateform.updateDate(date);
        },
        // writing into loaded settings (persist user selection)
        updateLoadedSettings(type) {
            if(this.currentSettingId === -1) {
                return;
            }
            const index = this.modes.indexOf(this.selectedMode);
            const modeKey = ['bookings', 'treatments', 'casebooks', 'admissions'][index];
            const targetIndex = this.loadedSettings.map(x => x.id === this.currentSettingId).indexOf(true);
            let json = this.loadedSettings[targetIndex]['json']

            if(typeof(json) === 'string') {
                this.loadedSettings[targetIndex]['json'] = JSON.parse(json);
            }
            if(type === 'titles') {
                this.loadedSettings[targetIndex]['json'][modeKey][type] = this.selectedTitles;
            }
            if(type === 'templates') {
                this.loadedSettings[targetIndex]['json'][modeKey][type] = this.selectedTemplates;
            }
        },
        // user presses delete
        onDeleteSetting(id) {
            autolettersApi.deleteSetting(id).then(response => {
                this.$refs.settingslistmodal.closeModal();
            }).then(response => {
                    this.loadSettingsData();
                    this.activateSnackBar('setting_deleted', 'success');
                    // reload if removing active setting
                    if(this.currentSettingId === id) {
                        setTimeout(() => {
                            location.reload(); 
                        }, "2500");
                    }
                }).catch(e => {
                    this.activateSnackBar('setting_deleted_error', 'error');
                });
        },
        // user presses default button
        onSetDefaultSetting(id) {
            autolettersApi.setDefault({ id }).then(response => {
                this.activateSnackBar('setting_default_set', 'success');
            }).catch(e => {
                this.activateSnackBar('setting_default_set_error', 'error');
            });
            this.$refs.settingslistmodal.closeModal();
        },
        onRemoveDefaultSetting() {
            autolettersApi.removeDefault().then(response => {
                this.activateSnackBar('setting_default_disabled', 'success');
            }).catch(e => {
                this.activateSnackBar('setting_default_disabled_error', 'error');
            });
            this.$refs.settingslistmodal.closeModal();
        },
        selectAll(type) {
            if(type === 'titles') {
                this.selectedTitles = this.titles;
            }
            else if(type === 'templates') {
                this.selectedTemplates = this.templates;
            }
        },
        settingsToggle(type) {
            let error = false;
            this.settingMode = !this.settingMode;
            if(type === "saved") {
                this.activateSnackBar('data_saved', 'success');
            } 
            else if(type === "saved_error") {
                error = true;
            }

            if(!this.settingMode) {
                this.loadSettingsData();
                if(error) {
                    this.snackbar = false;
                    setTimeout(() => {
                        this.activateSnackBar('data_saved_error', 'error');
                    }, "2500");
                }
            }
        },
        getTranslatedLabel(current) {
            return [
                this.$i18n.t('autoletter.settings.bookings'),
                this.$i18n.t('autoletter.settings.treatments'),
                this.$i18n.t('autoletter.settings.casebooks'),
                this.$i18n.t('autoletter.settings.admissions')
            ][this.modes.indexOf(current)];
        },
        fieldDisabled(type) {
            if(type === 'titles') {
                return ['case'].indexOf(this.selectedMode) >= 0
            }
            else if(type === 'templates') {
                return ['case', 'casebook','ipitem'].indexOf(this.selectedMode) >= 0
            }
        },
        resetData() {
            this.patientsDataset = [];
            this.addresses = [];
            this.diagnoses = [];
            this.caseBooks = [];
            this.selected = null;
            this.loading = false;
            this.error = null;
            this.attendance = {};
        },
        onDateSubmit({ from, to }) {
            this.resetData();
            this.loading = true;

            const noTitles = this.selectedTitles.length === 0;
            const cbm = this.selectedMode === 'casebook';
            if(cbm && noTitles) {
                this.activateSnackBar("cbm_no_title", 'error');
                this.loading = false;
                return;
            }

            if(this.selectedMode === 'tmitem') {
                // no templates
                if(this.selectedTemplates.length === 0) {
                    this.activateSnackBar("cbm_no_template", 'error');
                    this.loading = false;
                    return;
                }
                // no titles (all with template/s filter)
                if(this.selectedTitles.length === 0) {
                    this.getPatients(from, to, true);
                    this.getAddresses();
                    this.loading = false;
                    return;
                }
                // both, e.i. template/s and title/s
                this.filterTitles(from, to);
                this.getAddresses();
                this.loading = false;
                return;
            }

            if(this.selectedTitles.length > 0) {
                this.getPatientsWithCaseBookTitle(from, to);
            } else {
                this.getPatients(from, to, true);
            }

            this.getAddresses();
            this.loading = false;
        },
        rowClicked(row) {
            this.selected = this.patientsDataset[row];
            const { patientId, caseId, codenumber, outDate, inDate } = this.selected;
            this.getPatientsDoctor(codenumber);
            this.getPatientsDiagnoses(codenumber, inDate, outDate);
            this.getPatientCaseBooks(codenumber, inDate, outDate);

            if (caseId) {
                this.getPatientAttendance(patientId, caseId);
            }
            this.$refs.modal.openModal();
        },
        columnClicked(index, ascending, lastColumn) {
            // sorting by name key
            if(index === 1) {
                let temp = this.patientsDataset;
                if(ascending) {
                    temp.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    temp.sort((a, b) => b.name.localeCompare(a.name));
                }
                this.patientsDataset = temp;
            }
            // last column (sent), null is a possibility
            else if(lastColumn) {
                const empty = this.patientsDataset.filter(x => x.sendDate === null)
                let sendDates = this.patientsDataset.filter(x => x.sendDate != null)
                if(ascending) {
                    sendDates.sort((a, b) => this.compareDates(b.sendDate, a.sendDate));
                } else {
                    sendDates.sort((a, b) => this.compareDates(a.sendDate, b.sendDate));
                }
                this.patientsDataset = [...sendDates, ...empty];
            }
            // inDate
            else if(index === 2) {
                let temp = this.patientsDataset;
                if(ascending) {
                    temp.sort((a, b) => this.compareDates(b.inDate, a.inDate));
                } else {
                    temp.sort((a, b) => this.compareDates(a.inDate, b.inDate));
                }
                this.patientsDataset = temp;
            }
            // outDate
            else if(index === 3) {
                let temp = this.patientsDataset;
                if(ascending) {
                    temp.sort((a, b) => this.compareDates(b.outDate, a.outDate));
                } else {
                    temp.sort((a, b) => this.compareDates(a.outDate, b.outDate));
                }
                this.patientsDataset = temp;
            }
        },
        compareDates(d1, d2) {
            d1 = new Date(d1);
            d2 = new Date(d2);
            if(d1 === d2) {
                return 0
            }
            if(d1 > d2) {
                return 1
            }
            return -1
        },
        compareDates2(d1, d2) {
            const date1 = new Date(d1).format('Y-m-d').toString();
            const date2 = new Date(d2).format('Y-m-d').toString();
            return date1.toString() == date2.toString();
        },
        onModalClose() {
            this.selected = null;
            this.diagnoses = [];
            this.caseBooks = [];
            this.attendance = {};
        },
        onModalSend(data) {
            const addressEid = data.address.eid;
            let addressName = data.address.name;
            // only first part of addess is used
            if(addressName.indexOf('>') > 0) {
                addressName = addressName.split('>')[0].trim();  
            }

            const id = data.receiverId;
            const name = data.receiverName;
            const textContent = data.textContent;
            let content = [textContent];

            if(this.selectedMode === 'tmitem' || this.selectedMode === 'casebook') {
                const caseBookContent = this.caseBooks.map(({ title, keywords }) => {
                    const keywordStr = keywords.map(({ termName, computedValue }) => `\t${termName}: ${computedValue}`).join('\n');
                    return `${title}\n${keywordStr}`;
                }).join('\n');

                content.push(caseBookContent)
            }

            const treatments = this.selected.ipItemId ? [ this.selectedTreatment ] : [];

            const payload = {
                patientId: this.selected.patientId,
                addressEid,
                addressName,
                ipItemId: this.selected.ipItemId,
                tmItemId: this.selected.tmItemId,
                caseId: this.selected.caseId,
                receiver: { id, name },
                content: _.filter(content),
                treatments,
                diagnoses: this.diagnoses,
                caseBooks: this.caseBooks.map(caseBook => ({
                    title: caseBook.title || 'Nóta',
                    keywords: caseBook.keywords.map(kw => ({
                        name: kw.termName || 'Texti',
                        value: kw.computedValue
                    }))
                }))
            };

            this.sendLetter(payload);
        },
        setModalError(e, defaultMessage) {
            if (this.$refs.modal) {
                let msg = _.get(e, 'response.data.message');

                if (!msg || msg === '') {
                    msg = defaultMessage || e.message;
                }

                this.$refs.modal.setError(msg, _.get(e, 'response.data.errors', {}));
            }
        },
        // only for tmitems if title/titles are present
        filterTitles(from, to) {
            // fetches patients by template id
            this.getPatients(from, to, false).then(patients1 => {
                // fetches patients that have certain casebook titles
                autolettersApi.getPatientsWithCasebooks(from, to, this.selectedTitles, this.selectedMode).then(patients2 => {
                    let remIndexes = [], i = 0, patientsDataSet = patients1;
                    let codenumbers = patients2.data.map(v => {return [v.inDate, v.codenumber]});

                    // no entry found
                    if(patientsDataSet.length === 0 || codenumbers.length === 0) {
                        this.patientsDataset = [];
                        return;
                    }

                    // searching for a match
                    for(let patient of patientsDataSet) {
                        let found = false;
                        for(let titleData of codenumbers) {
                            if(titleData[1] != patient.codenumber) {
                                continue;
                            }
                            
                            if(this.compareDates2(titleData[0], patient.outDate)) {
                                found = true;
                                break;
                            }
                        }
                        !found ? remIndexes.push(i) : '';
                        i += 1;
                    }

                    remIndexes = remIndexes.reverse();
                    remIndexes.map(index => patientsDataSet.splice(index, 1));
                    this.patientsDataset = patientsDataSet;

                }).catch(e => {
                    console.log(e)
                    this.error = _.get(e, 'response.data.message') || this.$i18n.t('autoletter.error_patients_fetch');
                })
            });
        },
        async getTemplatesForSelect() {
            const unit_id =  this.$store.state.unit.id;
            const user_id =  this.$store.state.user.id;

            autolettersApi.templates({ 'data': { unit_id, user_id } }).then(response => {
                if(Object.keys(response).includes('data')) {
                    const data = response.data;
                    data.forEach(obj => {
                        Object.entries(obj).forEach(([id, name]) => {
                            this.templates.push({"id":id, "name": name})
                        });
                    });
                }
                this.templates.sort((a, b) => a.name.localeCompare(b.name));
                console.log('srl templates - ok');
            }).catch(e => this.error = this.$i18n.t('autoletter.error_templates'));
        },
        async getTitlesForSelect() {
            autolettersApi.titles().then(response => {
                if(Object.keys(response).includes('data')) {
                    const data = response.data;
                    if(Object.keys(data).includes('titles')) {
                        data.titles.map(obj => this.titles.push(obj.Title));
                    }
                }
                console.log('srl titles - ok');
            }).catch(e => {
                    if(this.error) {
                        this.error = this.$i18n.t('autoletter.error_titles_templates')
                        return;
                    }
                    this.error = this.$i18n.t('autoletter.error_templates');
                });
        },
        async sendLetter({ patientId, addressEid, addressName, receiver, content, treatments, diagnoses, ipItemId, tmItemId, caseId, caseBooks }) {
            try {
                const mode = this.selectedMode;
                let { data } = await autolettersApi.send(patientId, addressEid, addressName, ipItemId, tmItemId, caseId, receiver, content, treatments, diagnoses, caseBooks, mode);
                data = data.data;
                const item = { ...this.selected, sendDate: Date.fromCarbonIE(data.sent) };
                const index = this.patientsDataset.findIndex(({ id }) => id === item.id);
                if (index !== -1) {
                    // this.$set(this.patientsDataset, index, item);
                    this.patientsDataset[index] = item;
                } 
                if (this.$refs.modal) {
                    this.$refs.modal.closeModal();
                }
                this.activateSnackBar('autoletter_sent', 'success');

            } catch (e) {
                console.log(e)
                this.setModalError(e, this.$i18n.t('autoletter.error_send'));
            }
        },
        async getPatientsDiagnoses(codenumber, from, to) {
            try {
                const { data } = await autolettersApi.getPatientsDiagnoses(codenumber, from, to);
                this.diagnoses = data.data.map(({ code, name, comment }) => _.join(_.filter([code, name, comment]), ' - '));
            } catch (e) {
                this.setModalError(e, this.$i18n.t('autoletter.error_diagnoses_fetch'));
            }
        },
        async getPatientsDoctor(codenumber) {
            try {
                const { data } = await autolettersApi.getPatientsDoctor(codenumber);

                if (this.$refs.modal) {
                    const { id, name } = data.data;
                    this.$refs.modal.setReceiver(id, name);
                }
            } catch (e) {
                if (this.$refs.modal) {
                    this.$refs.modal.setDoctorWarning();
                }
            }
        },
        async getPatientCaseBooks(codenumber, from, to) {
            try {
                const titles = this.selectedTitles;
                const { data } = await autolettersApi.getPatientsCaseBooks(codenumber, from, to, titles);
                this.caseBooks = data.data.map(data => data);
                const dateTarget = new Date(this.selected.inDate).format('d.m.Y');
                this.caseBooks = this.caseBooks.filter(casebook => new Date(casebook.date).format('d.m.Y') === dateTarget);
            } catch (e) {
                this.setModalError(e, this.$i18n.t('autoletter.error_casebook_fetch'));
            }
        },
        async getPatientAttendance(patientId, caseId) {
            try {
                const { data } = await autolettersApi.getAttendance(patientId, caseId);
                this.attendance = data;
            } catch (e) {
                this.setModalError(e, this.$i18n.t('autoletter.error_attendance_fetch'));
            }
        },
        async getPatients(from, to, set) {
            this.loading = true;
            this.error = null;

            try {
                // using names to filter and get the ids
                const templateNames = this.selectedTemplates;
                const templates = this.templates.filter(val => templateNames.includes(val.name)).map(obj => obj.id);
                const mode = this.selectedMode;
                const { data } = await autolettersApi.getPatients(from, to, templates, mode);

                let temp = data.data
                temp = temp.map((v, idx) => {
                    return {
                        ...v,
                        inDate: Date.fromCarbonIE(v.inDate),
                        outDate: v.outDate ? Date.fromCarbonIE(v.outDate) : null,
                        sendDate: v.sendDate ? Date.fromCarbonIE(v.sendDate) : null,
                        id: idx
                    };
                });

                if(set) {
                    this.patientsDataset = temp;
                } else {
                    return temp;
                }
            } catch (e) {
                this.error = _.get(e, 'response.data.message') || this.$i18n.t('autoletter.error_patients_fetch');
            }
        },
        // for casebook mode
        async getPatientsWithCaseBookTitle(from, to) {
            const titles = this.selectedTitles;
            const mode = this.selectedMode;
            try {
                const { data } = await autolettersApi.getPatientsWithCasebooks(from, to, titles, mode);

                this.patientsDataset = data.map((v, idx) => {
                    return {
                        ...v,
                        inDate: Date.fromCarbonIE(v.inDate),
                        outDate: null,
                        sendDate: null,
                        id: idx
                    };
                });
            } catch (e) {
                console.log(e)
                this.error = _.get(e, 'response.data.message') || this.$i18n.t('autoletter.error_patients_fetch');
            }
        },
        async getAddresses() {
            try {
                const mode = this.selectedMode;
                let { data } = await autolettersApi.getAddresses(mode);
                const temp = _.orderBy(data.data, 'name');
                this.addresses = [];
                let seen = [];
                // removing duplicates
                for(let object of temp) {
                    if(seen.includes(object.name)) {
                        continue;
                    }
                    seen.push(object.name);
                    this.addresses.push(object);
                }
            } catch (e) {
                this.error = _.get(e, 'response.data.message') || this.$i18n.t('autoletter.error_address_fetch');
            }
        }
    }
};
</script>
<style scoped>
.v-btn {
    height: 56px;
}
.rounded-pill {
    border-radius: 9999px;
    background-color: rgb(33,150,243); 
    border: 2px solid white;
    width: fit-content;
    text-align: center;
    padding: 10px 10px 10px 10px;
    height: fit-content;
    font-size: 10px;
}
.eye-icon:hover {
    cursor: pointer; 
}
</style>
