const InvoiceCreate = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/create');
const InvoiceIndex = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/index');
const InvoiceEdit = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/edit');
const InvoicePrint = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/print');
const InvoicePDF = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/pdf');
const InvoicePatient = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/patient');
const InvoicePatientPrint = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/patient/print');

import { GUARD_BILLING as access } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/invoice', 
        component: InvoiceIndex, 
        name: 'invoice-index', 
    },
    {
        path: '/invoice/:id/edit', 
        component: InvoiceEdit, 
        name: 'invoice-edit', 
    },
    {
        path: '/invoice/create', 
        component: InvoiceCreate, 
        name: 'invoice-create',
    },
    {
        path: '/invoice/:id/pdf', 
        component: InvoicePDF, 
        name: 'invoice-pdf',
    },
    {
        path: '/invoice/patient/print',
        component: InvoicePatientPrint,
        name: 'invoice-patient-print'
    },
    {
        path: '/invoice/:id/print',
        component: InvoicePrint,
        name: 'invoice-print',
        props: true,
    },
    {
        path: '/invoice/patient',
        component: InvoicePatient,
        name: 'invoice-patient'
    }
].map(route => ({ ...route, meta: { access }}))