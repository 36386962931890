<template>
    <div class="card mb-2" :class="{ 'border-info': !exception, 'border-warning': exception }">
        <div class="card-header" :class="{ 'bg-info text-white border-info': !exception, 'bg-warning': exception }">
            <i class="fal" :class="{ 'fa-tablets': !exception, 'fa-prescription-bottle': exception }" /> {{ exception ?
        this.$t('prescription.exemption_drug') : this.$t('prescription.drug') }}
            <transition name="fade-fast" mode="out-in">
                <span v-if="removable" class="close pull-right" @click="onCloseClicked">&times;</span>
            </transition>
        </div>

        <!-- Medicine not found modal -->
        <simple-modal v-if="showMedicineNotFoundModal" size="large" @close="showMedicineNotFoundModal = false">
            <template slot="header">Lyf fannst ekki</template>
            <p class="mb-2">Lyf fannst ekki í sérlyfjaskrá en það gæti verið til undir öðru auðkenni</p>
            <search ref="modalSearch" :error="errors.medicine" :tabindex="tabIndexStart + 1" @selected="onSelected"
                @deselected="onDeSelected" />
            <div class="form-group col-3 mx-auto">
                <button class="btn btn-block btn-primary" @click="showMedicineNotFoundModal = false">Ok</button>
            </div>
        </simple-modal>

        <div class="card-body border-warning">
            <div class="row">
                <div class="col-md-6">
                    <search ref="select" :error="errors.medicine" :tabindex="String(tabIndexStart + 1)"
                        @selected="onSelected" @deselected="onDeSelected" />
                </div>
                <div class="col-md-3 mb-sm-1 mb-xs-1">
                    <button type="button" class="btn btn-outline-secondary btn-block" @click="onSynonymSearchClicked">
                        <i class="fal fa-search ws-normal" />
                        {{ $t('prescription.combination_drug') }}

                    </button>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <button type="button" class="btn btn-outline-info btn-block" @click="onInfoClicked">
                            <i class="fal fa-info-circle ws-normal" />
                            {{ $t('prescription.information') }}

                        </button>
                    </div>
                </div>
            </div>
            <div v-if="selected" class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ $t('prescription.Drug_name') }}
                        </label>
                        <div class="input-group">
                            <div class="form-control"
                                :class="{ 'non-form-info': !exception, 'non-form-warning': exception }">
                                {{ selected.name }}
                            </div>
                            <div class="input-group-append">
                                <check-box-button v-model="r" :tabindex="String(tabIndexStart + 7)">R</check-box-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>
                            {{ $t('prescription.drug_form') }}
                        </label>
                        <div class="form-control"
                            :class="{ 'non-form-info': !exception, 'non-form-warning': exception }">
                            {{ selected.form }}
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>
                            {{ $t('prescription.strength') }}
                        </label>
                        <div class="form-control"
                            :class="{ 'non-form-info': !exception, 'non-form-warning': exception }">
                            {{ selected.strength }} {{ selected.strength_unit }}
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>
                            {{ $t('prescription.prescribed_amount') }}

                        </label>
                        <div class="form-control"
                            :class="{ 'non-form-info': !exception, 'non-form-warning': exception }">
                            {{ prescribedAmount }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <text-input v-model="shortDosage"
                        v-tooltip="$t('prescription.content_will_not_appear_on_prescription')"
                        :label="$t('prescription.dosage')" :tabindex="String(tabIndexStart + 2)"
                        :error="errors.short_dosage && shortDosage.length > 20" @change="onShortDosageChange"
                        @key.enter.prevent.stop="" />
                    <span v-if="shortDosage.length > 20" :class="{ 'text-danger': errors.short_dosage }">
                        {{ $t('prescription.Max_length_of_dosage') }} ({{ shortDosage.length }})
                    </span>
                </div>
                <div class="col-md-3">
                    <text-input-number v-model="numPackages" :min="0" required :error="errors.number_of_packages"
                        :label="$t('prescription.no_of_packages')" :tabindex="String(tabIndexStart + 6)"
                        @input="clearError('number_of_packages')" />
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>&nbsp;</label><br>
                        <check-box-button v-model="whenNecessary" @change="onWhenNecessary" class="w-100">{{
        $t('prescription.as_needed')
    }}</check-box-button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>&nbsp;</label><br>
                        <check-box-button v-model="dosebox" class="w-100" :tabindex="String(tabIndexStart + 9)">{{
        $t('prescription.dispensed') }}</check-box-button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <text-input-area :value="instructions" required :label="$t('prescription.usage_descriptions')"
                        :tabindex="String(tabIndexStart + 3)" />

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <text-input v-model="comment" :label="$t('prescription.comment_for_pharmacist')"
                        :tabindex="String(tabIndexStart + 4)" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <text-input v-model="indication" :error="errors.indication"
                        :label="$t('prescription.drug_used_for')" :tabindex="String(tabIndexStart + 5)"
                        @input="clearError('indication')" />
                </div>
                <div class="col-md-3">
                    <text-input-number v-model="days_between_dispensations"
                        v-tooltip="$t('prescription.field_must_be_filled_out')" required :min="0"
                        :error="errors.days_between_dispensations" :label="$t('prescription.no_of_days_between_dose')"
                        :tabindex="String(tabIndexStart + 6)" @input="clearError('days_between_dispensations')" />
                </div>
                <div class="col-md-3">
                    <text-input v-show="medicineIsAddictive" v-model="daily_max_dosage"
                        v-tooltip="$t('prescription.field_must_be_filled_out')" required
                        :label="$t('prescription.max_daily_dose')" :tabindex="String(tabIndexStart + 7)"
                        :error="errors.daily_max_dosage" @input="clearError('daily_max_dosage')" />
                </div>
                <div v-if="medicineIsAddictive" class="col-md-3">
                    <text-input v-show="medicineIsAddictive" v-model="daily_max_dosage_unit" required
                        :label="$t('prescription.max_dose_unit')" :tabindex="String(tabIndexStart + 8)" />
                </div>
                <div v-else class="col-md-3">
                    <text-input-number v-model="days_between_dispensations"
                        v-tooltip="'Þennan reit þarf að fylla út ef lyfið sem er ávísað er merkt ávanabindandi'"
                        :min="0" :error="errors.days_between_dispensations"
                        :label="$t('prescription.no_of_days_between_dose')" :tabindex="tabIndexStart + 6"
                        @input="clearError('days_between_dispensations')" />
                </div>
            </div>
            <div v-if="exception" class="card-body bg-warning">
                <div class="row">
                    <div class="col-md-12">
                        <text-input-area v-model="argument" required :error="errors.arguments"
                            :label="$t('prescription.reason_for_exemption')" @input="clearError('arguments')" />
                        <check-box v-model="terms" required :error="errors.conditions"
                            @input="clearError('conditions')">
                            {{ $t('prescription.prescriber_of_drug') }}
                        </check-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, toRef } from 'vue';
import { mapGetters } from 'vuex';
import localService from '../../../../api/local.js';
import Search from './search';
import TextInput from '../../../textInput';
import TextInputArea from '../../../textInputArea';
import TextInputNumber from '../../../textInputNumber';
import CheckBox from '../../../checkBox';
import CheckBoxButton from '../../../checkBoxButton';
import ShortDosageParser from '../../../../classes/shortDosageParser';
import SimpleModal from '@components/modalSimple';

export default defineComponent({
    components: {
        Search,
        TextInput,
        TextInputArea,
        TextInputNumber,
        CheckBox,
        CheckBoxButton,
        SimpleModal
    },
    props: {
        // eslint-disable-next-line 
        preSelected: {
            type: Object,
            required: false,
        },
        removable: {
            type: Boolean,
            required: false,
            default: true
        },
        forms: {
            type: Array,
            required: false,
            default: () => []
        },
        expressions: {
            type: Array,
            required: false,
            default: () => []
        },
        tabIndexStart: {
            type: Number,
            required: false,
            default: 0
        }
    },
    setup(props, { emit }) {
        const selected = ref(undefined);
        const indication = ref('');
        const numPackages = ref(0);
        const shortDosage = ref('');
        const whenNecessary = ref(false);
        const dosebox = ref(false);
        const r = ref(false);
        const instructions = ref('');
        const argument = ref('');
        const comment = ref('');
        const terms = ref(false);
        const days_between_dispensations = ref(null);
        const daily_max_dosage = ref(null);
        const daily_max_dosage_unit = ref('');
        const showMedicineNotFoundModal = ref(false);
        const errors = {
            medicine: ref(false),
            instructions: ref(false),
            number_of_packages: ref(false),
            conditions: ref(false),
            arguments: ref(false),
            short_dosage: ref(false),
            days_between_dispensations: ref(false),
            daily_max_dosage: ref(false),
            indication: ref(false)
        };

        const expressions = toRef(props, 'expressions');

        const exception = computed(() => selected.value !== undefined && selected.value.exception === '1');

        const nordic_id = computed(() => selected.value !== undefined ? selected.value.nordic_id : '');

        const requestData = computed(() => {
            let request = {
                medicine: nordic_id.value,
                iterations: props.iterations,
                interval: props.interval,
                number_of_packages: numPackages.value,
                instructions: instructions.value,
                r: r.value,
                when_necessary: whenNecessary.value,
                dosebox: dosebox.value,
                short_dosage: shortDosage.value,
                indication: indication.value,
                comment: comment.value,
                days_between_dispensations: days_between_dispensations.value,
                daily_max_dosage: daily_max_dosage.value,
                daily_max_dosage_unit: daily_max_dosage_unit.value
            };

            if (exception.value) {
                request.exception = true;
                request.conditions = terms.value;
                request.arguments = argument.value;
            }

            return request;
        });

        const medicineIsAddictive = computed(() => selected.value !== undefined && selected.value.addictive != 0);

        const prescribedAmount = computed(() => {
            if (selected.value.amount > 0 && numPackages.value > 0) {
                let amount = selected.value.amount * numPackages.value;
                return `${amount} ${selected.value.amount_unit}`;
            } else {
                return '0';
            }
        });



        const onCloseClicked = () => {
            emit('close');
        };

        const onShortDosageChange = () => {
            console.log('triggered');
            console.log(instructions.value);

            if (selected.value !== undefined) {
                const parser = new ShortDosageParser(expressions.value, props.forms);
                if (instructions.value.trim().length === 0) {
                    instructions.value = parser.parse(shortDosage.value, selected.value.form);
                    clearError('instructions');
                }
            }
            if (shortDosage.value.length <= 20) {
                clearError('short_dosage');
            }
            console.log(instructions.value);
            if (whenNecessary.value) {
                instructions.value = instructions.value + " eftir þörfum"
            }
            // Make sure instructions are updated
            console.log(whenNecessary.value, 'check__________')
        };


        const onSelected = (item) => {
            clearError('medicine');
            selected.value = item;
            numPackages.value = item.number_of_packages;
            daily_max_dosage_unit.value = item.form;
        };

        const onDeSelected = () => {
            selected.value = undefined;
            numPackages.value = 0;
        };

        const onWhenNecessary = () => {
            whenNecessary.value = !whenNecessary.value; // Toggle the value of whenNecessary

            if (whenNecessary.value) {
                // Append "eftir þörfum" to instructions when the checkbox is checked
                instructions.value += " eftir þörfum";
            } else {
                // Remove "eftir þörfum" from instructions when the checkbox is unchecked
                instructions.value = instructions.value.replace(/eftir þörfum/gi, '').trim();
            }
        };

        const onSynonymSearchClicked = (e) => {
            e.stopPropagation();
            this.$refs.select.synonymSearch();
        };

        const onInfoClicked = () => {
            if (selected.value !== undefined) {
                emit('help', selected.value.atc);
            } else {
                emit('help');
            }
        };

        const setError = (error) => {
            errors[error].value = true;
        };

        const clearError = (error) => {
            errors[error].value = false;
        };

        return {
            selected,
            indication,
            numPackages,
            shortDosage,
            whenNecessary,
            dosebox,
            r,
            instructions,
            argument,
            comment,
            terms,
            days_between_dispensations,
            daily_max_dosage,
            daily_max_dosage_unit,
            errors,
            exception,
            nordic_id,
            requestData,
            medicineIsAddictive,
            prescribedAmount,
            onCloseClicked,
            onShortDosageChange,
            onSelected,
            onDeSelected,
            onWhenNecessary,
            onSynonymSearchClicked,
            onInfoClicked,
            setError,
            clearError,
            showMedicineNotFoundModal,
            hardcodedValue: "123"
        };
    },
    mounted() {
        if (this.preSelected) {
            const id = this.preSelected.medicine.nordic_id;
            localService.getMedicene(id)
                .then(response => {
                    this.instructions = this.preSelected.instruction;
                    this.numPackages = this.preSelected.number_of_packages;
                    this.days_between_dispensations = this.preSelected.days_between_dispensations;
                    this.selected = response.data;

                    if (this.selected.addictive === '1') {
                        this.daily_max_dosage = this.preSelected.daily_max_dosage;
                        this.daily_max_dosage_unit = this.preSelected.daily_max_dosage_unit;
                    }
                }).catch(err => {
                    // trying to set what can be set
                    const keys = Object.keys(this.preSelected);
                    if (keys.indexOf('instruction') >= 0) {
                        this.instructions = this.preSelected.instruction;
                    }
                    if (keys.indexOf('number_of_packages') >= 0) {
                        this.numPackages = this.preSelected.number_of_packages;
                    }
                    if (keys.indexOf('days_between_dispensations') >= 0) {
                        this.days_between_dispensations = this.preSelected.days_between_dispensations;
                    }

                    // opening modal and injecting atc_code
                    this.showMedicineNotFoundModal = true;
                    if (keys.indexOf('atc_code') >= 0) {
                        const atc_code = this.preSelected.atc_code;
                        if (atc_code.length > 0) {
                            setTimeout(() => { this.$refs.modalSearch.atcSearch(atc_code); }, 100);
                        }
                    }
                });
        }
    }
});
</script>
