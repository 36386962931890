<template>
    <div class="my-6"></div>
    <div class="d-flex">
        <v-date-input :disabled="disabled" class="ih" prepend-icon="mdi-calendar-edit-outline" v-model="date" variant="outlined" density="compact"  :label="title"/>
        <v-text-field
            v-if="type === 'datetime'"
            class="ih ml-4"
            v-model="time"
            :disabled="disabled"
            :active="modal"
            :focused="modal"
            :label="title + ' tími'"
            variant="outlined" 
            density="compact"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
        >
            <v-dialog :disabled="disabled" v-model="modal" activator="parent" width="auto">
                <v-time-picker :disabled="disabled" variant="outlined" density="compact" v-if="modal" format="24h" v-model="time"></v-time-picker>
                <v-btn @click="closeModal()">OK</v-btn>
            </v-dialog>
        </v-text-field>
    </div>
    <div class="my-8"></div>
</template>
<script>

import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

export default {
    components: {
        VDateInput,
        VTimePicker
    },
    watch: {
        date() {
            let time = '';
            this.time ? time = this.time : '';
            this.$emit('datechange', this.lineId, this.date, time);
        },
        time() {
            if(this.date) {
                this.$emit('datechange', this.lineId, this.date, this.time);
            } 
        }
    },
    mounted() {
        if(this.init.length) {
            this.date = new Date(this.init);
            if(this.init.indexOf('T') < 0) {
                return;
            }
            const time = this.init.split('T')[1];
            if(time.indexOf(':') < 0) {
                return;
            }
            if(time.split(':').length === 3) {
                const values = time.split(':');
                const hours = values[0];
                const minutes = values[1];
                this.time = `${hours}:${minutes}`;
            }
        }
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        lineId: {
            type: String,
            required: false,
            default: -1
        },
        type: {
            type: String,
            required: false,
            default: 'date'
        },
        init: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            date: null,
            modal: null,
            time: '00:00'
        };
    },
    methods: {
        closeModal() {
            this.modal = null
        }
    }
};
</script>
<style scoped>
.ih {
    height: 25px;
}
</style>
