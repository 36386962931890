<!-- <template lang="html">
    <div class="container">
        <div class="row justify-content-center">
            <div 
                id="login-panel" 
                class="col-md-4"
            >
                <div class="egatt-logo-center text-center mb-4">
                    <img v-if="telemed" :src="'./img/telemed_logo.png'" alt="" width="300px">
                    <logo-egatt v-else />
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-4">
                <login 
                    v-if="needsLogin"
                    :username-login="usernameLogin"
                    @authenticated="onAuthenticated"
                />
                <div v-else>
                    <form 
                        class="form-horizontal" 
                        @submit.prevent="onUnitCommit"
                    >
                        <unit-select 
                            :units="$store.state.availableUnits" 
                            @change="onUnitSelected" 
                        />
                        <div class="form-group">
                            <button 
                                type="submit" 
                                class="btn btn-primary btn-block"
                            >
                            {{ t('login_select.login') }}

                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Login from '../login';
import UnitSelect from '../unitSelect';
import LogoEgatt from '../logoEgatt';
import { GET_CURRENT_USER_UNITS, GET_CURRENT_UNIT, SELECT_UNIT } from '../../store/actionTypes';

export default {
    components: {
        Login,
        UnitSelect,
        LogoEgatt
    },
    props: {
        usernameLogin: {
            type: Boolean,
            required: false,
            default: true,
        },
        telemed: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            needsLogin: true,
            watcherHandle: undefined,
            unit: undefined,
        };
    },
    beforeDestroy() {
        if (this.watcherHandle) {
            this.watcherHandle();
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        onAuthenticated() {
            this.$store.dispatch(GET_CURRENT_USER_UNITS);
            this.$store.dispatch(GET_CURRENT_UNIT).then(() => {
                this.emitLoggedIn();
            }).catch(() => {
                this.needsLogin = false;
                this.registerWatcher();
            });
        },
        onUnitSelected(unit) {
            this.unit = unit;
        },
        onUnitCommit() {
            if (this.unit !== undefined) {
                this.$store.dispatch(SELECT_UNIT, this.unit).then(() => {
                    this.emitLoggedIn();
                }).catch(err => {
                    // Some server connection issue
                    console.error(err);
                });
            }
        },
        emitLoggedIn() {
            this.$emit('loggedIn');
        },
        registerWatcher() {
            this.watcherHandle = this.$store.watch((state, { unitExists }) => unitExists, value => {
                if (value) {
                    this.emitLoggedIn();
                }
            });
        }
    },
};
</script> -->


//vue3 


<template>
    <div class="container">
      <div class="row justify-content-center">
        <div id="login-panel" class="col-md-4">
          <div class="egatt-logo-center text-center mb-4">
            <img v-if="telemed" :src="'./img/telemed_logo.png'" alt="" width="300px" />
            <logo-egatt v-else />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <login v-if="needsLogin" :username-login="usernameLogin" @authenticated="onAuthenticated" />
          <div v-else>
            <form class="form-horizontal" @submit.prevent="onUnitCommit">
              <unit-select :units="availableUnits" @change="onUnitSelected" />
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block">{{$t('login_select.login')}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import Login from '@components/login/login.vue';
  import UnitSelect from '@components/unitSelect/unitSelect.vue';
  import LogoEgatt from '@components/logoEgatt/logoEgatt.vue';
  import { GET_CURRENT_USER_UNITS, GET_CURRENT_UNIT, SELECT_UNIT } from '../../store/actionTypes';
  
  export default defineComponent({
    components: {
      Login,
      UnitSelect,
      LogoEgatt
    },
    props: {
      usernameLogin: {
        type: Boolean,
        required: false,
        default: true
      },
      telemed: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        needsLogin: true,
        watcherHandle: undefined,
        unit: undefined
      };
    },
    computed: {
      availableUnits() {
        return this.$store.state.availableUnits;
      }
    },
    beforeUnmount() {
      if (this.watcherHandle) {
        this.watcherHandle();
      }
    },
    methods: {
      onAuthenticated() {
        this.$store.dispatch(GET_CURRENT_USER_UNITS);
        this.$store.dispatch(GET_CURRENT_UNIT).then(() => {
          this.emitLoggedIn();
        }).catch(() => {
          this.needsLogin = false;
          this.registerWatcher();
        });
      },
      onUnitSelected(unit) {
        this.unit = unit;
      },
      onUnitCommit() {
        if (this.unit !== undefined) {
          this.$store.dispatch(SELECT_UNIT, this.unit).then(() => {
            this.emitLoggedIn();
          }).catch(err => {
            // Some server connection issue
            console.error(err);
          });
        }
      },
      emitLoggedIn() {
        this.$emit('loggedIn');
      },
      registerWatcher() {
        this.watcherHandle = this.$store.watch((state, { unitExists }) => unitExists, value => {
          if (value) {
            this.emitLoggedIn();
          }
        });
      }
    }
  });
  </script>
  