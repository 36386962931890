<template>
    <div class="form-group">
        <label>{{ $t('invoice_create.payment_method') }}</label>
        <select 
            :disabled="disabled" 
            :value="value" 
            class="form-control" 
            v-bind:placeholder="[[$t('telemedicine.select_payment_method')]]" 
            @input="onInput" 
            @change="onInput"
        >
            <option 
                v-for="(type, index) in types" 
                :key="index" 
                :value="type.id" 
                :selected="type.id == value"
            >
                {{ type.name }}
            </option>
        </select>
    </div>
</template>
<script>
import localService from '../../api/local';

export default {
    props: {
        value: {
            required: false,
            default: ''  
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            types: [],
        };
    },
    mounted() {
        localService.getAvailablePaymentTypes().then(res => {
            this.types = res.data;
        }).catch(err => {
            this.$emit('error', this.$t('invoice.unable_to_fetch_payment_methods'));

        });
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        onInput(e) {
            this.$emit('input', e.target.value);
        }
    }
};
</script>
