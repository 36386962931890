<template>
  <v-container>
    <term-name v-model="termName" />
    <tiptap-editor v-model="termValue" />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import TiptapEditor from '@src/components/tiptapEditor/tiptapEditor.vue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'FormattedText',
  components: {
    TermName,
    TiptapEditor
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const termName = ref('This is FormattedText Text');
    const termValue = ref('');

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          termValue.value = newVal.term_value || termValue.value;
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    watch(termValue, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newVal });
    });

    return {
      termName,
      termValue
    };
  }
});
</script>

<style scoped>
/* Your styles here */
</style>
