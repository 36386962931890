<template>
  <div>
    <template v-if="attentionItems.length > 0">
      <div v-for="(item, index) in transformedAttentionItems" :key="index">
        <v-container>
          <v-card flat>
            <v-toolbar color="transparent">
              <v-avatar :color="hoverColor" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <v-icon v-if="isHovered" @click="editAttention(item)">mdi-pencil</v-icon>
                <template v-else>
                  <i v-if="item.type === '9'" :class="getCategoryIcon(item.category)"></i>
                  <i v-else class="fal fa-exclamation-triangle"></i>
                </template>
              </v-avatar>
              <v-toolbar-title>
                <strong>{{ item.type === '9' ? item.category : $t('Warning') }}</strong>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
            </v-toolbar>
            <div class="journal-indent">
              <v-card-text>{{ item.text }}</v-card-text>
              <v-card-title>ATC</v-card-title>
              <v-card-text>
                {{ item.atc_code_desc }}
                <v-chip class="ml-1" size="small">{{ item.atc_code }}</v-chip>
              </v-card-text>
              <v-card-title>Comment</v-card-title>
              <v-card-text>{{ item.comment }}</v-card-text>
            </div>
          </v-card>
        </v-container>
      </div>
    </template>

    <!-- Warning data -->
    <template v-if="warningItems.length > 0">
      <div v-for="(item, index) in transformedWarningItems" :key="index">
        <v-container>
          <v-card flat>
            <v-toolbar color="transparent" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
              <v-avatar :color="hoverColorwarning">
                <template v-if="isHovered">
                  <v-icon @click="editAttention(item)">mdi-pencil</v-icon>
                  <i class="fal fa-exclamation-triangle"></i>
                </template>
              </v-avatar>
              <v-toolbar-title>
                <strong>{{ $t('Warning') }}</strong>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-chip prepend-icon="mdi-doctor">{{ item.owner_name }}</v-chip>
            </v-toolbar>
            <div class="journal-indent">
              <v-card-text>{{ item.text }}</v-card-text>
              <v-card-title>ATC</v-card-title>
              <v-card-text>
                {{ item.atc_code_desc }}
                <v-chip class="ml-1" size="small">{{ item.atc_code }}</v-chip>
              </v-card-text>
              <v-card-title>Comment</v-card-title>
              <v-card-text>{{ item.comment }}</v-card-text>
            </div>
          </v-card>
        </v-container>
      </div>
    </template>

    <!-- Attention Editor Dialog -->
    <!-- <AttentionEditor
      v-if="isAttentionEditorOpen"
      :dataRowId="selectedDataRowId"
      :version="selectedVersion"
      @close="closeAttentionEditor"
    />      -->
  </div>
</template>

<script>
import AttentionEditor from '../../journalEditor/attentionEditor/attentionEditor.vue';
import { useJournalStore } from '@src/stores/journalStore';

export default {
  components: {
    AttentionEditor,
  },
  props: {
    journalData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      attentionItems: [],
      warningItems: [],
      hoverColor: 'blue',
      hoverColorwarning: 'red',
      isHovered: false,
      isAttentionEditorOpen: false,
      selectedDataRowId: null,
      selectedVersion: null,
      categoryIcons: {
        A: 'fal fa-person-dots-from-line',
        I: 'fal fa-virus',
        B: 'mdi mdi-water-alert',
        N: 'fal fa-exclamation-circle',
        P: 'fal fa-person-pregnant',
        L: 'fal fa-person-breastfeeding',
        M: 'mdi mdi-screw-round-top',
      },
    };
  },
  computed: {
    transformedAttentionItems() {
      return this.attentionItems.map(item => ({
        ...item.data,
        type: item.type,
      }));
    },
    transformedWarningItems() {
      return this.warningItems.map(item => ({
        ...item.data,
      }));
    },
  },
  methods: {
    handleMouseEnter() {
      this.isHovered = true;
      this.hoverColor = '#EEEEEE';
      this.hoverColorwarning = '#EEEEEE';
    },
    handleMouseLeave() {
      this.isHovered = false;
      this.hoverColor = 'blue';
      this.hoverColorwarning = 'red';
    },
    getCategoryIcon(category) {
      return this.categoryIcons[category] || 'fal fa-exclamation-triangle';
    },
    attentionItem() {
      const journalStore = useJournalStore();
      return journalStore.getAttentionItem();

    },
    editAttention(item) {

      this.$emit('edit-attention-item', item);
      const journalStore = useJournalStore();
      journalStore.setAttentionItem(item); // Store the item in the journal store

   
      
      if (this.isAttentionEditorOpen && this.selectedDataRowId === item.data_row_id) {
        this.closeAttentionEditor();
      } else {
        this.selectedDataRowId = item.data_row_id;
        this.selectedVersion = item.version;
        this.isAttentionEditorOpen = true;
      }
    },
    showEditor() {
      this.$emit('show-editor');
    },
    closeAttentionEditor() {
      this.isAttentionEditorOpen = false;
      this.selectedDataRowId = null;
      this.selectedVersion = null;
    },
  },
  mounted() {
    const type9Items = this.journalData.items.filter(item => item.type === '9');
    this.attentionItems = type9Items;

    const type3Items = this.journalData.items.filter(item => item.type === '3');
    this.warningItems = type3Items;
  },
};
</script>

<style scoped>
.journal-indent {
  margin-left: 40px;
}
</style>
