const Signatures = () => import(/*webpackChunkName: "group-sign" */ '@components/admin/signature');
const SignatureShow = () => import(/*webpackChunkName: "group-sign" */ '@components/admin/signature/show');
const SignatureIndex = () => import(/*webpackChunkName: "group-sign" */ '@components/admin/signature/index');


const Rest = () => import(/*webpackChunkName: "group-sign" */ '@components/dev/rest');


import { GUARD_SIGNATURE as access } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/sign/signature/show/:id',
        component: SignatureShow,
        name: 'user-signature-show',
        meta: { access  }
    },
    {
        path: '/sign/signature/index',
        component: SignatureIndex,
        name: 'user-signature-index',
        meta: { access },
    },
    {
        path: '/sign/signature',
        component: Signatures,
        name: 'signature-overview',
        meta: { access}
    },
]