<template>
  <div v-if="shouldRenderAttentionEditor">
    <AttentionEditor :dataRowId="attentionItem?.data_row_id" :version="attentionItem?.version"
      @close="closeAttentionEditor" />
  </div>
  <div v-if="shouldRenderDiagnoseEditor">
    <DiagnoseEditor :dataRowId="diagnoseItem?.data_row_id" :version="diagnoseItem?.version"
      @close="closeDiagnoseEditor" />
  </div>
  <div v-if="shouldRenderNoteEditor">
    <NoteEditor :dataRowId="noteItem?.data_row_id" @close="closeNoteEditor" />
  </div>
  <div v-if="shouldRenderlabResultEditor">
    <labResultEditor :dataRowId="labResultItem?.data_row_id" @close="closelabResultEditor" />
  </div>

  <div v-else class="vh-100 col-8 mx-auto mt-4 pt-4 mb-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <v-text-field v-model="searchQuery" variant="underlined" :label="$t('journal.editor-menu.search')" clearable
        @click:clear="clearSearch" prepend-icon="mdi-magnify" hide-details></v-text-field>
    </div>
    <h6 class="mb-2 font-weight-bold favorites">Favorites</h6>
    <div class="d-flex flex-column">
      <div class="pointer-cursor px-0" v-for="editor in filteredEditors" :key="editor.type"
        @click="selectEditor(editor.type)">
        <div class="d-flex flex-row justify-content-between py-1">
          <div class="d-flex justify-content-start">
            <v-icon class="icon" v-if="editor.icon.startsWith('mdi')" :color="editor.color">{{ editor.icon }}</v-icon>
            <i v-else class="icon-strange" :class="editor.icon" :style="{ color: getColor(editor.color) }"></i>
            <span class="ml-2">{{ editor.name }}</span>
          </div>
          <i class="fa-regular fa-star favourite-icon ml-auto"></i>
        </div>
      </div>
    </div>

    <div class="pointer-cursor px-0 ml-3 mb-3" v-for="item in cbmData" :key="item.template_id"
      @click="navigateToNoteEditor(item.template_id)">
      <v-icon class="icon" color="primary">mdi-file</v-icon>
      <span class="ml-2">{{ item.template_name }}</span>
    </div>
  </div>
</template>


<script>
import { mapState } from 'pinia';
import { useJournalStore } from '@src/stores/journalStore';
import AttentionEditor from '@src/components/journal/journalEditor/attentionEditor/attentionEditor.vue';
import DiagnoseEditor from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseEditor.vue';
import NoteEditor from '@src/components/journal/journalEditor/noteEditor/noteEditor.vue';
import labResultEditor from '@src/components/journal/journalEditor/labResultEditor/labResultEditor.vue';
import cbmtemplate from '@src/api/cbmtemplate';

export default {
  components: {
    AttentionEditor, DiagnoseEditor, NoteEditor, labResultEditor
  },
  data() {
    return {
      searchQuery: '',
      editors: [
        { type: 'PrescriptionEditor', name: this.$t('journal.editor-menu.prescription'), icon: 'mdi-prescription', color: 'primary' },
        { type: 'DiagnoseEditor', name: this.$t('journal.editor-menu.diagnose'), icon: 'fa fa-solid fa-person-dots-from-line', color: 'primary' },
        { type: 'AttentionEditor', name: this.$t('journal.editor-menu.attention'), icon: 'mdi-alert-circle', color: 'primary' },
        { type: 'LabResultEditor', name: this.$t('journal.editor-menu.lab'), icon: 'mdi-flask', color: 'primary' },
        { type: 'NoteEditor', name: this.$t('journal.editor-menu.note'), icon: 'mdi-file', color: 'primary' },
      ],
      cbmData: [], // Changed from an array of names to an array of objects
    };
  },
  computed: {
    ...mapState(useJournalStore, ['attentionItem', 'diagnoseItem', 'noteItem', 'labResultItem']),
    filteredEditors() {
      return this.editors.filter(editor =>
        editor.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    shouldRenderAttentionEditor() {
      return this.attentionItem && this.attentionItem.type == 9;
    },
    shouldRenderDiagnoseEditor() {
      return this.diagnoseItem && this.diagnoseItem.type == 2;
    },
    shouldRenderNoteEditor() {
      return this.noteItem && this.noteItem.type == 5;
    },
    shouldRenderlabResultEditor() {
      return this.labResultItem && this.labResultItem.type == 4;
    },
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      try {
        const response = await this.fetchDataWithParams(cbmtemplate);
        this.cbmData = response.data; // Ensure this is an array of objects
      } catch (error) {
        console.error('Error initializing component:', error);
      }
    },

    async fetchDataWithParams(api, params = {}) {
      try {
        return await api.index(params);
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    },

    selectEditor(editorType) {
      if (editorType === 'NoteEditor') {
        return;
      }

      this.$emit('select-editor', editorType);
    },

    navigateToNoteEditor(templateId) {

      this.$emit('select-editor', "NoteEditor");
      localStorage.setItem('templateId', templateId);


    },

    clearSearch() {
      this.searchQuery = '';
    },

    closeAttentionEditor() {
      const journalStore = useJournalStore();
      journalStore.clearAttentionItem();
    },

    closeDiagnoseEditor() {
      const journalStore = useJournalStore();
      journalStore.clearDiagnoseItem();
    },

    closeNoteEditor() {
      const journalStore = useJournalStore();
      journalStore.clearNoteItem();
    },

    closelabResultEditor() {
      const journalStore = useJournalStore();
      journalStore.clearlabResultItem();
    },

    getColor(color) {
      const colorMap = {
        primary: '#1976d2',
        secondary: '#009688',
      };
      return colorMap[color] || color;
    },
  },
  watch: {
    attentionItem: {
      handler(newVal) {
        if (newVal) {
          // Perform any operation based on attentionItem change
        }
      },
      deep: true,
      immediate: true,
    },
    diagnoseItem: {
      handler(newVal) {
        if (newVal) {
          // Perform any operation based on diagnoseItem change
        }
      },
      deep: true,
      immediate: true,
    },
    noteItem: {
      handler(newVal) {
        if (newVal) {
          // Perform any operation based on noteItem change
        }
      },
      deep: true,
      immediate: true,
    },
    labResultItem: {
      handler(newVal) {
        if (newVal) {
          // Perform any operation based on labResultItem change
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>



<style scoped>
.favorites {
  margin-top: 50px;
}

.pointer-cursor {
  cursor: pointer;
}

.icon {
  width: 25px;
}

/* because icon is different */
.icon-strange {
  width: 18px;
  margin: 5px 5px 0 3px;
}
</style>
