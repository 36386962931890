import Sorter from './Sorter';

export default class Locale {
    /**
     * Locale helper constructor.
     *
     * @param {String} alphabet 
     * @param {Array} monthNames
     * @param {Array} dayNames
     */
    constructor(alphabet, monthNames, dayNames) {
        this.sorter = new Sorter(alphabet);
        this.monthNames = monthNames;
        this.dayNames = dayNames;
    }

    /**
     * Get month formats keyed by month index.
     *
     * @return {Object}
     */
    getMonthFormats() {
        return this.formatNames(this.monthNames);
    }

    /**
     * Get day formats keyed by day index.
     *
     * @return {Object}
     */
    getDayFormats() {
        return this.formatNames(this.dayNames);
    }

    /**
     * Utility that returns names keyed by index with short and longname.
     *
     * @param {Array} names 
     * @returns {Object}
     */
    formatNames(names) {
        return names.reduce((o, name, i) => {
            o[i] = {
                longName: name,
                shortName: name.substr(0, 3)
            };
            return o;
        }, {});
    }
}
