<template>
    <div>
        <div v-if="!transactionInProcess && !isZero" class="text-center text-white">
            <button class="btn btn-info btn-lg shadow p-5 text-center" @click="pay()">
                <i class="fal fa-credit-card-front fa-5x d-block mb-3" />
                {{ $t('pos.pay_with_card') }}
            </button>
            <div v-if="error" class="alert alert-danger d-block mt-5"><i class="fal fa-exclamation-triangle fa-fw" /> {{ error.message }}</div>
            <button v-if="error" class="btn btn-danger btn-lg shadow text-center" @click="cancelTransaction()">
                {{ $t('pos.cancel') }}
            </button>
        </div>
        <div v-if="!transactionInProcess && isZero" class="text-center text-white">
            <h3 class="mb-5">{{ $t('pos.on_zero') }}</h3>
            <button class="btn btn-success btn-lg shadow text-center" @click="$emit('finish')">
                {{ $t('pos.continue') }}
                <i class="fal fa-chevron-right fa-fw" />
            </button>
        </div>
        <div v-if="transactionInProcess" class="text-center text-white">
            <i class="fal fa-credit-card-front fa-fw fa-10x" />
            <h1>{{ $t('pos.awaiting_transaction') }}</h1>
            <p v-if="status">{{ status.statusDescription }}</p>
        </div>
        <div v-if="transactionInProcess" class="mt-5">
            <div class="dot-pulse" />
        </div>
    </div>
</template>
<script>
import PosApi from '@src/api/pos';
import KioskApi from '@src/api/kiosk';
export default {
    components: {},
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            transactionInProcess: false,
            status: null,
            error: null
        };
    },
    computed: {
        pos() {
            return this.$store.state.kiosk.pos;
        },
        isZero() {
            return parseInt(this.invoice.patient_share) <= 0;
        }
    },
    created() {},
    mounted() {},
    methods: {
        pay() {
            this.error = null;
            this.transactionInProcess = true;       
            PosApi.postTransaction(this.pos.id, {'amount': parseInt(this.invoice.patient_share), 'invoice_id': parseInt(this.invoice.id)}).then(response => {
                // eslint-disable-next-line no-undef
                let channel = `${REDIS_KEY}.pos.${this.pos.id}`;
        
                window.Echo.channel(channel).listen('PosStatusUpdatedEvent', e => {
                    console.log(e);
                    this.status = e.status;
                }).listen('PosTransactionCancelledEvent', e => {
                    console.log(e);
                    this.error = {message : 'Hætt við færslu'};
                    this.transactionInProcess = false;
                }).listen('PosTransactionCompletedEvent', e => {
                    console.log(e);
                    this.$emit('finish');
                });
                this.tid = response;
                
            }).catch(err => {
                console.log(err.response.data);
                this.error = err.response.data;
                this.transactionInProcess = false;
            });
        },
        cancelTransaction() {
            this.$router.push({name: 'welcome'});
        }
    },
};
</script>
<style></style>