<template>
    <div class="brand-logo" @mousedown="onClick()">
        <egatt-logo height="50" width="50" />
    </div>
</template>
<script>
import EgattLogo from '@components/logoEgatt';
export default {
    components: {
        EgattLogo
    },
    props: {
        numberOfClicks: {
            type: Number,
            required: false,
            default: 5
        },
    },
    data() {
        return {
            clickCount: 0
        };
    },
    computed: {},
    watch: {
        clickCount(value) {
            if (value > this.numberOfClicks -1) {
                this.$router.push({name: 'kiosk-picker'});
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        onClick() {
            this.clickCount += 1;
        }
    },
};
</script>
<style>
    .brand-logo {
        position: absolute;
        bottom: 30px;
        right: 30px;
        max-height: 100px;
        max-width: 150px;
    }
</style>