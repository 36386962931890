<template>
    <div class="form-group">
      <div class="input-group">
        <div v-if="iconFront" class="input-group-prepend">
          <span class="input-group-text">
            <i class="fal fa-users" v-tooltip="$t('sidebar.unit')" v-bind:title="[$t('sidebar.unit')]"></i>
          </span>
        </div>
        <select class="form-control" v-model="selected" @change="onChange" @focus="onFocus" v-bind:placeholder="[$t('login_select.select_department')]">
          <option :value="-1" disabled>
            {{ $t('login_select.select_department') }}
          </option>
          <option v-for="(unit, index) in units" :key="index" :value="index">{{ unit.department }}</option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      units: {
        required: true,
        type: Array
      },
      iconFront: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    emits: ['change', 'click'],
    data() {
      return {
        selected: -1,
      }
    },
    mounted() {
      this.selected = this.units.findIndex(unit => this.currentUnitID === unit.id);
    },
    computed: {
      unitExists() {
        return this.$store.getters.unitExists;
      },
      currentUnitID() {
        return this.$store.getters.currentUnitID;
      }
    },
    methods: {
      t(key) {
        return this.$t(key);
      },
      onChange() {
        this.$emit('change', this.units[this.selected]);

      },
      onFocus() {
        if (this.$refs.default) {
    this.$refs.default.style.display = 'none';
  }
      },
      onClick() {
        if (this.selected !== -1) {
          this.$emit('click', this.units[this.selected]);
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .clickable:hover {
    cursor: pointer;
    opacity: .6;
  }
  </style>
  