<template>
  <v-app style="height: 100%;">
    <v-container class="fill-height d-flex justify-center align-center">
      <v-card v-if="!selectedSpecimen" class="fill-height d-flex flex-column">
        <v-toolbar color="blue" class="mb-3" density="compact">
          <v-app-bar-nav-icon><v-icon>mdi-microscope</v-icon></v-app-bar-nav-icon>
          <v-toolbar-title>Create new lab result</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="ml-lg-8" outline>
            <v-icon>mdi-floppy</v-icon>
            Save
          </v-btn>
          <v-btn icon="mdi-close" @click="handleClose"></v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div>
          <v-toolbar-title class="mb-2 text-center">
            <strong>Select Specimen</strong>
            <h6> Please select specimen to get started</h6>
          </v-toolbar-title>

          <!-- Specimen dropdown -->
          <v-container class="pt-0 pb-4">
            <v-select
              v-model="selectedSpecimen"
              :items="specimenOptions"
              label="Specimen"
              outlined
              dense
            ></v-select>
          </v-container>
        </div>

        <v-spacer></v-spacer>
      </v-card>

      <!-- Detailed Lab Result Screen -->
      <v-card v-if="selectedSpecimen" class="fill-height d-flex flex-column">
        <v-toolbar color="blue" dark>
          <v-toolbar-title>
            <v-icon>mdi-flask</v-icon>
            Create new lab results
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon="mdi-close" @click="handleClose"></v-btn>
        </v-toolbar>

        <v-card-text>
          <v-subheader class="font-weight-bold">Specimen: {{ selectedSpecimen }}</v-subheader>

          <v-row>
            <v-col cols="3">
              <v-text-field label="Analysis" v-model="glucoseAnalysis" ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Result" v-model="glucoseResult" ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Unit" v-model="glucoseUnit" ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Range" v-model="glucoseRange" ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Comment" v-model="glucoseComment" ></v-text-field>
            </v-col>
           
          </v-row>

          <v-row justify="center">
            <v-btn icon>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useJournalStore } from '@src/stores/journalStore';
import labResult from '@src/api/labResult';
import labcatalog from '@src/api/labCatalog'; // this api does not need longer
import labSpecimen from '@src/api/labSpecimen';

const journalStore = useJournalStore();
const showDialog = ref(true);
const selectedSpecimen = ref(null);
const specimenOptions = ref([]);

const emit = defineEmits(['close']);
const handleClose = () => {
  closelabResultEditor();
  emit('close');
};

const closelabResultEditor = () => {
  journalStore.clearlabResultItem();
  showDialog.value = false;
};

const params = {
  unit_id: 1,
  //  journal_type_id: 1,
  //  patient_id: 1
};

// Props
const props = defineProps({
  dataRowId: Number
});

const fetchlabspecimen = async()=>{
  try{
    const response = await labSpecimen.index(params);
    specimenOptions.value = response.data.map(specimen=>specimen.name);

  }catch(error) {
    console.error('Failed to fetch lab specimen:', error);

  }
};

const fetchLabResult = async () => {
  if (props.dataRowId) {
    try {
      const response = await labResult.show(props.dataRowId);
      labResultData.value = response.data;
    } catch (error) {
      console.error('Failed to fetch lab result:', error);
    }
  }
};


onMounted(() => {
  fetchlabspecimen();
});

// Initialize the lab result fields when a specimen is selected
watch(selectedSpecimen, (newVal) => {
  if (newVal) {
    glucoseAnalysis.value = '';
    glucoseResult.value = '';
    glucoseUnit.value = '';
    glucoseRange.value = '';
    glucoseComment.value = '';

  }
});

// Lab result fields
const glucoseAnalysis = ref('');
const glucoseResult = ref('');
const glucoseUnit = ref('');
const glucoseRange = ref('');
const glucoseComment = ref('');


</script>

<style scoped>
.fill-height {
  height: 100%;
  width: 100%;
}
/* .v-toolbar-title {
  display: flex;
  align-items: center;
} */
</style>
