<template>
    <div class="text-center mt-4">
        <v-autocomplete
            v-model="address"
            :items="addresses.map(val => val.name)"
            :label="$t('journal.electronic_sheets.send_to')"            
            variant="outlined" 
            density="compact"
            prepend-icon="mdi-home-edit-outline"
            :no-data-text="$t('journal.electronic_sheets.no_data_found')"
            class="mb-0"
        />
    </div>
</template>
<script>

export default {
    watch: {
        address() {
            if(!this.address) {
                this.$emit('change', {});
                return;
            }
            const address = this.addresses.find(item => item.name === this.address);
            this.$emit('changeAddress', { 'name': address.name, 'eid': address.eid });
        }
    },
    props: {
        addresses: {
            type: Array,
            required: false,
            default: () => []
        },
    },
    data() {
        return {
            address: ''
        };
    }
};
</script>
