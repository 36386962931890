import Appointment from '@src/components/kiosk/appointment';
import Picker from '@src/components/kiosk/picker';
import Welcome from '@components/kiosk/welcome';
import Payment from '@components/kiosk/payment';
import SignatureView from '@components/kiosk/signature-view';

export default [
    {
        path: '/welcome',
        component: Welcome,
        name: 'welcome',
    },
    {
        path: '/picker',
        component: Picker,
        name: 'kiosk-picker'
    },
    {
        path: '/payment',
        component: Payment,
        name: 'payment'
    },
    {
        path: '/appointment',
        component: Appointment,
        name: 'appointment'
    },
    {
        path: '/signature-view/',
        component: SignatureView,
        name: 'signature-view'
    },

];