import store from '@src/store';
import { UPDATE_SESSION } from '@src/store/mutationTypes';

export default (to, from, next)  => {
    store.commit(UPDATE_SESSION);
    
    if (to.name !== 'error-timeout' && store.state.session.expired) {
        next({ name: 'error-timeout' });
    }
    
    next();
};