<template lang="html">
    <div
        ref="el"
        tabindex="-1"
        @keydown.down.prevent="onDownKey"
        @keydown.up.prevent="onUpKey"
        @keyup.enter="onEnterKey"
        @keyup.esc="onEscKey"
        @keydown.tab="onTabKey"
        @blur="onBlur"
    >
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: {
            min: {
                required: false,
                type: Number,
                default: 0
            },
            max: {
                required: false,
                type: Number,
            }
        },
        data() {
            return {
                selected: undefined
            }
        },
        mounted() {
            this.reset();
        },
        methods: {
            reset() {
                this.selected = this.min - 1;
            },
            onBlur() {
                this.reset();
            },
            onEnterKey() {
                this.$emit('enter', this.selected);
            },
            onEscKey() {
                this.$emit('escape');
            },
            onDownKey() {
                if (this.max === undefined || this.selected < this.max) {
                    this.selected++;
                    this.emitChange();
                } else {
                    this.emitEndReached();
                }
            },
            onUpKey() {
                if (this.selected > this.min) {
                    this.selected--;
                    this.emitChange();
                } else {
                    this.emitStartReached();
                }
            },
            onTabKey() {
                this.$emit('tab');
            },
            emitChange() {
                this.$emit('change', this.selected);
            },
            emitStartReached() {
                this.$emit('start');
            },
            emitEndReached() {
                this.$emit('end');
            },
            focus() {
                this.$refs.el.focus();
            }
        }
    }
</script>
