<template>
    <button 
      type="button" 
      class="btn btn-outline-secondary" 
      :class="{ 'active': modelValue }" 
      :tabindex="tabindex" 
      @click="onClick" 
      @keyup.space="onClick"
    >
      <i :class="{ 'fal fa-square': !modelValue, 'fal fa-check-square': modelValue }" /> <slot />
    </button>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      tabindex: {
        required: false,
        default: 0,
        type: Number,
      },
    },
    emits: ['update:modelValue'],
    methods: {
      onClick() {
        this.$emit('update:modelValue', !this.modelValue);
      },
    },
  };
  </script>
  
  <style scoped>
  .btn {
    white-space: normal;
  }
  </style>
  