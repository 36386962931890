// Configure doctor module actions
import { UPDATE_DOCTOR_INVOICES, UPDATE_DOCTOR_PRESCRIPTIONS, UPDATE_DOCTOR_CASH_REGISTERS } from '../../mutationTypes';
import { GET_DOCTORS_INVOICES_TODAY, GET_DOCTORS_RECENT_PRESCRIPTIONS, GET_DOCTORS_CASH_REGISTERS } from '../../actionTypes';

import localService from '../../../api/local';

export default {
    [GET_DOCTORS_INVOICES_TODAY]({ commit }, doctor_id) {
        return new Promise((resolve, reject) => {
            localService.getDoctorsInvoicesToday(doctor_id).then(res => {
                commit(UPDATE_DOCTOR_INVOICES, res.data);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },
    [GET_DOCTORS_RECENT_PRESCRIPTIONS]({ commit }, doctor_id) {
        return new Promise((resolve, reject) => {
            localService.getDoctorsRecentPrescriptions(doctor_id).then(res => {
                commit(UPDATE_DOCTOR_PRESCRIPTIONS, res.data);
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }
};