const PrescriptionIndex = () => import(/* webpackChunkName: "group-presc" */ '@components/prescriptionIndex');
const PrescriptionCreate = () => import(/* webpackChunkName: "group-presc" */ '@components/prescriptionCreate');

import { GUARD_PRESC as access } from "@src/router/middleware/accessControl";

const routes = [
    {
        path: '/presc', 
        component: PrescriptionIndex, 
        name: 'presc-index',
        meta: { access }
    },
    {
        path: '/presc/create', 
        component: PrescriptionCreate, 
        name: 'presc-create',
        meta: { access }
    },
];

export default routes;
