<template>
    <!-- <div class="diagnosis-form">
      <form @submit.prevent="submitForm">
       
        <input v-model="formData.name" placeholder="Name" />
        <input v-model="formData.code" placeholder="Code" />
        <button type="submit">Save</button>
      </form>
    </div> -->
  </template>
  
  <script>
  import { ref, defineComponent, watch } from 'vue';
  
  export default defineComponent({
    name: 'DiagnoseForm',
    props: {
      dataRowId: {
        type: Number,
        default: undefined,
      },
      initialData: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ['save'],
    setup(props, { emit }) {
      const formData = ref({ ...props.initialData });
  
      watch(() => props.initialData, (newData) => {
        formData.value = { ...newData };
      });
  
      const submitForm = () => {
        emit('save', formData.value);
      };
  
      return {
        formData,
        submitForm,
      };
    },
  });
  </script>
  
  <style scoped>
  .diagnosis-form {
    
  }
  </style>
  