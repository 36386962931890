<template>
  <div class="diagnosis-editor">
    
    <DiagnoseTree :items="diagnosisItems" @fetch-children="fetchTreeChildren" />
    <DiagnoseSearch :dataRowId="dataRowId" :version="version" @search="handleSearch" />
    <DiagnoseForm :data-row-id="dataRowId" :initialData="formData" @save="handleSave" />
   
  </div>
</template>

<script setup>
import { ref, defineComponent, onMounted, watch, defineProps } from 'vue';
import DiagnoseForm from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseForm/diagnoseForm.vue';
import DiagnoseTree from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseTree/diagnoseTree.vue';
import DiagnoseSearch from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseSearch/diagnoseSearch.vue';
import { useStore } from 'vuex';
import { useJournalStore } from '@src/stores/journalStore';

const { journalStore } = useJournalStore();

const props = defineProps({
  dataRowId: {
    type: Number,
    default: null
  },
  version: {
    type: Number,
    default: null
  }
});



// Example function for handling search event
const handleSearch = (searchTerm) => {
  // Implement search logic here
};

</script>

<style scoped>
.diagnosis-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
