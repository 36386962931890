import Echo from 'laravel-echo';
import io from 'socket.io-client';
import events from './events';

const listener = {
    /**
     * Register an event with a handler check
     * 
     * @param {Object} channel
     * @param {String} event
     * @returns {Void}
     */
    registerEvent: (channel, event) => {
        channel.listen(`${event}Event`, e => {
            if (this.hasOwnProperty(`${event}Handler`)) {
                this[`${event}Handler`](e);
            }
        });
    },

    /**
     * Register an event handler for an event
     * 
     * @param {String} event
     * @param {Function} cb
     * @returns {Object}
     */
    on: function(event, cb) {
        this[`${event}Handler`] = cb;
        return this;
    },
};

// Call this to start listener
listener.start = user_id => {
    if (listener.channel === undefined) {
        window.io = io;
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: `${window.location.hostname}:${process.env.MIX_ECHO_PORT || 6001}`,
            transports: ['websocket', 'polling', 'flashsocket'] // Fix CORS error!
        });
        listener.channel = `${REDIS_KEY}.user.${user_id}`
        events.forEach(event => {
            listener.registerEvent(window.Echo.private(listener.channel), event);
        });
    }
};

// Call this to stop listener
listener.stop = () => {
    if (listener.channel !== undefined) {
        window.Echo.leave(this.channel);
    }

    listener.channel = undefined;
};

export default listener;