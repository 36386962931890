import journal from '@components/journal';

export default [
    {
        path: '/journal', 
        component: journal, 
        name: 'journal'
    },
]



   
