// Configure doctor module mutations
import { 
    UPDATE_DOCTOR_INVOICES, 
    UPDATE_DOCTOR_PRESCRIPTIONS, 
    ADD_DOCTOR_PRESCRIPTION, 
    ADD_DOCTOR_INVOICE,
    UPDATE_DOCTOR_PRESCRIPTION,
    UPDATE_DOCTOR_CASH_REGISTERS,
    UPDATE_INVOICE_PRINTED
} from '../../mutationTypes';

export default {
    [UPDATE_DOCTOR_INVOICES](state, invoices) {
        state.invoices = invoices;
    },
    [UPDATE_DOCTOR_PRESCRIPTIONS](state, prescriptions) {
        state.prescriptions = prescriptions;
    },
    [UPDATE_DOCTOR_PRESCRIPTION](state, prescription) {
        state.prescriptions = state.prescriptions.map(presc => presc.id === prescription.id ? prescription : presc);
    },
    [ADD_DOCTOR_PRESCRIPTION](state, prescription) {
        if (state.prescriptions.find(p => p.id === prescription.id) === undefined) {
            state.prescriptions.unshift(prescription);
        }
    },
    [UPDATE_DOCTOR_CASH_REGISTERS](state, cashRegisters) {
        state.cashRegisters = cashRegisters;
    },
    [ADD_DOCTOR_INVOICE](state, invoice) {
        if (state.invoices.find(i => i.id === invoice.id) == undefined) {
            state.invoices.push(invoice);
        }
    },
    [UPDATE_INVOICE_PRINTED](state, id) {
        state.invoices = state.invoices.map(invoice => invoice.id === id ? {...invoice, printed: '1'} : invoice);
    }
};