<template>
    <div class="container">
        <div v-if="!kiosk" class="row justify-content-center">
            <div class="col-md-6 mt-5 pt-5 text-center">
                <img :src="'./img/signature-logo.png'" alt="" class="signature-logo mt-5 pt-5">
                <h1 class="text-center mt-4">{{ $t('welcome.welcome') }}</h1>
                <p class="text-center">{{ $t('welcome.welcome_subtitle') }}</p>
            </div>
        </div>
        <div v-if="kiosk" class="row">
            <div v-if="kiosk.has_payments && !kiosk.has_appointments" class="col-md-12 py-5">
                <h1 class="text-center">{{ $t('welcome.pay_invoice') }}</h1>
                <p class="text-center">{{ $t('welcome.enter_codenumber_invoice') }}</p>
            </div>
            <div v-if="kiosk.has_appointments && !kiosk.has_payments" class="col-md-12 py-5">
                <h1 class="text-center">{{ $t('welcome.announce_arrival') }}</h1>
                <p class="text-center">{{ $t('welcome.enter_codenumber_appointment') }} </p>
            </div>
            <div v-if="kiosk.has_appointments && kiosk.has_payments" class="col-md-12 py-5">
                <h1 class="text-center">{{ $t('welcome.announce_or_pay') }}</h1>
                <p class="text-center">{{ $t('welcome.enter_codenumber_both') }}</p>
            </div>
        </div>
        <div v-if="notFound" class="row justify-content-center">
            <div class="col-md-7">
                <div class="alert alert-danger">
                    <div class="d-flex align-content-center">
                        <div class="flex-grow-1">
                            <strong><i class="fas fa-exclamation-triangle fa-fw" /> {{ $t('welcome.codenumber_not_found') }}</strong>
                            {{ $t('welcome.check_codenumber') }}
                        </div>
                        <div class="">
                            <button class="close" @click="notFound = false">
                                <span>&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="kiosk" class="row">
            <div class="col-md-4 offset-md-4">
                <keypad v-model="kennitala" />
            </div>
        </div>
        <lang />
        <logo v-if="kiosk" />
    </div>
</template>
<script>
import KioskApi from '@src/api/kiosk';
import Keypad from '@components/keypad/';
import Logo from '@components/kiosk/logo';
import Lang from '@components/kiosk/welcome/langchanger';
export default {
    components: {
        Keypad,
        Logo,
        Lang,
    },
    props: {},
    data() {
        return {
            kennitala: '',
            notFound: false
        };
    },
    computed: {
        kiosk() {
            return this.$store.state.kiosk;
        },
        patient() {
            return this.$store.state.patient;
        }
    },
    watch: {
        kennitala(value) {
            if (value.length == 10) {
                this.getPatient();
            }
        },
        patient(value) {
            this.onPatientChange();
        }
    },
    created() {
        
    },
    mounted() {
        this.getKiosk();
    },
    methods: {
        getKiosk() {
            KioskApi.getSessionKiosk().then(response => {
                this.$store.commit('setKiosk', response.data);
                if (response.data.is_signature_only) {
                    this.$router.push({name: 'signature-view'});
                }
            }).catch(err => {
                if (err.response.status === 404) {
                    this.$router.push({name: 'kiosk-picker'});
                }
            });
        },
        getPatient() {
            KioskApi.getPatient(this.kennitala).then(response => {
                this.$store.commit('setPatient', response);
            }).catch(() => {
                this.notFound = true;
            });
        },
        onPatientChange() {
            if (this.kiosk.has_payments && this.patient && this.patient.invoices.length > 0) {
                this.$router.push({name: 'payment'});
            } else {
                this.$router.push({name: 'appointment'});
            }
        }
    },
};
</script>
<style></style>