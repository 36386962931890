<template>
  <div class="journal-settings">
    <div class="mt-2">
      <v-select v-model="selectedTemplate" :items="templates" item-title="name" item-value="id"
        :label="$t('journal.setting.template')" variant="underlined" append-icon="mdi-plus"></v-select>
    </div>
    <div class="mt-6">
      <v-text-field v-model="searchQuery" ref="default" :label="$t('journal.setting.search')" clearable
        variant="underlined" />
    </div>
    <div class="mt-6">
      <h6>{{ $t('journal.setting.show') }}</h6>
      <div v-for="(text, index) in chiptext" :key="index" class="cfavouriteship-row margin-bottom-set">
        <v-chip v-if="selectedChips.includes(index)" color="primary" variant="outlined" append-icon=""
          @click="toggleChip(index)">
          {{ text }}
        </v-chip>
        <v-chip v-else color="primary" variant="elevated" @click="toggleChip(index)" append-icon="mdi-close"
          transparent="true">
          {{ text }}
        </v-chip>
      </div>
    </div>
    <div class="mt-10">
      <h6>{{ $t('journal.setting.show_external_data') }}</h6>
      <div v-for="(service, index) in externalServices" :key="index" class="chip-row margin-bottom-set">
        <v-chip v-if="service.active" color="primary" variant="elevated" @click="service.active = false"
          append-icon="mdi-close">
          {{ service.name }}
        </v-chip>
        <v-chip v-else color="primary" variant="outlined" @click="service.active = true">
          {{ service.name }}
        </v-chip>
      </div>
    </div>

    <div class="mt-10">
      <v-select v-model="sortBy" :items="[$t('journal.setting.date')]" :label="$t('journal.setting.sort_by')"
        :append-icon="sortorderIcon" @click:append="toggleSorting" variant="underlined" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onFocus() {
      if (this.$refs.default) {
        this.$refs.default.style.display = 'none';
      }
    },
    search() {
      this.$emit('search-query', this.searchQuery);
    },
    toggleChip(index) {
      if (this.selectedChips.includes(index)) {
        this.selectedChips = this.selectedChips.filter(chipIndex => chipIndex !== index);
      } else {
        this.selectedChips.push(index);
      }
      if (this.chiptext[index] === 'Notes') {
        this.$emit('hide-notes');
      }
      else if (this.chiptext[index] === 'Diagnoses') {
        this.$emit('hide-diagnoses');
      }
      else if (this.chiptext[index] === 'Measures') {
        this.$emit('hide-measure');
      }
      else if (this.chiptext[index] === 'Prescriptions') {
        this.$emit('hide-prescription');
      }
      else if (this.chiptext[index] === 'Vaccines') {
        this.$emit('hide-vaccines');
      }
      else if (this.chiptext[index] === 'Attentions') {
        this.$emit('hide-attentions');
      }
      else if (this.chiptext[index] === 'Warnings') {
        this.$emit('hide-warning');
      }
    },

    toggleSorting() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.$emit('update-sort-order', this.sortOrder);
    }

  },
  data() {
    return {
      showElevatedChip: null,
      showExternalData: false,
      externalServices: [{ name: 'Samtengt sjúkraskrá', active: false }],
      chiptext: [
        this.$t('journal.setting.admissions'),
        this.$t('journal.setting.attentions'),
        this.$t('journal.setting.documents'),
        this.$t('journal.setting.diagnoses'),
        this.$t('journal.setting.growth_charts'),
        this.$t('journal.setting.measures'),
        this.$t('journal.setting.notes'),
        this.$t('journal.setting.prescriptions'),
        this.$t('journal.setting.vaccines'),
        this.$t('journal.setting.warnings'),
      ], searchQuery: '',
      selectedChips: [],
      sortBy: 'Date',
      sortOrder: 'asc',
      sortorderIcon: 'mdi-sort-calendar-descending',
      templates: [
        { name: 'My Template', id: 0 },
        { name: 'My Template 2', id: 1 },
        { name: 'My Template 3', id: 2 },
      ],
      selectedTemplate: 0,

    };
  },
  removeChip(text) {
    const index = this.chiptext.indexOf(text);
    if (index !== -1) {
      this.chiptext.splice(index, 1);
    }
  },
  watch: {
    showExternalData(newValue) {
      if (!newValue) {
        this.externalDataUrl = '';
      }
    },

    searchQuery(newValue) {
      if (!newValue) {
        this.search();
      } else {
        this.search();
      }
    },
    sortOrder(newValue) {
      if (newValue === 'asc') {
        this.sortorderIcon = 'mdi-sort-calendar-ascending';
      } else {
        this.sortorderIcon = 'mdi-sort-calendar-descending';
      }
    },
  },
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
  text-decoration-color: grey;
  border: none;
  /* margin-right: 50%; */

}

#date-dropdown {
  border: none;
  border-bottom: 2px grey solid;
  width: 90%;
  background-color: transparent;
  margin-bottom: 20px;
}

#template-dropdown {
  border: none;
  border-bottom: 2px grey solid;
  width: 90%;
  background-color: transparent;
  margin-bottom: 20px;
}

.arrow-icon {
  position: absolute;
  top: calc(50% - 5px);
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.margin-bottom-set {
  margin-bottom: 7px;
}

.setting {
  margin-bottom: 16px;
}

.underline-container {
  display: flex;
  align-items: center;
}

.underline-input {
  /* flex: 1; */
  border: none;
  border-bottom: 2px solid gray;
  margin-bottom: 10px;
  background-color: transparent;
}

.search-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.search-button i {
  color: black;
}

.sort-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.sort-button i {
  color: black;
}

.setting {
  margin-bottom: 16px;
}

.select-container {
  position: relative;
}

.select-container::after {
  content: '';
  position: absolute;
  top: 25%;
  right: 15%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.form-control {}

.sorting-icon {
  margin-top: 4%;
}

.add-icon {
  margin-top: 4%;
}
</style>
