<template>
  <div class="tip-tap">
    <div v-if="editor">
      <!-- MARKS -->
      <div class="btn-group mr-2 mb-2">
        <button v-tooltip="'Bold'" :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()">
          <i class="fas fa-bold" />
        </button>
        <button v-tooltip="'Italic'" :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()">
          <i class="fal fa-italic" />
        </button>
        <button v-tooltip="'Underline'"
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()">
          <i class="fal fa-underline" />
        </button>
        <button v-tooltip="'Strikethrough'"
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()">
          <i class="fal fa-strikethrough" />
        </button>
      </div>
      <!-- HISTORY -->
      <div class="btn-group mr-2 mb-2">
        <button v-tooltip="'Undo'" class="btn btn-outline-primary" @click="editor.chain().focus().undo().run()">
          <i class="fal fa-undo" />
        </button>
        <button v-tooltip="'Redo'" class="btn btn-outline-primary" @click="editor.chain().focus().redo().run()">
          <i class="fal fa-redo" />
        </button>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import History from '@tiptap/extension-history';

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    initialize() {
      this.editor = new Editor({
        content: this.modelValue,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Strike,
          Underline,
          History,
        ],
        parseOptions: {
          preserveWhitespace: true,
        },
        onUpdate: () => {
          this.update();
        },
      });
    },
    update() {
      this.$emit('update:modelValue', this.editor.getHTML());
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.editor && this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue, false);
      }
    },
  },
};
</script>

<style scoped>
.tip-tap {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 200px;
}
</style>
