<template lang="html">
    <div class="form-group">
        <label-basic v-if="label !== undefined" :required="required">
            {{ label }}
        </label-basic>
        <textarea 
            class="form-control" 
            :class="{'is-invalid': error}"
            ref="text"
            :rows="rows" 
            :value="value"
            @input="onInput"
            :tabindex="tabindex"
        ></textarea>
    </div>
</template>
<script>
    import LabelBasic from '../labelBasic';

    export default {
        components: {
            LabelBasic
        },
        props: {
            value: {
                required: false,
                default: ''
            },
            required: {
                required: false,
                type: Boolean,
                default: false
            },
            rows: {
                required: false,
                type: Number,
                default: 3
            },
            error: {
                required: false,
                type: Boolean,
                default: false
            },
            label: {
                required: false,
                default: undefined,
            },
            tabindex: { 
                required: false,
                default: ''
            }
        },
        methods: {
            onInput() {
                if (this.$refs.text !== undefined) {
                    this.$emit('input', this.$refs.text.value);
                }
            }
        }
    }
</script>