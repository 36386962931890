import ApiBase from './apibase';

export default new class Kiosk extends ApiBase {

	/**
     * Find patient by codenumber
     * 
     * @param {String} codenumber
     * @returns {Promise}
     */
    getPatientByCodenumber(codenumber) {
        return this.getData(`patient/codenumber/${codenumber}`);
    }

    /**
     * Find patient by Id
     * 
     * @param {Integer} id 
     * @returns {Promise}
     */
    getPatientById(id) {
        return this.getData(`patient/${id}`);
    }
}