<template>
  <v-app>
    <v-toolbar color="transparent" class="pt-2">
      <v-app-bar-nav-icon class="ml-8 "></v-app-bar-nav-icon>
      <v-toolbar-title>{{ patient_name }} Journal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="IsEditorCollapsed" icon @click="showEditor">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid fill-height>
      <v-infinite-scroll :color="'primary'" :height="'100%'" :items="visibleGroupedByDateKeys" :onLoad="loadMore" :has-more="hasMore">
        <template v-for="(date, index) in visibleGroupedByDateKeys" :key="date">
          <journal-day :date="date" :entries="visibleGroupedByDate[date]" :journalData="visibleGroupedByDate[date]"
            @edit-diagnose-item="handleEditDiagnoseItem" @edit-attention-item="handleEditAttentionItem"  @edit-note-item="handleEditNoteItem"
            @edit-lab-item="handleEditlabResultItem" />
        </template>
      </v-infinite-scroll>

      <div v-if="!loading && !hasMore && journalData.length > 0" class="no-more-data text-center">
        <span class="text-h6">End of Journal Entries</span>
      </div>

    </v-container>
  </v-app>
</template>

<script>
import JournalDay from './journalday/journalday.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  props: {
    journalData: {
      type: Array,
      required: true,
    },
    sortOrder: {
      type: String,
      required: true,
    },
    IsEditorCollapsed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    JournalDay,
  },

  setup() {
    const store = useStore();
    const patient_name = computed(() => store.getters.currentPatientName);
    return {
      patient_name,
    };
  },
  data() {
    return {
      daysToShow: 1,  // Number of days to show initially
      loading: false,
      hasMore: true,
    };
  },
  computed: {
    // sort journalData by date
    sortedData() {
      let sortedData = this.journalData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
      if (this.sortOrder === 'desc') {
        sortedData.reverse();
      }
      return sortedData;
    },
    // Get the visible data grouped by date
    visibleGroupedByDate() {
      const groupedData = this.sortedData.reduce((grouped, item) => {
        let key = new Date(item.date).toISOString().split('T')[0];
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
      }, {});

      // Limit the number of days to show
      const visibleData = Object.keys(groupedData).slice(0, this.daysToShow).reduce((result, key) => {
        result[key] = groupedData[key];
        return result;
      }, {});

      return visibleData;
    },
    visibleGroupedByDateKeys() {
      return Object.keys(this.visibleGroupedByDate);
    }
  },
  methods: {
    handleEditDiagnoseItem(index) {
      this.$emit('edit-diagnose-item', index);
    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
    },
    handleEditNoteItem(item) {
      this.$emit('edit-note-item', item);
    },
    handleEditlabResultItem(item) {
      this.$emit('edit-lab-item', item);
    },
    showEditor() {
      this.$emit('show-editor');
    },
    // Async method to load more journal data for infinite scroll
    async loadMore({ done }) {
      const totalDays = Object.keys(this.sortedData.reduce((grouped, item) => {
        let key = new Date(item.date).toISOString().split('T')[0];
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
      }, {})).length;

      // Increase daysToShow by 3 if more days available, otherwise set hasMore to false
      if (this.daysToShow < totalDays) {
        this.daysToShow += 3;
        this.hasMore = true;
      } else {
        this.hasMore = false;
      }
      done('ok');
    },
  },
};
</script>
