import UserShow from '@components/userShow';
import UserEdit from '@components/userEdit';

export default [
    {
        path: '/user/show', 
        component: UserShow, 
        name: 'user-show'
    },
    {
        path: '/user/edit', 
        component: UserEdit, 
        name: 'user-edit' 
    }
]