import Reception from '@components/reception';
import Destinations from '@components/reception/destinations';
import ReceptionSignature from '@components/reception/ReceptionSignature';

export default [
    {
        path: '/reception', 
        component: Reception, 
        name: 'reception' 
    },
    {
        path: '/reception/destinations', 
        component: Destinations, 
        name: 'reception-destinations' 
    },
    {
        path: '/reception/signature',
        component: ReceptionSignature,
        name: 'reception-signature'
    }
]