export default class Sorter {
    /**
     * Sorter constructor for custom alphabet sorting.
     *
     * @param {String} alphabet 
     */
    constructor(alphabet) {
        this.alphabet = alphabet.split('').reduce(function (prev, c, i) {
            prev[c] = i;
            return prev;
        }, {});
    }

    /**
     * Get the alphabet value for a char.
     *
     * @param {String} c
     * @return {Number}
     */
    alphabetValue(c) {
        if (c === undefined) {
            return NaN;
        }
        let value = this.alphabet[c];
        return value !== undefined ? value : Object.keys(this.alphabet).length + c.charCodeAt(0);
    }

    /**
     * Character compare.
     *
     * @param {String} a
     * @param {String} b
     * @returns {Number}
     */
    compareChars(a, b) {
        return this.alphabetValue(a) - this.alphabetValue(b);
    }

    /**
     * String compare.
     *
     * @param {String} a
     * @param {String} b
     * @returns {Number}
     */
    compare(a, b) {
        const aStr = a + ''; // String cast
        const bStr = b + ''; // String cast
        const iters = Math.min(aStr.length, bStr.length);
        for (let i = 0; i < iters; ++i) {
            const cmp = this.compareChars(aStr[i], bStr[i]);
            if (cmp !== 0) {
                return cmp;
            }
        }
        return aStr.length - bStr.length;
    }
}
