<template>
    <div ref="container">
        <slot></slot>
        <div v-show="scrolling" ref="target">{{ message }}</div>
    </div>
</template>
<script>
    import scrollMonitor from 'scrollmonitor';

    export default {
        props: {
            message: {
                required: false,
                default: 'Sæki gögn...'
            },
            scrolling: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                watcher: undefined
            };
        },
        mounted() {
            let monitor = scrollMonitor.createContainer(this.$refs.container);
            this.watcher = monitor.create(this.$refs.target);
            this.watcher.enterViewport(() => {
               this.$emit('enterViewport');
            });
        }
    }
</script>
