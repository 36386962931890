import axios from 'axios';

window.updatePmoCurrents = (current_patient, current_unit) => {
    axios.get('pmo/statechange', { params: { current_patient, current_unit } });
};

window.bookingUpdate = (booking_data) => {
    axios.get('pmo/bookingupdate', { params: { booking_data } });
};

window.bookingUpdateNoData = (current_user, current_unit) => {
    axios.get('pmo/bookingupdatenodata', { params: { current_user, current_unit } });
};