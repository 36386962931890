<template>
  <v-list-item>
    <v-toolbar color="transparent">
      <v-avatar color="primary">
        <v-icon>mdi-calendar</v-icon>
      </v-avatar>
      <v-toolbar-title>
        <strong>{{ formattedDate }}</strong>
      </v-toolbar-title>
    </v-toolbar>
    <v-list>
      <journal-unit v-for="(unit, index) in unitDataVariable" :key="index" :journalData="unit" :unit="unit"  @edit-diagnose-item="handleEditDiagnoseItem" @edit-attention-item="handleEditAttentionItem" @edit-note-item="handleEditNoteItem" @edit-lab-item="handleEditlabResultItem"/>
    </v-list>
  </v-list-item>
</template>

<script>

import JournalUnit from '../journalunit/journalunit.vue';


export default {
  props: {
    journalData: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true
    }
  },
  components: {
    JournalUnit
  },
  computed: {
    formattedDate() {
      const months = [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
        'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
      ];
      const date = new Date(this.date);      
      const day = date.getDate(); 
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const monthName = months[parseInt(month, 10) - 1];
      return `${day} ${monthName} ${year}`;
    },
    unitDataVariable() {
     
      const grouped = [];
      let id 
      this.journalData.forEach(item => {
        const unitId = item.data.metadata.unit_id;
        if (!grouped[unitId]) {
          grouped[unitId] = {
            unit_name: item.data.metadata.unit_name,
            owner_name: item.data.metadata.owner_name,
            items: []
          };
        }
        grouped[unitId].items.push(item);
        id=unitId
      });
      return Object.values(grouped);
    }
  },
  methods: {
    handleEditDiagnoseItem(index) {
      this.$emit('edit-diagnose-item', index);

    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
    },
    handleEditNoteItem(item) {
      this.$emit('edit-note-item', item);
    },
    handleEditlabResultItem(item) {
      this.$emit('edit-lab-item', item);
    }
  }
};
</script>
