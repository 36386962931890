import attention from '@src/api/attention';
import { defineStore } from 'pinia';

export const useJournalStore = defineStore('journal', {
    state: () => ({
        view: {},
        settings: {},
        editor: {
            dataRowId: 1,
            templateId: undefined,
        },
        attentionItem:null,
        diagnoseItem:null,
        noteItem:null,
        labResultItem:null


    }),
    getters: {
        getEditorTemplateId: (state) => {
            return state.editor.templateId;
        },
        getAttentionItem: (state) => state.attentionItem,

        //for diagnose 

        getDiagnoseTemplateId: (state) => {
            return state.diagnose.templateId;
        },
        getDiagnoseItem: (state) => state.diagnoseItem,

         //for Note 

         getNoteTemplateId: (state) => {
            return state.note.templateId;
        },
        getNoteItem: (state) => state.noteItem,

      getlabResultItem: (state) => state.labResultItem

    },
    actions: {
        setEditorTemplateId: (templateId) => {
            this.editor.templateId = templateId;
        },
        setAttentionItem(item) {
            this.attentionItem = item; 
        },
        clearAttentionItem(){
            this.attentionItem = null;
        },

        //for diagnose
        setDiagnoseTemplateId: (templateId) => {
            this.diagnose.templateId = templateId;
        },
        setDiagnoseItem(item) {
            this.diagnoseItem = item; 
        },
        clearDiagnoseItem(){
            this.diagnoseItem = null;
        },

         //for Note
         setNoteTemplateId: (templateId) => {
            this.note.templateId = templateId;
        },
        setNoteItem(item) {
            this.noteItem = item; 
        },
        clearNoteItem(){
            this.noteItem = null;
        },
        
        //for lab result

        setlabResultItem(item) {
            this.labResultItem = item; 
        },
        clearlabResultItem(){
            this.labResultItem = null;
        }

    }
});