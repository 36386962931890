<template>
    <div>
        <span v-if="invoice" class="close cancel" @click="onCancel()">
            &times;
        </span>
        <logo v-if="!invoice && !signatureRequest" />
        <div v-if="invoice" class="container-fluid">
            <div class="row">
                <div class="col-sm-5 px-5 py-5">
                    <invoice :invoice="invoice" />
                </div>
                <div class="col-sm-7 p-5 bg-primary actions">
                    <div class="d-flex align-items-center justify-content-center">
                        <signature class="flex-fill" @signed="onSigned($event)" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="signatureRequest" id="signature-request" class="bg-primary">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-7 p-5">
                        <signature class="" @signed="onSigned($event)" />
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div v-if="error" class="alert alert-danger">
                        <i class="fas fa-exclamation-triangle fa-fw"></i> {{ error }}
                        <button class="close" @click="onReset()">
                            <span>&times;</span>
                        </button>

                    </div>
                    <div v-if="message" class="alert alert-success">
                        <i class="fas fa-check fa-fw"></i>{{ message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from './logo';
import Signature from '@components/kiosk/payment/signature';
import Invoice from '@components/kiosk/payment/invoice';
import KioskApi from '@src/api/kiosk';
import SignatureApi from '@src/api/signature';


export default {
    components: {
        Logo,
        Signature,
        Invoice
    },
    props: {},
    data() {
        return {
            signature: undefined,
            invoice: undefined,
            signatureRequest: undefined,
            error: undefined,
            message: undefined
        };
    },
    computed: {
        kiosk() {
            return this.$store.state.kiosk;
        }
    },
    watch: {
        kiosk(value) {
            if (value) {
                this.initializeSocketIo();
            }
        }
    },
    created() {
        if (this.kiosk) {
            this.initializeSocketIo();
        }
    },
    mounted() {},
    methods: {
        getInvoice(invoice_id) {
            KioskApi.getInvoiceDetails(invoice_id).then(response => {
                this.invoice = response.data;
            });
        },
        onSigned(value) {
            this.signature = value;
            if (this.invoice) {
                KioskApi.postSignature(this.invoice.id, {signature: value}).then(() => {
                    this.onReset();
                });
            } else if (this.signatureRequest) {
                this.signatureRequest['signature'] = value;
                SignatureApi.storeSignature(this.signatureRequest).then(response => {
                    this.message = response.message;
                    this.onReset();
                }).catch(err => {
                    this.error = err.response.data.message;
                });
            }
            
        },
        initializeSocketIo() {
            // eslint-disable-next-line no-undef
            let channel = `${REDIS_KEY}.kiosk.${this.kiosk.id}`;
            
            window.Echo.channel(channel).listen('RequestInvoiceSignatureEvent', e => {
                this.getInvoice(e.invoice_id);
            });
            window.Echo.channel(channel).listen('RequestSignatureEvent', e => {
                this.signatureRequest = e.signature_data;
            });
        },
        onCancel() {
            this.invoice = undefined;
        },
        onReset() {
            this.invoice = undefined;
            this.signatureRequest = undefined;
            this.message = undefined; 
            this.error = undefined;
        }
    },
};
</script>
<style>
#signature-view-logo , 
#signature-request {
    height: 100vh;
}
#signature-request {
    padding-top: calc(50vh - 232px);
}
.actions {
    min-height: 100vh;
}
.actions > div {
    min-height: 100%;
}
.cancel {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 200;
}
</style>