// Configure doctor module
import getters from './getters';
import mutations from './mutations';

export default {
    state: {
        list: [],
    },
    getters,
    mutations
};