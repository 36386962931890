<template>
    <div>
      <div>
        <div class="form-group">
          <div class="d-flex justify-content-center">
            <canvas
              id="cnv"
              ref="cnv"
              class="border border-secondary"
              :width="width"
              :height="height"
              name="cnv"
            />
          </div>
        </div>
        <div class="form-group">
          <p class="text-center"><i class="fal fa-info-circle" /> {{ $t('signature.patient_guardian_signature') }}</p>
        </div>
      </div>
      <button
        class="btn btn-outline-secondary float-right"
        @click="onClear"
      ><i class="fal fa-times" /> {{ $t('signature.clear') }}</button>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import SignatureTablet from '../../classes/signatureTablet';
  
  export default defineComponent({
    props: {
      width: {
        type: Number,
        default: 500
      },
      height: {
        type: Number,
        default: 100
      },
      refresh: {
        type: Number,
        default: 100
      }
    },
    mounted() {
      SignatureTablet.setContext(this.$refs.cnv.getContext('2d'));
      try {
        this.start();
      } catch (err) {
        this.emitError(err);
      }
    },
    beforeUnmount() {
      try {
        this.stop();
      } catch (err) {
        this.emitError(err);
      }
    },
    methods: {
      onClear() {
        SignatureTablet.clear();
      },
      start() {
        SignatureTablet.clear();
        SignatureTablet.start(this.refresh);
      },
      stop() {
        SignatureTablet.stop();
      },
      emitError(err) {
        this.$emit('error', err);
      }
    }
  });
  </script>
  
  <style lang="scss" scoped>
  #cnv {
    display: block;
  }
  </style>
  