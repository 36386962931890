<template>
    <div>
      <div class="nav-container no-print">
        <Navbar v-if="showNavbar" class="no-print" />
        <Notifications></Notifications>
      </div>
      <router-view :class="{'navbar-align': showNavbar}" />
    </div>
  </template>
  
  <script>
  import Navbar from '@components/navbar/navbar';
  import { defineComponent } from 'vue';
  import {  
      GET_CURRENT_PATIENT, 
      GET_DOCTORS_RECENT_PRESCRIPTIONS, 
      GET_DOCTORS_INVOICES_TODAY, 
      GET_IS_CLIENT,
      GET_SIGNATURE_TYPE
  } from '../../store/actionTypes';
  import { useStore } from 'vuex';
  import { ADD_NOTIFICATION } from '@src/store/mutationTypes';
  import Notifications from '@components/kiosk/notification/notifications/notifications';
  import Echo from 'laravel-echo';
  
  
  export default defineComponent({
    components: { 
      Navbar,
      Notifications
    },
    setup() {
      const store = useStore();
  
      const showNavbar = () => {
        return !store.state.print;
      };
  
      const watcherHandle = undefined;
  
      const registerWatcher = () => {
        watcherHandle = store.watch((state, { userExists }) => userExists, value => {
          if (!value) {
            emitLogout();
          }
        });
      };
  
      const emitLogout = () => {
        this.$emit('loggedOut');
      };
  
      const onkeydown = (event) => {
        if(event.keyCode == 112) {
          event.preventDefault();
          router.push({name: 'invoice-create'});
        } else if(event.keyCode == 113) {
          event.preventDefault();
          router.push({name: 'presc-create'});                    
        } else if(event.keyCode == 114) {
          event.preventDefault();
          router.push({name: 'presc-registry'});                   
        } else if(event.keyCode == 115) {
          event.preventDefault();
          router.push({name: 'patient-index'});              
        }
      };
  
      const mounted = () => {
        registerWatcher();
        store.dispatch(GET_CURRENT_PATIENT);
        if (this.$route.query.client != 'true'){
          store.dispatch(GET_DOCTORS_RECENT_PRESCRIPTIONS, store.getters.currentUserID);
          store.dispatch(GET_DOCTORS_INVOICES_TODAY, store.getters.currentUserID);
        }
        if(!store.state.debug) {
          document.addEventListener('contextmenu', function(e) {
            e.preventDefault();
          }, false);
        }
        document.addEventListener('keydown', onkeydown, false);
        
        store.dispatch(GET_IS_CLIENT);
  
        store.dispatch(GET_SIGNATURE_TYPE);
  
        // Listen for notifications
        window.Echo
          .private(`patientArrived.` + store.getters.currentUserID)
          .listen('PatientArrivedEvent', (e) => {
            let text = `${e.data.patientName} með tíma kl ${e.data.appStart} er mættur.`;
            store.commit(ADD_NOTIFICATION, {
              title: 'Sjúklingur mættur!',
              text: text,
              type: 'patient-arrived',
              timeout: true,
              delay: 600000
            });
          });
      };
  
      const beforeUnmount = () => {
        if (watcherHandle) {
          watcherHandle();
        }
        document.removeEventListener('keydown', onkeydown);
        if(store.state.debug) {
          document.removeEventListener('contextmenu');
        }
      };
  
      return { showNavbar, mounted, beforeUnmount };
    }
  });
  </script>
  
  <style>
  .nav-container {
    display: flex;
    position: fixed;
    justify-content: flex-end;
    top: 5rem;
    right: 2rem;
    z-index: 10;
  }
  </style>
  