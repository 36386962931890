<template>
    <form
      class="form-horizontal"
      role="form"
      method="POST"
      @submit.prevent="submitLogin"
    >
      <alert-error v-if="error" :header="$t('login.authentication')" />
      <text-input
        v-model="username"
        :error="error"
        :required="true"
        :autofocus="true"
        :placeholder="$t('login.username')"
      />
      <text-input
        v-model="password"
        :error="error"
        :required="true"
        :placeholder="$t('login.password')"
        type="password"
      />
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">{{ $t('login.sign_in') }}</button>
      </div>
    </form>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import TextInput from '../textInput';
  import AlertError from '../alertError';
  import { PASSWORD_LOGIN } from '@src/store/actionTypes';
  
  export default defineComponent({
    components: {
      TextInput,
      AlertError,
    },
    setup() {
      const username = ref('');
      const password = ref('');
      const error = ref(false);
      const store = useStore();
  
      const submitLogin = () => {
        store.dispatch(PASSWORD_LOGIN, {
        username: username.value,
        password: password.value,
      }).catch(() => {
        this.error = true;
        // Handle error
      });
      };
      return {
        username,
        password,
        error,
        submitLogin,
      };
    },
  });
  </script>
