<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <h1>{{ $t('invoice_create.edit') }}</h1>
                <form class="form" @submit.prevent="onSubmit">
                    <div class="form-group">
                        <label>{{ $t('prescription.email') }}</label>
                        <input type="text" name="email" v-model="email" class="form-control">
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-success">{{ $t('access_control.save') }}</button>
                        <router-link :to="{name: 'user-show'}" class="btn btn-default">{{ t('navbar.back') }}</router-link>
                    </div>
                    <div class="form-group">
                        <div class="alert alert-info">
                            <p><strong>eGátt</strong> {{ $t('prescription.user_information') }} <strong>PMO</strong>. {{ $t('prescription.user_permission') }}<strong>{{ $t('prescription.email_address') }}</strong> {{ $t('prescription.contact_institution') }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import { UPDATE_CURRENT_USER_EMAIL } from '../../store/actionTypes';

    export default {
        data() {
            return {
                email: ''
            }
        },
        mounted() {
            this.email = this.$store.getters.currentUserEmail;
        },
        methods: {
            t(key) {
            return this.$t(key);
        },
            onSubmit() {
                this.$store.dispatch(UPDATE_CURRENT_USER_EMAIL, this.email).then(res => {
                    this.$router.push({ name: 'user-show' });
                }).catch(err => {
                    // Some err
                });
            }
        }
    }
</script>