<template>
  <div class="col-xl-2 col-md-3 col-sm-6 p-1">
      <button class="btn btn-block" :class="{ 'btn-light': !visible, 'btn-outline-primary': visible, 'active': selected }" @click="onClick">
          <i class="far" :class="{ 'fa-eye': visible, 'fa-eye-slash': !visible }" /> {{ text }}
      </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
      text: {
          type: String,
          default: ''
      },
      visible: {
          type: Boolean,
          default: true
      },
      selected: {
          type: Boolean,
          default: false
      }
  },
  methods: {
      onClick() {
          this.$emit('click');
      }
  }
});
</script>
